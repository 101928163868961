.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .button-container button {
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  
  .image-container {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }