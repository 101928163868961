.page-content {
    margin-top: 10px;
}

.styles_auctionsList__i4K01 {
    display: grid;
    flex-wrap: wrap;
}

.styles_listItemContainer__34R8_ {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(78, 78, 78, .141);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.styles_listImg__4ia1l {
    width: 168px;
    height: 94px;
}

.styles_listImg__4ia1l .styles_imageWrapper__WhasL {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 6px 0 0 6px;
    overflow: hidden;
}

.styles_listImg__4ia1l .styles_imageWrapper__WhasL .styles_cardImg__peZtj {
    width: 100%;
    height: 100%;
}

.styles_addToFavorite__O4hNZ {
    position: absolute;
    top: 9px;
    left: 10px;
    z-index: 10;
}

.styles_privateWrapper__0efy_, .styles_wrapper__ofM1O {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.styles_listIcon__KeCMP {
    width: 24px;
    height: 24px;
    margin: 0 0 2px;
    padding: 4px;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .24);
    background-color: rgba(64, 64, 64, .6);
}

.styles_smallIcon__drxJ4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    cursor: pointer;
}

.styles_listIcon__i__CM {
    width: 24px;
    height: 24px;
    margin: 0 0 24px;
    padding: 4px;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .24);
    background-color: rgba(64, 64, 64, .6);
}

.styles_smallOption__6M8gc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    cursor: pointer;
}

.styles_listDetailsProperty__qUkgq, .styles_listDetails__Du48R {
    position: relative;
    height: 94px;
    display: flex;
    padding: 8px 16px 7px;
    flex-direction: column;
    flex: 1;
}

.styles_exactDotsMenu____Rfo {
    position: absolute;
    top: 8px;
    right: 16px;
}

.styles_smallIcon__0BJ8U {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    background-color: #fff;
    cursor: pointer;
}

.styles_listStatus__BH4qo {
    display: flex;
    flex: 1;
    position: absolute;
    bottom: 37.5px;
    right: 16px;
}

.styles_cardTitleLarge__GN874 {
    display: flex;
    width: fit-content;
    max-width: 400px;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.styles_cardTitle__yML2U {
    max-width: 100%;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 0 6px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    letter-spacing: normal;
    color: #404040;
} 

.styles_productStatusProperty__iZpAt, .styles_productStatus__Aiv_Y {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    gap: 5.7px;
}

.styles_cartBadge__yFUpO {
    height: 20px;
    color: #404040;
    display: flex;
    padding: 5px 6.3px 5.1px 6px;
    align-items: center;
    border-radius: 2px;
    text-transform: capitalize;
    justify-content: center;
    background-color: #e8eaed;
    inset-inline-start: -4px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17 !important;
    letter-spacing: normal;
    color: #2b2f33;
    margin-bottom: 5.5px;
}

.styles_productStatus__3yo_H {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5.5px    ;
}

.styles_productStatus__3yo_H .styles_labelStatus__LlNtn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 63px;
    height: 20px;
    border-radius: 2px;
    padding: 4px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #404040;
    cursor: pointer;
}

.styles_wrapper__4w_5e {
    display: flex;
    gap: 50px;
    align-items: center;
    border-top: 1px solid #dedede;
}

.styles_wrapper__4w_5e :first-child{
    margin-inline-start: -4px;
}

.styles_listWrapper__mlWdq {
    padding: 7.5px 8px;
    color: #2c2c2c;
}

.styles_wrapper__6swlq {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 7.5px 0;
    gap: 4px;
    color: #2c2c2c;
}

.styles_productStatus__3yo_H .styles_tagImg__WItpE {
    margin-inline-end: 3px;
}

.styles_wrapper__6swlq .styles_text__GSIbG {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9f9f9f;
}

.styles_wrapper__6swlq .styles_listData__5uy5S {
    font-size: 14px;
    margin-left: 5px;
}

.styles_wrapper__6swlq .styles_data__HPULm {
    font-size: 10px;
}

.styles_wrapper__6swlq .styles_data__HPULm, .styles_wrapper__6swlq .styles_largeData__fFCxz {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.styles_wrapper__4w_5e .styles_divider__mLML4 {
    background: #dedede;
    width: 1px;
    height: 56%;
}

.styles_listWrapper__mlWdq {
    padding: 7.5px 8px;
    color: #2c2c2c;
}

.styles_wrapper__6swlq {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 7.5px 0;
    gap: 4px;
    color: #2c2c2c;
}

.styles_black__BBy8T {
    margin-left: -20px;
    opacity: 0;
}

.styles_wrapperList__KKOXU {
    width: 194px;
    height: 94px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    border-radius: 0 6px 6px 0;
}

.styles_smallPrice__mlvXY {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    color: #404040;
}

.styles_wrapper__4w_5e .styles_biddingCount__xU8XR {
    padding-inline-start: 4px;
}

.styles_smallPrice__mlvXY .styles_currency__qIwfP {
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -.42px;
    color: #838383;
}

.styles_smallPrice__mlvXY span {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -.62px;
}

.styles_btn__KTmso, .styles_btn__KTmso:hover {
    background-image: golden;
}

.styles_btn__KTmso {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 100%;
    height: 34px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
}

.biddingIconsContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
}

.styles_white__RZ_zn {
    opacity: 1;
    margin-inline-end: 18px;
}

.styles_black__tl8HL {
    opacity: 0;
    position: absolute;
    margin-inline-end: 18px;
}

@media (max-width: 1280px) {
    .styles_gridCardWrapper__t5glg {
        grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
        gap: 16px;
    }

    .styles_listImg__4ia1l {
        width: 100%;
    }

    .styles_wrapper__4w_5e {
        gap: 16px;
    }

    .styles_wrapper__6swlq .styles_text__GSIbG,
    .styles_wrapper__6swlq .styles_listData__5uy5S {
        font-size: 12px;
    }
}

@media (max-width: 1440px) {
    .styles_cardTitleLarge__GN874 {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .styles_listImg__4ia1l {
        width: 110px;
        height: auto;
    }

    .styles_wrapperList__KKOXU {
        width: calc(100% - 110px);
        height: auto;
        position: absolute;
        bottom: 0;
        right: 0;
        border-color: transparent;
        padding: 4px 8px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 0;
        border-radius: 0;
    }
}

@media (max-width: 820px) {
    .styles_listDetailsProperty__qUkgq, .styles_listDetails__Du48R {
        position: static;
        width: 100%;
        height: 110px;
        justify-content: flex-start;
        padding: 8px 8px 0;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .styles_exactDotsMenu____Rfo {
        right: 8px;
    }

    .styles_listStatus__BH4qo {
        top: -8px;
        bottom: unset;
        left: 28px;
        right: unset;
    }

    .styles_cardTitleLarge__GN874 {
        font-size: 14px;
        max-width: 198px;
        margin: 0 0 4px;
    }

    .styles_cartBadge__yFUpO {
        line-height: normal !important;
        height: 20px;
        font-size: 10px;
        font-weight: 500;
        padding: 3px 5px;
        margin-bottom: 7.5px;
    }

    .styles_productStatus__3yo_H {
        margin-top: 0;
    }

    .styles_productStatus__3yo_H .styles_labelStatus__LlNtn {
        font-size: 9px !important;
        height: 19px;
        margin-bottom: 0;
    }

    .styles_wrapper__4w_5e {
        gap: 7.5px;
        flex-wrap: wrap;
        justify-content: flex-start;
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        padding: 0;
    }

    .styles_listWrapper__mlWdq {
        padding: 3px 6px;
    }

    .styles_wrapper__6swlq {
        gap: 2px;
        flex-wrap: nowrap;
    }

    .styles_wrapper__6swlq .styles_listData__5uy5S {
        font-size: 12px;
    }

    .styles_wrapper__4w_5e :first-child {
        margin-inline-end: 4px;
    }

    .styles_large__zr9ao {
        width: max-content !important;
        font-size: 12px;
        min-width: 90px;
    }

    .styles_listWrapper__mlWdq {
        padding: 3px 6px;
    }

    .styles_wrapperList__KKOXU {
        width: calc(100% - 110px);
        height: auto;
        position: absolute;
        bottom: 0;
        right: 0;
        border-color: transparent;
        padding: 4px 8px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 0;
        border-radius: 0;
    }

    .styles_smallPrice__mlvXY {
        gap: 3px;
        margin: 0;
    }
    .styles_smallPrice__mlvXY .styles_currency__qIwfP {
        font-size: 9px;
    }

    .styles_smallPrice__mlvXY span {
        font-size: 12px;
        line-height: normal;
    }

    .styles_btn__KTmso {
        width: unset;
        min-width: 87px;
        height: 24px;
        font-size: 12px;
    }

    .styles_btn__KTmso img {
        width: 12px;
        height: 12px;
    }

    .styles_white__RZ_zn {
        margin-inline-end: 8px;
    }

    .styles_black__tl8HL {
        margin-inline-end: 8px;
    }
}

@media (max-width: 575.98px) {
    .styles_cardTitle__yML2U {
        font-size: 14px;
    }
}

/* 
.card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card-container {
    outline: 0px;
    box-shadow: rgba(78, 78, 78, 0.14) 0px 5px 15px;
    border-radius: 10px;
    overflow: hidden;
    width: 300px;
    margin: 20px;
    background-color: white;
  }
  
  .card-pinned {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .card-media {
    position: relative;
  }
  
  .card-image-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .card-img-wrapper img {
    width: 100%;
    height: auto;
  }
  
  .lot-container {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .lot-wrapper {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .card-options-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .card-options {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .large-option img, .large-icon img {
    width: 20px;
    height: 20px;
  }
  
  .card-content-container {
    padding: 20px;
  }
  
  .card-content {
    margin-bottom: 10px;
  }
  
  .card-title {
    font-size: 1.2em;
    margin: 0;
  }
  
  .product-status {
    display: flex;
    align-items: center;
  }
  
  .label-status {
    display: flex;
    align-items: center;
    background-color: rgba(26, 63, 63, 0.247);
    color: rgb(63, 63, 63);
    margin-inline-end: 6px;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .label-status img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
  
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .wrapper-item {
    display: flex;
    align-items: center;
  }
  
  .data .small {
    display: flex;
  }
  
  .bidding-count {
    display: flex;
    align-items: center;
  }
  
  .largeData {
    margin-left: 5px;
  }
  
  .largePrice {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .currency {
    font-size: 0.8em;
  }
  
  .largeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .largeBtn:hover {
    background-color: #c9302c;
  }
  
  .biddingIconsContainer {
    display: flex;
    align-items: center;
  }
  
  .iconWhite, .iconBlack {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
   */