@import url('https://fonts.googleapis.com/css?family=Public%20Sans:300,400,500,600,700,800,900&amp;display=swap');

/** 
* 
* -----------------------------------------------------------------------------
*

*
* -----------------------------------------------------------------------------
* 
**//* TABLE OF CONTENT
---------------------------------------------------------
01. General CSS
02. Global Class CSS
03. Header Section CSS
04. Sticky Menu CSS
05. About Section CSS
06. Ebook Read Section CSS
07. Feature Section CSS
08. Breadcrumbs Section CSS
09. Banner Section CSS
10. Slider Section CSS
11. Cta Section CSS
12. Newsletter Section CSS
13. Counter Section CSS
14. Skillbar Section CSS
15. Contact Section CSS
16. Video Section CSS
17. Choose Section CSS
18. Services Section CSS
19. Faq Section CSS
20. Team Section CSS
21. Process Section CSS
22. Partner Section CSS
23. Pricing Section CSS
24. Project Section CSS
25. Blog Section CSS
26. Tab Section CSS
27. Testimonial Section CSS
28. Shop Section CSS
29. Single Shop Section CSS
30. Cart Section CSS
31. Checkout Section CSS
32. Account Section CSS
33. Footer Section CSS
34. Scroll Up CSS
35. Woocommerce Section CSS
36. Preloader CSS
37. Animation Part CSS


--------------------------------------------------------*/
/* -----------------------------------
	01. General CSS
-------------------------------------*/
html,
body {
  font-size: 16px;
  color: #3e3e3e;
  font-family: 'Public Sans', sans-serif;
  vertical-align: baseline;
  line-height: 1.7;
  font-weight: 400;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  margin: 0 0 26px;
  line-height: 1.8;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Public Sans', sans-serif;
  color: #080d41;
  margin: 0 0 26px;
  line-height: 1.2;
}
h1 {
  font-size: 52px;
  line-height: 62px;
  font-weight: 700;
}
h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}
h3 {
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
}
h4 {
  font-size: 26px;
  line-height: 36px;
  font-weight: 700;
}
h5 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
h6 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}
ul.cnt2 li {
    display: inline-block;
    font-size: 14px;
    list-style-type: none;
    padding: 5px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: #fff;
    text-align: center;
    font-weight: 500;
}
ul.cnt22 li {
    display: inline-block;
    font-size: 10px;
    list-style-type: none;
    padding: 5px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: #0D4058;
    text-align: center;
    font-weight: 600;
}
ul.cnt2 li span {
  display: block;
  text-align: center;
  margin-bottom: 9px;
}
ul.cnt22 li span {
  display: block;
  text-align: center;
  margin-bottom: 9px;
}
h4.sif {
    letter-spacing: -0.005em;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}
.al-ri {
	text-align: right;
}
.al-lef {
	text-align: left;
}
.linth {
	color: rgba(41, 37, 37, 0.79);
    font-size: 20px;
    font-weight: 500;
    text-decoration: line-through;
    margin-top: 60px;
}
.yellin {
	color: #C69944;
    font-size: 28px;
    font-weight: 700;
}
.brdor {
    border-radius: 9px;
    border: 2px solid #C69944;
    padding: 60px;
}
h4.bgohe {
    color: #C69944;
    font-family: EB Garamond;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%;
    letter-spacing: 1.04px;
    margin-bottom: 10px;
}
p.bgo-det {
    color: #292525;
    /* font-family: Inter; */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 155%;
    letter-spacing: 0.42px;
}
span.bgop {
    color: #C69944;
    /* font-family: Inter; */
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 165.5%;
    letter-spacing: 0.88px;
    margin-left: 10px;
}
img.bgoim {
    margin-top: 5px;
}
.infotex {
	    color: #292525;
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 165.5%;
    letter-spacing: 0.64px;
	margin-bottom: 15px;
}
img.carsr {
    margin-top: -6px;
}
h4.ticku {
    color: #292525;
    /* font-family: Inter; */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.42px;
	margin-bottom: 0;
}
h4.srpkhe {
    color: #FFF;
    font-family: EB Garamond;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: #C69944;
    padding: 10px 70px;
    border-radius: 0 0 0 15px;
	    margin-right: -15px;
    margin-left: -15px;
}
h4.srpkhe2 {
    color: #FFF;
    font-family: EB Garamond;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: #C69944;
    padding: 10px 20px;
	    margin-right: -15px;
    margin-left: -15px;
}
h4.srpkhe3 {
    color: #FFF;
    font-family: EB Garamond;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: #C69944;
    padding: 10px 70px;
    border-radius: 0 0 15px 0;
	    margin-right: -15px;
    margin-left: -15px;
}
.frmi {
    background: #0D4058;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    padding: 15px;
	border-radius: 5px;
}
.brdi {
    background: rgba(252, 252, 252, 0.56);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    padding: 10px;
    border-radius: 5px;
}
.frm-text {
	display: inline-block;
}
.frmbtn {
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    background: #C69944;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 15px;
}
.frm-text {
    color: #FFF;
    /* font-family: Inter; */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
}
.frmtph {
    font-size: 20px;
    padding-right: 10px;
    line-height: 20px;
    /* transform: rotate(265deg); */
}
.frm-icon {
	display: inline-block;
}


.investsq {
  position: relative;
}
.invesdot:before {
    content: url(assets/img/dot.png);
    border: 0;
    color: transparent;
    /* display: block; */
    /* width: 15px; */
    line-height: 30px;
}
.taxido {
	color: #0D4058;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 165.523%; /* 33.105px */
letter-spacing: 0.8px;
}
.whitenum {
	color: rgba(255, 255, 255, 0.81);
    /* font-family: Inter; */
    font-size: 61.474px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.229px;
}
.whtbx {
    background: rgba(217, 217, 217, 0.24);
    padding: 20px;
	min-height: 260px;
}
.oggbx {
    border-radius: 8px;
    background: #C69944;
    box-shadow: 0px 4px 25px 0px rgba(39, 38, 37);
	padding: 25px;
	min-height: 200px;
}
.oggbx:hover {
	background: #987532;
}
.whtbx:hover {
	background: rgb(217 217 217);
}
h4.arwhe {
    color: rgba(13, 64, 88, 0.09);
    /* font-family: Inter; */
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: 165.5%;
    letter-spacing: 2.24px;
    /* text-align: right; */
}
.appt-bx {
    border-radius: 21px;
    border: 2px solid #C69944;
    background: #F6F6F6;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 25px 35px 25px;
	margin-bottom: 30px;
}
p.appt-det {
    color: #494949;
    text-align: center;
    /* font-family: Lora; */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 146.523%;
    letter-spacing: 0.49px;
}
h4.appt-he {
    color: #383333;
    text-align: center;
    font-family: Lora;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 146.523%;
    letter-spacing: 0.7px;
    margin-bottom: 10px;
}
h4.appt-nm {
    color: rgba(198, 153, 68, 0.75);
    /* text-align: center; */
    font-family: Lora;
    font-size: 43.75px;
    font-style: normal;
    font-weight: 700;
    line-height: 146.523%;
    letter-spacing: 1.531px;
    margin-bottom: -20px;
}
.yout img {
    border-radius: 13px;
    border: 11px solid #C69944;
}
.yout {
    text-align: center;
    padding: 30px 0;
}
.appt-bx img {
    width: 60px;
}
.appt-bx {
    min-height: 310px;
}
.regs-he {
    color: #0D4058;
    font-family: EB Garamond;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.12px;
    margin-bottom: 25px;
}
.yelr {
    background: #C69944;
    color: #fff;
    padding: 10px 7px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 165%;
    letter-spacing: 0.8px;
    text-align: center;
}
.bluer {
	 background: #0D4058;
    color: #fff;
    padding: 10px 7px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 165%;
    letter-spacing: 0.8px;
    text-align: center;
}
.tbimpd {
	    padding: 10px 15px;
}
h4.rgsty {
    color: #0D4058;
    /* font-family: Inter; */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 165%;
    letter-spacing: 0.8px;
    margin-bottom: 20px;
}








.rs-project.project-style1.project-modify3 .slider .slick-arrow {
    border: 2px solid #C69944 !important;
}
.rs-project.project-style1.project-modify3 .slider .slick-arrow.slick-prev:before, .rs-project.project-style1.project-modify3 .slider .slick-arrow.slick-next:before {
    color: #C69944;
}
.rs-project.project-style1 .slider .slick-arrow.slick-prev {
    right: 21.2% !important;
}
.rs-project.project-style1.project-modify3 .slider .slick-arrow:hover {
    background: #C69944 !important;
    border: 1px solid #C69944 !important;
	color: #fff !important;
}
.oggbh img {
    margin-top: -10px;
}
h4.oggbx-he {
    display: inline-block;
    padding-left: 20px;
	margin-bottom: 15px;
}
.oggbx-he {
	color: #FFF;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 165.5%; /* 39.72px */
letter-spacing: 0.96px;
}
.whtbx img {
    margin-top: 24px;
	width: 45px;
}
.whtbx h4 {
    color: #0D4058;
    font-family: EB Garamond;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.84px;
    margin-top: 30px;
    margin-bottom: 10px;
}
.whtbx p {
    color: rgba(41, 37, 37, 0.80);
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    margin-bottom: 5px;
}
.inveshe {
	    color: #0D4058;
    /* font-family: Inter; */
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.96px;
	margin-bottom: 10px;
	margin-top: -13px;
}
.invesdeta {
	color: #292525;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 165.523%; /* 26.484px */
letter-spacing: 0.64px;
}
.investsq::before {
    content: "";
    /* background-image: url(assets/img/investment-1p.png); */
    /* background-size: cover; */
    background-position: right top;
    position: absolute;
    top: -8px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
}
.investsq2 {
	position: relative;
}
.investsq2::before {
    content: "";
    /* background-image: url(assets/img/investment-1p.png); */
    /* background-size: cover; */
    background-position: left bottom;
    position: absolute;
    bottom: -8px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
}
.investsq3 {
	position: relative;
}
.investsq3::before {
    content: "";
    /* background-image: url(assets/img/investment-1p.png); */
    /* background-size: cover; */
    background-position: right bottom;
    position: absolute;
    bottom: -8px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
}
.fillorg {
	position: relative;
}
.fillorg::before {
    content: "";
    width: 300px;
    height: 300px;
    border-radius: 5px;
    background: #C69944;
    background-position: right bottom;
    position: absolute;
    bottom: -15px;
    left: 20px;
    z-index: -1;
    background-repeat: no-repeat;
}
.fillorg2 {
	position: relative;
}
.fillorg2::before {
    content: "";
    width: 300px;
    height: 300px;
    border-radius: 5px;
    background: #C69944;
    background-position: right bottom;
    position: absolute;
    bottom: -15px;
    right: 20px;
    z-index: -1;
    background-repeat: no-repeat;
}
.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
    width: 60px !important;
}
.select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 38px !important;
    border-radius: 0 4px 4px 0 !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 0px !important;
    user-select: none;
    -webkit-user-select: none;
}
.modal-header {
    background: #d4aa59;
    color: #fff;
	text-align: center;
}
button.btn.btn-danger {
    background: #d4aa59;
    border-color: #d4aa59;
    margin-right: auto;
    margin-left: auto;
}
.infotex img {
    transition: transform .7s;
}
.infotex img:hover {
    transform: scale(1.2);
	cursor: pointer;
}
h4.modal-title {
    color: #fff;
	font-family: EB Garamond;
	font-size: 22px;
	letter-spacing: 0.7px;
    
}
h4.touchi {
    color: #FFF;
    font-family: EB Garamond;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.28px;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 10px;
}
form.frmides label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
}
form.frmides input {
    border-radius: 4px;
    background: #F9F9F9;
    /* height: 38px; */
    margin-bottom: 10px;
}
.imgcen {
	text-align: center;
}
.rowtiku {
    border-bottom: 1px solid #D1D1D1;
    padding: 10px 15px;
}
.rowhead {
background: #C69944;
    padding: 10px 15px;
}
.rowhead h4 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    color: #fff;
    margin-bottom: 5px;
}
.infotex img {
    width: 21px;
    margin-left: 15px;
}
.num-bx {
	border-radius: 9px;
    border: 1.5px solid #C69944;
    padding: 20px;
}
.dark-bx {
    background: #0D4058;
    box-shadow: 0px 4px 30px rgba(198, 153, 68, 0.8);
    border-radius: 8px;
    padding: 20px;
	-webkit-animation: .5s infinite alternate glow;
    animation: .5s infinite alternate glow;
}
@keyframes glow {
	0% {
    -webkit-box-shadow: 0 0 10px -10px rgba(198, 153, 68, 0.8);
    box-shadow: 0 0 10px -10px rgba(198, 153, 68, 0.8);
}
100% {
    -webkit-box-shadow: 0 0 10px 10px rgba(198, 153, 68, 0.8);
    box-shadow: 0 0 10px 10px rgba(198, 153, 68, 0.8);
}
}

.sli-he {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 129.5%;
    letter-spacing: 0.04em;
    color: #0D4058;
	    margin-bottom: 25px;
}
.ser-he {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 129.5%;
    letter-spacing: 0.04em;
    color: #0D4058;
	    margin-bottom: 25px;
		margin-top: 30px;
}
.fly {
	    background: #C69944;
		border: 1.8px solid #C69944;
    border-radius: 4px;
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    /* padding: 20px; */
    width: 66px;
    height: 56px;
}
.spano {
	border: 1.8px solid #C69944;
    border-radius: 4px;
    width: 66px;
    height: 56px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    font-size: 24px;
	padding: 5px;
}
.fly2 {
	    background: #C69944;
		border: 1.8px solid #C69944;
    border-radius: 4px;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    /* padding: 20px; */
    width: 60px;
    height: 47px;
}
.spano2 {
    border: 1.8px solid #fff;
    border-radius: 4px;
    width: 60px;
    height: 47px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    font-size: 24px;
    color: #0D4058;
    background: #fff;
}
.spano22 {
    border: 1px solid rgba(13, 64, 88, 0.29);
    background: #FEFCF6;
    border-radius: 4px;
    width: 60px;
    height: 47px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    font-size: 24px;
    color: #0D4058;
}
h3.blio {
    font-weight: 700;
    font-size: 23.3492px;
    line-height: 129.5%;
    letter-spacing: 0.04em;
    color: #0D4058;
    margin: 30px 0;
}
img.plyst2 {
    margin-top: -3px;
    padding-left: 8px;
    width: 28px;
}
.hh {
	font-weight: 700;
    font-size: 18px;
    line-height: 158.5%;
    letter-spacing: 0.04em;
    color: #fff;
}
button.cpn {
    border: 1.8px dashed #C69944;
    border-radius: 3px;
    background: transparent;
    padding: 10px 20px;
    letter-spacing: 0.04em;
    color: #0D4058;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    margin-left: 93px;
}
.mgi {
	    margin-top: 20px;
    margin-left: 70px;
}
img.plyst {
    margin-top: -20px;
    padding-right: 10px;
	width: 36px;
}
.cll {
	width: 15px;
    transform: rotate(10deg);
    margin-right: 10px;
}
.fa-whatsapp:before {
    content: "\f232";
    color: #C69944;
    font-size: 24px;
    margin-right: 10px;
}
.clt {
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #C69944;
    font-size: 18px;
    font-weight: 600;
}
i.fa.fa-whatsapp {
    vertical-align: middle;
}
.pdtim {
	padding-top: 35px;
}
.bxy {
    background: #FEFCF6;
    border: 1.8px solid transparent;
    box-shadow: 0px 4px 25px rgba(39, 38, 37, 0.2);
    border-radius: 8px;
    padding: 40px 2px;
    margin: 0 0 15px 0;
}
.bxy:hover {
    background: #FEFCF6;
    border: 1.8px solid rgba(198, 153, 68, 0.49);
    box-shadow: 0px 4px 25px rgba(198, 153, 68, 0.6);
    
}
.bxhy {
    letter-spacing: 0.04em;
    color: #0D4058;
    font-size: 24px;
    font-weight: 600;
	    line-height: 18px;
		text-align: left;
}
.bxhy2 {
	color: #C69944;
	font-size: 24px;
    font-weight: 600;
	letter-spacing: 0.04em;
}
.tbcs {
	background: rgba(253, 248, 235, 0.41);
	padding: 100px 0;
}
.orgtb {
	border: 2px solid #C69944; border-radius: 78px;
}
p.dsntb {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 201.5%;
    letter-spacing: 0.04em;
    color: #292525;
}
ul.footer-social img {
    padding: 0 6px;
}
img.plyst4 {
    width: 33px;
    margin-top: -22px;
    padding-right: 5px;
}
img.plyst4-ar {
    width: 33px;
    margin-top: -22px;
    padding-left: 5px;
}
.dnl4 {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.01em;
	text-align: left;
}
hr.whtline {
    border-bottom: 1.6px solid #FFFFFF;
    opacity: 1;
}
.app-st4 {
    line-height: 12px;
    letter-spacing: 0.01em;
    font-size: 18px;
    font-weight: 600;
}
img.blue {
    width: 100%;
    margin-bottom: -13px;
}
img.blue-ar {
	width: 100%;
    margin-bottom: -13px;
	    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
img.gari {
    /* margin-left: auto; */
    position: relative;
    margin-bottom: -65px;
	width: 400px;
}
img.gari-ar {
    /* margin-left: auto; */
    position: relative;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
	margin-bottom: -65px;
	width: 400px;
}
.r-bx {
    background: #FFFFFF;
    box-shadow: 0px 4px 35px rgba(198, 153, 68, 0.21);
    border-radius: 10px;
    padding: 30px 30px 50px 30px;
    margin: 30px;
    text-align: center;
	margin-top: 60px;
}
p.dtails {
    font-weight: 500;
    font-size: 20px;
    line-height: 129.5%;
    letter-spacing: 0.04em;
    color: #0D4058;
}
p.drvtxt {
    font-weight: 500;
    font-size: 20px;
    line-height: 129.5%;
    letter-spacing: 0.04em;
    color: #0D4058;
}
.pt-ser {
	padding-top: 100px;
}
.ser-det {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #0D4058;
	line-height: 165.5%;
}
span.drn {
    background: #C69944;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    padding: 5px;
    margin: 5px;
}
p.r-des {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #636060;
}
.r-qou img {
    margin-left: auto;
    width: 30px;
}
.stars img {
    display: inline-block;
}

img.r-img {
    margin-right: auto;
    margin-left: auto;
    position: relative;
    margin-top: -70px;
    /* padding-bottom: 30px; */
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
}
h4.r-he {
    font-weight: 500;
    font-size: 24px;
    line-height: 129.5%;
    letter-spacing: 0.04em;
    color: #0D4058;
    margin-bottom: 0;
}
ul.ticki li:before {
    content: url(assets/img/ticki.png);
    border: 0;
    color: transparent;
    /* display: block; */
    /* width: 15px; */
    line-height: 38px;
}
ul.doubleticki li:before {
    content: url(assets/img/double-tick.png);
    border: 0;
    color: transparent;
    line-height: 36px;
    background: #d4aa59;
}
ul.ticki-ar li:before {
    content: url(assets/img/ticki.png);
    border: 0;
    color: transparent;
    right: 0;
    line-height: 38px;
}

ul.bxi-ar li:before {
    content: url(assets/img/bx.png);
    border: 0;
    color: transparent;
    right: 0;
    line-height: 38px;
}
.loca li:before {
    content: url(assets/img/bx.png);
    border: 0;
    color: transparent;
    right: 0;
    line-height: 38px;
}
.ticki-ar li {
    padding: 3px 48px 5px 0px !Important;
}
.bxi-ar li {
    padding: 3px 48px 5px 0px !Important;
}
.dotti li {
    border-left: 2px dashed #C69944;
}
ul.bxi li:before {
    content: url(assets/img/bx.png);
    border: 0;
    color: transparent;
    /* display: block; */
    /* width: 15px; */
    line-height: 38px;
}
ul.dott li:before {
    content: url(assets/img/dot.png);
    border: 0;
    color: transparent;
    /* display: block; */
    /* width: 15px; */
    line-height: 30px;
}
ul.wsh1 li:before {
    content: url(assets/img/wsh1.png);
    border: 0;
    color: transparent;
    /* display: block; */
    /* width: 15px; */
    line-height: 30px;
}
ul.wsh2 li:before {
    content: url(assets/img/wsh2.png);
    border: 0;
    color: transparent;
    /* display: block; */
    /* width: 15px; */
    line-height: 30px;
}
ul.wsh3 li:before {
    content: url(assets/img/wsh3.png);
    border: 0;
    color: transparent;
    /* display: block; */
    /* width: 15px; */
    line-height: 30px;
}
ul.dottex.wsh1 li {
    padding-left: 100px;
}
ul.dottex.wsh2 li {
    padding-left: 100px;
}
ul.dottex.wsh3 li {
    padding-left: 100px;
}
ul.wsh1ar li:before {
    content: url(assets/img/wsh1.png);
    border: 0;
    color: transparent;
    /* display: block; */
    /* width: 15px; */
    line-height: 30px;
	right: 0;
}
ul.wsh2ar li:before {
    content: url(assets/img/wsh2.png);
    border: 0;
    color: transparent;
    /* display: block; */
    /* width: 15px; */
    line-height: 30px;
	right: 0;
}
ul.wsh3ar li:before {
    content: url(assets/img/wsh3.png);
    border: 0;
    color: transparent;
    /* display: block; */
    /* width: 15px; */
    line-height: 30px;
	right: 0;
}
ul.dottex.wsh1ar li {
    padding-right: 100px;
}
ul.dottex.wsh2ar li {
    padding-right: 100px;
}
ul.dottex.wsh3ar li {
    padding-right: 100px;
}
img.wshimg {
    filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.15));
    border-radius: 20px 20px 0 0;
}
.ssimgg {
	margin: 20px;
}
.terms h2 {
    font-size: 26px;
    margin-bottom: 10px;
}
.terms h1 {
    font-size: 32px;
    margin-bottom: 15px;
}
.dsinl img {
    width: 101px;
    padding-top: 30px;
}
.wsh {
	    color: #0D4058;
    /* font-family: Inter; */
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 152%;
    letter-spacing: 1.12px;
    margin-bottom: 10px;
}
.wsp {
	color: #292525;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 165%; /* 26.4px */
letter-spacing: 0.64px;
}
ul.dottar li:before {
    content: url(assets/img/dot.png);
    border: 0;
    color: transparent;
    right: 0 !important;
    line-height: 30px;
}
ul.dotti li:before {
    content: url(assets/img/dot.png);
    border: 0;
    color: transparent;
    left: -15px;
    top: -13px;
    line-height: 30px;
}
.tbb-he {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 129.5%;
    letter-spacing: 0.04em;
    color: #0D4058;
    margin-bottom: 25px;
}
h2.coming {
    text-align: center;
    color: #c69944;
}
.d-sm-block {
    display: block!important;
}
.whatsappIconFixedIcon {
    position: fixed;
    left: 20px;
    bottom: 44px;
    z-index: 9999;
    display: inline-block;
    text-align: center;
}
.mb-4, .my-4 {
    margin-bottom: 1.5rem!important;
}
#bookingForm, .category-list, .line_dsg, .plate_wrapper, .whatsappIconLink {
    position: relative;
    display: block;
}

.whatsappIconLink {
    height: 50px;
    width: 50px;
    -webkit-transition: .3s ease-in;
    transition: .3s ease-in;
    padding: 10px;
    z-index: 9999;
    background-color: #0d4058;
    border-radius: 100%;
}
.whatsappIconFixedIcon .whatsappIcon:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.ssimg {
    position: relative;
    /* width: 100px; */
    height: 100px;
}
.ssimg img{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}
























.text-md-left {
  text-align: left;
}
.text-md-right {
  text-align: right;
}
.text-right {
  text-align: right;
}
.no-border {
  border: none !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.extra-bold {
  font-weight: 800 !important;
}
.white-color {
  color: #fff !important;
}
.white-color2 {
  color: rgba(255, 255, 255, 0.8) !important;
}
.title-color {
  color: rgba(8, 13, 65, 0.8) !important;
}
.body-color {
  color: #3e3e3e !important;
}
.white-bg {
  background-color: #fff;
}
.gray-bg {
  background-color: #F4F4FB;
}
.gray-bg2 {
  background-color: #FAFAFA;
}
.gray-bg3 {
  background: #f6f4ff;
}
.gray-bg4 {
  background: #f9f9f9;
}
.gray-bg5 {
  background: #f4f4f4;
}
.gray-bg6 {
  background: #f5faff;
}
.gray-bg7 {
  background: #f5faff;
  border-radius: 85px;
}
.gray-bg7 {
  background: #F8FFF2;
}
.gray-bg8 {
  background: #F7FCFF;
}
.gray-bg9 {
  background: #f4f4f4;
}
.gray-bg10 {
  background-color: #83A30030;
}
.gray-bg11 {
  background-color: #83a300;
}
.gray-bg12 {
  background-color: #080d41;
}
.gray-bg13 {
  background-color: #f9f8ff;
}
.gray-bg14 {
  background-color: #060928;
}
.gray-bg15 {
  background-color: #F3F1FF;
}
.gray-bg16 {
  background-color: #EBEBEB;
}
.gray-bg17 {
  background-color: #f9f9f9;
}
.gray-bg18 {
  background-color: #F4F4FBA6;
}
.gray-bg19 {
  background-color: #F4F4FBA6;
}
.gray-bg20 {
  background-image: linear-gradient(180deg, #f4f4f4 0%, #ffffff 100%);
}
.gray-bg22 {
  background-image: linear-gradient(180deg, #f4faff 0%, #f7f4fd 100%);
}
.gray-bg23 {
  background-color: #F2F6FC;
}
.gray-bg24 {
  background-color: #F5F8FF;
}
.gray-bg25 {
  background-color: #F6F8FF;
}
.gray-bg26 {
  background: #F5F7FE;
}
.gray-bg27 {
  background-color: #F6F9FE;
}
.gray-bg28 {
  background-color: #FBFBFB;
}
.gray-bg29 {
  background-color: #f3f8ff;
}
.gray-bg30 {
  background-color: #F1F7FF8C;
}
.gray-bg31 {
  background-color: #f3fbff;
}
.gray-bg32 {
  background-color: #f1f4f9;
}
.black-bg1 {
  background-color: #010139;
}
.black-bg2 {
  background-color: #000806;
}
.pt-310 {
  padding-top: 310px;
}
.pt-300 {
  padding-top: 300px;
}
.pb-260 {
  padding-bottom: 260px;
}
.pt-210 {
  padding-top: 210px;
}
.pb-220 {
  padding-bottom: 220px;
}
.pb-210 {
  padding-bottom: 210px;
}
.relatives {
  position: relative;
  z-index: 11;
}
a {
  color: #126ee8;
  transition: all 0.3s ease;
  text-decoration: none !important;
  outline: none !important;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  color: #126ee8;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.clear {
  clear: both;
}
::-moz-selection {
  background: #126ee8;
  text-shadow: none;
  color: #ffffff;
}
::selection {
  background: #126ee8;
  text-shadow: none;
  color: #ffffff;
}
p.para-wh {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 165.5%;
    letter-spacing: 0.8px;
    color: #fff;
}
.grd-cvr {
    /* background-image: url(assets/img/cvr.png); */
    z-index: 100;
    position: relative;
    margin-bottom: 40px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
	padding: 90px 0;
}
.grd-cvr::before{
        content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(13, 64, 88, 0.70) 0%, rgba(13, 64, 88, 0.12) 100%);
    opacity: 1;
    z-index: -1;
}
.tborbr {
	    border: 3px solid #C69944;
}
.tborbx {
	background: #C69944;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 38px;
}
.tbwhbx {
	color: #0D4058;
	font-size: 18px;
    font-weight: 600;
    line-height: 38px;
}
.mbl-cvr {
    /* background-image: url(assets/img/mobileman.png); */
    z-index: 100;
    position: relative;
    margin-bottom: 40px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
	padding: 90px 0;
}
.mbl-cvr::before{
        content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(89deg, rgba(13, 64, 88, 0.80) 0%, rgba(13, 64, 88, 0.00) 73.44%);
    opacity: 1;
    z-index: -1;
}
img.rightline {
    margin-bottom: -13px;
}
img.leftline {
    margin-top: -20px;
}
.prtm {
	color: #C69944;
    text-shadow: 4px 25px #0D4058;
    /* font-family: Inter; */
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.28px;
    text-shadow: 0 4px 25px #0D4058;
    margin-bottom: 0;
}
.prtmp {
	color: #FFF;
    /* font-family: Inter; */
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.96px;
    padding-top: 10px;
}
.part-cvr {
    /* background-image: url(assets/img/handshake.png); */
    z-index: 100;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
	padding: 90px 0;
}
.part-cvr::before{
        content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(89deg, rgba(13, 64, 88, 0.78) 0%, rgba(13, 64, 88, 0.29) 83.33%);
    opacity: 1;
    z-index: -1;
}
.bg1 {
  background-color: #E6E8FF;
  /* background-image: url(assets/images/bg/about-bg1.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg2 {
  background-color: #F4F4FB;
  /* background-image: url(assets/images/bg/cta-bg1.jpg); */
  border-radius: 30px 30px 30px 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg3 {
  background-color: #F1F7FF8C;
  /* background-image: url(assets/images/bg/process-bg1.png); */
  background-repeat: no-repeat;
}
.bg4 {
  /* background-image: url(assets/images/bg/project-bg6.png); */
  background-position: bottom left;
  background-repeat: no-repeat;
}
.bg5 {
  /* background-image: url(assets/images/bg/blog-bg6.png); */
  background-position: right;
  background-repeat: no-repeat;
}
.bg6 {
  /* background-image: url(assets/images/bg/contact-bg7.png); */
  background-position: center left;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg7 {
  /* background-image: url(assets/images/bg/main-services-bg.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg8 {
  background-color: #060928;
  /* background-image: url(assets/images/bg/map-img.png); */
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 52% auto;
  opacity: 1;
}
.bg9 {
  /* background-image: url(assets/images/bg/team-about-bg1.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg10 {
  /* background-image: url(assets/images/bg/white-stripe.png); */
  background-color: #080D41;
}
.bg11 {
  position: relative;
  /* background-image: url(assets/images/bg/pnr-bg1.png); */
  background-color: #F2F5FC;
}
.bg12 {
  /* background-image: url(assets/images/bg/testi-bg2.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg13 {
  /* background-image: url(assets/images/services/style4/ser-vactor-bg.png); */
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto;
}
.bg14 {
  /* background-image: url(assets/images/bg/faq-bg1.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg15 {
  background-color: #0F0E0E;
  /* background-image: url(assets/images/bg/cta-bg2.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg16 {
  /* background-image: url(assets/images/bg/count-bg1.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0px 50px 30px;
}
.bg17 {
  /* background-image: url(assets/images/bg/project-single.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 64px 35px 38px 35px;
}
.bg18 {
  /* background-image: url(assets/images/bg/services-bg11.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg19 {
  /* background-image: url(assets/images/bg/project-bg11.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg20 {
  /* background-image: url(assets/images/bg/price-new-bg11.jpg); */
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg21 {
  /* background-image: url(assets/images/bg/cta-bg3.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg22 {
  /* background-image: url(assets/images/bg/testi-bg11.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg23 {
  padding: 160px 0 160px;
  /* background-image: url(assets/images/bg/cta-box-bg-img.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg24 {
  /* background-image: url(assets/images/bg/contact-bg11.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg25 {
  /* background-image: url(assets/images/bg/contact-bg11.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg26 {
  /* background-image: url(assets/images/bg/services-bg12.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg27 {
  /* background-image: url(assets/images/bg/img-bg-scaled.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg28 {
  /* background-image: url(assets/images/bg/cta-bg4.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
}
.bg29 {
  /* background-image: url(assets/images/bg/cta-bg1-img.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg30 {
  background-color: #fff;
  /* background-image: url(assets/images/bg/testi-bg12.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg31 {
  /* background-image: url(assets/images/bg/ser-bg4.png); */
  background-color: #F3F2FF;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg32 {
  /* background-image: url(assets/images/bg/feature-bg6.jpg); */
  background-color: #F3F2FF;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg33 {
  /* background-image: url(assets/images/bg/testi-bg-6.jpg); */
  background-color: #F3F2FF;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg34 {
  /* background-image: url(assets/images/bg/blog-bg6.jpg); */
  background-color: #F3F2FF;
  background-repeat: no-repeat;
}
.bg35 {
  /* background-image: url(assets/images/bg/contact-bg6.jpg); */
  background-color: #292965;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg36 {
  /* background-image: url(assets/images/bg/testi-bg-7.jpg); */
  background-repeat: no-repeat;
}
.bg37 {
  background-color: #fafafa;
  /* background-image: url(assets/images/bg/feature-bg8.jpg); */
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.bg38 {
  background-color: #f6f4ff;
  /* background-image: url(assets/images/bg/pro-bg8.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg39 {
  background-color: #F6F4FF;
  /* background-image: url(assets/images/bg/ser-bg-8.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
}
.bg40 {
  /* background-image: url(assets/images/bg/testi-bg8.png); */
  background-repeat: no-repeat;
  background-size: cover;
}
.bg41 {
  /* background-image: url(assets/images/bg/pro-sidebar-bg.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 55px 35px 53px 35px;
  border-radius: 10px 10px 10px 10px;
}
.bg42 {
  /* background-image: url(assets/images/bg/count-down-bg.png); */
  background-color: #1A73E9;
  background-repeat: no-repeat;
  position: relative;
}
.bg43 {
  /* background-image: url(assets/images/bg/contact-inner-bg.png); */
  background-position: bottom center;
  background-repeat: no-repeat;
}
.bg44 {
  /* background-image: url(assets/images/bg/contact-inner-bg2.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg45 {
  /* background-image: url(assets/images/bg/contact-inner-bg3.png); */
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg47 {
  /* background-image: url(assets/images/bg/testi-bg11.png); */
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.offwrap {
  /* cursor: url(assets/images/close.png), auto; */
  width: 100%;
  left: 100%;
  transition: all 0.8s ease-out 0s;
  position: fixed;
  background: #fff;
  height: 100vh;
  top: 0;
  bottom: 0;
  opacity: .8;
  z-index: 999;
}
.copyright p {
  font-size: 15px;
}
body.nav-expanded .offwrap {
  left: 0%;
  transition: all 0.8s ease-out 0s;
}
.bg-unset {
  background: unset !important;
}
.rs-menu-toggle {
  color: #126ee8;
  text-align: right;
}
button {
  cursor: pointer;
}
.glyph-icon:before {
  margin: 0;
}
.z-index-1 {
  position: relative;
  z-index: 1;
}
.gridFilter .portfolio-filter button + button {
  margin-left: 20px;
}
.gridFilter .portfolio-filter button {
  padding: 8px 20px 8px 20px !important;
  border-radius: 0px 0px 0px 0px !important;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #0D5AC0;
  margin: 0 0 0px;
  display: inline-block;
  padding: 0;
  transition: 0.4s;
  outline: none;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  position: relative;
  border: 0;
}
.gridFilter .portfolio-filter button:hover,
.gridFilter .portfolio-filter button.active {
  background-color: #1A73E9;
  color: #FFFFFF;
}
.gridFilter.grid-fliter1 .portfolio-filter button:hover,
.gridFilter.grid-fliter1 .portfolio-filter button.active {
  background-color: #1A73E9;
  color: #FFFFFF;
}
.gridFilter.grid-fliter1 .portfolio-filter button {
  padding: 8px 20px 8px 20px !important;
  border-radius: 10px 10px 10px 10px !important;
  font-size: 17px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #00204A17;
  background: 0 0;
  color: #121933;
}
.dots {
  list-style: disc;
  margin: 0 0 1.5em 3em;
}
section.grytext {
    background: rgba(13, 64, 88, 0.10);
    padding: 35px;
    text-align: center;
    border-radius: 80px 0;
}
.grytext p {
    color: #292525;
    text-align: center;
    /* font-family: Inter; */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 165%;
    letter-spacing: 0.72px;
    margin-bottom: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}
.dottex li {
  position: relative;
  list-style: none;
  /* padding: ; */
  margin: 4px 0;
  font-size: 16px;
  color: #292525;
}
.dottex-ar li {
  position: relative;
  list-style: none;
  padding: 3px 34px 5px 0;
  margin: 4px 0;
  font-size: 16px;
  color: #292525;
}
.dottex-ar li:before {
  content: "\eb7b";
  font-family: remixicon!important;
  position: absolute;
  right: 0;
  left: auto;
  top: 0;
  font-size: 18px;
  color: #1a73e9;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(26, 115, 233, 0.1411764706);
  line-height: 30px;
  text-align: center;
  display: block;
  border-radius: 50%;
}


.check-arrow li {
  position: relative;
  list-style: none;
  padding: 3px 0 5px 48px;
  margin: 4px 0;
  font-size: 18px;
  color: #292525;
}
.check-arrow li:before {
  content: "\eb7b";
  font-family: remixicon!important;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  font-size: 18px;
  color: #1a73e9;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(26, 115, 233, 0.1411764706);
  line-height: 30px;
  text-align: center;
  display: block;
  border-radius: 50%;
}
.check-arrow-ar li {
  position: relative;
  list-style: none;
  padding: 3px 48px 5px 0;
  margin: 4px 0;
  font-size: 18px;
  color: #292525;
}
.check-arrow-ar li:before {
  content: "\eb7b";
  font-family: remixicon!important;
  position: absolute;
  right: 0;
  left: auto;
  top: 0;
  font-size: 18px;
  color: #1a73e9;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(26, 115, 233, 0.1411764706);
  line-height: 30px;
  text-align: center;
  display: block;
  border-radius: 50%;
}
.double-arrow li {
    position: relative;
    list-style: none;
    padding: 3px 0 5px 48px;
    margin: 4px 0;
    font-size: 22px;
    color: #fff;
    line-height: 30px;
    margin-bottom: 15px;
}
.double-arrow li:before {
  content: "\eb7b";
  font-family: remixicon!important;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  font-size: 18px;
  color: #1a73e9;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(26, 115, 233, 0.1411764706);
  line-height: 30px;
  text-align: center;
  display: block;
  border-radius: 50%;
}
.double-arrow-ar li {
    position: relative;
    list-style: none;
    padding: 3px 48px 5px 0;
    margin: 4px 0;
    font-size: 22px;
    color: #fff;
    line-height: 30px;
    margin-bottom: 15px;
}
.double-arrow-ar li:before {
  content: "\eb7b";
  font-family: remixicon!important;
  position: absolute;
  right: 0;
  left: auto;
  top: 0;
  font-size: 18px;
  color: #1a73e9;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(26, 115, 233, 0.1411764706);
  line-height: 30px;
  text-align: center;
  display: block;
  border-radius: 50%;
}
.dottex li {
  position: relative;
  list-style: none;
  padding: 3px 0 5px 34px;
  margin: 4px 0;
  font-size: 16px;
  color: #292525;
}
.dottex li:before {
  content: "\eb7b";
  font-family: remixicon!important;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  font-size: 18px;
  color: #1a73e9;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(26, 115, 233, 0.1411764706);
  line-height: 30px;
  text-align: center;
  display: block;
  border-radius: 50%;
}

.check-arrow-ar li {
  position: relative;
  list-style: none;
  padding: 3px 48px 5px 0;
  margin: 4px 0;
  font-size: 18px;
  color: #292525;
}
.check-arrow-ar li:before {
  content: "\eb7b";
  font-family: remixicon!important;
  position: absolute;
  right: 0;
  left: auto;
  top: 0;
  font-size: 18px;
  color: #1a73e9;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(26, 115, 233, 0.1411764706);
  line-height: 30px;
  text-align: center;
  display: block;
  border-radius: 50%;
}
ul.services-list li a {
  display: block;
  padding: 14px 20px 14px 20px;
  transition: all .5s ease;
  position: relative;
  background: #F6F9FE;
  color: #080d41;
  border-style: solid;
  border-width: 0.5px 0.5px 0.5px 0.5px;
  border-color: #00204A1A;
  border-radius: 5px 5px 5px 5px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}
ul.services-list li a:before {
  content: "\ea6c";
  font-family: remixicon!important;
  position: absolute;
  right: 18px;
  top: 16px;
  color: #080d41;
}
ul.services-list li a.active,
ul.services-list li a:hover {
  background: #126ee8;
  color: #ffffff;
}
ul.services-list li a.active:before,
ul.services-list li a:hover:before {
  color: #ffffff;
}
.shape-divider {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  transform: rotate(180deg);
  top: -45px;
  z-index: -11;
}
.shape-divider svg {
  display: block;
  width: calc(101.3%);
  position: relative;
  left: 50%;
  height: 50px;
  transform: translateX(-50%) rotateY(180deg);
}
.shape-divider svg .shape-fill {
  fill: #F6F9FE;
  transform-origin: center;
  transform: rotateY(0deg);
}
.shape-divider.ser-shape-bottom {
  bottom: -6px;
  top: 0px;
}
.shape-divider.ser-shape-bottom svg .shape-fill {
  fill: #F6F9FE;
}
.shape-divider.project-shape-top {
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  top: -60px;
}
.shape-divider.project-shape-top svg {
  height: 60px;
  display: block;
  width: calc(101.3%);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.shape-divider.project-shape-top svg .shape-fill {
  fill: #F6F9FE;
  transform-origin: center;
  transform: rotateY(0deg);
}
.shape-divider.project-shape-bottom {
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  bottom: 7px;
}
.vertiline {
    width: 1.8px;
    height: 88.5px;
    background: #C69944;
	margin: auto;
}
.dsinl {
    display: inline-block;
    vertical-align: middle;
}
.dsinl h4 {
    color: #0D4058;
    /* font-family: Inter; */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 152%;
    letter-spacing: 0.8px;
}
.shape-divider.project-shape-bottom svg {
  height: 60px;
  display: block;
  width: calc(101.3%);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.shape-divider.project-shape-bottom svg .shape-fill {
  fill: #F6F9FE;
  transform-origin: center;
  transform: rotateY(0deg);
}
ul.check-lists .list-item {
  padding-bottom: calc(9px);
}
ul.check-lists .list-item .icon-list-icon i:before {
  color: #126ee8;
  font-size: 22px;
  position: relative;
}
ul.check-lists .list-item .list-text {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  color: #080d41;
  padding-left: 5px;
}
ul.check-lists .list-item:last-child {
  padding-bottom: 0;
}
ul.check-lists.check-main .list-item .icon-list-icon i:before {
  font-size: 14px;
  color: #1a73e9;
  padding: 2px 2px 2px 2px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #1A73E9;
  border-radius: 50% 50% 50% 50%;
  font-weight: 300;
}
ul.check-lists.check-main .list-item .list-text {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #183760;
  padding-left: 5px;
}
ul.check-lists.check-list2 .list-item .icon-list-icon i:before {
  font-size: 22px;
  color: #f05c4e;
}
ul.check-lists.check-list2 .list-item .list-text {
  font-size: 20px;
  line-height: 30px;
}
ul.check-lists.check-list3 .list-item .icon-list-icon i:before {
  font-size: 22px;
  color: #00a775;
}
ul.check-lists.check-list3 .list-item .list-text {
  font-size: 20px;
  line-height: 30px;
}
ul.check-lists.check-list4 .list-item .icon-list-icon i:before {
  font-size: 24px;
  color: #d32f2f;
  font-weight: 900;
}
ul.check-lists.check-list4 .list-item .list-text {
  font-size: 20px;
  line-height: 30px;
}
ul.check-lists.check-list5 .list-item .icon-list-icon i:before {
  font-size: 24px;
  color: #00a775;
  font-weight: 900;
}
ul.check-lists.check-list5 .list-item .list-text {
  font-size: 20px;
  line-height: 30px;
}
ul.check-lists.check-list6 .list-item .icon-list-icon i:before {
  font-size: 24px;
  color: #b65df0;
  font-weight: 900;
}
ul.check-lists.check-list6 .list-item .list-text {
  font-size: 20px;
  line-height: 30px;
}
ul.check-lists.check-list7 .list-item .icon-list-icon i:before {
  font-size: 24px;
  color: #83a300;
  font-weight: 900;
}
ul.check-lists.check-list7 .list-item .list-text {
  font-size: 20px;
  line-height: 30px;
}
ul.check-lists.check-list8 .list-item .icon-list-icon i:before {
  font-size: 24px;
  color: #ff6600;
  font-weight: 900;
}
ul.check-lists.check-list8 .list-item .list-text {
  font-size: 20px;
  line-height: 30px;
}
ul.check-lists.check-list9 .list-item .icon-list-icon i:before {
  font-size: 22px;
  color: #126ee8;
  font-weight: 900;
}
ul.check-lists.check-list9 .list-item .list-text {
  font-size: 20px;
  line-height: 30px;
}
ul.check-lists.check-list11 .list-item {
  padding-bottom: calc(9px);
}
ul.check-lists.check-list11 .list-item .icon-list-icon i:before {
  font-size: 16px;
  color: #634bf8;
  font-weight: 400;
}
ul.check-lists.check-list11 .list-item .list-text {
  font-size: 17px;
  line-height: 27px;
  font-weight: 400;
}
ul.check-lists.check-list10 .list-item {
  padding-bottom: calc(9px);
}
ul.check-lists.check-list10 .list-item .icon-list-icon i:before {
  color: #0092f2;
}
ul.check-lists.check-list10 .list-item .list-text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}
ul.check-lists.check-list12 .list-item {
  padding-bottom: calc(6px);
}
ul.check-lists.check-list12 .list-item .icon-list-icon i:before {
  font-size: 20px;
  color: #FE7A24;
  font-weight: 900;
}
ul.check-lists.check-list12 .list-item .list-text {
  font-size: 17px;
  line-height: 30px;
}
.rs-features-list2 li {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #656567;
  margin: 0px 0px 15px 0px;
}
.rs-features-list2 li i:before {
  color: #1A73E9;
  font-size: 20px;
  font-weight: 900;
  margin-right: 10px;
}
.rs-features-list2 li:last-child {
  margin: 0px 0px 0px 0px;
}
.rs-features-list li {
  display: flex;
  align-items: center;
  margin: 0px 0px 10px 0px;
}
.rs-features-list li img {
  margin: 0px 10px 0px 0px;
  width: 20px;
  height: 20px;
}
.rs-features-list li .feature-text {
  font-weight: 600;
}
.rs-contact-box .address-item {
  display: flex;
  position: relative;
  justify-content: flex-end;
  padding-right: 30px;
}
.rs-contact-box .address-item .address-icon {
  display: inline-block;
  position: relative;
  background: #fff;
  height: 70px;
  width: 70px;
  line-height: 70px;
  border-radius: 100%;
  text-align: center;
}
.rs-contact-box .address-item .address-icon:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  background: #2b59ff;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  opacity: 1;
  visibility: visible;
  background-color: #ffffff;
}
.rs-contact-box .address-item .address-icon i {
  top: -3px !important;
  position: relative;
  font-size: 24px !important;
  line-height: 77px;
  color: #000000c7 !important;
}
.rs-contact-box .address-item .address-text .text {
  margin: -10px 0 0 25px;
}
.rs-contact-box .address-item .address-text .text .label {
  color: #FFFFFF !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
  margin: 0px 0px 2px 0px;
  display: block;
}
.rs-contact-box .address-item .address-text .text a {
  font-size: 25px;
  font-weight: 700;
  color: #FFFFFF;
}
.rs-contact-box .address-item .address-text .text a:hover {
  opacity: 0.9;
}
.rs-contact-box.contact-box1 .address-item .address-icon i {
  color: #3a15fb !important;
}
.stylelisting {
  list-style-type: none;
  margin-left: 20px !important;
}
.stylelisting li {
  position: relative;
  padding-left: 22px !important;
  line-height: 34px;
  border: none !important;
}
.stylelisting li:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  content: "\f054";
  font-family: 'FontAwesome';
  color: #126ee8;
  font-size: 12px;
}
.pagination-area {
  display: inline-block;
}
.pagination-area .nav-link {
  display: flex;
  align-items: center;
  padding: 15px 10px 15px 8px;
  border-radius: 0;
  background: #126ee8;
}
.pagination-area .nav-link .page-number {
  padding: 2px 15px 0 20px;
  line-height: 12px;
  font-size: 18px;
  text-decoration: none;
  transition: all 0.4s ease-in-out 0s;
  text-align: center;
  background: 0 0;
  color: #eee;
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #e6e6e6;
}
.pagination-area .nav-link .page-number.border-none {
  border-right: unset;
}
.pagenav-link ul {
  border: 1px solid #f2f2f2;
  border-radius: 3px;
  display: inline-block;
  padding: 0 3px;
}
.pagenav-link ul li {
  display: inline-block;
  padding: 0 18px;
  margin: 10px 0;
  border-right: 1px solid #E6E6E6;
  color: #126ee8;
  font-size: 18px;
}
.pagenav-link ul li a {
  font-size: 18px;
  font-weight: 500;
  color: #3e3e3e;
}
.pagenav-link ul li a i:before {
  position: relative;
  padding-left: 3px;
  color: #010d14;
  font-size: 20px;
  top: 0px;
  display: inline-block;
  transition: all 0.3s ease;
}
.pagenav-link ul li a:hover {
  color: #126ee8;
}
.pagenav-link ul li a:hover i:before {
  color: #126ee8;
}
.pagenav-link ul li:last-child {
  border-right: none;
}
blockquote {
  margin: 40px 0;
  padding: 30px 40px 30px 100px;
  color: #3a3a3a;
  position: relative;
  background: #f2f0ff;
  font-weight: normal;
  font-style: italic;
  font-weight: 400;
  border-radius: 20px;
}
blockquote .quote-part {
  font-size: 20px;
  color: #3a3a3a;
  margin: 0;
}
blockquote .quote-part cite {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: #080d41;
  margin-bottom: 10px;
}
blockquote .quote-part cite span {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #3a3a3a;
  position: relative;
  right: -54px;
  display: block;
}
blockquote .quote-part cite:before {
  content: "";
  font-size: 28px;
  padding-bottom: 0px;
  display: inline-block;
  background: #126ee8;
  height: 2px;
  width: 40px;
  font-weight: 400;
  text-align: center;
  top: -4px;
  margin-right: 10px;
  position: relative;
}
blockquote:before {
  content: "\ec51";
  font-size: 64px;
  color: #126ee8;
  padding-bottom: 0;
  display: inline-block;
  font-family: remixicon;
  font-weight: 800;
  text-align: center;
  top: 37px;
  background: 0 0;
  position: absolute;
  left: 10px;
}
.slick-dots {
  position: absolute;
  line-height: 10px;
  bottom: -45px;
  z-index: 3;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
}
.slick-dots li {
  display: inline-block;
  width: unset;
  height: unset;
  font-size: 0;
  margin: 0 5px;
}
.slick-dots li button {
  width: 15px;
  height: 15px;
  display: inline-block;
  font-size: unset;
  line-height: unset;
  padding: unset;
  border: none;
  background: #0092F2;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  border-radius: 15px;
}
.slick-dots li.slick-active button,
.slick-dots li:hover button {
  width: 24px;
  background: #0092f2;
  opacity: 40%;
}
/* no-gutters Class Rules */
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}
/* ------------------------------------
	02. Global Class CSS
---------------------------------------*/
.y-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.y-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.project-item .vertical-middle {
  position: relative;
  z-index: 9;
}
.vertical-middle {
  display: table;
  height: 100%;
  width: 100%;
}
.vertical-middle-cell {
  display: table-cell;
  vertical-align: middle;
}
.readon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  color: #ffffff;
  background: #126ee8;
  padding: 16px 35px 16px 35px;
  z-index: 1;
  border-radius: 0px 0px 0px 0px;
  transition: all 0.4s;
  overflow: hidden;
}
.readon.started {
  position: relative;
  border-color: #126EE8;
  color: rgba(255, 255, 255, 0.8);
  padding: 18px 46px;
  border-radius: 50px;
}
.readon.started.footer-more {
  padding: 16px 44px 20px;
  color: #FFFFFF;
  font-weight: 600;
}
.readon.started.footer-more i:before {
  margin-left: 4px;
  font-size: 14px;
}
.readon.started.footer-more.dis-pink {
  padding: 16px 40px 20px;
  background-color: #b65df0;
  border-radius: 15px;
}
.readon.started.footer-more.dis-pink:before {
  background-color: #b65df0bd;
}
.readon.started.footer-more.dis-pink:hover {
  background-color: #b65df0bd;
}
.readon.started.footer-more.blue-more {
  background-color: #83a300;
  border-radius: 12px 12px 12px 12px;
}
.readon.started.footer-more.blue-more:before {
  background-color: #83A3008A;
}
.readon.started.footer-more.blue-more:hover {
  background-color: #83A3008A;
}
.readon.started.get-blue1 {
  color: #FFFFFF;
  font-weight: 600;
  padding: 20px 47px;
}
.readon.started.phone-number {
  padding: 16px 34px 16px 34px;
}
.readon.started.phone-number .btn-icon i {
  margin-right: 5px;
  color: #126ee8;
  background-color: #ffffff;
  width: 39px;
  height: 39px;
  line-height: 40px;
  text-align: center;
  border-radius: 50px 50px 50px 50px;
}
.readon.started.phone-number .btn-icon i:before {
  font-size: 18px;
}
.readon.started.phone-number.phone-small {
  padding: 13px 26px 13px 26px;
  background-color: #ffffff;
  color: rgba(8, 13, 65, 0.9);
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #FFFFFF;
}
.readon.started.phone-number.phone-small .btn-icon i {
  margin-right: 5px;
  color: #ffffff;
  background-color: #126ee8;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50px 50px 50px 50px;
}
.readon.started.phone-number.phone-small .btn-icon i:before {
  font-size: 16px;
}
.readon.started.phone-number.phone-small:before {
  background-color: #534FF800;
}
.readon.started.phone-number.phone-small:hover {
  background-color: #534FF800;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #FFFFFF;
  color: #FFFFFF !important;
}
.readon.started.phone-number.phone-small:hover .btn-icon i {
  color: #126ee8;
  background-color: #ffffff;
}
.readon.started.phone-number.pink-phone {
  padding: 15px 30px 15px 30px;
  background-color: #ffffff;
  color: #080d41;
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #FFFFFF;
}
.readon.started.phone-number.pink-phone .btn-icon i {
  margin-right: 5px;
  color: #ffffff;
  background-color: #b65df0;
  width: 39px;
  height: 39px;
  line-height: 40px;
  text-align: center;
  border-radius: 50px 50px 50px 50px;
}
.readon.started.phone-number.pink-phone .btn-icon i:before {
  font-size: 18px;
}
.readon.started.phone-number.pink-phone:before {
  background-color: #55008C;
}
.readon.started.phone-number.pink-phone:hover {
  background-color: #55008C;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #FFFFFF;
  color: #FFFFFF !important;
}
.readon.started.phone-number.pink-phone:hover .btn-icon i {
  color: #b65df0;
  background-color: #ffffff;
}
.readon.started.phone-number.normal-blue {
  text-align: center;
    padding: 5px 25px;
    color: #fff;
    border-style: solid;
    border-width: 0;
    border-color: #1A73E9;
    border-radius: 50px 50px 50px 50px;
    background: #C69944;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    margin-bottom: 15px;
}
.btn-inl {
    display: inline-block;
}
.dnl {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.01em;
	text-align: left;
}
.app-st {
    line-height: 12px;
    letter-spacing: 0.01em;
    font-size: 20px;
    font-weight: 600;
}
.readon.started.phone-number.normal-blue .btn-icon i {
  margin-right: 5px;
  color: #ffffff;
  background-color: transparent;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50px 50px 50px 50px;
}
.readon.started.phone-number.normal-blue .btn-icon i:before {
  font-size: 35px;
}
.readon.started.phone-number.normal-blue:hover {
  border-style: solid;
  border-width: 0;
}
.readon.started.phone-number.normal-blue:hover .btn-icon i {
  color: #fff;
    background-color: transparent;
}
.readon.started.phone-number.cta-phone {
  padding: 15px 23px 15px 23px;
  background-color: #FFFFFF00;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #FFFFFF;
  border-radius: 50px 50px 50px 50px;
}
.readon.started.phone-number.cta-phone .btn-text {
  color: rgba(255, 255, 255, 0.8);
}
.readon.started.phone-number.cta-phone:before {
  background-color: transparent;
}
.readon.started.phone-number.cta-phone:hover {
  background-color: transparent;
}
.readon.started.phone-number.cta-phone2 {
  padding: 16px 30px 16px 30px;
  background-color: #FFFFFF00;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #FFFFFF;
  border-radius: 50px 50px 50px 50px;
}
.readon.started.phone-number.cta-phone2 .btn-icon i {
  color: #3a15fb;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  transition: all .4s;
}
.readon.started.phone-number.cta-phone2 .btn-text {
  color: #fff;
}
.readon.started.phone-number.cta-phone2:before {
  background-color: #FFFFFF;
}
.readon.started.phone-number.cta-phone2:hover {
  background-color: #FFFFFF;
}
.readon.started.phone-number.cta-phone2:hover .btn-text {
  color: #3a15fb;
}
.readon.started.phone-number.cta-phone2:hover .btn-icon i {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #4200FF;
}
.readon.started.phone-number:hover {
  box-shadow: 0px 4px 30px rgb(198 153 68);
}
.readon.started.phone-number:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
/* .readon.started:before { */
  /* display: block; */
  /* position: absolute; */
  /* top: 0px; */
  /* right: 0px; */
  /* height: 100%; */
  /* width: 0px; */
  /* z-index: -1; */
  /* content: ""; */
  /* color: #000 !important; */
  /* background-color: #e29f1e; */
  /* transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1); */
/* } */
.readon.started:hover {
  background-color: #126EE8DE;
}
.readon.started:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.consultant {
  font-weight: 700;
  background: #634bf8;
  border-radius: 15px;
  color: #ffffff;
  padding: 21px 31px;
}
.readon.consultant:hover {
  background: #533be5d1;
}
.readon.consultant.phone-number {
  background-color: transparent;
  padding: 21px 20px;
}
.readon.consultant.phone-number.yellow-icon1 {
  padding: 21px 17px;
}
.readon.consultant.phone-number.yellow-icon1 .btn-icon i {
  background-color: #f8b401;
  width: 64px;
  height: 64px;
  line-height: 67px;
}
.readon.consultant.phone-number.yellow-icon1 .btn-text {
  font-weight: 600;
  color: #3a3a3a;
}
.readon.consultant.phone-number.yellow-icon1:hover .btn-icon i {
  background-color: #f8b401;
}
.readon.consultant.phone-number .btn-icon i {
  margin-right: 5px;
  color: #ffffff;
  background-color: #634BF8;
  width: 43px;
  height: 43px;
  line-height: 45px;
  text-align: center;
  border-radius: 50px 50px 50px 50px;
}
.readon.consultant.phone-number .btn-icon i:before {
  font-size: 18px;
}
.readon.consultant.phone-number .btn-text {
  font-weight: 600;
  color: #3e3e3e;
}
.readon.consultant.phone-number:hover .btn-icon i {
  background-color: #634BF8DB;
}
.readon.consultant.phone-number:hover .btn-text {
  color: #634bf8;
}
.readon.consultant.phone-number.orange-phn .btn-icon i {
  background-color: #292965;
}
.readon.consultant.phone-number.orange-phn:hover .btn-text {
  color: #f05c4e;
}
.readon.consultant.phone-number.orange-light .btn-icon i {
  background-color: #f05c4e;
}
.readon.consultant.phone-number.orange-light .btn-text {
  font-weight: 600;
  color: #ffffff;
}
.readon.consultant.phone-number.orange-light:hover .btn-text {
  color: #f05c4e;
}
.readon.consultant.phone-number.blue-light {
  padding: 0px 0px;
}
.readon.consultant.phone-number.blue-light .btn-icon i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: #FFFFFF;
  color: #126ee8;
  text-align: center;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #5463FF;
  border-radius: 50%;
}
.readon.consultant.phone-number.blue-light .btn-text {
  font-weight: 600;
  color: #080d41;
}
.readon.consultant.phone-number.blue-light:hover .btn-text {
  color: #126ee8;
}
.readon.consultant.phone-number.yellow-light .btn-icon i {
  color: #3e3e3e;
  background-color: #f8b401;
}
.readon.consultant.phone-number.yellow-light .btn-text {
  font-weight: 600;
  color: #ffffff;
}
.readon.consultant.phone-number.yellow-light:hover .btn-icon i {
  color: #f8b401;
  background-color: #ffffff;
}
.readon.consultant.phone-number.yellow-light:hover .btn-text {
  color: #f8b401;
}
.readon.consultant.phone-number.purple-light .btn-icon i {
  color: #ffffff;
  background-color: #080D41;
}
.readon.consultant.phone-number.purple-light:hover .btn-text {
  color: #0092f2;
}
.readon.consultant.phone-number.green-light .btn-icon i {
  background-color: #080D41;
}
.readon.consultant.phone-number.green-light:hover .btn-text {
  color: #00a775;
}
.readon.consultant.phone-number.green-white .btn-icon i {
  background-color: #ffffff;
  color: #00a775;
}
.readon.consultant.phone-number.green-white .btn-text {
  font-weight: 600;
  color: #ffffff;
}
.readon.consultant.phone-number.green-white:hover .btn-text {
  color: #00a775;
}
.readon.consultant.phone-number.blue-phone1 .btn-icon i {
  background-color: #ffffff;
  color: #3a15fb;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #4200ff;
  height: 43px !important;
  width: 43px !important;
  line-height: 43px;
}
.readon.consultant.phone-number.blue-phone1 .btn-icon i:before {
  font-size: 20px;
}
.readon.consultant.phone-number.blue-phone1:hover .btn-text {
  color: #3a15fb;
}
.readon.consultant.get-con {
  padding: 20px 46px;
  border-radius: 50px;
  background-color: #126ee8;
  text-transform: capitalize;
}
.readon.consultant.get-con:hover {
  background-color: #126EE8A6;
  color: #ffffff;
}
.readon.consultant.appointment {
  padding: 18px 35px 18px 35px;
  background-color: #126ee8;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #5463FF;
  border-radius: 50px 50px 50px 50px;
}
.readon.consultant.appointment.get-light {
  padding: 18px 45px 18px 45px;
}
.readon.consultant.appointment:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background-color: #126EE8DE;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.readon.consultant.appointment:hover {
  background-color: #126EE8DE;
}
.readon.consultant.appointment:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.consultant.get-started {
  padding: 18px 44px;
}
.readon.consultant.get-started.blue-small {
  border-radius: 50px;
}
.readon.consultant.get-started.blue-small:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background-color: #634BF8DB;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.readon.consultant.get-started.blue-small:hover {
  background-color: #634BF8DB;
}
.readon.consultant.get-started.blue-small:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.consultant.get-orange {
  border-radius: 0;
  background-color: #f05c4e;
  background-color: #F05C4E;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #F05C4E;
}
.readon.consultant.get-orange:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background-color: #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.readon.consultant.get-orange:hover {
  color: #f05c4e;
  background-color: #fff;
  border-color: #fff;
}
.readon.consultant.get-orange:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.consultant.get-orange.small-normal:hover {
  color: #f05c4e;
  background-color: transparent;
  border-color: #f05c4e;
}
.readon.consultant.get-blue {
  padding: 20px 42px 20px 42px;
  background-color: #1A73E9;
  border-radius: 50px 50px 50px 50px;
}
.readon.consultant.get-green {
  padding: 18px 43px;
  border-radius: 65px;
  background-color: #00a775;
}
.readon.consultant.get-green:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background: #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.readon.consultant.get-green:hover {
  color: #00a775;
  background-color: #00a775ab;
}
.readon.consultant.get-green:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.consultant.get-green.get-green2 {
  background-color: #00A775;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #00A775;
  border-radius: 50px 50px 50px 50px;
  padding: 17px 38px;
}
.readon.consultant.get-green.get-green2:hover {
  color: #00a775;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #00A775;
}
.readon.consultant.get-green.get-green2:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.consultant.get-red {
  padding: 18px 43px;
  border-radius: 60px;
  background-color: #d32f2f;
}
.readon.consultant.get-yellow {
  padding: 20px 46px;
  background-color: #f8b401;
  color: #3e3e3e;
  border-radius: 65px;
}
.readon.consultant.get-yellow:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background-color: #ffc107de;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.readon.consultant.get-yellow:hover {
  background-color: #ffc107de;
}
.readon.consultant.get-yellow:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.consultant.get-yellow.blue-light {
  padding: 20px 46px;
  background-color: #1a73e9;
  color: #ffffff;
}
.readon.consultant.get-yellow.blue-light:before {
  background-color: #1a73e9d6;
}
.readon.consultant.get-yellow.blue-light:hover {
  background-color: #1a73e9d6;
}
.readon.consultant.get-yellow.blue-light.download {
  padding: 14px 46px;
}
.readon.consultant.get-pink {
  padding: 20px 42px;
  background-color: #b65df0;
  color: #ffffff;
  border-radius: 65px;
}
.readon.consultant.get-pink:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background: #b65df0bd;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.readon.consultant.get-pink:hover {
  background-color: #b65df0bd;
}
.readon.consultant.get-pink:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.consultant.get-green3 {
  padding: 19px 36px;
  border-radius: 15px;
  background-color: #83a300;
}
.readon.consultant.get-green3:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background: #83a3009e;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.readon.consultant.get-green3:hover {
  background-color: #83a3009e;
}
.readon.consultant.get-green3:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.consultant.get-green3.blue-quote1 {
  font-weight: 500;
  padding: 17px 42px 17px 42px;
  background-color: transparent;
  background-image: linear-gradient(86deg, #1447bf 0%, #4201ff 93%);
  border-radius: 15px 15px 15px 15px;
}
.readon.consultant.get-green3.blue-quote1:before {
  background-color: #4201FF;
}
.readon.consultant.get-orange2 {
  padding: 19px 42px;
  background-color: #ff6600;
  border-radius: 65px 65px 65px 65px;
}
.readon.consultant.discover {
  padding: 20px 42px;
  background-color: #0092f2;
  color: #ffffff;
  border-radius: 180px 180px 180px 180px;
}
.readon.consultant.discover.learn-more {
  padding: 19px 42px;
}
.readon.consultant.discover:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background-color: #0092f299;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.readon.consultant.discover:hover {
  background-color: #0092f299;
}
.readon.consultant.discover:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.consultant.discover.together {
  padding: 16px 30px 16px 30px;
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #FFFFFF;
  color: #00204A;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
}
.readon.consultant.discover.together:before {
  background: #fff;
}
.readon.consultant.discover.together:hover {
  background-color: #FFFFFFD6;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #FFFFFF;
}
.readon.consultant.discover.together:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.consultant.get-free {
  padding: 20px 25px 20px 25px;
  background-color: #634BF8;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #634BF8;
  border-radius: 0px 0px 0px 0px;
}
.readon.consultant.get-free.ser-started {
  padding: 17px 38px 17px 38px;
  text-transform: capitalize;
}
.readon.cta-btn.phn-text {
  background-color: #f8b401;
  padding: 16px 28px 16px 28px;
  color: #3e3e3e;
  border-radius: 15px;
}
.readon.cta-btn.phn-text .btn-icon i {
  padding-right: 4px;
}
.readon.cta-btn.phn-text i:before {
  color: #3e3e3e;
}
.readon.cta-btn.phn-text.cta-yellow2 {
  border-radius: 50px;
}
.readon.cta-btn.contact {
  padding: 15px 48px 15px 48px;
  background-color: #F8B40100;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 15px;
}
.readon.cta-btn.contact:hover {
  background-color: #f8b401;
  border-color: #f8b401;
  color: #3e3e3e;
}
.readon.cta-btn.contact.cont-yellow2 {
  border-radius: 50px;
}
.readon.cta-btn.lets-talks {
  font-size: 17px;
  padding: 18px 42px 18px 42px;
  background-color: #126EE8;
  border-radius: 50px 50px 50px 50px;
}
.readon.cta-btn.lets-talks i:before {
  color: #ffffff;
  font-family: 'remixicon' !important;
  position: relative;
  top: 1px;
  font-size: 17px;
  margin-left: 4px;
  transition: all .3s ease;
  display: inline-block;
}
.readon.cta-btn.lets-talks:before {
  position: absolute;
  display: block;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background: #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.readon.cta-btn.lets-talks:hover {
  color: #126ee8;
}
.readon.cta-btn.lets-talks:hover i:before {
  color: #126ee8;
}
.readon.cta-btn.lets-talks:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.submit {
  padding: 16px 35px 16px 35px;
  color: #080d41;
  background: #ffffff;
  border-radius: 15px;
  border: none;
}
.readon.submit:hover {
  opacity: 0.9;
}
.readon.submit.submit-yellow1 {
  color: #00204A;
  background-color: #F8B401;
  padding: 16px 50px 16px 50px;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  border-radius: 50px 50px 50px 50px;
  box-shadow: 0px 10px 30px 0px rgba(221, 199, 0, 0.1);
}
.readon.submit.submit-purple2 {
  color: #0092F2;
  background-color: #FFFFFF;
  width: 100%;
  padding: 16px 25px 16px 25px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 60px 60px 60px 60px;
}
.readon.submit.submit-purple2:hover {
  background-color: #0092f2;
  color: #ffffff;
}
.readon.submit.submit-orange3 {
  width: 100%;
  background-color: #FF6600;
  color: #ffffff;
  margin: 5px 0px 0px 0px;
  padding: 19px 145px 19px 145px;
  text-transform: uppercase;
  border-radius: 7px 7px 7px 7px;
  box-shadow: 0px 15px 30px 0px rgba(43, 89, 255, 0.38);
}
.readon.submit.submit-purple {
  width: 100%;
  color: #0092f2;
  border-radius: 60px 60px 60px 60px;
  background-color: #ffffff;
}
.readon.submit.submit-green {
  width: 100%;
  color: #ffffff;
  background-color: #00a775;
  padding: 19px 35px 19px 35px;
  border-radius: 60px 60px 60px 60px;
}
.readon.submit.submit-white1 {
  width: 100%;
  color: #5463FF;
  background-color: #ffffff;
  border-radius: 60px 60px 60px 60px;
}
.readon.submit.submit-blue1 {
  width: 100%;
  color: #5463FF;
  background-color: #ffffff;
  border-radius: 0;
}
.readon.submit.submit-blue2 {
  background-color: #126EE8;
  margin: 5px 0px 0px 0px;
  padding: 18px 47px 18px 47px;
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  border-radius: 50px 50px 50px 50px;
  color: #ffffff;
  box-shadow: 0px 15px 30px 0px rgba(43, 89, 255, 0.38);
}
.readon.submit.submit-blue2.sent-message {
  border-radius: 10px 10px 10px 10px;
}
.readon.submit.submit-blue2.sent-message2 {
  color: #0092F2;
  background-color: #FFFFFF;
  padding: 16px 25px 16px 25px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 60px 60px 60px 60px;
  width: 100%;
}
.readon.submit.submit-blue2.inner-submit1 {
  border-radius: 0px 0px 0px 0px;
  box-shadow: 0px 15px 30px 0px rgba(43, 89, 255, 0.38);
  background-image: linear-gradient(120deg, #6936f5 28%, #b39cf4 100%);
}
.readon.submit.submit-blue2.submit-small {
  background-color: #1A73E9;
  margin: 1px 0px 0px 0px;
  padding: 18px 32px 18px 32px;
  font-size: 18px;
  border-radius: 6px 6px 6px 6px;
}
.readon.submit.submit-green1 {
  color: #306C62;
  background-color: #FFFFFF;
  padding: 19px 0px 19px 0px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  border-style: none;
  border-radius: 10px 10px 10px 10px;
  width: 100%;
  box-shadow: 0px 40px 60px 0px rgba(26, 115, 233, 0.2);
}
.readon.submit.submit-green1.onepage-submit1 {
  color: #1a73e9;
}
.readon.submit.submit-green1.onepage-submit2 {
  color: #3a15fb;
}
.readon.submit.submit-green1.onepage-submit3 {
  color: #b65df0;
}
.readon.submit.submit-green1.onepage-submit4 {
  color: #83a300;
}
.readon.submit.sent-quore {
  background-color: #1A73E9;
  color: #fff;
  padding: 20px 0px 20px 0px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 50px 50px 50px 50px;
  box-shadow: 0px 40px 60px 0px rgba(26, 115, 233, 0.2);
  width: 100%;
}
.readon.submit.post {
  color: #ffffff;
  background-color: #126ee8;
  border-radius: 40px;
}
.readon.submit.submit-orange2 {
  width: 100%;
  color: #f05c4e;
  background-color: #ffffff;
  border-radius: 0;
}
.readon.contact-icon {
  padding: 18px 36px 18px 36px;
  background-color: #ffffff;
  color: #f05c4e;
}
.readon.contact-icon i:before {
  font-weight: 600;
  font-size: 16px;
  color: #f05c4e;
  top: 0px;
  margin-left: 4px;
  transition: all ease 0.3s;
  display: inline-block;
  position: relative;
}
.readon.contact-icon:hover i:before {
  transform: translateX(10px);
}
.readon.contact-icon.icon-purple1 {
  color: #0092f2;
  border-radius: 180px 180px 180px 180px;
}
.readon.contact-icon.icon-purple1 i:before {
  color: #0092f2;
}
.readon.contact-icon.icon-paste1 {
  color: #333333;
  background-color: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
}
.readon.contact-icon.icon-paste1 i:before {
  color: #333333;
}
.readon.pricing {
  box-shadow: 0px 20px 40px 0px rgba(0, 146, 242, 0.15);
  color: #ffffff;
  border-radius: 40px;
  background-color: #0092f2;
  width: 100%;
}
.readon.pricing:hover {
  opacity: 0.9;
}
.readon.buy-now {
  padding: 10px 32px 10px 32px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #080D41;
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #080D41;
  border-radius: 30px 30px 30px 30px;
}
.readon.buy-now:hover {
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #126ee8;
  color: #126ee8;
}
.readon.buy-now.blue-active {
  color: #FFFFFF;
  background-color: #126ee8;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #126ee8;
  border-radius: 30px 30px 30px 30px;
}
.readon.direction {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  position: relative;
  padding: 20px 40px 20px 40px;
  background-color: #126EE8;
  border-radius: 50px 50px 50px 50px;
}
.readon.direction:before {
  position: absolute;
  display: block;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background: #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.readon.direction:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.direction.inner-purple1 {
  padding: 16px 42px 16px 42px;
  font-weight: 600;
  background-image: linear-gradient(90deg, #6936f5 0%, #b69ef6 100%);
  border-radius: 0px 0px 0px 0px;
}
.readon.direction.inner-purple1:before {
  background-image: linear-gradient(126deg, #cdbef6 0%, #6936f5 100%);
}
.readon.direction.inner-purple1:hover {
  color: #ffffff;
}
.readon.tab-started {
  padding: 16px 35px;
  background-color: #080D41;
  color: #FFFFFF;
  z-index: 1;
  border-radius: 3px;
}
.readon.tab-started:hover {
  background-color: #FF6600DB;
  color: #FFFFFF;
}
.readon.contact {
  font-size: 18px;
  font-weight: 600;
  background-color: #1a73e9;
  color: #fff;
  border-radius: 10px;
  padding: 22px 42px;
}
.readon.contact:hover {
  color: #00204a;
  background-color: #ffffff;
}
.readon.contact.con-small {
  background-color: #ffffff;
  color: #00204a;
}
.readon.contact.con-small:hover {
  background-color: #1a73e9;
  color: #fff;
}
.readon.contact-us {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  background-color: #ffffff;
  color: #1A73E9;
  padding: 8px 15px 10px 15px;
  border-radius: 10px 10px 10px 10px;
}
.readon.contact-us i:before {
  font-size: 17px;
  margin-left: 10px;
}
.readon.about-us {
  font-size: 17px;
  font-weight: 400;
  color: #FFFFFF;
  background: transparent;
  padding: 0 0 0 0;
}
.readon.about-us:hover {
  opacity: 0.9;
}
.readon.price {
  width: 100%;
  font-weight: 400;
  text-transform: uppercase;
  color: #156FE8;
  background-color: #C61D1D00;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #156FE8;
  border-radius: 5px 5px 5px 5px;
  padding: 10px 20px;
}
.readon.price:hover {
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #156FE8;
  color: #ffffff;
  background-color: #156FE8;
}
.readon.free-con {
  background-color: #1A73E9;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #1A73E9;
  border-radius: 10px 10px 10px 10px;
  padding: 14px 22px 14px 22px;
}
.readon.free-con i:before {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  top: 0px;
  margin-left: 4px;
  transition: all ease 0.3s;
  display: inline-block;
  position: relative;
}
.readon.free-con:hover {
  color: #1A73E9;
  background-color: #02010100;
}
.readon.free-con:hover i:before {
  color: #1A73E9;
}
.readon.free-con.learn-more {
  background-color: #02010100;
  color: #1A73E9;
}
.readon.free-con.learn-more i:before {
  color: #1A73E9;
}
.readon.free-con.learn-more:hover {
  color: #fff;
  background-color: #1A73E9;
}
.readon.free-con.learn-more:hover i:before {
  color: #fff;
}
.readon.filter-price {
  border: none;
  font-size: 12px;
  padding: 10px 36px !important;
  background-color: #126ee8 !important;
}
/*-- Add Button CSS --*/
.add-btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all 0.4s;
  padding: 8px 33px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  background: #126ee8;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
}
.add-btn:hover {
  color: #ffffff;
  background: #080d41;
}
.add-btn.cart {
  text-transform: capitalize;
}
/*-- Services Button CSS --*/
.services-button a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #3e3e3e;
  transition: all ease 0.3s;
}
.services-button a i:before {
  color: #3e3e3e;
  font-family: 'remixicon' !important;
  position: relative;
  top: 1px;
  font-size: 18px;
  transition: all .3s ease;
  display: inline-block;
}
.services-button a:hover {
  color: #126ee8;
}
.services-button a:hover i:before {
  transform: translateX(10px);
  color: #126ee8;
}
.services-button.ser-btn2 a {
  color: #3e3e3e;
}
.services-button.ser-btn2 a i:before {
  display: none;
}
.services-button.ser-btn2 a:hover {
  color: #d32f2f;
}
.services-button.ser-btn3:hover {
  color: #1a73e9;
}
.services-button.ser-btn3:hover i:before {
  transform: translateX(10px);
  color: #1a73e9;
}
.services-button.ser-btn4 a {
  color: #656567;
  font-size: 18px;
  font-weight: 600;
}
.services-button.ser-btn4 a:hover {
  color: #1a73e9;
}
.services-button.ser-btn5 a {
  color: #656567;
  font-size: 16px;
  font-weight: 600;
}
.services-button.ser-btn5 a i:before {
  color: #3e3e3e;
  font-size: 16px;
}
.services-button.ser-btn5 a:hover {
  color: #3a15fb;
}
.services-button.ser-btn5 a:hover i:before {
  transform: translateX(4px);
  color: #3a15fb;
}
.services-button.ser-btn6 a {
  color: #656567;
  font-size: 16px;
  font-weight: 600;
}
.services-button.ser-btn6 a i:before {
  color: #656567;
  font-size: 16px;
}
.services-button.ser-btn6 a:hover {
  color: #3a15fb;
}
.services-button.ser-btn6 a:hover i:before {
  transform: translateX(4px);
  color: #3a15fb;
}
/*-- Faq Button CSS --*/
.faq-button.faq-btn1 {
  font-size: 18px;
  line-height: 28px;
  color: #656567;
}
.faq-button.faq-btn1 a {
  padding-left: 6px;
  color: #656567;
}
.faq-button.faq-btn1 a:hover {
  color: #0092f2;
}
/*-- Project Button CSS --*/
.project-button a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  transition: all ease 0.3s;
  background-color: #f8b401;
  padding: 18px 35px;
  border-radius: 50px;
}
.project-button a .btn-txt {
  color: #3e3e3e;
}
.project-button a i:before {
  font-weight: 600;
  font-size: 18px;
  color: #3e3e3e;
  font-family: 'remixicon' !important;
  position: relative;
  top: 0px;
  transition: all ease 0.3s;
  display: inline-block;
  margin-left: 4px;
}
.project-button a:hover i:before {
  transform: translateX(10px);
}
.project-button.team-view a {
  padding: 18px 46px;
}
.project-button.pro-btn2 a {
  background-color: #0092f2;
}
.project-button.pro-btn2 a .btn-txt {
  color: #ffffff;
}
.project-button.pro-btn2 a i:before {
  color: #ffffff;
}
.project-button.pro-btn3 a {
  background-color: #00a775 !important;
}
.project-button.pro-btn3 a .btn-txt {
  color: #ffffff;
}
.project-button.pro-btn3 a i:before {
  color: #ffffff;
}
.project-button.pro-btn4 a {
  background-color: #b65df0 !important;
}
.project-button.pro-btn4 a .btn-txt {
  color: #ffffff;
}
.project-button.pro-btn4 a i:before {
  color: #ffffff;
}
.project-button.pro-btn5 a {
  background-color: #83a300 !important;
}
.project-button.pro-btn5 a .btn-txt {
  color: #ffffff;
}
.project-button.pro-btn5 a i:before {
  color: #ffffff;
}
/*-- Blog Button CSS --*/
.blog-button a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  transition: all ease 0.3s;
}
.blog-button a .btn-txt {
  color: #3e3e3e;
}
.blog-button a i:before {
  font-weight: 500;
  font-size: 17px;
  color: #3e3e3e;
  font-family: 'remixicon' !important;
  position: relative;
  top: 1px;
  transition: all ease 0.3s;
  display: inline-block;
  margin-left: 4px;
}
.blog-button a:hover .btn-txt {
  color: #126ee8;
}
.blog-button a:hover i:before {
  transform: translateX(6px);
  color: #126ee8;
}
.blog-button.inner-btn a {
  display: inline-block;
  position: relative;
  transition: all .4s;
  padding: 13px 22px 13px 22px;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #121933;
  z-index: 1;
  border-radius: 10px;
  border: 2px solid rgba(26, 115, 233, 0.1215686275);
}
.blog-button.inner-btn a i {
  margin-left: 0px;
  transition: all ease 0.3s;
}
.blog-button.inner-btn a i:before {
  font-weight: 500;
  font-size: 18px;
  color: #121933;
  margin-left: 2px;
}
.blog-button.inner-btn a:hover {
  color: #ffffff;
  background-color: #126ee8;
  border: 2px solid #126ee8;
}
.blog-button.inner-btn a:hover i:before {
  color: #ffffff;
}
.blog-button.blog-btn1 a {
  padding: 13px 28px 13px 28px;
  font-size: 16px;
  border-radius: 50px 50px 50px 50px;
  color: #FFFFFF;
  background-color: #126EE8;
}
.blog-button.blog-btn1 a .btn-txt {
  color: #ffffff;
}
.blog-button.blog-btn1 a i:before {
  transform: rotate(-45deg);
  transition: 0.5s;
  margin-left: 0px;
  color: #ffffff;
}
.blog-button.blog-btn1 a:hover .btn-txt {
  color: #f8f9fad9;
}
.blog-button.blog-btn1 a:hover i:before {
  transform: rotate(0deg);
  color: #f8f9fad9;
}
.blog-button.blog-btn2 a {
  padding: 15px 30px 15px 30px;
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #b65df0;
  border-radius: 10px 10px 10px 10px;
}
.blog-button.blog-btn2 a .btn-txt {
  color: #b65df0;
}
.blog-button.blog-btn2 a i:before {
  font-weight: 600;
  font-size: 16px;
  color: #b65df0;
  top: 0px;
  margin-left: 4px;
}
.blog-button.blog-btn2 a:hover {
  background-color: #b65df0;
  color: #ffffff;
}
.blog-button.blog-btn2 a:hover .btn-txt {
  color: #ffffff;
}
.blog-button.blog-btn2 a:hover i:before {
  color: #ffffff;
}
.blog-button.blog-btn3 a {
  padding: 16px 26px;
  border: 2px solid #f05c4e63;
}
.blog-button.blog-btn3 a .btn-txt {
  color: #f05c4e;
}
.blog-button.blog-btn3 a i:before {
  font-weight: 600;
  font-size: 16px;
  color: #f05c4e;
  top: 0px;
  margin-left: 4px;
}
.blog-button.blog-btn3 a:hover {
  background-color: #f05c4e;
  border: 2px solid #f05c4e;
}
.blog-button.blog-btn3 a:hover .btn-txt {
  color: #ffffff;
}
.blog-button.blog-btn3 a:hover i:before {
  color: #ffffff;
}
.blog-button.blog-btn4 a:hover .btn-txt {
  color: #83a300;
}
.blog-button.blog-btn4 a:hover i:before {
  color: #83a300;
}
.blog-button.blog-btn5 a {
  padding: 16px 26px;
  border: 1px solid  #ff6600;
  border-radius: 15px 15px 15px 15px;
}
.blog-button.blog-btn5 a .btn-txt {
  color: #ff6600;
}
.blog-button.blog-btn5 a i:before {
  font-weight: 600;
  font-size: 16px;
  color: #ff6600;
  top: 0px;
  margin-left: 4px;
}
.blog-button.blog-btn5 a:hover {
  background-color: #ff6600;
  border: 1px solid #ff6600;
}
.blog-button.blog-btn5 a:hover .btn-txt {
  color: #ffffff;
}
.blog-button.blog-btn5 a:hover i:before {
  color: #ffffff;
}
.blog-button.blog-btn6 a .btn-txt {
  color: #3a3a3a;
  font-weight: 500;
}
.blog-button.blog-btn6 a:hover .btn-txt {
  color: #1a73e9 !important;
}
.blog-button.blog-btn7 a {
  padding-left: 25px;
}
.blog-button.blog-btn7 a .btn-txt {
  color: #3a3a3a;
  font-weight: 500;
}
.blog-button.blog-btn7 a:hover .btn-txt {
  color: #3a15fb !important;
}
.blog-button.blog-btn8 a {
  padding-left: 25px;
}
.blog-button.blog-btn8 a .btn-txt {
  color: #3a3a3a;
  font-weight: 500;
}
.blog-button.blog-btn8 a:hover .btn-txt {
  color: #634bf8 !important;
}
.blog-button.blog-btn8 a:hover i:before {
  color: #634bf8 !important;
}
.blog-button.blog-btn9 a {
  padding: 13px 28px 13px 28px;
  font-size: 16px;
  border-radius: 50px 50px 50px 50px;
  color: #FFFFFF;
  background-color: #00a775;
}
.blog-button.blog-btn9 a .btn-txt {
  color: #ffffff;
}
.blog-button.blog-btn9 a i:before {
  transform: rotate(-45deg);
  transition: 0.5s;
  margin-left: 0px;
  color: #ffffff;
}
.blog-button.blog-btn9 a:hover .btn-txt {
  color: #f8f9fad9;
}
.blog-button.blog-btn9 a:hover i:before {
  transform: rotate(0deg);
  color: #f8f9fad9;
}
.blog-button.blog-btn10 a {
  padding: 13px 28px 13px 28px;
  font-size: 16px;
  border-radius: 50px 50px 50px 50px;
  color: #FFFFFF;
  background-color: #83a300;
}
.blog-button.blog-btn10 a .btn-txt {
  color: #ffffff;
}
.blog-button.blog-btn10 a i:before {
  transform: rotate(-45deg);
  transition: 0.5s;
  margin-left: 0px;
  color: #ffffff;
}
.blog-button.blog-btn10 a:hover .btn-txt {
  color: #f8f9fad9;
}
.blog-button.blog-btn10 a:hover i:before {
  transform: rotate(0deg);
  color: #f8f9fad9;
}
.blog-button.blog-btn10.onepage-blog-btn1 a {
  color: #FFFFFF;
  background-color: #0092f2;
}
.blog-button.blog-btn11 {
  color: #FFFFFF;
  padding: 13px 34px 13px 34px;
  background-color: #126ee8;
  border-radius: 50px 50px 50px 50px;
  display: inline-block;
}
.blog-button.blog-btn11 .btn-txt {
  color: #ffffff;
}
.blog-button.blog-btn11:hover .btn-txt {
  opacity: 0.79;
  color: #ffffff;
}
.address-wrap {
  display: flex;
  align-items: center;
}
.address-wrap .address-icon {
  display: inline-block;
  position: relative;
  height: 70px;
  width: 70px;
  line-height: 70px;
  border-radius: 100%;
  text-align: center;
  background: #fff;
}
.address-wrap .address-icon:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  background: #fff;
}
.address-wrap .address-icon i {
  text-align: center;
  color: #ff7425;
  border-radius: 100%;
  font-size: 30px;
  position: relative;
  line-height: 70px;
  z-index: 9;
  width: 100%;
  vertical-align: middle;
  margin-left: 0;
  height: auto;
  line-height: 55px;
}
.address-wrap .content-txt {
  padding-left: 30px;
}
.address-wrap .des {
  font-size: 25px;
  font-weight: 500;
  line-height: 40px;
  color: #fff;
}
/*Video Icon*/
.rs-videos .animate-border .popup-border i {
  display: inline-block;
  color: #FFFFFF;
  background: #3E3E3E;
  width: 38px;
  height: 38px;
  line-height: 35px;
  text-align: center;
  margin-right: 7px;
  border-radius: 50% 50% 50% 50%;
}
.rs-videos .animate-border .popup-border i:before {
  font-size: 12px;
}
.rs-videos .animate-border .popup-border .btn-text {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #3e3e3e;
}
.rs-videos .animate-border .popup-border:hover i {
  background-color: #634bf8;
  color: #ffffff;
}
.rs-videos .animate-border .popup-border:hover .btn-text {
  color: #634bf8;
}
.rs-videos .animate-border.animate-style1 .popup-border i {
  color: #f05c4e;
  background: #ffffff;
}
.rs-videos .animate-border.animate-style1 .popup-border .btn-text {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}
.rs-videos .animate-border.animate-style1 .popup-border:hover .btn-text {
  color: #f05c4e;
}
.rs-videos .animate-border.animate-style2 .popup-border:hover .btn-text {
  color: #00a775;
}
.rs-videos .animate-border.animate-style2 .popup-border:hover i {
  color: #FFFFFF;
  background: #3E3E3E;
}
.rs-videos .animate-border.animate-style3 {
  display: inline-block;
  color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #FFFFFF;
  padding: 13px 30px;
  border-radius: 180px 180px 180px 180px;
}
.rs-videos .animate-border.animate-style3 .popup-border i {
  color: #0092f2;
  background: #ffffff;
}
.rs-videos .animate-border.animate-style3 .popup-border .btn-text {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
}
.rs-videos .animate-border.animate-style3:hover {
  color: #0092f2;
  background-color: #ffffff;
}
.rs-videos .animate-border.animate-style3:hover .popup-border i {
  color: #ffffff;
  background: #0092f2;
}
.rs-videos .animate-border.animate-style3:hover .popup-border .btn-text {
  color: #0092f2;
}
.rs-videos .animate-border.animate-style3.cta-phone {
  padding: 11px 24px;
}
.rs-videos .animate-border.animate-style4 {
  padding: 13px 0px;
}
.rs-videos .animate-border.animate-style4 .popup-border i {
  color: #d32f2f;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #0C46DBCC;
  background-color: #3E3E3E00;
}
.rs-videos .animate-border.animate-style4 .popup-border i:before {
  font-size: 14px;
}
.rs-videos .animate-border.animate-style4 .popup-border .btn-text {
  font-weight: 500;
  color: #3e3e3e;
}
.rs-videos .animate-border.animate-style4 .popup-border:hover .btn-text {
  color: #d32f2f;
}
.rs-videos .animate-border.animate-style5 {
  padding: 13px 0px;
}
.rs-videos .animate-border.animate-style5 .popup-border i {
  color: #126ee8;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #126ee8;
  background-color: transparent;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  margin-right: 7px;
  border-radius: 50% 50% 50% 50%;
}
.rs-videos .animate-border.animate-style5 .popup-border i:before {
  font-size: 22px;
}
.rs-videos .animate-border.animate-style5 .popup-border .btn-text {
  color: #080D41;
  font-size: 18px;
  font-weight: 600;
}
.rs-videos .animate-border.animate-style5 .popup-border:hover .btn-text {
  color: #126ee8;
}
.rs-videos .animate-border.animate-style6 .popup-border i {
  color: #ffffff;
  background-color: #0C46DB;
}
.rs-videos .animate-border.animate-style6 .popup-border .btn-text {
  color: #646464;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}
.rs-videos .animate-border.animate-style6 .popup-border:hover .btn-text {
  color: #d32f2f;
}
.margin-remove {
  margin: 0 !important;
}
.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin: 0;
}
.container-fluid {
  padding-left: 130px;
  padding-right: 130px;
}
.container-fluid2 {
  padding-left: 175px;
  padding-right: 175px;
}
.display-table {
  display: table;
  height: 100%;
  width: 100%;
}
.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.relative {
  position: relative;
}
.pt-relative {
  position: relative !important;
}
.sec-title {
  position: relative;
}
.sec-title .sub-text {
  position: relative;
  padding-left: 40px;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #f05c4e;
  text-transform: uppercase;
  margin: 0 0 10px;
}
.sec-title .sub-text:after {
  content: '';
  width: 24px;
  border: 1px solid #f05c4e;
  position: absolute;
  top: 12px;
  left: 0;
}
.sec-title .sub-text.sub-text2 {
  padding-left: 0px;
  color: #0092f2;
}
.sec-title .sub-text.sub-text2:after {
  display: none;
}
.sec-title .sub-text.sub-text2.sub-text3 {
  color: #d32f2f;
}
.sec-title .sub-text.sub-text2.sub-text4 {
  color: #0c46db;
}
.sec-title .sub-text.sub-text2.sub-text5 {
  color: #83a300;
}
.sec-title .sub-text.sub-text2.sub-text6 {
  color: #f05c4e;
}
.sec-title .sub-text.sub-text2.sub-text7 {
  color: #126ee8;
}
.sec-title .sub-text.sub-text2.sub-text8 {
  color: #00a775;
}
.sec-title .sub-text.sub-text2.sub-text9 {
  color: #ffffff;
}
.sec-title .sub-text.sub-text2.sub-text10 {
  font-size: 18px;
  color: #00469C;
}
.sec-title .sub-text.sub-text2.sub-text11 {
  font-size: 18px;
  color: #F97123;
}
.sec-title .title {
  position: relative;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  color: #080d41;
  margin: 0;
}
.sec-title .title.title2 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  color: #080d41;
}
.sec-title .title.title3 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
}
.sec-title .title.title4 {
  font-size: 20px;
  line-height: 30px;
  color: #080d41;
  font-weight: 600;
}
.sec-title .title.title5 {
  position: relative;
}
.sec-title .title.title5:before {
  content: "";
  position: absolute;
  border: 0;
  width: 65px;
  height: 5px;
  z-index: 1;
  top: -28px;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-color: #126ee8;
}
.sec-title .title.title5.heading-border:before {
  left: 0;
  right: unset;
  margin: unset;
}
.sec-title .title.title5.heading-yellow:before {
  left: 0;
  right: unset;
  margin: unset;
  background-color: #fff;
}
.sec-title .title.title5.heading-pink:before {
  background-color: #B65DF0;
}
.sec-title .title.title5.heading-white:before {
  background-color: #fff;
}
.sec-title .title.title5.heading-orange:before {
  background-color: #FF6600;
}
.sec-title .title.title6 {
  font-size: 24px;
  line-height: 34px;
  color: #080d41;
  font-weight: 600;
}
.sec-title .title.title7 {
  font-size: 26px;
  line-height: 36px;
  color: #080d41;
  font-weight: 600;
}
.sec-title .title.title8 {
  font-size: 36px;
  line-height: 46px;
  color: #080d41;
  font-weight: 600;
}
.sec-title .title.title9 {
  font-size: 22px;
  line-height: 32px;
  color: #3483eb;
  font-weight: 500;
}
.sec-title .title.title10 {
  position: relative;
  top: -22px;
}
.sec-title .title.title11 {
  position: relative;
  font-weight: 800;
  margin: 0px 0px 30px 0px;
  padding: 19px 0px 0px 28px;
}
.sec-title .title.title11:before {
  display: block !important;
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background: transparent;
  z-index: 1;
  top: 0;
  border-style: solid;
  border-color: #1a73e9;
  border-top-width: 5px;
  border-bottom-width: 0;
  pointer-events: none;
  left: 0;
  border-left-width: 5px;
  border-right-width: 0;
}
.sec-title .title.title11.white-subnormal:before {
  border-color: #ffffff;
}
.sec-title .title.title11.blue-subnormal {
  font-weight: 700;
}
.sec-title .title.title11.blue-subnormal:before {
  border-color: #3a15fb;
}
.sec-title .title.title11.ser-details {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}
.sec-title .title.title12 {
  position: relative;
  font-weight: 700;
  margin: 0px 0px 30px 0px;
  padding: 19px 0px 0px 28px;
}
.sec-title .title.title12 .watermark {
  position: relative;
}
.sec-title .title.title12 .watermark:before {
  display: block !important;
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background: transparent;
  z-index: 1;
  top: -2px;
  border-style: solid;
  border-color: #1a73e9;
  border-top-width: 5px;
  border-bottom-width: 0;
  pointer-events: none;
  left: -20px;
  border-left-width: 5px;
  border-right-width: 0;
}
.sec-title .title.title12 .watermark:after {
  display: block !important;
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background: transparent;
  z-index: 1;
  top: -2px;
  border-style: solid;
  border-color: #1a73e9;
  border-top-width: 5px;
  border-bottom-width: 0;
  pointer-events: none;
  right: -20px;
  border-right-width: 5px;
  border-left-width: 0;
}
.sec-title .title.title12.two-blue-subnormal .watermark:before {
  border-color: #3a15fb;
}
.sec-title .title.title12.two-blue-subnormal .watermark:after {
  border-color: #3a15fb;
}
.sec-title .title.title12.two-blue-subnormal.main-subnormal .watermark:before {
  border-color: #1a73e9;
}
.sec-title .title.title12.two-blue-subnormal.main-subnormal .watermark:after {
  border-color: #1a73e9;
}
.sec-title .title.title13 {
  font-size: 40px;
  color: #ffffff;
}
.sec-title .title.title13 span {
  color: #126ee8;
}
.sec-title .title.title13.blue-title1 span {
  color: #3a15fb;
}
.sec-title .title.title14 {
  font-size: 36px;
  line-height: 46px;
  color: #080d41;
  font-weight: 700;
}
.sec-title .title.title15 span {
  color: #b4d3fb;
}
.sec-title .title.title16 span {
  color: #F971231A;
}
.sec-title .title.title17 {
  position: relative;
  font-size: 30px;
  line-height: 40px;
  margin: 0 0 0;
}
.sec-title .title.title17:before {
  content: "";
  position: absolute;
  left: -6px;
  top: 9px;
  width: 24px;
  height: 24px;
  background: #1a73e9;
  border-radius: 50%;
  opacity: .2;
  display: block;
}
.sec-title .border-item:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 115px;
  background-color: #E5E9ED;
  z-index: 1;
  left: 99%;
  top: 4%;
}
.sec-title .line-border {
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #dedfde;
  padding: 0px 25px 40px 0px;
  margin-bottom: 40px;
}
.sec-title .desc {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #3e3e3e;
  margin: 0;
}
.sec-title .desc.desc2 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}
.sec-title .desc.desc3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
}
.sec-title .desc.desc4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}
.sec-title .desc.desc5 {
  color: #FFFFFF9C;
}
.sec-title .desc.desc6 {
  color: #3e3e3e;
}
.sec-title .desc.desc7 {
  color: #FFFFFFE0;
}
.sec-title .desc.desc8 {
  font-size: 18px;
  color: #656567;
}
.sec-title .desc.desc9 {
  font-size: 18px;
  color: #656567;
}
.sec-title .desc.desc11 {
  font-size: 18px;
  color: #dfdfdf;
}
.sec-title .desc.desc10 {
  position: relative;
  font-size: 18px;
  padding-left: 25px;
  color: #656567;
}
.sec-title .desc.desc10:after {
  background-color: #634BF8;
  z-index: 1;
  margin-left: 0;
  left: 0;
  top: 10px;
  height: 50px;
  width: 5px;
  position: absolute;
  content: '';
  border: 0;
  border-radius: 18px 18px 18px 18px;
}
.sec-title.sec-modify1 .sub-text {
  color: #f05c4e;
}
.sec-title.sec-modify1 .sub-text:after {
  border: 1px solid #f05c4e;
}
.sec-title.sec-modify1 .title {
  color: #ffffff;
}
.sec-title.pro-sec {
  bottom: -64px;
}
.sec-title .heading-border-line {
  position: relative;
}
.sec-title .heading-border-line:before {
  content: "";
  width: 12px;
  height: 4px;
  background-color: #126ee8;
  position: absolute;
  bottom: -4px;
  left: 50%;
  margin-left: -35px;
  transform: translateX(-50%);
  border-radius: 2px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 1;
}
.sec-title .heading-border-line:after {
  content: "";
  width: 65px;
  height: 4px;
  background-color: #126ee8;
  position: absolute;
  bottom: -4px;
  right: 0;
  left: 50%;
  margin-left: -20px;
  border-radius: 2px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 0;
}
.sec-title .heading-border-line.left-style {
  position: relative;
  margin-left: 44px;
}
.sec-title .heading-border-line.left-style:before {
  left: 0;
}
.sec-title .heading-border-line.left-style:after {
  left: 0;
}
.sec-title .heading-border-line.pre-heading-line1:before {
  background-color: #00a775;
}
.sec-title .heading-border-line.pre-heading-line1:after {
  background-color: #00a775;
}
.sec-title:hover .heading-border-line {
  position: relative;
}
.sec-title:hover .heading-border-line:before {
  margin-left: -5px;
  width: 65px;
  background-color: #126ee8;
}
.sec-title:hover .heading-border-line:after {
  width: 12px;
  margin-left: 35px;
  background-color: #126ee8;
}
.sec-title:hover .heading-border-line.pre-heading-line1:before {
  background-color: #00a775;
}
.sec-title:hover .heading-border-line.pre-heading-line1:after {
  background-color: #00a775;
}
/* -----------------------
	03. Header Section CSS
--------------------------*/
.full-width-header {
  position: relative;
}
.full-width-header .rs-header {
    position: relative;
    background: #D4AA59;
    border: 4px solid rgba(198, 153, 68, 0.23);
	padding: 20px 0;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main {
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 0px 0px 2px 0px;
  border-color: #EEF5FF;
  padding: 0px 40px 0px 40px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-contact ul li {
  display: inline-block;
  border-style: solid;
  border-width: 0px 2px 0px 0px;
  border-color: #EEF5FF;
  margin-right: 20px;
  padding-right: 20px;
  line-height: 38px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-contact ul li i {
  font-size: 20px;
  color: #b65df0;
  margin-right: 5px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-contact ul li i:before {
  top: 2px;
  position: relative;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-contact ul li a {
  font-size: 14px;
  color: #5B5858;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-contact ul li a:hover {
  opacity: 0.9;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-contact ul li .des {
  font-size: 14px;
  color: #5B5858;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-contact ul li:last-child {
  margin-right: 0;
  padding-right: 0;
  border: none;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-sl-share li {
  display: inline-block;
  margin-right: 14px;
  font-size: 14px;
  line-height: 38px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-sl-share li.opening {
  border-style: solid;
  border-width: 0px 2px 0px 0px;
  border-color: #EEF5FF;
  margin-right: 20px;
  padding-right: 20px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-sl-share li.opening i {
  font-size: 22px;
  color: #b65df0;
  margin-right: 5px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-sl-share li.opening i:before {
  top: 2px;
  position: relative;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-sl-share li.opening .des {
  font-size: 14px;
  color: #5B5858;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-sl-share li a i {
  font-size: 14px;
  color: #524F4F;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1 .topbar-main .toolbar-sl-share li a i:hover {
  color: #b65df0;
}
.full-width-header .rs-header .toolbar-area.toolbar-style1.toolbar-modify2 .topbar-main .toolbar-sl-share li a i:hover,
.full-width-header .rs-header .toolbar-area.toolbar-style1.toolbar-modify2 .topbar-main .toolbar-sl-share li.opening i,
.full-width-header .rs-header .toolbar-area.toolbar-style1.toolbar-modify2 .topbar-main .toolbar-contact ul li i {
  color: #00a775;
}
.full-width-header .rs-header .toolbar-area.toolbar-style2 {
  background-color: #080D41;
}
.full-width-header .rs-header .toolbar-area.toolbar-style2 .toolbar-area span {
  color: #FFFFFF;
  font-size: 15px;
  line-height: 44px;
  font-weight: 500;
}
.full-width-header .rs-header .toolbar-area.toolbar-style2 .toolbar-sl-share {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.full-width-header .rs-header .toolbar-area.toolbar-style2 .toolbar-sl-share .opening {
  display: inline-block;
  font-size: 14px;
  line-height: 44px;
  border-style: solid;
  border-width: 0px 1px 0px 0px;
  border-color: #222546;
  margin-right: 20px;
  padding-right: 20px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style2 .toolbar-sl-share .opening i {
  font-size: 22px;
  color: #ffffff;
  margin-right: 5px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style2 .toolbar-sl-share .opening i:before {
  top: 2px;
  position: relative;
}
.full-width-header .rs-header .toolbar-area.toolbar-style2 .toolbar-sl-share .opening .des {
  font-size: 14px;
  color: #fff;
}
.full-width-header .rs-header .toolbar-area.toolbar-style2 .toolbar-sl-share li {
  display: inline-block;
  font-size: 14px;
  line-height: 44px;
  border-style: solid;
  border-width: 0px 1px 0px 0px;
  border-color: #222546;
  margin-right: 20px;
  padding-right: 20px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style2 .toolbar-sl-share li a i {
  font-size: 14px;
  color: #fff;
}
.full-width-header .rs-header .toolbar-area.toolbar-style2 .toolbar-sl-share li a i:hover {
  color: #ccc;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 {
  background-color: #1a73e9;
  padding: 12px 0 12px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .toolbar-wrap .sl-share-icon .follow-text {
  display: inline-block;
  margin-right: 10px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .toolbar-wrap .sl-share-icon .follow-text span {
  color: #fff;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .toolbar-wrap .sl-share-icon li {
  display: inline-block;
  margin-right: 10px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .toolbar-wrap .sl-share-icon li:last-child {
  margin-right: 0;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .toolbar-wrap .sl-share-icon li a i {
  font-size: 14px;
  color: #FFFFFFCC;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .address-contact-box {
  display: flex;
  justify-content: flex-end;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .address-contact-box li {
  display: inline-block;
  margin-right: 20px;
  border-right: 2px solid #ffffff3b;
  padding-right: 17px;
  line-height: 13px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .address-contact-box li:last-child {
  margin-right: 0;
  border-right: none;
  padding-right: 0px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .address-contact-box li .address-item {
  display: flex;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .address-contact-box li .address-item .address-icon {
  margin-right: 6px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .address-contact-box li .address-item .address-icon i:before {
  font-size: 18px;
  color: #FFFFFF;
  position: relative;
  top: -2px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .address-contact-box li .address-item .address-text .des {
  font-size: 15px;
  color: #FFFFFF;
  display: block;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .address-contact-box li .address-item .address-text a {
  color: #fff;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3 .address-contact-box li .address-item .address-text a:hover {
  color: #FFFFFFDB;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify3 {
  position: relative;
  z-index: 11;
  background-color: #ffffff;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify3 .address-contact-box li .address-item .address-icon i:before {
  color: #126ee8;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify3 .address-contact-box li .address-item .address-text a {
  color: #666666;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify3 .address-contact-box {
  justify-content: flex-start;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify3 .address-contact-box li .address-item .address-text .des {
  font-size: 15px;
  color: #666666;
  display: block;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify3 .address-contact-box li {
  border-right: 2px solid #dae8fb;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify3 .address-contact-box li:last-child {
  margin-right: 0;
  border-right: none;
  padding-right: 0px;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify3 .address-contact-box li .address-item .address-text a:hover {
  color: #126ee8;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify3:before {
  content: "";
  background: #00204A;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -50px;
  height: 100%;
  width: 32%;
  z-index: -11;
  transform: skew(30deg);
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify4 {
  background-color: #1A001B;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify4 .address-contact-box {
  display: flex;
  justify-content: flex-start;
}
.full-width-header .rs-header .toolbar-area.toolbar-style3.toolbar-modify4 .toolbar-wrap .sl-share-icon {
  display: flex;
  justify-content: flex-end;
}
.full-width-header .rs-header .middle-header {
  padding-top: 23px;
  padding-bottom: 23px;
}
.full-width-header .rs-header .middle-header .row-table {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .middle-header .row-table .col-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .middle-header .row-table .col-cell.header-logo {
  display: flex;
  flex: 1;
}
.full-width-header .rs-header .middle-header .logo-part a img {
  transition: 0.4s;
  max-height: 35px;
}
.full-width-header .rs-header .middle-header .rs-contact-info {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .middle-header .rs-contact-info .contact-part {
  display: flex;
  align-items: center;
  padding-left: 75px;
  margin-right: 25px;
}
.full-width-header .rs-header .middle-header .rs-contact-info .contact-part .info-icon {
  margin-right: 5px;
}
.full-width-header .rs-header .middle-header .rs-contact-info .contact-part .info-icon i {
  font-size: 30px;
  color: #126ee8;
  position: relative;
  top: -10px;
}
.full-width-header .rs-header .middle-header .rs-contact-info .contact-part .contact-info .info-title {
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  color: #282828;
  display: block;
}
.full-width-header .rs-header .middle-header .rs-contact-info .contact-part .contact-info .info-des {
  color: #3E3E3E;
}
.full-width-header .rs-header .middle-header .rs-contact-info .contact-part .contact-info .info-des a {
  color: #3E3E3E;
}
.full-width-header .rs-header .middle-header .rs-contact-info .contact-part .contact-info .info-des a:hover {
  color: #126ee8;
}
.full-width-header .rs-header .middle-header .rs-contact-info .contact-part.margin-no {
  margin-right: 0;
}
.full-width-header .rs-header .middle-header .rs-contact-info .contact-part:hover .info-icon i:before {
  transform: translateY(-3px);
}
.full-width-header .rs-header .menu-area {
  transition: all 0.3s ease 0s;
  background: transparent;
}
.full-width-header .rs-header .menu-area .row-table {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .menu-area .row-table .col-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .menu-area .row-table .col-cell.header-logo {
  display: flex;
  flex: 1;
}
.full-width-header .rs-header .menu-area .logo-area a .sticky-logo {
  display: none;
}
.full-width-header .rs-header .menu-area .logo-area img {
  transition: 0.4s;
  max-height: 38px;
}
.full-width-header .rs-header .menu-area .rs-menu-area {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .menu-area .main-menu {
  position: unset;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li {
  display: inline-block;
  margin-right: 28px;
  padding: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li:last-child {
  margin-right: 0 !important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
  height: 130px;
  line-height: 130px;
  padding: 0;
  color: #3e3e3e;
  position: relative;
  transition: all 0.3s ease;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #126ee8;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children > a {
  padding-right: 17px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children > a:before {
  position: absolute;
  left: auto;
  content: '\EA50' !important;
  font-family: remixicon !important;
  font-size: 24px;
  right: -4px;
  top: 48%;
  text-align: center;
  z-index: 9999;
  display: block;
  cursor: pointer;
  transform: translateY(-50%);
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #0d4058;
  margin: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li {
  margin: 0 !important;
  border-bottom-style: solid;
  border-bottom-color: #C8C0E230;
  border-bottom-width: 1px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #F5F5F5;
  height: unset;
  line-height: 30px;
  text-transform: capitalize;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #126ee8;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li:last-child {
  margin: 0;
  border-bottom-width: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.menu-item-has-children a:before {
  right: 20px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu.right {
  left: auto;
  right: 100%;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.onepage-menu li.current-menu-item > a {
  color: #126ee8;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #126ee8 !important;
}
.full-width-header .rs-header .menu-area.sticky {
  background: #fff;
  padding: 20px 0;
}
.full-width-header .rs-header .menu-area.sticky .logo-area a .sticky-logo {
  display: block;
}
.full-width-header .rs-header .menu-area.sticky .logo-area a .normal-logo {
  display: none;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  height: 110px;
  line-height: 110px;
  color: #080d41;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #126ee8 !important;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a {
  color: #fff !important;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button {
  color: #080d41;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #126ee8;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .btn-quote a {
  color: #ffffff;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .btn-quote a:hover {
  color: #ffffff;
  background: #0D4058;
}
.full-width-header .rs-header .expand-btn-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .expand-btn-inner ul {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .expand-btn-inner ul .btn-quote a {
         padding: 10px 40px 10px 40px;
    transition: all 0.3s ease;
    width: 131px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.18);
    border-radius: 7px;
    color: #0D4058 !important;
    font-weight: 600;
    font-size: 18px;
    /* line-height: 30px; */
    letter-spacing: 0.04em;
}
.full-width-header .rs-header .expand-btn-inner ul .btn-quote a:hover {
  border-color: #0D4058;
    background-color: #0D4058;
    color: #fff !important;
}
.full-width-header .rs-header .expand-btn-inner ul .address-box .address-item .address-icon {
  padding-left: 23px;
}
.full-width-header .rs-header .expand-btn-inner ul .nav-link {
  display: none;
}
.full-width-header .rs-header .expand-btn-inner ul .nav-link .nav-expander {
  background: #fff;
  padding: 13px 14px 14px 14px;
  width: 52px;
  height: 52px;
  display: block;
  border-radius: 50%;
  margin-left: 28px;
}
.full-width-header .rs-header .expand-btn-inner ul .nav-link .nav-expander .bar span {
  height: 2px;
  width: 25px;
  display: block;
  background: #333;
  transition: none;
  cursor: pointer;
  list-style: none;
  margin: 5px 0;
}
/* .full-width-header .rs-header .expand-btn-inner ul .nav-link .nav-expander .bar span.dot1 { */
  /* width: 16px; */
  /* float: right; */
  /* clear: right; */
  /* margin-right: 2px; */
/* } */
.full-width-header .rs-header .expand-btn-inner ul .nav-link .nav-expander .bar span.dot2 {
  clear: both;
}
/* .full-width-header .rs-header .expand-btn-inner ul .nav-link .nav-expander .bar span.dot3 { */
  /* width: 16px; */
  /* margin-left: 2px; */
/* } */
.full-width-header .rs-header .expand-btn-inner ul .search-parent {
  position: relative;
}
.full-width-header .rs-header .expand-btn-inner ul .search-parent a i {
  border-right: 1px solid #9e9e9e38;
  line-height: 13px;
  padding-right: 25px;
}
.full-width-header .rs-header .expand-btn-inner ul .search-parent a i:before {
  font-size: 22px;
  color: #656567;
}
.full-width-header .rs-header .expand-btn-inner ul .search-parent .sticky_form {
  position: absolute;
  top: 61px;
  max-width: 350px;
  width: 350px;
  z-index: 9;
  border-radius: 4px;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.09);
  opacity: 0;
  visibility: hidden;
  right: 0;
  transition: 0.4s;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0 0;
}
.full-width-header .rs-header .expand-btn-inner ul .search-parent .sticky_form .screen-reader-text {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.full-width-header .rs-header .expand-btn-inner ul .search-parent .sticky_form input {
  background: #fff;
  border: none;
  color: #000;
  outline: none;
  border-radius: 4px;
  padding: 14px 17px;
  width: 100%;
}
.full-width-header .rs-header .expand-btn-inner ul .search-parent .sticky_form button {
  background: transparent !important;
  border: medium none;
  padding: 13px 18px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  font-size: 16px;
  outline: none;
  border-radius: 0;
  color: #106eea;
}
.full-width-header .rs-header .expand-btn-inner ul .search-parent .sticky_form button i:before {
  font-size: 18px;
  font-weight: 600;
  opacity: 0.7;
  color: #333;
}
.full-width-header .rs-header .expand-btn-inner ul .search-parent.open_add_class .sticky_form {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.full-width-header .rs-header.header-main-style1 .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #126ee8;
}
.full-width-header .rs-header.header-main-style1 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-main-style1 .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-main-style1 .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-main-style1 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-main-style1 .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #FFFFFFAB !important;
}
.full-width-header .rs-header.header-main-style1 .box-layout {
  position: relative;
  border-radius: 5px 5px 5px 5px;
  max-width: 1296px;
  margin: 0 auto;
  background-color: #080D41;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: -33px;
}
.full-width-header .rs-header.header-main-style1 .menu-area .row-table .col-cell.header-logo {
  display: none;
  flex: 1;
}
.full-width-header .rs-header.header-main-style1 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  height: 68px;
  line-height: 68px;
  color: #F9F9F9;
}
.full-width-header .rs-header.header-main-style1 .expand-btn-inner ul .btn-quote a i {
  margin-right: 5px;
}
.full-width-header .rs-header.header-main-style1 .expand-btn-inner ul .btn-quote a {
  color: #FFFFFF;
  background-color: #126ee8;
  padding: 16px 25px 16px 25px;
  border-radius: 30px 30px 30px 30px;
  transition: all 0.3s ease;
}
.full-width-header .rs-header.header-main-style1 .expand-btn-inner ul .btn-quote a:hover {
  border-color: #126EE8A6;
  background-color: #126EE8A6;
}
.full-width-header .rs-header.header-main-style1 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  height: unset;
  line-height: 30px;
  color: #ffffff;
}
.full-width-header .rs-header.header-main-style1 .menu-area.sticky .box-layout {
  background: transparent;
  margin-bottom: 0;
}
.full-width-header .rs-header.header-main-style1 .menu-area.sticky .logo-area a .sticky-logo {
  display: block;
}
.full-width-header .rs-header.header-main-style1 .menu-area.sticky .col-cell.header-logo {
  display: block;
}
.full-width-header .rs-header.header-main-style1 .menu-area.sticky .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-main-style1 .menu-area.sticky .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #126EE8A6 !important;
}
.full-width-header .rs-header.header-main-style1 .menu-area.sticky .expand-btn-inner ul .btn-quote a:hover {
  border-color: #126EE8A6;
  background-color: #126EE8A6;
}
.full-width-header .rs-header.header-main-style1 .menu-area.sticky .main-menu .rs-menu ul.nav-menu li a {
  height: 80px;
  line-height: 80px;
}
.full-width-header .rs-header.header-main-style1 .menu-area.sticky .main-menu .rs-menu ul.sub-menu li a {
  height: unset;
  line-height: 30px;
}
.full-width-header .rs-header.header-main-style1 .menu-area.sticky .box-layout {
  padding-left: 0px;
  padding-right: 0px;
}
.full-width-header .rs-header.header-main-style1 .menu-area.sticky {
  transition: all 0.3s ease 0s;
  background: #080D41 !important;
}
.full-width-header .rs-header.header-style1 .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style1 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style1 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1 .main-menu .rs-menu > ul > li.current-menu-item > a {
  color: #00a775 !important;
}
.full-width-header .rs-header.header-style1 .main-menu .rs-menu ul.sub-menu li.menu-item-has-children a:before,
.full-width-header .rs-header.header-style1 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style1 .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style1 .main-menu .rs-menu ul ul > li.current-menu-item > a {
  color: #ffffffc4 !important;
}
.full-width-header .rs-header.header-style1 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #fff;
}
.full-width-header .rs-header.header-style1 .menu-area.sticky .expand-btn-inner ul .btn-quote a:hover {
  border-color: #00a775;
  background-color: #00a775;
}
.full-width-header .rs-header.header-style1 .expand-btn-inner ul .btn-quote a i {
  margin-right: 5px;
}
.full-width-header .rs-header.header-style1 .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #00a775;
}
.full-width-header .rs-header.header-style1 .expand-btn-inner ul .btn-quote a {
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #534FF800;
  padding: 17px 35px 17px 35px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #FFFFFF;
  border-radius: 55px 55px 55px 55px;
  transition: all 0.3s ease;
}
.full-width-header .rs-header.header-style1 .expand-btn-inner ul .btn-quote a:hover {
  border-color: #00a775;
  background-color: #00a775;
}
.full-width-header .rs-header.header-style1 .menu-area .rs-menu-area {
  padding-right: 0px;
}
.full-width-header .rs-header.header-style1 .menu-area .row-table .col-cell.header-logo {
  display: unset;
  flex: unset;
}
.full-width-header .rs-header.header-style1 .menu-area.sticky {
  background: #061932 !important;
}
.full-width-header .rs-header.header-style1.header-modify3 .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #f05c4e;
}
.full-width-header .rs-header.header-style1.header-modify3 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style1.header-modify3 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify3 .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header.header-style1.header-modify3 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify3 .main-menu .rs-menu > ul > li.current-menu-item > a {
  color: #f05c4e !important;
}
.full-width-header .rs-header.header-style1.header-modify3 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify3 .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style1.header-modify3 .main-menu .rs-menu ul ul > li.current-menu-item > a {
  color: #ffffffc4 !important;
}
.full-width-header .rs-header.header-style1.header-modify3 .menu-area.sticky {
  background: #282860 !important;
}
.full-width-header .rs-header.header-style1.header-modify3 .expand-btn-inner ul .btn-normal a {
  position: relative;
  overflow: hidden;
  display: inline-block;
  z-index: 1;
  font-size: 16px;
  transition: all .4s;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #f05c4e;
  padding: 17px 35px 17px 35px;
  border-radius: 0px 0px 0px 0px;
  transition: all 0.3s ease;
}
.full-width-header .rs-header.header-style1.header-modify3 .expand-btn-inner ul .btn-normal a:before {
  display: block;
  position: absolute;
  top: 0;
  z-index: -1;
  right: 0;
  height: 100%;
  width: 0;
  content: "";
  color: #000!important;
  background: #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.full-width-header .rs-header.header-style1.header-modify3 .expand-btn-inner ul .btn-normal a:hover {
  color: #f05c4e;
}
.full-width-header .rs-header.header-style1.header-modify3 .expand-btn-inner ul .btn-normal a:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.full-width-header .rs-header.header-style1.header-modify5 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  height: 110px;
  line-height: 110px;
}
.full-width-header .rs-header.header-style1.header-modify5 .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #634bf8;
}
.full-width-header .rs-header.header-style1.header-modify5 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify5 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify5 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify5 .main-menu .rs-menu > ul > li.current-menu-item > a {
  color: #634bf8 !important;
}
.full-width-header .rs-header.header-style1.header-modify5 .main-menu .rs-menu ul ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify5 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify5 .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #c9dbf3 !important;
}
.full-width-header .rs-header.header-style1.header-modify5 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #3a3a3a;
}
.full-width-header .rs-header.header-style1.header-modify5 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #F5F5F5;
  height: unset;
  line-height: 25px;
}
.full-width-header .rs-header.header-style1.header-modify5 .menu-area.sticky {
  background: #fff !important;
}
.full-width-header .rs-header.header-style1.header-modify5 .expand-btn-inner ul .btn-normal a {
  position: relative;
  overflow: hidden;
  display: inline-block;
  z-index: 1;
  font-size: 16px;
  transition: all .4s;
  font-weight: 500;
  color: #0a0a0a;
  background-color: #f8b401;
  border-radius: 10px;
  padding: 17px 35px 17px 35px;
  transition: all 0.3s ease;
}
.full-width-header .rs-header.header-style1.header-modify5 .expand-btn-inner ul .btn-normal a:hover {
  color: #0a0a0a;
  background-color: #ebd40da3;
}
.full-width-header .rs-header.header-style1.header-modify6 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  height: 86px;
  line-height: 86px;
  color: #353535;
}
.full-width-header .rs-header.header-style1.header-modify6 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify6 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify6 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify6 .main-menu .rs-menu > ul > li.current-menu-item > a {
  color: #83a300 !important;
}
.full-width-header .rs-header.header-style1.header-modify6 .main-menu .rs-menu ul ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify6 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify6 .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #c9dbf3 !important;
}
.full-width-header .rs-header.header-style1.header-modify6 .expand-btn-inner ul .btn-quote a i {
  margin-right: 5px;
}
.full-width-header .rs-header.header-style1.header-modify6 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #F5F5F5;
  height: unset;
  line-height: 30px;
}
.full-width-header .rs-header.header-style1.header-modify6 .menu-area.sticky {
  background: #f5faff !important;
}
.full-width-header .rs-header.header-style1.header-modify6 .menu-area {
  background: #f5faff;
}
.full-width-header .rs-header.header-style1.header-modify6 .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #83a300;
}
.full-width-header .rs-header.header-style1.header-modify6 .expand-btn-inner ul .btn-quote a {
  position: relative;
  overflow: hidden;
  display: inline-block;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  padding: 14px 35px 14px 35px;
  background-color: #83a300;
  border-color: none;
  border-style: none;
  border-width: none;
  transition: all 0.3s ease;
  border-radius: 15px 15px 15px 15px;
}
.full-width-header .rs-header.header-style1.header-modify6 .expand-btn-inner ul .btn-quote a:hover {
  background-color: #83A3008A;
}
.full-width-header .rs-header.header-style1.header-modify6 .expand-btn-inner ul .btn-quote a:before {
  display: block;
  position: absolute;
  top: 0;
  z-index: -1;
  right: 0;
  height: 100%;
  width: 0;
  content: "";
  color: #000!important;
  background-color: #83A3008A;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.full-width-header .rs-header.header-style1.header-modify6 .expand-btn-inner ul .btn-quote a:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.full-width-header .rs-header.header-style1.header-modify6 .menu-area.sticky .expand-btn-inner ul .btn-quote a:hover {
  border-color: #83A3008A;
  background-color: #83A3008A;
}
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #0092f2;
}
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify7 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify7 .main-menu .rs-menu > ul > li.current-menu-item > a {
  color: #0092f2 !important;
}
.full-width-header .rs-header.header-style1.header-modify7 .main-menu .rs-menu ul ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #c9dbf3 !important;
}
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #fff;
}
.full-width-header .rs-header.header-style1.header-modify7 .menu-area.sticky {
  background: #164a96 !important;
}
.full-width-header .rs-header.header-style1.header-modify7 .expand-btn-inner ul .btn-normal a {
  position: relative;
  overflow: hidden;
  display: inline-block;
  z-index: 1;
  font-size: 16px;
  transition: all .4s;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #0092f2;
  padding: 17px 35px 17px 35px;
  border-radius: 180px 180px 180px 180px;
  transition: all 0.3s ease;
}
.full-width-header .rs-header.header-style1.header-modify7 .expand-btn-inner ul .btn-normal a:before {
  display: block;
  position: absolute;
  top: 0;
  z-index: -1;
  right: 0;
  height: 100%;
  width: 0;
  content: "";
  color: #000!important;
  background: #0092F266;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.full-width-header .rs-header.header-style1.header-modify7 .expand-btn-inner ul .btn-normal a:hover {
  background: #0092F266;
}
.full-width-header .rs-header.header-style1.header-modify7 .expand-btn-inner ul .btn-normal a:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.full-width-header .rs-header.header-style3 .menu-area .row-table .col-cell.header-logo {
  display: flex;
  flex: 1;
}
.full-width-header .rs-header.header-style3 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  
    height: 32px;
    line-height: 25px;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 19px; */
    letter-spacing: 0.04em;
    color: #fff;
}
.full-width-header .rs-header.header-style3 .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style3 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style3 .main-menu .rs-menu > ul > li.current-menu-item > a {
  color: #fff !important;
    border-bottom: 4px solid #fff;
	
}
.full-width-header .rs-header.header-style3 .main-menu .rs-menu ul.sub-menu li.menu-item-has-children a:before,
.full-width-header .rs-header.header-style3 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style3 .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style3 .main-menu .rs-menu ul ul > li.current-menu-item > a {
  color: #ccc !important;
}
.full-width-header .rs-header.header-style3 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  font-size: 15px;
  font-weight: 500;
  height: unset;
  line-height: 25px;
  color: #ffffff;
}
.full-width-header .rs-header.header-style3 .menu-area .main-menu .rs-menu ul.nav-menu li {
  margin-right: 30px;
}
.full-width-header .rs-header.header-style3 .menu-area .rs-menu-area {
  
}
.full-width-header .rs-header.header-style3 .expand-btn-inner ul .address-box .address-item {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header.header-style3 .expand-btn-inner ul .address-box .address-item .address-icon {
  padding-left: 30px;
}
.full-width-header .rs-header.header-style3 .expand-btn-inner ul .address-box .address-item .address-text {
  padding-left: 14px;
}
.full-width-header .rs-header.header-style3 .expand-btn-inner ul .address-box .address-item .address-text .label {
  color: #606060 !important;
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  display: block;
}
.full-width-header .rs-header.header-style3 .expand-btn-inner ul .address-box .address-item .address-text a {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #00204A;
}
.full-width-header .rs-header.header-style3 .expand-btn-inner ul .address-box .address-item .address-text a:hover {
  color: #1a73e9;
}
.full-width-header .rs-header.header-style3 .expand-btn-inner ul .nav-link .nav-expander {
  background: transparent;
}
.full-width-header .rs-header.header-style3 .expand-btn-inner ul .nav-link .nav-expander .bar span {
  background: #fff;
}
.full-width-header .rs-header.header-style3.header-modify1 .menu-area {
  position: relative;
  background-color: #F5FAFF;
}
.full-width-header .rs-header.header-style3.header-modify1 .menu-area:before {
  content: "";
  background: #1a73e9;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50px;
  height: 100%;
  width: 30%;
  z-index: 0;
  transform: skew(30deg);
}
.full-width-header .rs-header.header-style3.header-modify1 .expand-btn-inner ul .btn-quote a {
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #126EE8;
  padding: 20px 38px 20px 38px;
  border-radius: 50px 50px 50px 50px;
  transition: all 0.3s ease;
}
.full-width-header .rs-header.header-style3.header-modify1 .expand-btn-inner ul .btn-quote a:hover {
  color: #FFFFFF;
  border-color: #FFFFFFE0;
  background-color: #126EE8A6;
}
.full-width-header .rs-header.header-style3.header-modify2 .menu-area .rs-menu-area {
  padding-right: 15px;
}
.full-width-header .rs-header.header-style3.header-modify2 .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #3a15fb;
}
.full-width-header .rs-header.header-style3.header-modify2 .expand-btn-inner ul .search-btn {
  border-right: unset;
  border-left: 1px solid #9e9e9e38;
  line-height: 13px;
  padding-left: 25px;
}
.full-width-header .rs-header.header-style3.header-modify2 .expand-btn-inner ul .header-btn-wrapper a {
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
}
.full-width-header .rs-header.header-style3.header-modify2 .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style3.header-modify2 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style3.header-modify2 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style3.header-modify2 .expand-btn-inner ul .address-box .address-item .address-text a:hover,
.full-width-header .rs-header.header-style3.header-modify2 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style3.header-modify2 .main-menu .rs-menu > ul > li.current-menu-item > a {
  color: #3a15fb !important;
}
.full-width-header .rs-header.header-style3.header-modify2 .main-menu .rs-menu ul.sub-menu li.menu-item-has-children a:before,
.full-width-header .rs-header.header-style3.header-modify2 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style3.header-modify2 .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style3.header-modify2 .main-menu .rs-menu ul ul > li.current-menu-item > a {
  color: #ccc !important;
}
.full-width-header .rs-header.header-style3.header-modify2 .expand-btn-inner ul .header-btn-wrapper {
  background-color: transparent;
  background-image: linear-gradient(94deg, #1a73e9 0%, #4200ff 100%);
  padding: 15px 35px 15px 35px;
  border-radius: 10px 10px 10px 10px;
  margin-right: 25px;
}
.full-width-header .rs-header.header-style3.header-modify2 .expand-btn-inner ul .address-box .address-item .address-icon {
  padding-left: 0px;
  display: inline-block;
  position: relative;
  background-color: #E8F1FD;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  line-height: 60px !important;
  transform: skewX(0deg);
  border-radius: 100%;
  text-align: center;
}
.full-width-header .rs-header.header-style3.header-modify2 .expand-btn-inner ul .address-box .address-item .address-icon i {
  position: relative;
  top: 2px;
}
.full-width-header .rs-header.header-style3.header-modify2 .expand-btn-inner ul .address-box .address-item .address-icon i:before {
  font-size: 21px;
  color: #3a15fb;
}
.full-width-header .rs-header.header-style3.header-modify2 .expand-btn-inner ul .nav-link .nav-expander {
  background: #3a15fb;
}
.full-width-header .rs-header.header-style3.header-modify2 .expand-btn-inner ul .nav-link .nav-expander .bar span {
  background: #fff;
}
.full-width-header .rs-header.header-style3.header-modify4 .box-layout {
  position: relative;
  border-radius: 0px 0px 10px 10px;
  max-width: 1360px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 11px 24px 0px rgba(0, 0, 0, 0.08);
  padding-left: 30px;
  padding-right: 30px;
}
.full-width-header .rs-header.header-style3.header-modify4 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style3.header-modify4 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style3.header-modify4 .expand-btn-inner ul .address-box .address-item .address-text a:hover,
.full-width-header .rs-header.header-style3.header-modify4 .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style3.header-modify4 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style3.header-modify4 .main-menu .rs-menu > ul > li.current-menu-item > a {
  color: #b65df0 !important;
}
.full-width-header .rs-header.header-style3.header-modify4 .main-menu .rs-menu ul.sub-menu li.menu-item-has-children a:before,
.full-width-header .rs-header.header-style3.header-modify4 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style3.header-modify4 .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style3.header-modify4 .main-menu .rs-menu ul ul > li.current-menu-item > a {
  color: #ccc !important;
}
.full-width-header .rs-header.header-style3.header-modify4 .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #b65df0;
}
.full-width-header .rs-header.header-style3.header-modify4 .main-menu .rs-menu .nav-menu .mega-menu {
  left: -27px;
  min-width: 1360px;
}
.full-width-header .rs-header.header-style3.header-modify4 .menu-area.sticky {
  background: transparent !important;
  box-shadow: unset;
}
.full-width-header .rs-header.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.full-width-header .mobile-menu {
  display: none;
}
.full-width-header .mobile-menu .mobile-logo {
  float: left;
}
.full-width-header .mobile-menu .mobile-logo img {
  max-height: 30px;
}
.full-width-header .mobile-menu .rs-menu-toggle {
  color: #ffffff;
  float: right;
}
.full-width-header .mobile-menu .rs-menu-toggle.primary {
  color: #126ee8;
}
.full-width-header .mobile-menu .rs-menu-toggle.secondary {
  color: #f45d48;
}
.full-width-header .mobile-menu .rs-menu-toggle .nav-dots {
  position: absolute;
  right: 0;
  width: 25px;
  height: 2px;
  background: #fff;
  z-index: 11;
  top: 23px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot1,
.full-width-header .mobile-menu .rs-menu-toggle .dot3 {
  width: 15px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot2 {
  top: 28px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot3 {
  top: 34px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot4 {
  top: 40px;
}
.full-width-header .right_menu_togle {
  background: #0D4058;
  box-shadow: 0px 0px 10px 0px #eee;
  padding: 90px 60px 60px 50px;
  font-size: 1.15em;
  max-width: 480px;
  width: 100%;
  right: -500px;
  z-index: 999999;
  transition: all 0.3s ease;
  position: fixed;
  overflow-y: auto;
}
.full-width-header .right_menu_togle .close-btn {
  overflow: visible;
  position: absolute;
  top: 10px;
  display: block;
  z-index: 99;
  right: 57px;
  cursor: pointer;
  transition: all .3s ease;
}
.full-width-header .right_menu_togle .close-btn .nav-close {
  position: absolute;
  top: 23px;
  display: block;
  z-index: 99;
  right: 3px;
  cursor: pointer;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 1px !important;
  height: 25px !important;
  background: #fff;
  border: none !important;
  transition: all 0.3s ease;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line span.line2 {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.full-width-header .right_menu_togle .close-btn:hover .nav-close .line span {
  background: #126ee8;
}
.full-width-header .right_menu_togle .canvas-logo {
  padding-left: 0;
  padding-bottom: 35px;
}
.full-width-header .right_menu_togle .canvas-logo img {
  max-height: 45px;
}
.full-width-header .right_menu_togle .offcanvas-text {
  border-bottom: 1px solid rgba(62, 62, 62, 0.1);
  margin-bottom: 45px;
  padding-bottom: 24px;
}
.full-width-header .right_menu_togle .offcanvas-text p {
  font-size: 16px;
  line-height: 30px;
  color: #333333;
  margin: 0px 0 38px;
}
.full-width-header .right_menu_togle .canvas-contact {
  margin-top: 50px;
}
.full-width-header .right_menu_togle .canvas-contact ul {
  display: flex;
  align-items: center;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-signin .signin-button {
  font-size: 16px;
  background: transparent;
  height: 60px;
  line-height: 62px;
  margin-right: 25px;
  color: #080d41;
  font-weight: 600;
  display: inline-block;
  border-radius: 50px;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-signin .signin-button .sign-text i {
  font-weight: 400;
  margin-right: 5px;
  font-family: 'remixicon' !important;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-signin .signin-button:hover {
  color: #126ee8;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-quote a {
  font-size: 16px;
  font-weight: 600;
  background-color: #126ee8;
  display: inline-block;
  position: relative;
  color: #ffffff;
  padding: 13px 27px 13px 27px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #126ee8;
  border-radius: 55px 55px 55px 55px;
  transition: all 0.3s ease;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-quote a .btn-text {
  position: relative;
  z-index: 4;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-quote a .btn-text i {
  font-weight: 400;
  top: 2px;
  margin-right: 4px;
  font-family: 'remixicon' !important;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-quote a:hover {
  border-color: #f45d48;
  background-color: #f45d48;
}
.full-width-header .mobile-navbar-menu .sub-menu {
  display: none;
  position: relative;
  left: 0;
  width: 100%;
  margin: 0;
  text-align: left;
}
.full-width-header .mobile-navbar-menu .nav-menu li {
  position: relative;
}
.full-width-header .mobile-navbar-menu .nav-menu li a {
  border-bottom: 1px solid rgb(165 165 165);
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 10px 0;
  line-height: 30px;
  width: 100%;
}
.full-width-header .mobile-navbar-menu .nav-menu li li a {
  padding-left: 25px;
}
.full-width-header .mobile-navbar-menu .nav-menu li li li a {
  padding-left: 50px;
}
.full-width-header .mobile-navbar-menu span.submenu-button {
  position: absolute;
  z-index: 99;
  right: 0;
  top: 5px;
  display: block;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.full-width-header .mobile-navbar-menu span.submenu-button:before,
.full-width-header .mobile-navbar-menu span.submenu-button:after {
  position: absolute;
  top: 24px;
  right: 10px;
  width: 20px;
  height: 1px;
  display: block;
  background: #fff;
  z-index: 2;
  content: '';
  right: 12px;
  width: 12px;
}
.full-width-header .mobile-navbar-menu span.submenu-button:before {
  width: 1px;
  height: 12px;
  right: 17px;
  top: 18px;
}
.full-width-header .mobile-navbar-menu span.submenu-button em {
  display: block;
  position: absolute;
  right: 0;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
}
.full-width-header .mobile-navbar-menu .hash-has-sub span.submenu-button {
  width: 100%;
  cursor: pointer;
  top: 0;
  height: 50px;
}
.full-width-header .mobile-navbar-menu .submenu-button.submenu-opened:before {
  display: none;
}
.full-width-header .mobile-navbar-menu ul ul li,
.full-width-header .mobile-navbar-menu li:hover > ul > li {
  height: auto;
}
.full-width-header .mobile-navbar-menu ul.nav-menu > li:first-child > span.submenu-button:after {
  top: 19px;
}
.full-width-header .mobile-navbar-menu ul.nav-menu > li:first-child > span.submenu-button:before {
  top: 13px;
}
.full-width-header .mobile-navbar-menu .nav-menu li.active-menu a,
.full-width-header .mobile-navbar-menu .nav-menu li a:hover {
  color: #126ee8;
}
body.modal-open {
  padding: 0 !important;
}
body .modal.show .modal-dialog {
  width: 100%;
  max-width: 500px;
}
body .modal {
  z-index: 9999;
}
body .search-modal {
  padding: 0 !important;
  background: rgba(187, 11, 11, 0.9);
}
body .search-modal .modal-content {
  position: initial;
  border: 0;
  background: unset;
}
body .search-modal .search-block form {
  position: relative;
}
body .search-modal .search-block form input {
  background: 0 0;
  border: none;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  outline: none;
  padding: 14px 17px;
  border-radius: unset;
}
body .search-modal .search-block form button {
  background: transparent;
  border: none;
  padding: 5px 20px;
  color: #ffffff;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  position: absolute;
  content: '';
  right: 0;
  top: 11px;
}
body .search-modal .search-block form button i:before {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  opacity: .7;
}
body .search-modal .search-block ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #fff;
  opacity: 1;
}
body .search-modal .search-block ::-moz-placeholder {
  /* Firefox 19+ */

  color: #fff;
  opacity: 0.3;
}
body .search-modal .search-block :-ms-input-placeholder {
  /* IE 10+ */

  color: #fff;
  opacity: 1;
}
body .search-modal .search-block :-moz-placeholder {
  /* Firefox 18- */

  color: #fff;
  opacity: 1;
}
body .search-modal .close {
  position: fixed;
  right: 50px;
  top: 50px;
  background: #ffffff;
  color: #0a0a0a;
  width: 40px;
  height: 40px;
  line-height: 43px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all .5s ease;
  opacity: unset;
  box-shadow: unset;
  border: none;
}
body .search-modal .close i:before {
  font-size: 23px;
  position: relative;
  top: 4px;
  left: 0px;
  font-weight: 700;
}
body .search-modal .close:hover {
  opacity: 0.8 !important;
}
/*------------------------------------
	04. Sticky Menu CSS
--------------------------------------*/
.menu-sticky {
  background: #fff;
  margin: 0;
  z-index: 999;
  width: 100%;
  top: 0;
  position: relative;
}
@-webkit-keyframes sticky-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes sticky-animation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* ------------------------------------
	05. About Section CSS
---------------------------------------*/
.rs-about.about-style1 .about-img {
  margin: 0 0px 0 -80px;
}
.rs-about.about-style1 .feature-btn {
  display: flex;
  align-items: center;
}
.rs-about.about-style1 .feature-btn li {
  display: inline-block;
  margin-right: 24px;
}
.rs-about.about-style1 .feature-btn li:last-child {
  margin-right: 0;
}
.rs-about.about-style1 .about-level2 .about-images {
  position: relative;
}
.rs-about.about-style1 .about-level2 .about-images img {
  position: relative;
  right: -65px;
}
.rs-about.about-style1 .about-level2 .about-images .imgae-shape {
  position: absolute;
}
.rs-about.about-style1 .about-level2 .about-images .imgae-shape.one {
  left: -65px;
  top: 19%;
}
.rs-about.about-style1 .about-level2 .about-images .imgae-shape.two {
  right: -65px;
  top: 19%;
}
.rs-about.about-style1.about-modify1 .about-img {
  position: relative;
  margin: 0 0px 0 -85px;
}
.rs-about.about-style1.about-modify1 .about-img .about-shape {
  position: absolute;
  right: 27px;
  bottom: 10%;
}
.rs-about.about-style1.about-modify1 .about-img .about-shape img {
  border-radius: 121.5px;
}
.rs-about.about-style1.about-modify1 .feature-btn li {
  margin-right: 14px;
}
.rs-about.about-style2 .about-img {
  margin: 0px -50px 0px 0px;
}
.rs-about.about-style3 .about-item .about-img {
  position: relative;
}
.rs-about.about-style3 .about-item .about-img img {
  border-radius: 10px 10px 10px 10px;
}
.rs-about.about-style3 .about-item .about-img .rs-videos {
  position: absolute;
  bottom: 84px;
  left: 110px;
}
.rs-about.about-style3 .about-item .about-img .rs-videos.video-style1.main-video .video-item .overly-border a i {
  font-size: 20px;
  line-height: 80px;
  color: #126ee8;
}
.rs-about.about-style3 .about-item .about-img .rs-videos.video-style1.main-video .video-item .overly-border a,
.rs-about.about-style3 .about-item .about-img .rs-videos.video-style1.main-video .video-item .overly-border a:before {
  background: #ffffff;
}
.rs-about.about-style3 .about-item .about-img .rs-counter-list {
  position: absolute;
  bottom: 0%;
  padding: 60px 66px 27px 50px;
  background-color: transparent;
  border-radius: 0px 0px 0px 10px;
}
.rs-about.about-style3 .about-item .about-img .rs-counter-list .count-text .count-number .rs-count {
  font-size: 50px;
  line-height: 65px;
  font-weight: 700;
  color: #ffffff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}
.rs-about.about-style3 .about-item .about-img .rs-counter-list .count-text .count-number .prefix {
  font-size: 50px;
  line-height: 65px;
  font-weight: 700;
  color: #ffffff;
}
.rs-about.about-style3 .about-item .about-img .rs-counter-list .count-text .title {
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0px 0px 0px;
  line-height: 18px;
  color: #ffffff;
  transition: all 0.3s ease 0s;
  display: block;
}
.rs-about.about-style3 .about-item .about-img .addon-services {
  position: absolute;
  right: 0;
  max-width: 400px;
  bottom: -70px;
}
.rs-about.about-style3 .about-item .about-img .addon-services .services-content {
  padding: 35px 40px 35px 40px;
  background-color: #1A73E9;
  /* background-image: url(assets/images/about/style2/shape.png); */
  background-position: center right;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
}
.rs-about.about-style3 .about-item .about-img .addon-services .services-content .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin: 0 0 10px;
}
.rs-about.about-style3 .about-item .about-img .addon-services .services-content .title a {
  color: #FFFFFF;
}
.rs-about.about-style3 .about-item .about-img .addon-services .services-content .desc-text {
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  color: #FFFFFF;
  margin: 0px 0px 17px 0px;
}
.rs-about.about-style3 .about-wrapper .about-img {
  position: relative;
  margin: 0 0 0 -80px;
}
.rs-about.about-style3 .about-wrapper .about-img img {
  border-radius: 30px;
}
.rs-about.about-style3 .about-wrapper .about-img .rocket-wrap {
  position: absolute;
  right: -53px;
  top: -40px;
}
.rs-about.about-style3 .about-wrapper .about-img .rocket-wrap img {
  max-width: 187px;
}
.rs-about.about-style3 .about-wrapper .about-img .rocket-wrap .icon-part img {
  position: relative;
  top: -126px;
  max-width: 70px;
  right: -56px;
}
.rs-about.about-style3 .about-wrapper .about-img .about-counter {
  position: absolute;
  left: -70px;
  bottom: 50px;
  padding: 28px 20px 28px 20px;
  background-color: #1A73E9;
  border-radius: 15px 15px 15px 15px;
}
.rs-about.about-style3 .about-wrapper .about-img .about-counter .count-number .rs-count {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #fff;
}
.rs-about.about-style3 .about-wrapper .about-img .about-counter .count-number .prefix {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #fff;
}
.rs-about.about-style3 .about-wrapper .about-img .about-counter .title {
  font-size: 18px;
  color: #FFFFFF;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.rs-about.about-style3 .about-wrapper .about-btn-inner {
  display: flex;
  align-items: center;
}
.rs-about.about-style3 .about-wrapper .about-btn-inner li {
  display: inline-block;
  margin-right: 25px;
}
.rs-about.about-style3 .about-wrapper .about-btn-inner li:last-child {
  margin-right: 0;
}
.rs-about.about-style3.about-modify3 .feature-provide {
  background-color: #f1f7ff;
  padding: 130px 100px 130px 320px;
  border-radius: 40px 0px 0px 40px;
}
.rs-about.about-style3.about-modify3 .feature-woman {
  /* background-image: url(assets/images/about/about-h9.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0px 40px 40px 0px;
}
.rs-about.about-style4 .wrapper-content .about-img {
  position: relative;
  margin: 0 0 0 -80px;
}
.rs-about.about-style4 .wrapper-content .about-img img {
  border-radius: 30px;
}
.rs-about.about-style4 .wrapper-content .about-img .shape-wrap {
  position: absolute;
  right: -57px;
  bottom: -110px;
}
.rs-about.about-style4 .wrapper-content .about-img .shape-wrap img {
  max-width: 310px;
}
.rs-about.about-style4 .wrapper-content .about-img .about-counter {
  position: absolute;
  left: -80px;
  top: 70px;
  padding: 20px 20px 20px 24px;
  max-width: 166px;
  background-color: #4301FF;
  transform: skewX(-4deg);
  border-radius: 10px;
}
.rs-about.about-style4 .wrapper-content .about-img .about-counter .count-number .rs-count {
  font-size: 30px;
  line-height: 32px;
  font-style: italic;
  font-weight: 700;
  color: #fff;
}
.rs-about.about-style4 .wrapper-content .about-img .about-counter .count-number .prefix {
  font-size: 30px;
  line-height: 32px;
  font-style: italic;
  font-weight: 700;
  color: #fff;
}
.rs-about.about-style4 .wrapper-content .about-img .about-counter .title {
  font-size: 16px;
  line-height: 26px;
  color: #FFFFFFD9;
  font-weight: 500;
  margin: 8px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.rs-about.about-style4 .wrapper-content .sign-img img {
  max-width: 220px;
}
.rs-about.about-style4 .wrapper-content .addon-services {
  display: flex;
}
.rs-about.about-style4 .wrapper-content .addon-services .services-icon {
  margin-right: 25px;
}
.rs-about.about-style4 .wrapper-content .addon-services .services-icon img {
  width: 64px;
}
.rs-about.about-style4 .wrapper-content .addon-services .services-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #080d41;
  margin: 0 0 0px;
}
.rs-about.about-style4 .wrapper-content .addon-services .services-content .services-txt {
  color: #3a3a3a;
  margin: 0 0 0;
}
/* ------------------------------------
	06. Ebook Read Section CSS
---------------------------------------*/
.rs-book-store.ebook-style1 .book-item .read-wrap {
  position: relative;
  overflow: visible;
}
.rs-book-store.ebook-style1 .book-item .read-wrap .read-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.rs-book-store.ebook-style1 .book-item .read-wrap .read-img a img {
  border-radius: 20px 20px 0 0;
  transform: scale(1.1);
  transition: 0.4s;
}
.rs-book-store.ebook-style1 .book-item .read-wrap .book-content {
  padding: 30px 25px 30px 25px;
  border-radius: 0px 0px 20px 20px;
  background-color: #ffffff;
  box-shadow: 0px 30px 60px 0px rgba(0, 0, 1, 0.05);
  text-align: center;
}
.rs-book-store.ebook-style1 .book-item .read-wrap .book-content .sub-text {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: #83a300;
  display: block;
  margin: 0 0 10px;
}
.rs-book-store.ebook-style1 .book-item .read-wrap .book-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 0 0 10px;
}
.rs-book-store.ebook-style1 .book-item .read-wrap .book-content .title a {
  color: #080d41;
}
.rs-book-store.ebook-style1 .book-item .read-wrap .book-content .title a:hover {
  color: #83a300;
}
.rs-book-store.ebook-style1 .book-item .read-wrap .book-content .price {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #3e3e3e;
  margin: 0 0 0px;
}
.rs-book-store.ebook-style1 .book-item .read-wrap:hover .read-img a img {
  transform: scale(1.2);
}
.rs-book-store.ebook-style1.ebook-modify1 .sign-content .sign-img img {
  margin-bottom: 20px;
}
.rs-book-store.ebook-style1.ebook-modify1 .sign-content .text-part .title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #080d41;
  margin: 0 0 0;
}
/* ------------------------------------
	07. Feature Section CSS
---------------------------------------*/
.rs-feature.feature-style1 .addon-services {
  text-align: left;
  display: flex;
  align-items: start;
}
.rs-feature.feature-style1 .addon-services .services-icon {
  margin: 0px 23px 0px 0px;
  background-color: transparent;
  background-image: linear-gradient(180deg, #fcfcfc 0%, #fdfdfd 100%);
  padding: 15px 15px 15px 15px;
  border-radius: 50px 50px 50px 50px;
}
.rs-feature.feature-style1 .addon-services .services-icon img {
  max-width: 34px;
}
.rs-feature.feature-style1 .addon-services .services-content .title {
  font-size: 19px;
  line-height: 29px;
  font-weight: 700;
  color: #121933;
  margin: 0 0 10px;
}
.rs-feature.feature-style1 .addon-services .services-content .services-txt {
  font-size: 17px;
  line-height: 27px;
  font-weight: 500;
  color: #3e3e3e;
  margin: 0 0 0px;
}
.rs-feature.feature-style1 .feature-list {
  background-color: #FFFFFF;
  padding: 25px 25px 25px 25px;
  border-radius: 10px 10px 10px 10px;
}
.rs-feature.feature-style1 .feature-img {
  position: relative;
}
.rs-feature.feature-style1 .feature-img .feature-shape {
  position: absolute;
  bottom: -10%;
  right: -7%;
}
.rs-feature.feature-style1 .feature-img .feature-shape img {
  max-width: 215px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 30px 30px 60px 0px rgba(84, 99, 255, 0.2);
}
.rs-feature.feature-style1 .feature-wrap .feature-border {
  position: relative;
}
.rs-feature.feature-style1 .feature-wrap .feature-border:before {
  content: '';
  width: 640px;
  height: 1px;
  background-color: #3e3e3e;
  opacity: 30%;
  bottom: 29px;
  position: absolute;
}
.rs-feature.feature-style1 .morphine-wrap {
  position: relative;
  z-index: 11;
  left: -7%;
}
.rs-feature.feature-style1 .morphine-wrap img {
  padding: 0px 0px 20px 40px;
  background-color: transparent;
  background-image: linear-gradient(180deg, #ffe43b 0 0%, #ffe43b 100%);
}
.rs-feature.feature-style1 .useful-wrap {
  position: relative;
  z-index: 11;
  right: -16%;
}
.rs-feature.feature-style1 .useful-wrap .morphine-wrap {
  z-index: unset;
}
.rs-feature.feature-style1 .useful-wrap .morphine-wrap img {
  padding: 0px 0px 20px 0px;
  background-color: transparent;
}
.rs-feature.feature-style1 .useful-wrap .feature-shape {
  position: absolute;
  bottom: 0;
}
.rs-feature.feature-style1 .useful-wrap .feature-shape img {
  width: 30.698%;
  max-width: 35.698%;
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 30px 30px 60px 0px rgba(84, 99, 255, 0.2);
}
.rs-feature.feature-style1 .useful-wrap .feature-shape.one {
  left: 74px;
  bottom: -5%;
}
.rs-feature.feature-style1 .feature-btn {
  display: flex;
  align-items: center;
}
.rs-feature.feature-style1 .feature-btn li {
  display: inline-block;
  margin-right: 25px;
}
.rs-feature.feature-style1 .feature-btn li:last-child {
  margin-right: 0;
}
.rs-feature.feature-style1 .feature-counter {
  position: absolute;
  bottom: 0;
}
.rs-feature.feature-style1 .feature-counter .feature-counter-list {
  width: 200px;
  text-align: left;
  box-shadow: -25px 25px 40px rgba(0, 0, 1, 0.1);
  border-radius: 13px;
  background-color: #ffffff;
  padding: 17px 25px 17px 20px;
  display: inline-block;
}
.rs-feature.feature-style1 .feature-counter .feature-counter-list .count-text .count-number .rs-count {
  font-size: 25px;
  line-height: 35px;
  font-weight: 700;
  color: #634bf8;
}
.rs-feature.feature-style1 .feature-counter .feature-counter-list .count-text .count-number .prefix {
  font-size: 25px;
  line-height: 35px;
  font-weight: 700;
  color: #634bf8;
}
.rs-feature.feature-style1 .feature-counter .feature-counter-list .count-text .title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #3e3e3e;
  margin: 0px 0px 0px 0px;
}
.rs-feature.feature-style1 .feature-counter.count-box1 {
  top: 2%;
  left: -17%;
}
.rs-feature.feature-style1 .feature-skillbar {
  position: absolute;
  right: -32px;
  top: 24px;
}
.rs-feature.feature-style1 .feature-skillbar .cl-skill-bar {
  box-shadow: -25px 25px 40px rgba(0, 0, 1, 0.1);
  border-radius: 13px;
  background-color: #ffffff;
  padding: 17px 25px 17px 20px;
  width: 280px;
}
.rs-feature.feature-style1 .feature-skillbar .cl-skill-bar .skillbar-title {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #3e3e3e;
}
.rs-feature.feature-style1 .feature-skillbar .cl-skill-bar .skillbar {
  height: 7px;
  line-height: 7px;
  background: #E9EBFF;
  margin: 10px 0 10px;
  border-radius: 50px 50px 50px 50px;
  position: relative;
  width: 100%;
}
.rs-feature.feature-style1 .feature-skillbar .cl-skill-bar .skillbar .skillbar-bar {
  height: 7px;
  line-height: 7px;
  background: #634bf8;
  border-radius: 50px 50px 50px 50px;
  margin-bottom: 0;
  overflow: visible !important;
  width: 0;
}
.rs-feature.feature-style1 .feature-skillbar .cl-skill-bar .skillbar .skill-bar-percent {
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: -38px;
  font-size: 14px;
  font-weight: 500;
  color: #634bf8;
}
.rs-feature.feature-style1 .feature-skillbar .cl-skill-bar .skillbar.margin-no {
  margin: 10px 0 0;
}
.rs-feature.feature-style1.feature-modify1 .images-wrap {
  position: relative;
  z-index: 11;
  right: -14%;
}
.rs-feature.feature-style1.feature-modify1 .images-wrap .feature-shape {
  position: absolute;
  bottom: 0;
}
.rs-feature.feature-style1.feature-modify1 .images-wrap .feature-shape.one {
  right: 3%;
  bottom: -15%;
}
.rs-feature.feature-style1.feature-modify1 .images-wrap .feature-counter {
  position: absolute;
  bottom: 0;
}
.rs-feature.feature-style1.feature-modify1 .images-wrap .feature-counter .feature-counter-list .count-text .count-number .prefix,
.rs-feature.feature-style1.feature-modify1 .images-wrap .feature-counter .feature-counter-list .count-text .count-number .rs-count {
  font-size: 25px;
  font-weight: 600;
  line-height: 35px;
  color: #f05c4e;
}
.rs-feature.feature-style1.feature-modify1 .images-wrap .feature-counter .feature-counter-list .count-text .title {
  font-size: 14px;
  line-height: 20px;
  color: #3e3e3e;
}
.rs-feature.feature-style1.feature-modify1 .images-wrap .feature-counter .feature-counter-list {
  text-align: left;
  box-shadow: 25px 25px 40px 0px rgba(0, 0, 1, 0.1);
  width: 185px;
  border-radius: 7px 7px 7px 7px;
  background-color: #FFFFFF;
  background-image: unset;
  padding: 15px 0px 15px 18px;
}
.rs-feature.feature-style1.feature-modify1 .images-wrap .feature-counter.count-box1 {
  top: 13%;
  left: -13%;
}
.rs-feature.feature-style1.feature-modify2 .feature-images {
  position: relative;
}
.rs-feature.feature-style1.feature-modify2 .feature-images .morphine-wrap img {
  padding: 0px 0px 20px 40px;
  background-color: #FFE43B00;
}
.rs-feature.feature-style1.feature-modify2 .feature-images .circle-shape {
  position: absolute;
  right: 6%;
  bottom: -4%;
  z-index: -11;
}
.rs-feature.feature-style1.feature-modify3 .feature-provide {
  background-color: #f1f7ff;
  padding: 130px 100px 130px 168px;
  border-radius: 40px 0px 0px 40px;
}
.rs-feature.feature-style1.feature-modify3 .feature-woman {
  /* background-image: url(assets/images/feature/feature-img5.html); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0px 40px 40px 0px;
}
.rs-feature.feature-style1.feature-modify4 .morphine-wrap {
  left: 4%;
}
.rs-feature.feature-style1.feature-modify4 .feature-item .feature-icon {
  width: 55px;
  height: 55px;
  line-height: 55px;
  background-color: #5463FF;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
}
.rs-feature.feature-style1.feature-modify4 .feature-item .feature-icon img {
  max-width: 23px;
}
.rs-feature.feature-style1.feature-modify4 .feature-vector-img {
  text-align: center;
}
.rs-feature.feature-style1.feature-modify4 .feature-vector-img img {
  max-width: 55%;
}
.rs-feature.feature-style1.feature-modify4 .feature-morphine3 .morphine-wrap {
  left: -6%;
}
.rs-feature.feature-style2 .feature-btn {
  display: flex;
  align-items: center;
}
.rs-feature.feature-style2 .feature-btn li {
  display: inline-block;
  margin-right: 26px;
}
.rs-feature.feature-style2 .feature-btn li:last-child {
  margin-right: 0;
}
.rs-feature.feature-style2 .images-wrap {
  position: relative;
  z-index: 11;
  right: -13%;
}
.rs-feature.feature-style2 .images-wrap .morphine-wrap img {
  padding: 0px 0px 20px 40px;
}
.rs-feature.feature-style2 .images-wrap .shape-img {
  position: absolute;
  left: 15px;
  top: -7px;
  z-index: -11;
}
.rs-feature.feature-style2 .useful-wrap {
  position: relative;
  left: -12%;
  z-index: 11;
}
.rs-feature.feature-style2 .useful-wrap .morphine-wrap img {
  padding: 0px 0px 20px 40px;
}
.rs-feature.feature-style2 .useful-wrap .shape-img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -11;
}
.rs-feature.feature-style2 .feature-wrap .feature-border {
  position: relative;
}
.rs-feature.feature-style2 .feature-wrap .feature-border:before {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #3e3e3e;
  opacity: 30%;
  bottom: 29px;
  position: absolute;
}
.rs-feature.feature-style2.feature-modify4 .feature-item {
  display: flex;
  align-items: center;
}
.rs-feature.feature-style2.feature-modify4 .feature-item .feature-icon {
  display: inline-block;
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  background-color: #00a775;
  border-radius: 15px;
  transition: all 0.3s;
  margin-right: 20px;
}
.rs-feature.feature-style2.feature-modify4 .feature-item .feature-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #080d41;
  margin: 0 0 5px;
}
.rs-feature.feature-style2.feature-modify4 .feature-item .feature-content .desc {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: #3e3e3e;
  margin: 0 0 0;
}
.rs-feature.feature-style2.feature-modify4 .feature-images {
  position: relative;
  right: -12%;
}
.rs-feature.feature-style2.feature-modify4 .feature-images .feature-shape {
  position: absolute;
  left: -20%;
  bottom: 10px;
}
.rs-feature.feature-style2.feature-modify4 .feature-images .feature-shape img {
  border-radius: 121.5px;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap {
  position: relative;
  right: 6%;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap .feature-img {
  position: absolute;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap .feature-img.one {
  left: -6%;
  top: 10%;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap .feature-img.two {
  top: -8px;
  right: -23px;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap .feature-img.three {
  right: -22px;
  bottom: -50px;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap .feature-img.three img {
  max-width: 380px;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap2 {
  position: relative;
  right: -12%;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap2 .feature-img {
  position: absolute;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap2 .feature-img.one {
  left: -6%;
  top: 7%;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap2 .feature-img.two {
  top: -64px;
  left: -65px;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap2 .feature-img.three {
  right: -30px;
  bottom: -70px;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap2 .feature-img.three img {
  max-width: 380px;
}
.rs-feature.feature-style2.feature-modify5 .images-wrap3 {
  position: relative;
  right: -14%;
}
.rs-feature.feature-style3 .feature-btn {
  display: flex;
  align-items: center;
}
.rs-feature.feature-style3 .feature-btn li {
  display: inline-block;
  margin-right: 26px;
}
.rs-feature.feature-style3 .feature-btn li:last-child {
  margin-right: 0;
}
.rs-feature.feature-style3 .feature-services-img {
  position: relative;
  text-align: right;
}
.rs-feature.feature-style3 .feature-services-img img {
  width: 85%;
}
.rs-feature.feature-style3 .feature-ser2-img {
  text-align: center;
}
.rs-feature.feature-style3 .feature-ser2-img img {
  width: 550px;
}
.rs-feature.feature-style3 .feature-bg-wrap .content-wrap {
  background-color: #F7F7FF;
  /* background-image: url(assets/images/bg/service-page-bg.png); */
  margin: 0px 60px 0px -96px;
  padding: 80px 80px 80px 80px;
}
.rs-feature.feature-style4 .feature-wrap .feature-item {
  display: flex;
  align-items: center;
}
.rs-feature.feature-style4 .feature-wrap .feature-item .feature-icon {
  background-color: #634BF8;
  border-radius: 6px 6px 6px 6px;
  padding: 9px 12px 9px 12px;
}
.rs-feature.feature-style4 .feature-wrap .feature-item .feature-icon img {
  max-width: 20px;
}
.rs-feature.feature-style4 .feature-wrap .feature-item .feature-content {
  margin-left: 14px;
}
.rs-feature.feature-style4 .feature-wrap .feature-item .feature-content .title {
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  color: #00204A;
  margin: 0;
}
.rs-feature.feature-style4 .useful-wrap {
  position: relative;
  right: -16%;
  z-index: 11;
}
.rs-feature.feature-style4 .useful-wrap .morphine-wrap img {
  padding: 0px 0px 20px 40px;
}
.rs-feature.feature-style4 .useful-wrap .feature-shape {
  position: absolute;
  left: -4px;
  bottom: 14px;
}
.rs-feature.feature-style4 .useful-wrap .feature-shape img {
  max-width: 200px;
}
.rs-feature.feature-style4 .useful-wrap .feature-counter {
  position: absolute;
  bottom: 0;
}
.rs-feature.feature-style4 .useful-wrap .feature-counter .feature-counter-list {
  width: 210px;
  text-align: left;
  box-shadow: -25px 25px 40px rgba(0, 0, 1, 0.1);
  border-radius: 5px;
  background-color: #ffffff;
  padding: 17px 25px 17px 20px;
  display: inline-block;
}
.rs-feature.feature-style4 .useful-wrap .feature-counter .feature-counter-list .count-text .count-number .rs-count {
  font-size: 50px;
  line-height: 70px;
  font-weight: 700;
  color: #634bf8;
}
.rs-feature.feature-style4 .useful-wrap .feature-counter .feature-counter-list .count-text .count-number .prefix {
  font-size: 50px;
  line-height: 70px;
  font-weight: 700;
  color: #634bf8;
}
.rs-feature.feature-style4 .useful-wrap .feature-counter .feature-counter-list .count-text .title {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  color: #00204A;
  margin: 0px 0px 0px 0px;
}
.rs-feature.feature-style4 .useful-wrap .feature-counter.count-box1 {
  top: 2%;
  right: -20px;
}
/* ------------------------------------
	08. Breadcrumbs Section CSS
---------------------------------------*/
.rs-breadcrumbs {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top left !important;
}
.rs-breadcrumbs .breadcrumbs-inner {
  padding: 160px 10px 150px 10px;
}
.rs-breadcrumbs .breadcrumbs-inner .page-title {
  font-size: 65px;
  line-height: 75px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 25px;
}
.rs-breadcrumbs .breadcrumbs-inner .des {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px;
}
.rs-breadcrumbs .breadcrumbs-inner .breadcrumbs-area {
  background-color: #FFFFFF1F;
  margin: 0px 0px 0px 0px;
  padding: 7px 12px 7px 12px;
  display: inline-block;
  border-radius: 2px 2px 2px 2px;
}
.rs-breadcrumbs .breadcrumbs-inner .breadcrumbs-area li {
  color: #ffffff;
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
}
.rs-breadcrumbs .breadcrumbs-inner .breadcrumbs-area li a {
  position: relative;
  color: #ffffff;
  padding: 0px 15px 0px 0px;
  transition: all .3s;
}
.rs-breadcrumbs .breadcrumbs-inner .breadcrumbs-area li a:before {
  content: "\ea6c";
  position: absolute;
  font-family: remixicon!important;
  font-size: 16px;
  top: -4px;
  right: -8px;
  opacity: 1;
  transition: all .3s;
}
.rs-breadcrumbs .breadcrumbs-inner .breadcrumbs-area li a:hover {
  color: #ccc;
}
.rs-breadcrumbs .breadcrumbs-inner .breadcrumbs-area li:last-child {
  margin-right: 0;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 {
  max-width: 680px;
}
.rs-breadcrumbs.img1 {
  background-position: center !important;
  /* background: url(assets/images/breadcrumbs/inr-1.jpg); */
}
.rs-breadcrumbs.img2 {
  background-position: center !important;
  /* background: url(assets/images/breadcrumbs/inr-2.jpg); */
}
.rs-breadcrumbs.img3 {
  background-position: center !important;
  /* background: url(assets/images/breadcrumbs/inr-3.jpg); */
}
.rs-breadcrumbs.img4 {
  background-position: center !important;
  /* background: url(assets/images/breadcrumbs/inr-4.jpg); */
}
/* ------------------------------------
	09. Banner Section CSS
---------------------------------------*/
.rs-banner.banner-style1 {
  position: relative;
  /* background-image: url(assets/images/banner/style1/banner-bg.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-banner.banner-style1 .content-wrap {
  padding: 240px 0px 200px 0px;
}
.rs-banner.banner-style1 .content-wrap .title {
  font-size: 100px;
  line-height: 110px;
  font-weight: 700;
  color: #080d41;
  margin: 0 0 25px;
}
.rs-banner.banner-style1 .content-wrap .title span {
  display: block;
  color: #634bf8;
}
.rs-banner.banner-style1 .content-wrap .description p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #656567;
  margin: 0 0 40px;
}
.rs-banner.banner-style1 .content-wrap .bnr-btn {
  display: flex;
  align-items: center;
}
.rs-banner.banner-style1 .content-wrap .bnr-btn li {
  display: inline-block;
  margin-right: 10px;
}
.rs-banner.banner-style1 .content-wrap .bnr-btn li:last-child {
  margin-right: 0;
}
.rs-banner.banner-style1 .content-wrap .bnr-btn li .content-part {
  display: flex;
  align-items: center;
}
.rs-banner.banner-style1 .content-wrap .bnr-btn li .content-part .text-part .label {
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  color: #656567;
  display: block;
}
.rs-banner.banner-style1 .content-wrap .bnr-btn li .content-part .text-part .phone-nmb {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  color: #00204a;
  cursor: pointer;
}
.rs-banner.banner-style1 .content-wrap .bnr-btn li .content-part .text-part .phone-nmb:hover {
  color: #f8b401;
}
.rs-banner.banner-style1 .widget-images {
  position: relative;
  right: -19%;
  width: 900px;
  max-width: 900px;
  top: -100px;
}
.rs-banner.banner-style1 .banner-animate {
  position: absolute;
  right: 26%;
  bottom: 14%;
}
.rs-banner.banner-style2 {
  /* background-image: url(assets/images/banner/banner-bg2.jpg); */
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 180px 0px 130px 0px;
}
.rs-banner.banner-style2 .content-wrap {
  padding-left: 40px;
}
.rs-banner.banner-style2 .content-wrap .sub-title {
  position: relative;
  padding-left: 40px;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #f05c4e;
  text-transform: uppercase;
  margin: 0 0 15px;
}
.rs-banner.banner-style2 .content-wrap .sub-title:after {
  content: '';
  width: 24px;
  border: 1px solid #f05c4e;
  position: absolute;
  top: 12px;
  left: 0;
}
.rs-banner.banner-style2 .content-wrap .title {
  font-size: 80px;
  line-height: 90px;
  font-weight: 600;
  color: #ffffff;
  margin: 0 0 7px;
}
.rs-banner.banner-style2 .content-wrap .description p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 47px;
}
.rs-banner.banner-style2 .content-wrap .bnr-btn {
  display: flex;
  align-items: center;
}
.rs-banner.banner-style2 .content-wrap .bnr-btn li {
  display: inline-block;
  margin-right: 30px;
}
.rs-banner.banner-style2 .content-wrap .bnr-btn li:last-child {
  margin-right: 0;
}
.rs-banner.banner-style2 .bnr-images {
  margin: 0px 0px 0px -142px;
}
.rs-banner.banner-style3 {
  /* background-image: url(assets/images/banner/style2/banner-bg4.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 260px 0px 320px 0px;
}
.rs-banner.banner-style3 .content-wrap .title {
  font-size: 83px;
  line-height: 93px;
  font-weight: 600;
  color: #ffffff;
  margin: 0 0 25px;
}
.rs-banner.banner-style3 .content-wrap .description p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  margin: 0 0 40px;
}
.rs-banner.banner-style3 .content-wrap .bnr-btn {
  display: flex;
  align-items: center;
}
.rs-banner.banner-style3 .content-wrap .bnr-btn li {
  display: inline-block;
  margin-right: 9px;
}
.rs-banner.banner-style3 .content-wrap .bnr-btn li:last-child {
  margin-right: 0;
}
.rs-banner.banner-style3 .bnr-images {
  position: relative;
  z-index: 11;
}
.rs-banner.banner-style3 .bnr-images .morphine-wrap {
  width: 1200px;
  max-width: 1200px;
  top: -584px;
  left: 186px;
  position: absolute;
}
.rs-banner.banner-style4 {
  /* background-image: url(assets/images/banner/style3/banner-bg5.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.rs-banner.banner-style4 .content-wrap {
  max-width: 930px;
  margin: 0 auto;
  padding: 260px 0px 260px 0px;
  text-align: center;
}
.rs-banner.banner-style4 .content-wrap .sub-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #0092F2;
  display: block;
  text-transform: uppercase;
  margin: 0 0 25px;
}
.rs-banner.banner-style4 .content-wrap .title {
  font-size: 70px;
  line-height: 75px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 40px;
  z-index: 111;
  position: relative;
}
.rs-banner.banner-style4 .content-wrap .description p {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #FFFFFFCC;
  margin: 0 0 40px;
}
.rs-banner.banner-style4 .content-wrap .bnr-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rs-banner.banner-style4 .content-wrap .bnr-btn li {
  display: inline-block;
  margin-right: 24px;
}
.rs-banner.banner-style4 .content-wrap .bnr-btn li:last-child {
  margin-right: 0;
}
.rs-banner.banner-style4 .banner-images {
  position: relative;
  z-index: 11;
}
.rs-banner.banner-style4 .banner-images .image-wrap {
  position: absolute;
}
.rs-banner.banner-style4 .banner-images .image-wrap.left-img {
  left: -21%;
  bottom: 0;
}
.rs-banner.banner-style4 .banner-images .image-wrap.left-img img {
  max-width: 480px;
}
.rs-banner.banner-style4 .banner-images .image-wrap.right-img {
  right: -21%;
  top: -995px;
  z-index: -11;
}
.rs-banner.banner-style4 .banner-images .image-wrap.right-img img {
  max-width: 580px;
}
.rs-banner.banner-style4 .banner-images .arrow-shape {
  position: absolute;
  left: 20%;
  bottom: 238px;
}
.rs-banner.banner-style5 {
  position: relative;
  z-index: 11;
  /* background-image: url(assets/images/banner/style4/banner-bg1.png); */
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 46% auto;
}
.rs-banner.banner-style5 .content-wrap {
  padding: 200px 0 200px;
}
.rs-banner.banner-style5 .content-wrap .sub-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #d32f2f;
  display: block;
  text-transform: uppercase;
  margin: 0 0 15px;
}
.rs-banner.banner-style5 .content-wrap .title {
  font-size: 90px;
  line-height: 100px;
  font-weight: 600;
  color: #080d41;
  margin: 0 0 25px;
}
.rs-banner.banner-style5 .content-wrap .description p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #3e3e3e;
  margin: 0 0 40px;
}
.rs-banner.banner-style5 .content-wrap .bnr-btn {
  display: flex;
  align-items: center;
}
.rs-banner.banner-style5 .content-wrap .bnr-btn li {
  display: inline-block;
  margin-right: 24px;
}
.rs-banner.banner-style5 .content-wrap .bnr-btn li:last-child {
  margin-right: 0;
}
.rs-banner.banner-style5 .banner-images .image-wrap {
  z-index: 11;
  position: relative;
  right: -32%;
  top: -130px;
}
.rs-banner.banner-style5 .banner-images .image-wrap .images-shape {
  position: absolute;
}
.rs-banner.banner-style5 .banner-images .image-wrap .images-shape.one {
  top: -20px;
  left: -18%;
}
.rs-banner.banner-style5 .banner-images .image-wrap .images-shape.two {
  left: -20%;
  bottom: -25%;
  z-index: -11;
}
.rs-banner.banner-style5 .banner-images .image-wrap .images-shape.three {
  right: -59px;
  bottom: -98%;
}
.rs-banner.banner-style5:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -22%;
  height: 100%;
  width: 100%;
  /* background-image: url(assets/images/banner/style4/banner-bg-left.png); */
  background-position: left;
  background-repeat: no-repeat;
  z-index: -11;
}
.rs-banner.banner-style6 {
  position: relative;
  z-index: 11;
  /* background-image: url(assets/images/banner/style5/banner-bg7.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.rs-banner.banner-style6 .content-wrap {
  padding: 230px 0 150px;
}
.rs-banner.banner-style6 .content-wrap .title {
  font-size: 90px;
  line-height: 100px;
  font-weight: 600;
  color: #ffffff;
  margin: 0 0 25px;
}
.rs-banner.banner-style6 .content-wrap .description p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 40px;
}
.rs-banner.banner-style6 .banner-images .image-wrap {
  z-index: 11;
  position: relative;
  right: -20%;
  top: 174px;
}
.rs-banner.banner-style6 .banner-images .image-wrap .images-shape {
  position: absolute;
}
.rs-banner.banner-style6 .banner-images .image-wrap .images-shape.one {
  top: 110px;
  left: 1%;
}
.rs-banner.banner-style6 .banner-images .image-wrap .images-shape.two {
  left: -27%;
  bottom: 16%;
}
.rs-banner.banner-style6 .banner-images .image-wrap .images-shape.three {
  right: -5px;
  top: -5px;
}
.rs-banner.banner-style6 .banner-images .image-wrap .images-shape.four {
  right: -89px;
  bottom: -3%;
}
.rs-banner.banner-style7 {
  /* background-image: url(assets/images/banner/style6/banner-bg8.png); */
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-banner.banner-style7 .content-wrap {
  padding: 250px 0 210px;
}
.rs-banner.banner-style7 .content-wrap .title {
  position: relative;
  font-size: 90px;
  line-height: 100px;
  font-weight: 800;
  color: #080d41;
  margin: 0 0 42px;
}
.rs-banner.banner-style7 .content-wrap .title span {
  color: #b65df0;
}
.rs-banner.banner-style7 .content-wrap .title img {
  position: absolute;
  right: 75px;
  bottom: -10px;
}
.rs-banner.banner-style7 .content-wrap .description p {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #3e3e3e;
  margin: 0 0 40px;
}
.rs-banner.banner-style7 .bnr-images {
  position: relative;
}
.rs-banner.banner-style7 .bnr-images .images-wrap {
  position: absolute;
}
.rs-banner.banner-style7 .bnr-images .images-wrap.one {
  top: -480px;
  left: 17%;
}
.rs-banner.banner-style7 .bnr-images .images-wrap.one img {
  max-width: 400px;
}
.rs-banner.banner-style7 .bnr-images .images-wrap.two {
  right: -290px;
  bottom: -183px;
}
.rs-banner.banner-style7 .bnr-images .images-wrap.three {
  top: 12px;
  left: 17%;
}
.rs-banner.banner-style7 .bnr-images .images-wrap.three img {
  max-width: 410px;
}
.rs-banner.banner-style7 .bnr-images .images-wrap.four {
  right: -285px;
  bottom: -483px;
}
.rs-banner.banner-style8 {
  background-color: #F4F4F4;
}
.rs-banner.banner-style8 .content-wrap {
  padding: 135px 117px 140px 0px;
}
.rs-banner.banner-style8 .content-wrap .sub-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  color: #83a300;
  text-transform: uppercase;
  display: block;
  margin: 0 0 10px;
}
.rs-banner.banner-style8 .content-wrap .title {
  position: relative;
  font-size: 70px;
  line-height: 80px;
  font-weight: 800;
  color: #080d41;
  margin: 0 0 30px;
}
.rs-banner.banner-style8 .content-wrap .description p {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #3e3e3e;
  margin: 0 0 40px;
}
.rs-banner.banner-style8 .bnr-images .images-wrap {
  position: relative;
}
.rs-banner.banner-style8 .bnr-images .images-wrap img {
  position: relative;
  bottom: 0px;
  right: -15%;
  max-width: 648px;
}
.rs-banner.banner-style8 .bnr-images .images-wrap .shape-img {
  position: absolute;
}
.rs-banner.banner-style8 .bnr-images .images-wrap .shape-img.one {
  right: -39%;
  top: 39%;
}
.rs-banner.banner-style8 .bnr-images .images-wrap .shape-img.two {
  left: 3%;
  bottom: 94px;
}
.rs-banner.banner-style9 {
  background-color: #FFFAE1;
}
.rs-banner.banner-style9 .content-wrap {
  padding: 260px 60px 170px 0px;
}
.rs-banner.banner-style9 .content-wrap .sub-title {
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  color: #ff6600;
  text-transform: uppercase;
  display: block;
  margin: 0 0 10px;
}
.rs-banner.banner-style9 .content-wrap .title {
  position: relative;
  font-size: 90px;
  line-height: 100px;
  font-weight: 600;
  color: #080d41;
  margin: 0 0 33px;
}
.rs-banner.banner-style9 .content-wrap .description p {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #3e3e3e;
  margin: 0 0 42px;
}
.rs-banner.banner-style9 .content-wrap .newsletter-form .form-inner input[type="email"] {
  position: relative;
  padding: 22px 20px 22px 30px;
  border-radius: 20px 20px 20px 20px;
  border-style: solid;
  border-color: #E9E9E9;
  border-width: 1px 1px 1px 1px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #303030;
  width: 430px;
  max-width: 100%;
  opacity: 1;
}
.rs-banner.banner-style9 .content-wrap .newsletter-form .form-inner input[type="submit"] {
  outline: none;
  transition: all 0.5s ease;
  background: #534FF8;
  border: none;
  position: relative;
  color: #FFFFFF;
  background-color: #FF6600;
  margin: 0px 0px 0px 24px;
  padding: 22px 34px 22px 34px;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  border-radius: 20px 20px 20px 20px;
}
.rs-banner.banner-style9 .content-wrap .newsletter-form .form-inner input[type=submit]:hover {
  opacity: 0.9;
}
.rs-banner.banner-style9 .bnr-images .images-wrap {
  position: relative;
  z-index: 11;
}
.rs-banner.banner-style9 .bnr-images .images-wrap img {
  position: relative;
  bottom: -98px;
  left: 11%;
  max-width: 700px;
}
.rs-banner.banner-style9 .bnr-images .images-wrap .shape-img {
  position: absolute;
}
.rs-banner.banner-style9 .bnr-images .images-wrap .shape-img.one {
  right: -23%;
  top: 26%;
  z-index: -11;
}
.rs-banner.banner-style9 .bnr-images .images-wrap .shape-img.two {
  left: 19%;
  bottom: 0px;
  z-index: -11;
}
.rs-banner.banner-style10 {
  position: relative;
  /* background-image: url(assets/images/banner/style9/bnr-shape-bg1.png); */
  background-repeat: no-repeat;
  background-size: contain;
  padding: 210px 0px 310px 0px;
}
.rs-banner.banner-style10 .bnr-wrap-overlay {
  /* background-image: url(assets/images/banner/style9/bnr-imge-bg.png); */
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.rs-banner.banner-style10 .content-wrap {
  position: relative;
  max-width: 545px;
}
.rs-banner.banner-style10 .content-wrap .title {
  font-size: 80px;
  line-height: 90px;
  font-weight: 700;
  color: #00204a;
  margin: 0 0 20px;
}
.rs-banner.banner-style10 .content-wrap .description p {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #656567;
  margin: 0 0 40px;
}
.rs-banner.banner-style10 .content-wrap .animate-circle {
  position: absolute;
  left: -143px;
  top: -50px;
}
.rs-banner.banner-style10 .content-wrap .animate-circle img {
  opacity: 0.8;
  max-width: 230px;
}
/* ------------------------------------
	10. Slider Section CSS
---------------------------------------*/
.rs-slider.slider-style1 .slider {
  position: relative;
}
.rs-slider.slider-style1 .slider .slider-item {
  /* background-image: url(assets/images/slider/style1/slide1.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-slider.slider-style1 .slider .slider-item .slider-content {
  padding: 255px 0 220px;
}
.rs-slider.slider-style1 .slider .slider-item .slider-content .sub-text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  text-transform: uppercase;
  color: #126ee8;
  display: block;
  margin: 0 0 18px;
}
.rs-slider.slider-style1 .slider .slider-item .slider-content .small-title {
  font-size: 70px;
  line-height: 60px;
  font-weight: 800;
  color: #121933;
  text-transform: uppercase;
  margin: 0 0 27px;
}
.rs-slider.slider-style1 .slider .slider-item .slider-content .title {
  font-size: 90px;
  line-height: 90px;
  font-weight: 800;
  color: #121933;
  text-transform: uppercase;
  margin: 0 0 40px;
}
.rs-slider.slider-style1 .slider .slider-item.slide-bg2 {
  /* background-image: url(assets/images/slider/style1/slide2.jpg); */
}
.rs-slider.slider-style1 .slider .slick-dots {
  top: 50%;
  right: -47%;
}
.rs-slider.slider-style1 .slider .slick-dots li {
  display: block;
}
.rs-slider.slider-style1 .slider .slick-dots li button {
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  background: transparent;
  border-radius: 15px;
  margin-bottom: 10px;
}
.rs-slider.slider-style1 .slider .slick-dots li button:before {
  content: '';
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1);
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.rs-slider.slider-style1 .slider .slick-dots li.slick-active button,
.rs-slider.slider-style1 .slider .slick-dots li:hover button {
  border: 2px solid #126ee8;
  width: 18px;
  background: transparent;
  opacity: unset;
}
.rs-slider.slider-style1 .slider .slick-dots li.slick-active button:before,
.rs-slider.slider-style1 .slider .slick-dots li:hover button:before {
  background: #126ee8;
  transform: scale(0.4);
}
.rs-slider.slider-style2 .slider {
  position: relative;
}
.rs-slider.slider-style2 .slider .slider-item {
  /* background-image: url(assets/images/slider/style2/slide1.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.rs-slider.slider-style2 .slider .slider-item .slider-inner {
  display: flex;
  flex-wrap: wrap;
}
.rs-slider.slider-style2 .slider .slider-item .slider-inner .slide-bg-layer {
  flex: 0 0 55%;
  max-width: 55%;
}
.rs-slider.slider-style2 .slider .slider-item .slider-inner .slide-des {
  flex: 0 0 45%;
  max-width: 45%;
}
.rs-slider.slider-style2 .slider .slider-item .slider-inner .slide-des .content-wrap {
  padding: 234px 100px 220px 0;
}
.rs-slider.slider-style2 .slider .slider-item .slider-inner .slide-des .content-wrap .title {
  position: relative;
  z-index: 11;
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 20px;
}
.rs-slider.slider-style2 .slider .slider-item .slider-inner .slide-des .content-wrap .title .watermark {
  position: absolute;
  top: -39px;
  font-size: 89px;
  font-weight: 800;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.15);
  letter-spacing: 9px;
  z-index: -11;
  width: 100%;
  left: -1px;
  opacity: 1;
}
.rs-slider.slider-style2 .slider .slider-item .slider-inner .slide-des .content-wrap .desc {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #ffffff;
  margin: 0 0 40px;
}
.rs-slider.slider-style2 .slider .slider-item .slider-inner .slide-des .content-wrap .slider-btn li {
  display: inline-block;
  margin-right: 17px;
}
.rs-slider.slider-style2 .slider .slider-item .slider-inner .slide-des .content-wrap .slider-btn li:last-child {
  margin-right: 0;
}
.rs-slider.slider-style2 .slider .slider-item .dot-animate .animation {
  position: absolute;
  bottom: 0;
}
.rs-slider.slider-style2 .slider .slider-item .dot-animate .animation.dot-1 {
  left: 36%;
  top: 8%;
  opacity: 0.9;
}
.rs-slider.slider-style2 .slider .slider-item .dot-animate .animation.dot-2 {
  right: 120px;
  bottom: 50px;
  opacity: 0.9;
}
.rs-slider.slider-style2 .slider .slider-item.slide-bg2 {
  /* background-image: url(assets/images/slider/style2/slide2.jpg); */
}
.rs-slider.slider-style2 .slider .slider-item.slide-bg3 {
  /* background-image: url(assets/images/slider/style2/slide3.jpg); */
}
.rs-slider.slider-style2 .slider .slick-dots {
  bottom: 50px;
  transform: translateX(40%);
  display: flex;
}
.rs-slider.slider-style2 .slider .slick-dots li {
  display: block;
}
.rs-slider.slider-style2 .slider .slick-dots li button {
  width: 14px;
  height: 14px;
  border: 1px solid rgba(255, 255, 255, 0.46);
  background: transparent;
  border-radius: 15px;
  margin-bottom: 10px;
}
.rs-slider.slider-style2 .slider .slick-dots li button:before {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.rs-slider.slider-style2 .slider .slick-dots li.slick-active button,
.rs-slider.slider-style2 .slider .slick-dots li:hover button {
  border: 1px solid rgba(255, 255, 255, 0.46);
  background: #fff;
  opacity: 0.79;
}
.rs-slider.slider-style3 .slider {
  position: relative;
}
.rs-slider.slider-style3 .slider .slider-item {
  position: relative;
  z-index: 11;
  /* background-image: url(assets/images/slider/style3/slide-1.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-slider.slider-style3 .slider .slider-item .slider-content {
  padding: 290px 0 250px;
}
.rs-slider.slider-style3 .slider .slider-item .slider-content .content-wrap {
  position: relative;
}
.rs-slider.slider-style3 .slider .slider-item .slider-content .content-wrap .shape-top {
  position: absolute;
  top: -307px;
  left: -96px;
  z-index: -11;
}
.rs-slider.slider-style3 .slider .slider-item .slider-content .content-wrap .title {
  font-size: 63px;
  line-height: 73px;
  font-weight: 800;
  color: #ffffff;
  text-transform: capitalize;
  margin: 0 0 25px;
}
.rs-slider.slider-style3 .slider .slider-item .slider-content .content-wrap .desc {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  margin: 0 0 40px;
}
.rs-slider.slider-style3 .slider .slider-item .slider-content .content-wrap .shape-bottom {
  position: absolute;
  top: -259px;
  left: -6%;
  z-index: -11;
}
.rs-slider.slider-style3 .slider .slider-item .slider-content .content-wrap .shape-bottom img {
  opacity: 0.80;
}
.rs-slider.slider-style3 .slider .slider-item.slide-bg2 {
  /* background-image: url(assets/images/slider/style3/slide-2.png); */
}
.rs-slider.slider-style3 .slider .slider-item.slide-bg3 {
  /* background-image: url(assets/images/slider/style3/slide-3.png); */
}
.rs-slider.slider-style3 .slider .slider-item .animate-wrap .animation {
  position: absolute;
  bottom: 0;
  z-index: -11;
}
.rs-slider.slider-style3 .slider .slider-item .animate-wrap .animation.one {
  top: 232px;
  left: 296px;
}
.rs-slider.slider-style3 .slider .slider-item .animate-wrap .animation.one img {
  opacity: 1;
}
.rs-slider.slider-style3 .slider .slider-item .animate-wrap .animation.two {
  top: 650px;
  left: 700px;
}
.rs-slider.slider-style3 .slider .slider-item .animate-wrap .animation.three {
  right: -70px;
  bottom: -165px;
}
.rs-slider.slider-style3 .slider .slider-item .animate-wrap .animation.three img {
  opacity: 0.79;
}
.rs-slider.slider-style3 .slider .slick-dots {
  top: 47.5%;
  right: -47%;
}
.rs-slider.slider-style3 .slider .slick-dots li {
  display: block;
}
.rs-slider.slider-style3 .slider .slick-dots li button {
  width: 14px;
  height: 14px;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 15px;
  margin-bottom: 10px;
}
.rs-slider.slider-style3 .slider .slick-dots li button:before {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.rs-slider.slider-style3 .slider .slick-dots li.slick-active button,
.rs-slider.slider-style3 .slider .slick-dots li:hover button {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 2px #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #fff;
  opacity: 0.9;
}
.rs-slider.project-slide {
  position: relative;
}
.rs-slider.project-slide .slick-list {
  padding: 0 !important;
}
.rs-slider.project-slide .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  display: block;
  bottom: 0;
  font-size: 0;
  z-index: 9;
  background: transparent;
  transition: 0.4s;
}
.rs-slider.project-slide .slick-arrow.slick-next {
  left: 18%;
}
.rs-slider.project-slide .slick-arrow.slick-next:before {
  content: "\EA64";
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  text-align: center;
}
.rs-slider.project-slide .slick-arrow.slick-prev {
  right: 18%;
}
.rs-slider.project-slide .slick-arrow.slick-prev:before {
  content: "\EA6E";
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  text-align: center;
}
/* ------------------------------------
	11. Cta Section CSS
---------------------------------------*/
.rs-cta.cta-main-home {
  position: relative;
  overflow: hidden;
  z-index: 11;
}
.rs-cta.cta-main-home .rs-html5-video {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: 0;
}
.rs-cta.cta-main-home .background-video-wrap .background-overlay {
  background-color: #126EE8;
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.rs-cta.cta-main-home .animate-arrow {
  position: absolute;
  left: 57%;
  top: 36%;
}
.rs-cta.cta-style1 .content-wrap {
  padding: 70px 40px 40px 60px;
}
.rs-cta.cta-style2 .background-video-wrap .background-overlay {
  background-color: #55008C;
  opacity: 0.9;
  mix-blend-mode: unset;
}
.rs-cta.cta-style3 .newsletter-form .form-inner {
  position: relative;
}
.rs-cta.cta-style3 .newsletter-form .form-inner input[type="email"] {
  width: 416px;
  margin: 0px 0px 0px 4px;
  padding: 24px 0px 24px 30px;
  border-radius: 15px 15px 15px 15px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  background-color: transparent;
  border-color: #C6E35354;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #FFFFFF80;
}
.rs-cta.cta-style3 .newsletter-form .form-inner ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #ffffff;
  opacity: 0.5;
}
.rs-cta.cta-style3 .newsletter-form .form-inner ::-moz-placeholder {
  /* Firefox 19+ */

  color: #ffffff;
  opacity: 0.5;
}
.rs-cta.cta-style3 .newsletter-form .form-inner :-ms-input-placeholder {
  /* IE 10+ */

  color: #ffffff;
  opacity: 0.5;
}
.rs-cta.cta-style3 .newsletter-form .form-inner :-moz-placeholder {
  /* Firefox 18- */

  color: #ffffff;
  opacity: 0.5;
}
.rs-cta.cta-style3 .newsletter-form .form-inner input[type="submit"] {
  text-transform: capitalize;
  outline: none;
  transition: all 0.5s ease;
  background: #534FF8;
  position: absolute;
  border: none;
  color: #83A300;
  background-color: #FFFFFF;
  margin: 0px 0px 0px 24px;
  padding: 24px 52px 24px 52px;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  border-radius: 15px 15px 15px 15px;
}
.rs-cta.cta-style3 .newsletter-form .form-inner input[type=submit]:hover {
  opacity: 0.9;
}
.rs-cta.cta-style4 .cta-widget-wrap {
  margin-bottom: -160px;
  padding: 100px 20px 100px;
  position: relative;
  z-index: 111;
  /* background-image: url(assets/images/bg/cta-bg8.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 10px 10px 10px;
}
.rs-cta.cta-style4 .cta-widget-wrap .cta-btn-wrap {
  display: flex;
  justify-content: center;
}
.rs-cta.cta-style4 .cta-widget-wrap .cta-btn-wrap li {
  display: inline-block;
  margin-right: 20px;
}
.rs-cta.cta-style4 .cta-widget-wrap .cta-btn-wrap li:last-child {
  margin-right: 0;
}
.rs-cta.cta-style5 {
  position: relative;
}
.rs-cta.cta-style5 .cta-overly-bg {
  background-color: #000000;
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.rs-cta.cta-style6 {
  position: relative;
}
.rs-cta.cta-style6 .cta-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.rs-cta.cta-style6 .cta-btn-wrap li {
  display: inline-block;
  margin-right: 18px;
}
.rs-cta.cta-style6 .cta-btn-wrap li:last-child {
  margin-right: 0;
}
.rs-cta.cta-style6 .animate-arrow {
  left: 52%;
  top: 58%;
}
.rs-cta.cta-style6 .animate-wrap {
  position: absolute;
  left: 12%;
  top: 2%;
}
.rs-cta.cta-style6 .animate-wrap img {
  max-width: 476px;
}
.rs-cta.cta-style7 {
  position: relative;
  background-color: #00204A;
}
.rs-cta.cta-style7 .animate-wrap {
  position: absolute;
  left: 56%;
  top: 44%;
  transform: translate(-50%, -50%);
}
.rs-cta.cta-style7 .animate-wrap img {
  max-width: 73px;
}
.rs-cta.cta-style7:before {
  content: "";
  background: #1a73e9;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 41.5%;
  z-index: 0;
  transform: skew(-30deg);
  right: -44px;
}
.rs-cta.cta-style7.cta-modify1 {
  background-color: #1E0A26;
}
.rs-cta.cta-style7.cta-modify1:before {
  background: #3a15fb;
}
.rs-cta.cta-style8 .background-video-wrap .background-overlay {
  background-color: #164A97;
}
/* ------------------------------------
	12. Newsletter Section CSS
---------------------------------------*/
.rs-newsletter.newsletter-style1 {
  position: relative;
  margin-top: 0px;
  margin-bottom: -130px;
  padding: 0px 0px 0px 0px;
  z-index: 5;
}
.rs-newsletter.newsletter-style1 .newsletter-wrapper {
  background-color: #3181EB;
  padding: 75px 50px 75px 50px;
  border-radius: 10px 10px 10px 10px;
}
.rs-newsletter.newsletter-style1 .newsletter-wrapper .newsletter-form {
  position: relative;
  display: flex;
  margin: 35px 0px 0px 55px;
}
.rs-newsletter.newsletter-style1 .newsletter-wrapper .newsletter-form .newsletter-input {
  color: #303030;
  width: 100%;
  height: 100%;
  padding: 26px 31px 26px 31px;
  border-radius: 10px 0px 0px 10px;
  background-color: #F6F9FE;
  border: 1px solid #F6F9FE;
  max-width: 100%;
  opacity: 1;
}
.rs-newsletter.newsletter-style1 .newsletter-wrapper .newsletter-form .newsletter-button {
  background-color: #00204A;
  padding: 20px 31px 22px 31px;
  border-radius: 0px 10px 10px 0px;
  border: none;
  position: relative;
  top: 0px;
  font-size: 0px;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.4s;
}
.rs-newsletter.newsletter-style1 .newsletter-wrapper .newsletter-form i {
  position: relative;
  top: 0;
}
.rs-newsletter.newsletter-style1 .newsletter-wrapper .newsletter-form i:before {
  display: block;
  position: absolute;
  z-index: 5;
  right: 21px;
  top: 18px;
  color: #fff;
  font-size: 24px;
  pointer-events: none;
}
/* ------------------------------------
	13. Counter Section CSS
---------------------------------------*/
.rs-counter.counter-style1 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 35px;
  line-height: 45px;
  font-weight: 600;
  color: #126ee8;
}
.rs-counter.counter-style1 .rs-counter-list .count-text .count-number .prefix {
  font-size: 35px;
  line-height: 45px;
  font-weight: 600;
  color: #126ee8;
}
.rs-counter.counter-style1 .rs-counter-list .count-text .title {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #3e3e3e;
  margin: 7px 0 0 0;
  display: block;
}
.rs-counter.counter-style1.counter-modify1 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify1 .rs-counter-list .count-text .count-number .rs-count {
  color: #0092f2;
}
.rs-counter.counter-style1.counter-modify2 {
  position: relative;
}
.rs-counter.counter-style1.counter-modify2 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify2 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 55px;
  line-height: 65px;
  color: #ffffff;
}
.rs-counter.counter-style1.counter-modify2 .rs-counter-list .count-text .title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: #ffffff;
}
.rs-counter.counter-style1.counter-modify2 .rs-counter-list.count-box-1 {
  position: absolute;
  width: 300px;
  max-width: 300px;
  left: 60px;
  top: -44px;
}
.rs-counter.counter-style1.counter-modify2 .rs-counter-list.count-box-1 .count-text {
  text-align: center;
  padding: 100px 0 100px 0;
  background-image: linear-gradient(180deg, #0092f2 0%, #7cc8f9 100%);
  border-radius: 50% 50% 50% 50%;
}
.rs-counter.counter-style1.counter-modify2 .rs-counter-list.count-box-2 {
  position: absolute;
  width: 240px;
  max-width: 240px;
  padding: 70px 0 70px 0;
  left: 433px;
  top: -90px;
}
.rs-counter.counter-style1.counter-modify2 .rs-counter-list.count-box-2 .count-text {
  text-align: center;
  padding: 70px 0 70px 0;
  background-image: linear-gradient(180deg, #0092f2 0%, #7cc8f9 100%);
  border-radius: 50% 50% 50% 50%;
}
.rs-counter.counter-style1.counter-modify2 .rs-counter-list.count-box-3 {
  position: absolute;
  width: 240px;
  max-width: 240px;
  bottom: -454px;
  left: 315px;
}
.rs-counter.counter-style1.counter-modify2 .rs-counter-list.count-box-3 .count-text {
  text-align: center;
  padding: 70px 0 70px 0;
  background-image: linear-gradient(180deg, #0092f2 0%, #7cc8f9 100%);
  border-radius: 50% 50% 50% 50%;
}
.rs-counter.counter-style1.counter-modify2.about-count1 .rs-counter-list.count-box-1 {
  left: -12px;
  top: 6px;
}
.rs-counter.counter-style1.counter-modify2.about-count1 .rs-counter-list.count-box-2 {
  left: 353px;
  top: -22px;
}
.rs-counter.counter-style1.counter-modify2.about-count1 .rs-counter-list.count-box-3 {
  bottom: -434px;
  left: 316px;
}
.rs-counter.counter-style1.counter-modify2.about-count1 .rs-counter-list.count-box-3 .count-text,
.rs-counter.counter-style1.counter-modify2.about-count1 .rs-counter-list.count-box-2 .count-text,
.rs-counter.counter-style1.counter-modify2.about-count1 .rs-counter-list.count-box-1 .count-text {
  background-image: linear-gradient(180deg, #5463ff 0%, #060928 100%);
}
.rs-counter.counter-style1.counter-modify3 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify3 .rs-counter-list .count-text .count-number .rs-count {
  color: #0c46db;
}
.rs-counter.counter-style1.counter-modify4 .rs-counter-list .count-text .title {
  color: #ffffff;
}
.rs-counter.counter-style1.counter-modify5 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify5 .rs-counter-list .count-text .count-number .rs-count {
  color: #126ee8;
}
.rs-counter.counter-style1.counter-modify6 .rs-counter-list .count-text .count-number .prefix {
  font-size: 30px;
  font-weight: 500;
  color: #080d41;
}
.rs-counter.counter-style1.counter-modify6 .rs-counter-list .counter-icon img {
  width: 55px;
}
.rs-counter.counter-style1.counter-modify6 .rs-counter-list {
  display: flex;
}
.rs-counter.counter-style1.counter-modify6 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 50px;
  color: #010d14;
  font-weight: 700;
  padding-left: 20px;
}
.rs-counter.counter-style1.counter-modify6 .rs-counter-list .count-text .title {
  position: relative;
  margin-bottom: 23px;
  z-index: 1;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #363636;
  margin: 25px 0 0px -28px;
}
.rs-counter.counter-style1.counter-modify6 .rs-counter-list .count-text .title:before {
  content: "";
  position: absolute;
  border: 0;
  width: 40px;
  height: 5px;
  background: #534FF8;
  z-index: 1;
  margin-left: 0;
  left: 0;
  bottom: -20px;
}
.rs-counter.counter-style1.counter-modify7 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify7 .rs-counter-list .count-text .count-number .rs-count {
  color: #634bf8;
}
.rs-counter.counter-style1.counter-modify8 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify8 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  color: #1a73e9;
}
.rs-counter.counter-style1.counter-modify8 .counter-images-style .count-wrap {
  box-shadow: 0px 20px 70px 0px rgba(0, 0, 1, 0.05);
  border-radius: 10px 10px 10px 10px;
  padding: 20px 22px 20px 22px;
  background-color: #ffffff;
}
.rs-counter.counter-style1.counter-modify8 .counter-images-style .count-wrap .content-part p {
  font-size: 14px;
  line-height: 24px;
  color: #3A3A3A;
  margin: 0 0 0;
}
.rs-counter.counter-style1.counter-modify8 .counter-images-style .count-wrap .rs-counter.counter-style1.counter-modify8,
.rs-counter.counter-style1.counter-modify8 .counter-images-style .count-wrap .rs-counter-list .count-text .count-number .rs-count {
  font-size: 50px;
  line-height: 50px;
  font-weight: 600;
  color: #1a73e9;
}
.rs-counter.counter-style1.counter-modify8 .counter-images-style .count-wrap .rs-counter-list .count-text .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #3A3A3A;
  margin: 0px 0 8px 0;
  display: block;
}
.rs-counter.counter-style1.counter-modify8 .counter-images-style .count-wrap .rs-counter-list {
  display: flex;
}
.rs-counter.counter-style1.counter-modify8 .counter-images-style .count-wrap .rs-counter-list .images-logo img {
  max-width: 85px;
  position: relative;
  right: -22px;
}
.rs-counter.counter-style1.counter-modify8 .counter-images-style .count-wrap.counter-bg1 {
  padding: 41px 25px 41px 25px;
}
.rs-counter.counter-style1.counter-modify10 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify10 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
  color: #634bf8;
}
.rs-counter.counter-style1.counter-modify10 .rs-counter-list .count-text .title {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #3a3a3a;
  margin: 7px 0 0 0;
  display: block;
}
.rs-counter.counter-style1.counter-modify11 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify11 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #1a73e9;
}
.rs-counter.counter-style1.counter-modify11 .rs-counter-list .count-text .title {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #3a3a3a;
  margin: 5px 0 0 0;
  display: block;
}
.rs-counter.counter-style1.counter-modify12 {
  position: relative;
  z-index: 11;
}
.rs-counter.counter-style1.counter-modify12 .counter-overlay {
  /* background-image: url(assets/images/bg/count-down-bg2.png); */
  background-position: bottom right;
  background-repeat: no-repeat;
  opacity: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -11;
}
.rs-counter.counter-style1.counter-modify12 .rs-counter-list .count-text {
  text-align: center;
}
.rs-counter.counter-style1.counter-modify12 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify12 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #ffffff;
}
.rs-counter.counter-style1.counter-modify12 .rs-counter-list .count-text .title {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #FFFFFFBF;
  margin: 5px 0 0 0;
  display: block;
}
.rs-counter.counter-style1.counter-modify13 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify13 .rs-counter-list .count-text .count-number .rs-count {
  color: #3a15fb;
}
.rs-counter.counter-style1.counter-modify14 .counter-wrap {
  background-color: #F6F9FE;
  padding: 30px 0px 30px 90px;
  border-radius: 10px 10px 10px 10px;
}
.rs-counter.counter-style1.counter-modify14 .counter-wrap .rs-counter-list {
  position: relative;
}
.rs-counter.counter-style1.counter-modify14 .counter-wrap .rs-counter-list .project-right-border:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 60px;
  background: #E2DDDF;
  top: 12px;
  right: 30px;
}
.rs-counter.counter-style1.counter-modify14 .counter-wrap .rs-counter-list .count-text .count-number .rs-count {
  font-size: 40px;
  line-height: 45px;
  font-weight: 600;
  color: #126ee8;
}
.rs-counter.counter-style1.counter-modify14 .counter-wrap .rs-counter-list .count-text .title {
  color: #666;
  line-height: 18px;
}
.rs-counter.counter-style2 .counter-area .counter-list {
  display: flex;
  align-items: center;
}
.rs-counter.counter-style2 .counter-area .counter-list .counter-icon img {
  width: 75px;
  display: inline-block;
  padding-right: 15px;
}
.rs-counter.counter-style2 .counter-area .counter-list .counter-number .rs-count {
  font-size: 44px;
  line-height: 60px;
  font-weight: 700;
  color: #080d41;
}
.rs-counter.counter-style2 .counter-area .counter-list .counter-number .prefix {
  font-size: 30px;
  font-weight: 500;
  color: #080d41;
}
.rs-counter.counter-style2 .counter-area .content-part .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #363636;
  margin: 0px 54px 0px 0px;
  position: relative;
  padding-bottom: 20px;
}
.rs-counter.counter-style2 .counter-area .content-part .title:before {
  content: "";
  position: absolute;
  border: 0;
  width: 40px;
  height: 2px;
  background-color: #1a73e9;
  z-index: 1;
  margin-left: 0;
  left: 0;
  bottom: 0px;
}
.rs-counter.counter-style3 {
  position: relative;
  margin-top: -260px;
}
.rs-counter.counter-style3 .rs-counter-list {
  padding: 40px 25px 30px 25px;
  border-radius: 10px 10px 10px 10px;
  background-color: #A29494;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-counter.counter-style3 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 70px;
  line-height: 70px;
  font-weight: 700;
  color: #ffffff;
}
.rs-counter.counter-style3 .rs-counter-list .count-text .count-number .prefix {
  font-size: 70px;
  line-height: 70px;
  font-weight: 700;
  color: #ffffff;
}
.rs-counter.counter-style3 .rs-counter-list .count-text .title {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  color: #FFFFFF;
  display: block;
  margin: 0px 0 14px 0;
}
.rs-counter.counter-style3 .rs-counter-list .count-text .desc {
  font-size: 16px;
  line-height: 26px;
  color: #FFFFFFD4;
  margin: 10px 0px 0px 0px;
}
.rs-counter.counter-style3 .rs-counter-list.count-bg1 {
  /* background-image: url(assets/images/counter/style2/count-b1.jpg); */
}
.rs-counter.counter-style3 .rs-counter-list.count-bg2 {
  /* background-image: url(assets/images/counter/style2/count-b2.jpg); */
}
.rs-counter.counter-style3 .rs-counter-list.count-bg3 {
  /* background-image: url(assets/images/counter/style2/count-b3.jpg); */
}
.rs-counter.counter-style3 .rs-counter-list.count-bg4 {
  /* background-image: url(assets/images/counter/style2/count-b4.jpg); */
}
/* ------------------------------------
	14. Skillbar Section CSS
---------------------------------------*/
.rs-skillbar.skillbar-style1 .skillbar-wrap {
  position: relative;
  margin: 0 0 0 -20px;
}
.rs-skillbar.skillbar-style1 .skillbar-wrap .skillbar-counter {
  position: absolute;
  bottom: 0;
}
.rs-skillbar.skillbar-style1 .skillbar-wrap .skillbar-counter .skillbar-counter-list {
  box-shadow: -25px 25px 40px rgba(0, 0, 1, 0.1);
  background-color: #ffffff;
  padding: 17px 25px 17px 20px;
  display: inline-block;
}
.rs-skillbar.skillbar-style1 .skillbar-wrap .skillbar-counter .skillbar-counter-list .count-text .count-number .rs-count {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  color: #f05c4e;
}
.rs-skillbar.skillbar-style1 .skillbar-wrap .skillbar-counter .skillbar-counter-list .count-text .count-number .prefix {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  color: #f05c4e;
  letter-spacing: -4.7px;
}
.rs-skillbar.skillbar-style1 .skillbar-wrap .skillbar-counter .skillbar-counter-list .count-text .title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #3e3e3e;
}
.rs-skillbar.skillbar-style1 .skillbar-wrap .skillbar-counter.count-box1 {
  bottom: 10%;
  right: 20px;
}
.rs-skillbar.skillbar-style1 .skillbar-wrap .shape-img {
  position: absolute;
  left: 0;
  top: 0;
}
.rs-skillbar.skillbar-style1 .cl-skill-bar .skillbar-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #080d41;
}
.rs-skillbar.skillbar-style1 .cl-skill-bar .skillbar {
  background: rgba(240, 92, 78, 0.2);
  height: 7px;
  line-height: 7px;
  margin: 10px 0 20px;
  overflow: visible;
  position: relative;
  width: 100%;
}
.rs-skillbar.skillbar-style1 .cl-skill-bar .skillbar .skillbar-bar {
  height: 7px;
  line-height: 7px;
  background: #f05c4e;
  margin-bottom: 0;
  overflow: visible !important;
  width: 0;
}
.rs-skillbar.skillbar-style1 .cl-skill-bar .skillbar .skill-bar-percent {
  height: 30px;
  position: absolute;
  right: 0px;
  top: -38px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #f05c4e;
}
.rs-skillbar.skillbar-style1.skillbar-modify1 .cl-skill-bar .skillbar .skillbar-bar {
  background: #126ee8;
  border-radius: 50px;
}
.rs-skillbar.skillbar-style1.skillbar-modify1 .cl-skill-bar .skillbar {
  background: #e9ebff;
  border-radius: 50px;
}
.rs-skillbar.skillbar-style1.skillbar-modify2 .cl-skill-bar .skillbar .skillbar-bar {
  background: #135c51;
}
.rs-skillbar.skillbar-style1.skillbar-modify2 .cl-skill-bar .skillbar {
  background-color: #135C5126;
}
.rs-skillbar.skillbar-style1.skillbar-modify2 .cl-skill-bar .skillbar .skill-bar-percent {
  color: #00a775;
}
.rs-skillbar.skillbar-style1.skillbar-modify3 {
  position: relative;
}
.rs-skillbar.skillbar-style1.skillbar-modify3 .videos-item {
  /* background-image: url(assets/images/video/video-bg-5.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.rs-skillbar.skillbar-style1.skillbar-modify3 .path-success {
  border-radius: 0px 40px 40px 0px;
  padding: 130px 150px 120px 100px;
  background-color: #F1F7FF;
}
.rs-skillbar.skillbar-style1.skillbar-modify3 .path-success .feature-border {
  position: relative;
}
.rs-skillbar.skillbar-style1.skillbar-modify3 .path-success .feature-border:before {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #080D411A;
  opacity: 30%;
  bottom: 29px;
  position: absolute;
}
.rs-skillbar.skillbar-style1.skillbar-modify3 .cl-skill-bar .skillbar .skillbar-bar {
  background: #0092f2;
  border-radius: 50px 50px 50px 50px;
}
.rs-skillbar.skillbar-style1.skillbar-modify3 .cl-skill-bar .skillbar {
  background-color: #DCD7FA;
  border-radius: 50px 50px 50px 50px;
}
.rs-skillbar.skillbar-style1.skillbar-modify3 .feature-btn {
  display: flex;
  align-items: center;
}
.rs-skillbar.skillbar-style1.skillbar-modify3 .feature-btn li {
  display: inline-block;
  margin-right: 10px;
}
.rs-skillbar.skillbar-style1.skillbar-modify3 .feature-btn li:last-child {
  margin-right: 0;
}
.rs-skillbar.skillbar-style1.skillbar-modify4 .cl-skill-bar .skillbar .skillbar-bar {
  background: #5463ff;
  border-radius: 50px;
}
.rs-skillbar.skillbar-style1.skillbar-modify4 .cl-skill-bar .skillbar {
  background: #e9ebff;
  border-radius: 50px;
}
.rs-skillbar.skillbar-style1.skillbar-modify4 .rs-counter-list .count-text .title {
  color: #ffffff;
}
.rs-skillbar.skillbar-style1.skillbar-modify5 .cl-skill-bar .skillbar .skillbar-bar {
  background: #126ee8;
  border-radius: 50px;
}
.rs-skillbar.skillbar-style1.skillbar-modify5 .cl-skill-bar .skillbar {
  background: #e9ebff;
  border-radius: 50px;
}
.rs-skillbar.skillbar-style1.skillbar-modify6 .cl-skill-bar .skillbar .skillbar-bar {
  background-image: linear-gradient(180deg, #5463ff 0%, #5463ff 100%);
}
.rs-skillbar.skillbar-style1.skillbar-modify6 .cl-skill-bar .skillbar {
  background-color: #DDE9F7;
}
.rs-skillbar.skillbar-style1.skillbar-modify6 .cl-skill-bar .skillbar-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #212121;
}
.rs-skillbar.skillbar-style1.skillbar-modify6 .cl-skill-bar .skillbar .skill-bar-percent {
  height: 30px;
  position: absolute;
  right: 0px;
  top: -38px;
  font-size: 13px;
  line-height: 30px;
  font-weight: 500;
  color: #121933;
}
.rs-skillbar.skillbar-style1.skillbar-modify7 .cl-skill-bar .skillbar .skillbar-bar {
  background-image: linear-gradient(180deg, #634bf8 0%, #634bf8 100%);
}
.rs-skillbar.skillbar-style1.skillbar-modify7 .cl-skill-bar .skillbar {
  background-color: #DDE9F7;
}
.rs-skillbar.skillbar-style1.skillbar-modify7 .cl-skill-bar .skillbar-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #212121;
}
.rs-skillbar.skillbar-style1.skillbar-modify7 .cl-skill-bar .skillbar .skill-bar-percent {
  height: 30px;
  position: absolute;
  right: 0px;
  top: -38px;
  font-size: 13px;
  line-height: 30px;
  font-weight: 500;
  color: #121933;
}
.rs-skillbar.skillbar-style1.skillbar-modify8 .cl-skill-bar .skillbar .skillbar-bar {
  background: #634bf8;
  border-radius: 50px;
}
.rs-skillbar.skillbar-style1.skillbar-modify8 .cl-skill-bar .skillbar {
  background: #e9ebff;
  border-radius: 50px;
}
.rs-skillbar.skillbar-style1.skillbar-modify9 .cl-skill-bar .skillbar {
  height: 8px;
  line-height: 8px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #1A73E933;
  border-radius: 50px 50px 50px 50px;
  background-color: #B9C4D400;
}
.rs-skillbar.skillbar-style1.skillbar-modify9 .cl-skill-bar .skillbar .skillbar-bar {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  background-color: #1273eb;
  border-radius: 50px 50px 50px 50px;
  -webkit-animation: rs-progress-pie-stripes 1s linear infinite;
  animation: rs-progress-pie-stripes 1s linear infinite;
}
.rs-skillbar.skillbar-style1.skillbar-modify10 .cl-skill-bar .skillbar {
  height: 6px;
  line-height: 6px;
  background-color: #0999F84D;
  border-radius: 50px 50px 50px 50px;
}
.rs-skillbar.skillbar-style1.skillbar-modify10 .cl-skill-bar .skillbar .skillbar-bar {
  height: 6px;
  line-height: 6px;
  background-color: #4301FF;
  border-radius: 50px 50px 50px 50px;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  -webkit-animation: rs-progress-pie-stripes 1s linear infinite;
  animation: rs-progress-pie-stripes 1s linear infinite;
}
.rs-skillbar.skillbar-style1.skillbar-modify11 .cl-skill-bar .skillbar-title {
  color: #3A3A3A;
  font-size: 16px;
  font-weight: 400;
}
.rs-skillbar.skillbar-style1.skillbar-modify11 .cl-skill-bar .skillbar {
  height: 8px;
  line-height: 8px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #1A73E933;
  border-radius: 50px 50px 50px 50px;
  background-color: #B9C4D400;
}
.rs-skillbar.skillbar-style1.skillbar-modify11 .cl-skill-bar .skillbar .skillbar-bar {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  background-color: #1273eb;
  border-radius: 50px 50px 50px 50px;
  -webkit-animation: rs-progress-pie-stripes 1s linear infinite;
  animation: rs-progress-pie-stripes 1s linear infinite;
}
.rs-skillbar.skillbar-style1.skillbar-modify11 .cl-skill-bar .skillbar .skill-bar-percent {
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  color: #121933;
  line-height: 1;
  top: -27px;
  color: #1A73E9;
}
/* ------------------------------------
	15. Contact Section CSS
---------------------------------------*/
.rs-contact.contact-style1 {
  position: relative;
}
.rs-contact.contact-style1 .contact-img {
  /* background-image: url(assets/images/contact/contact-1.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-contact.contact-style1 .contact-wrap {
  padding: 70px 323px 70px 60px;
  background-color: #634BF8;
  /* background-image: url(assets/images/contact/contact-h8.jpg); */
  background-size: cover;
}
.rs-contact.contact-style1 .contact-wrap .from-control {
  font-size: 16px;
  padding: 16px 22px 16px 22px;
  border-radius: 8px 8px 8px 8px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #FFFFFF33;
  background-color: transparent;
  color: #ffffff;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.contact-style1 .contact-wrap textarea {
  height: 160px;
}
.rs-contact.contact-style1 .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style1 .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style1 .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style1 .contact-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style1 .contact-wrap .arrow-icons {
  position: absolute;
  right: 25%;
  bottom: 50px;
}
.rs-contact.contact-style1 .address-boxstyle1 {
  background-color: #FFFFFF;
  box-shadow: 30px 30px 801px 0px rgba(0, 21, 35, 0.07);
  padding: 30px 35px 30px 35px;
  border-radius: 20px 20px 20px 20px;
}
.rs-contact.contact-style1 .address-boxstyle1 .address-icon {
  margin-bottom: 20px;
}
.rs-contact.contact-style1 .address-boxstyle1 .address-icon img {
  max-width: 60px;
}
.rs-contact.contact-style1 .address-boxstyle1 .text .label {
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  color: #183760;
  margin: 0 0 10px;
  display: block;
}
.rs-contact.contact-style1 .address-boxstyle1 .address-item {
  display: flex;
}
.rs-contact.contact-style1 .address-boxstyle1 .address-item .address-icon {
  margin-bottom: 4px;
}
.rs-contact.contact-style1 .address-boxstyle1 .address-item .address-icon i:before {
  font-size: 22px;
  color: #1a73e9;
  position: relative;
  top: -5px;
  margin-right: 8px;
}
.rs-contact.contact-style1 .address-boxstyle1 .address-item .address-text .des {
  font-size: 16px;
  color: #656567;
}
.rs-contact.contact-style1 .address-boxstyle1 .address-item .address-text .des a {
  color: #656567;
}
.rs-contact.contact-style1 .address-boxstyle1 .address-item .address-text .des a:hover {
  color: #1a73e9;
}
.rs-contact.contact-style1.contact-modify4 .address-item {
  display: flex;
  align-items: center;
}
.rs-contact.contact-style1.contact-modify4 .address-item .address-icon {
  display: inline-block;
  position: relative;
  background: #2b59ff;
  background-color: #5463FF;
  margin-right: 15px !important;
  width: 55px;
  min-width: 55px;
  max-width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 0px 0px 0px 0px;
  text-align: center;
}
.rs-contact.contact-style1.contact-modify4 .address-item .address-icon i {
  font-size: 25px;
  top: 4px !important;
  position: relative;
  color: #ffffff;
}
.rs-contact.contact-style1.contact-modify4 .address-item .address-text .label {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: #FFFFFF !important;
  display: block;
}
.rs-contact.contact-style1.contact-modify4 .address-item .address-text .des {
  color: #FFFFFF;
}
.rs-contact.contact-style1.contact-modify4 .contact-wrap {
  padding: 60px 60px 65px 60px;
  background-color: #5463FF;
  border-radius: 0;
}
.rs-contact.contact-style1.contact-modify4 .contact-wrap .from-control {
  padding: 16px 22px 16px 22px;
  border-width: 2px 2px 2px 2px;
  border-radius: 0;
  border-color: #e9ecef17;
}
.rs-contact.contact-style2 .contact-wrap {
  padding: 65px 40px 70px 40px;
  background-color: #f05c4e;
}
.rs-contact.contact-style2 .contact-wrap .from-control {
  font-size: 16px;
  padding: 16px 22px 16px 22px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.contact-style2 .contact-wrap textarea {
  height: 130px;
}
.rs-contact.contact-style2 .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style2 .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style2 .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style2 .contact-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style2 .touch-wrap {
  padding: 109px 108px 103px 100px;
}
.rs-contact.contact-style2 .touch-wrap .contact-button {
  display: flex;
  align-items: center;
}
.rs-contact.contact-style2 .touch-wrap .contact-button li {
  display: inline-block;
  margin-right: 10px;
}
.rs-contact.contact-style2 .touch-wrap .contact-button li:last-child {
  margin-right: 0;
}
.rs-contact.contact-style3 .con-btn-wrap {
  display: flex;
  align-items: center;
}
.rs-contact.contact-style3 .con-btn-wrap li {
  display: inline-block;
  margin-right: 20px;
}
.rs-contact.contact-style3 .con-btn-wrap li:last-child {
  margin-right: 0;
}
.rs-contact.contact-style3 .contact-wrap {
  background-color: #ffffff;
  padding: 60px 50px 60px 50px;
  border-radius: 50px;
}
.rs-contact.contact-style3 .contact-wrap .wpcf7-form-control-wrap {
  display: block;
  position: relative;
}
.rs-contact.contact-style3 .contact-wrap .wpcf7-form-control-wrap i {
  position: absolute;
  left: 23px;
  top: 16px;
  font-size: 20px;
  z-index: 11;
}
.rs-contact.contact-style3 .contact-wrap .wpcf7-form-control-wrap .select-arrow {
  position: relative;
  padding: 21px 15px 21px 50px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 15px 1px 1px;
  border-color: #fff;
  color: #080d41;
  box-shadow: 0px 0px 2px 0px #8386a0;
  background-color: #fff;
  width: 100%;
  cursor: pointer;
}
.rs-contact.contact-style3 .contact-wrap .wpcf7-form-control-wrap .from-control {
  padding: 20px 15px 20px 50px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #080d412e;
  width: 100%;
}
.rs-contact.contact-style3 .contact-wrap .rs-tooltip {
  position: relative;
  height: 10px;
  border: 1px solid #4DC19E;
  border-radius: 40px;
  background: #4DC19E;
  margin-top: 40px;
}
.rs-contact.contact-style3 .contact-wrap .rs-tooltip .esrstooltip {
  position: absolute;
  width: auto;
  top: -50px;
  background: #00a775;
  color: #fff;
  text-align: center;
  padding: 2px 5px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: bold;
}
.rs-contact.contact-style3 .contact-wrap .rs-tooltip .handle-part {
  position: absolute;
  top: -13px;
  left: -1%;
  background-color: #00a775;
  border-radius: 100%;
  height: 30px;
  width: 30px;
}
.rs-contact.contact-style4 .contact-img {
  /* background-image: url(assets/images/contact/contact-2.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px 0px 0px 10px;
  background-size: cover;
  position: relative;
}
.rs-contact.contact-style4 .contact-wrap {
  background-color: transparent;
  padding: 54px 47px 54px 47px;
  background-image: linear-gradient(180deg, #080d41 0%, #080d41 100%);
  border-radius: 0px 10px 10px 0px;
}
.rs-contact.contact-style4 .contact-wrap .wpcf7-form-control-wrap {
  display: block;
  position: relative;
}
.rs-contact.contact-style4 .contact-wrap .wpcf7-form-control-wrap i {
  position: absolute;
  left: 23px;
  top: 16px;
  font-size: 20px;
  z-index: 11;
  color: #868383;
}
.rs-contact.contact-style4 .contact-wrap .wpcf7-form-control-wrap .from-control {
  padding: 20px 30px 20px 50px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #0A136E87;
  color: #fff;
  background-color: #F7F8FB1F;
  max-width: 100%;
  width: 100%;
  opacity: 1;
}
.rs-contact.contact-style4 .contact-wrap .wpcf7-form-control-wrap textarea {
  height: 160px;
}
.rs-contact.contact-style4 .contact-wrap .wpcf7-form-control-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #868383;
  opacity: 0.79;
}
.rs-contact.contact-style4 .contact-wrap .wpcf7-form-control-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #868383;
  opacity: 0.79;
}
.rs-contact.contact-style4 .contact-wrap .wpcf7-form-control-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #868383;
  opacity: 0.79;
}
.rs-contact.contact-style4 .contact-wrap .wpcf7-form-control-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #868383;
  opacity: 0.79;
}
.rs-contact.contact-style4 .contact-info {
  padding: 50px 40px 50px 60px;
  background-image: linear-gradient(240deg, #ffffff 34%, #d9daff 100%);
}
.rs-contact.contact-style4 .contact-info .address-item {
  display: flex;
  margin-bottom: 35px;
}
.rs-contact.contact-style4 .contact-info .address-item .address-icon {
  margin-right: 25px;
}
.rs-contact.contact-style4 .contact-info .address-item .address-icon i {
  font-size: 40px;
  top: -6px !important;
  position: relative;
  color: #126ee8;
}
.rs-contact.contact-style4 .contact-info .address-item .address-text .label {
  font-size: 17px;
  font-weight: 600;
  line-height: 32px;
  color: #3e3e3e;
  display: block;
}
.rs-contact.contact-style4 .contact-info .address-item .address-text .desc {
  font-size: 17px;
  font-weight: 500;
  line-height: 32px;
  color: #3e3e3e;
  margin: 0;
}
.rs-contact.contact-style4.contact-modify1 .contact-wrap {
  background-image: unset;
  padding: 54px 40px 54px 40px;
  background-color: #F6F9FE;
  border-radius: 0px 10px 10px 0px;
}
.rs-contact.contact-style4.contact-modify1 .contact-wrap .wpcf7-form-control-wrap .from-control {
  padding: 15px 30px 15px 50px;
  border-color: #fff;
  color: #fff;
  background-color: #fff;
}
.rs-contact.contact-style4.contact-modify1 .contact-wrap .wpcf7-form-control-wrap i {
  top: 12px;
}
.rs-contact.contact-style4.contact-modify2 .contact-wrap {
  background-image: unset;
  padding: 54px 40px 54px 40px;
  background-color: #1a73e9;
  border-radius: 0px 10px 10px 0px;
}
.rs-contact.contact-style4.contact-modify2 .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style4.contact-modify2 .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style4.contact-modify2 .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style4.contact-modify2 .contact-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style4.contact-modify2 .contact-wrap .wpcf7-form-control-wrap .from-control {
  padding: 15px 30px 15px 25px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #FFFFFF26;
  background-color: #FFFFFF00;
}
.rs-contact.contact-style4.contact-modify5 .contact-img {
  border-radius: 0px 0px 0px 0px;
}
.rs-contact.contact-style4.contact-modify5 .contact-info {
  background-image: linear-gradient(240deg, #00204a BD 34%, #00204a B3 100%) !important;
}
.rs-contact.contact-style4.contact-modify5 .contact-info .address-item .address-text .label {
  color: #fff;
}
.rs-contact.contact-style4.contact-modify5 .contact-info .address-item .address-text .desc {
  color: #FFFFFFB8;
}
.rs-contact.contact-style4.contact-modify5 .contact-info .address-item .address-icon i {
  color: #6936f5;
}
.rs-contact.contact-style4.contact-modify5 .contact-wrap {
  background-image: linear-gradient(120deg, #290f6f 6 E 40%, #220e5a 82 100%);
  border-radius: 0px 10px 10px 0px;
}
.rs-contact.contact-style4.contact-modify5 .contact-wrap .wpcf7-form-control-wrap .from-control {
  padding: 16px 30px 16px 50px;
  border-radius: 0px 0px 0px 0px;
  background-color: #A78EEA4D;
  color: #FFFFFF;
}
.rs-contact.contact-style4.contact-modify5 .contact-wrap .wpcf7-form-control-wrap i {
  top: 13px;
  font-size: 20px;
  color: #fff;
}
.rs-contact.contact-style4.contact-modify5 .contact-wrap .wpcf7-form-control-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #fff;
  opacity: 0.69;
}
.rs-contact.contact-style4.contact-modify5 .contact-wrap .wpcf7-form-control-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #fff;
  opacity: 0.69;
}
.rs-contact.contact-style4.contact-modify5 .contact-wrap .wpcf7-form-control-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #fff;
  opacity: 0.69;
}
.rs-contact.contact-style4.contact-modify5 .contact-wrap .wpcf7-form-control-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #fff;
  opacity: 0.69;
}
.rs-contact.contact-style5 .address-box {
  display: flex;
  align-items: center;
}
.rs-contact.contact-style5 .address-box .address-icon {
  display: inline-block;
  position: relative;
  background: #2b59ff;
  background-color: #83A300;
  margin-right: 20px !important;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  line-height: 54px !important;
  border-radius: 100%;
  text-align: center;
}
.rs-contact.contact-style5 .address-box .address-icon i {
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
}
.rs-contact.contact-style5 .address-box .address-text .label {
  color: #FFFFFF !important;
  font-size: 20px;
  font-weight: 500;
  margin: 0px 0px 0px 0px;
  display: block;
}
.rs-contact.contact-style5 .address-box .address-text .des {
  color: #FFFFFF;
}
.rs-contact.contact-style5 .address-box .address-text .social-icon a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #3b5998;
  border-radius: 100%;
  margin-right: 6px;
  color: #ffffff;
  text-align: center;
  display: inline-block;
}
.rs-contact.contact-style5 .address-box .address-text .social-icon a.icon2 {
  background: #1da1f2;
}
.rs-contact.contact-style5 .address-box .address-text .social-icon a.icon3 {
  background: #cb201f;
}
.rs-contact.contact-style5 .address-box .address-text .social-icon a.icon4 {
  background: #0077b5;
}
.rs-contact.contact-style5 .address-box .address-text .social-icon a.icon5 {
  background: #e3304e;
}
.rs-contact.contact-style6 .address-boxs {
  display: flex;
  position: relative;
  align-items: center;
}
.rs-contact.contact-style6 .address-boxs .address-icon {
  margin-right: 28px;
  background-color: #306C62;
  width: 78px;
  min-width: 78px;
  max-width: 78px;
  line-height: 72px;
  height: 70.1px;
  border-radius: 10px;
  text-align: center;
}
.rs-contact.contact-style6 .address-boxs .address-icon i:before {
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
  top: 4px;
  position: relative;
}
.rs-contact.contact-style6 .address-boxs .address-text .text .label {
  font-size: 16px;
  line-height: 36px;
  font-weight: 500;
  color: #656567;
  text-transform: uppercase;
  display: block;
}
.rs-contact.contact-style6 .address-boxs .address-text .text .des {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #282525;
}
.rs-contact.contact-style6 .contact-wrap {
  background-color: #306C62;
  padding: 45px 40px 60px 40px;
  border-radius: 10px 10px 10px 10px;
}
.rs-contact.contact-style6 .contact-wrap .wpcf7-form-control-wrap {
  display: block;
  position: relative;
}
.rs-contact.contact-style6 .contact-wrap .wpcf7-form-control-wrap i {
  position: absolute;
  left: 23px;
  top: 10px;
  font-size: 18px;
  color: rgba(69, 69, 69, 0.7411764706);
  z-index: 11;
  transition: all 0.3s ease;
}
.rs-contact.contact-style6 .contact-wrap .wpcf7-form-control-wrap .select-arrow {
  position: relative;
  padding: 12.5px 15px 12.5px 50px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #1A73E933;
  color: #3A3A3ACC !important;
  background-color: #F7F7F7E6;
  width: 100%;
  cursor: pointer;
}
.rs-contact.contact-style6 .contact-wrap .wpcf7-form-control-wrap .from-control {
  padding: 12.5px 15px 12.5px 50px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #1A73E933;
  color: #3A3A3ACC !important;
  background-color: #F7F7F7E6;
  width: 100%;
}
.rs-contact.contact-style6 .contact-wrap .wpcf7-form-control-wrap textarea {
  height: 120px;
}
.rs-contact.contact-style6 .contact-wrap .wpcf7-form-control-wrap:hover i {
  color: #306c62;
}
.rs-contact.contact-style6 .map-canvas {
  overflow: hidden;
}
.rs-contact.contact-style6 .map-canvas iframe {
  height: 400px;
  float: left;
  width: 100%;
  border: none;
}
.rs-contact.contact-style6.contact-modify6 .address-boxs .address-icon {
  background-color: #126ee8;
}
.rs-contact.contact-style6.contact-modify6 .contact-wrap {
  background-color: #126ee8;
}
.rs-contact.contact-style6.contact-modify6 .contact-wrap .wpcf7-form-control-wrap:hover i {
  color: #126ee8;
}
.rs-contact.contact-style6.contact-modify7 .address-boxs .address-icon {
  background-color: #3a15fb;
}
.rs-contact.contact-style6.contact-modify7 .contact-wrap {
  background-color: #3a15fb;
}
.rs-contact.contact-style6.contact-modify7 .contact-wrap .wpcf7-form-control-wrap:hover i {
  color: #3a15fb;
}
.rs-contact.contact-style6.contact-modify8 .address-boxs .address-icon {
  background-color: #b65df0;
}
.rs-contact.contact-style6.contact-modify8 .contact-wrap {
  background-color: #b65df0;
}
.rs-contact.contact-style6.contact-modify8 .contact-wrap .wpcf7-form-control-wrap:hover i {
  color: #b65df0;
}
.rs-contact.contact-style6.contact-modify9 .address-boxs .address-icon {
  background-color: #83a300;
}
.rs-contact.contact-style6.contact-modify9 .contact-wrap {
  background-color: #83a300;
}
.rs-contact.contact-style6.contact-modify9 .contact-wrap .wpcf7-form-control-wrap:hover i {
  color: #83a300;
}
.rs-contact.contact-style7 .icon-box-wrapper {
  padding: 35px 40px 35px 40px;
  border-radius: 10px 10px 10px 10px;
  /* background-image: url(assets/images/bg/opening-time.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-contact.contact-style7 .icon-box-wrapper .icon-box-item {
  display: flex;
  align-items: center;
}
.rs-contact.contact-style7 .icon-box-wrapper .icon-box-item .box-icon i {
  color: #ffffff;
  font-size: 22px;
  padding-right: 14px;
}
.rs-contact.contact-style7 .icon-box-wrapper .icon-box-item .box-text .label {
  font-size: 17px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
}
.rs-contact.contact-style7 .arrow-icon img {
  position: relative;
  max-width: 95px;
  right: -32%;
}
.rs-contact.contact-style7 .services-part .content-txt {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  color: #656567;
  margin-bottom: 0;
}
.rs-contact.contact-style7 .services-part .rs-counter-list .count-text .count-number .rs-count {
  color: #00204A;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
}
.rs-contact.contact-style7 .services-part .rs-counter-list .count-text .count-number .prefix {
  color: #00204A;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
}
.rs-contact.contact-style7 .services-part .support-client img {
  max-width: 250px;
}
.rs-contact.contact-style7 .contact-wrapper {
  margin-top: -226px;
  margin-bottom: 0px;
}
.rs-contact.contact-style7 .contact-wrapper .content-part {
  padding: 20px 20px 20px 40px;
  background-color: #1A73E9;
  border-radius: 10px 10px 0px 0px;
}
.rs-contact.contact-style7 .contact-wrapper .contact-wrap {
  padding: 40px 40px 50px 40px;
  background-color: #F6F9FE;
  border-radius: 0px 0px 10px 10px;
}
.rs-contact.contact-style7 .contact-wrapper .contact-wrap .wpcf7-form-control-wrap {
  display: block;
  position: relative;
}
.rs-contact.contact-style7 .contact-wrapper .contact-wrap .wpcf7-form-control-wrap i {
  position: absolute;
  left: 23px;
  top: 13px;
  font-size: 18px;
  z-index: 11;
  color: rgba(69, 69, 69, 0.7411764706);
}
.rs-contact.contact-style7 .contact-wrapper .contact-wrap .wpcf7-form-control-wrap .from-control {
  padding: 14px 30px 14px 50px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #E0ECFC;
  color: #3A3A3ACC !important;
  background-color: #F6F9FE;
  max-width: 100%;
  width: 100%;
  opacity: 1;
}
.rs-contact.contact-style7 .contact-wrapper .contact-wrap .wpcf7-form-control-wrap textarea {
  height: 160px;
}
.rs-contact.contact-style7 .contact-wrapper .contact-wrap .wpcf7-form-control-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: rgba(58, 58, 58, 0.8);
  opacity: 0.79;
}
.rs-contact.contact-style7 .contact-wrapper .contact-wrap .wpcf7-form-control-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: rgba(58, 58, 58, 0.8);
  opacity: 0.79;
}
.rs-contact.contact-style7 .contact-wrapper .contact-wrap .wpcf7-form-control-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: rgba(58, 58, 58, 0.8);
  opacity: 0.79;
}
.rs-contact.contact-style7 .contact-wrapper .contact-wrap .wpcf7-form-control-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: rgba(58, 58, 58, 0.8);
  opacity: 0.79;
}
.rs-contact.contact-style8 .contact-wrap {
  padding: 70px 40px 70px 40px;
  background-color: #164A97;
}
.rs-contact.contact-style8 .contact-wrap .from-control {
  font-size: 16px;
  padding: 16px 22px 16px 22px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #FFFFFF26;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 10px 10px 10px 10px;
  background-color: #FFFFFF00;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.contact-style8 .contact-wrap textarea {
  height: 130px;
}
.rs-contact.contact-style8 .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style8 .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style8 .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style8 .contact-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #ffffff;
  opacity: 0.57;
}
.rs-contact.contact-style9 .address-boxstyle1 .address-item {
  display: flex;
  align-items: center;
}
.rs-contact.contact-style9 .address-boxstyle1 .address-item .address-icon {
  background-color: #FFFFFF;
  width: 78px;
  min-width: 78px;
  max-width: 78px;
  height: 70.1px;
  box-shadow: -10px 10px 0px 0px rgba(255, 255, 255, 0.2);
  border-radius: 10px 10px 10px 10px;
  transform: skewX(-8deg);
  margin-right: 30px;
  text-align: center;
  line-height: 72px;
}
.rs-contact.contact-style9 .address-boxstyle1 .address-item .address-icon i:before {
  font-size: 28px;
  color: #1a73e9;
  position: relative;
  top: -5px;
  margin-right: 8px;
}
.rs-contact.contact-style9 .address-boxstyle1 .address-item .address-text .label {
  font-size: 16px;
  font-style: normal;
  line-height: 26px;
  color: #FFFFFFCC !important;
  display: block;
}
.rs-contact.contact-style9 .address-boxstyle1 .address-item .address-text .des {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  margin: 0px 0px 0px 0px;
}
.rs-contact.contact-style9 .address-boxstyle1 .address-item .address-text .des a {
  color: #FFFFFF !important;
}
.rs-contact.contact-style9 .address-boxstyle1 .address-item .address-text .des a:hover {
  color: #ccc;
}
.rs-contact.contact-style9 .contact-wrap {
  box-shadow: 30px 30px 0px 0px rgba(255, 255, 255, 0.2);
  background-color: #ffffff;
  padding: 45px 40px 60px 40px;
}
.rs-contact.contact-style9 .contact-wrap .wpcf7-form-control-wrap {
  display: block;
  position: relative;
}
.rs-contact.contact-style9 .contact-wrap .wpcf7-form-control-wrap i {
  position: absolute;
  left: 23px;
  top: 14px;
  font-size: 18px;
  color: rgba(69, 69, 69, 0.7411764706);
  z-index: 11;
}
.rs-contact.contact-style9 .contact-wrap .wpcf7-form-control-wrap .select-arrow {
  position: relative;
  padding: 21px 15px 21px 50px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 15px 1px 1px;
  border-color: #fff;
  color: #080d41;
  box-shadow: 0px 0px 2px 0px #8386a0;
  background-color: #fff;
  width: 100%;
  cursor: pointer;
}
.rs-contact.contact-style9 .contact-wrap .wpcf7-form-control-wrap .from-control {
  padding: 14px 0px 14px 47px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #1A73E933;
  color: #3A3A3ACC !important;
  background-color: #F7F7F7E6;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.contact-style9 .contact-wrap .wpcf7-form-control-wrap textarea {
  height: 130px;
}
/* ------------------------------------
	16. Video Section CSS
---------------------------------------*/
.rs-videos.video-style1 {
  position: relative;
}
.rs-videos.video-style1 .video-item {
  position: relative;
}
.rs-videos.video-style1 .video-item .overly-border a {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  line-height: 90px;
  padding: 0;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  z-index: 9;
  text-align: center;
  background: #126ee8;
}
.rs-videos.video-style1 .video-item .overly-border a i {
  font-size: 30px;
  line-height: 80px;
  color: #ffffff;
  position: relative;
  z-index: 1;
}
.rs-videos.video-style1 .video-item .overly-border a:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  transition: all 0.3s ease;
  background: #126ee8;
}
.rs-videos.video-style1 .video-item .overly-border a:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  transition: all 200ms;
  animation: pulse-border 1.5s ease-out infinite;
}
.rs-videos.video-style1.purple-video1 {
  position: absolute;
  left: 25%;
  transform: translateY(-364px);
  z-index: 11;
}
.rs-videos.video-style1.purple-video1 .video-item .overly-border a:before,
.rs-videos.video-style1.purple-video1 .video-item .overly-border a {
  background: #0092f2;
}
.rs-videos.video-style1.white-video1 {
  position: absolute;
  left: unset;
  right: 24%;
}
.rs-videos.video-style1.white-video1 .video-item .overly-border a:before,
.rs-videos.video-style1.white-video1 .video-item .overly-border a {
  background: #fff;
}
.rs-videos.video-style1.white-video1 .video-item .overly-border a i {
  color: #126ee8;
}
.rs-videos.video-style1.yellow-video1 {
  position: absolute;
  left: 86%;
  top: 77%;
  z-index: 11;
  transform: translateY(-50%);
}
.rs-videos.video-style1.yellow-video1 .video-item .overly-border a:before,
.rs-videos.video-style1.yellow-video1 .video-item .overly-border a {
  background: #F8B401;
}
.rs-videos.video-style1.blue-video1 {
  position: absolute;
  left: 5%;
  top: 90%;
  z-index: 11;
  transform: translateY(-50%);
  padding: 20px 20px 20px 20px;
  background-color: #FFFFFF;
}
.rs-videos.video-style1.blue-video1 .video-item .overly-border a:before,
.rs-videos.video-style1.blue-video1 .video-item .overly-border a {
  background: #ffffff;
}
.rs-videos.video-style1.blue-video1 .video-item .overly-border a i {
  color: #126ee8;
}
.rs-videos.video-style1.blue-video1 .video-item.video-images {
  /* background-image: url(assets/images/feature/style9/service-video.png); */
  position: relative;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  text-align: center;
  padding: 85px 80px;
}
.rs-videos.video-style1.ser-video1 {
  /* background-image: url(assets/images/about/style1/about1.png); */
  position: relative;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  text-align: center;
  padding: 280px 0px 280px 0px;
}
.rs-videos.video-style1.video-woman1 {
  /* background-image: url(assets/images/video/video-img1.jpg); */
  position: relative;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.rs-videos.video-style1.video-woman1 .video-item {
  position: relative;
}
.rs-videos.video-style1.video-woman1 .video-item .overly-border a {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  background-color: #3C0BF6;
}
.rs-videos.video-style1.video-woman1 .video-item .overly-border a i {
  font-size: 30px;
  line-height: 80px;
  color: #ffffff;
  position: relative;
  z-index: 1;
}
.rs-videos.video-style1.video-woman1 .video-item .overly-border a:before {
  content: "";
  border: 2px solid #080d41;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  -webkit-animation: zoomBig 3.25s linear infinite;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: .75s;
  animation-delay: .75s;
}
.rs-videos.video-style1.video-woman1 .video-item .overly-border a:after {
  content: "";
  border: 2px solid #080d41;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  -webkit-animation: zoomBig 3.25s linear infinite;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.rs-videos.video-style1.video-woman1 .divider-separator {
  margin: 0px 0px 0px 0px;
}
.rs-videos.video-style1.video-woman1 .divider-separator .content-wrap {
  padding: 100px 100px;
  transform: skewY(0deg);
}
.rs-videos.video-style2 .animate-border2 .popup-border {
  background: #126ee8;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.rs-videos.video-style2 .animate-border2 .popup-border i {
  font-size: 20px;
  line-height: 80px;
  display: block;
  color: #ffffff;
}
.rs-videos.video-style2 .animate-border2 .popup-border:before {
  content: "";
  border: 2px solid #126ee8;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: .75s;
  animation-delay: .75s;
}
.rs-videos.video-style2 .animate-border2 .popup-border:after {
  content: "";
  border: 2px solid #126ee8;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.rs-videos.video-style2.choose-video {
  position: relative;
}
.rs-videos.video-style2.choose-video .animate-border2 .popup-border {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rs-videos.video-style2.woman-video1 {
  /* background-image: url(assets/images/video/video-img1.jpg); */
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  position: relative;
  padding: 190px 0 0px;
}
.rs-videos.video-style2.woman-video1 .animate-border2 .popup-border {
  left: 25%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #3a15fb;
}
.rs-videos.video-style2.woman-video1 .animate-border2 .popup-border:before {
  border: 2px solid #3a15fb;
}
.rs-videos.video-style2.woman-video1 .animate-border2 .popup-border:after {
  border: 2px solid #3a15fb;
}
.rs-videos.video-style2.woman-video1 .divider-separator {
  padding: 160px 250px 130px 150px;
}
.rs-videos.video-style2.about-video1 {
  /* background-image: url(assets/images/about/style5/about-us.jpg); */
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  position: relative;
  border-radius: 15px 15px 15px 15px;
  padding: 355px 0 355px;
  margin-top: 45px;
}
.rs-videos.video-style2.about-video1 .animate-border2 .popup-border:after,
.rs-videos.video-style2.about-video1 .animate-border2 .popup-border:before {
  border: 2px solid #fff;
}
.rs-videos.video-style2.about-video1 .animate-border2 .popup-border i {
  color: #1a73e9;
}
.rs-videos.video-style2.about-video1 .animate-border2 .popup-border {
  background: #ffffff;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}
@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}
/* ------------------------------------
	17. Choose Section CSS
---------------------------------------*/
.rs-choose.choose-style1 .feature-content {
  padding: 25px 25px 25px 25px;
  border-radius: 10px 10px 10px 10px;
  background-color: #FBFBFF;
}
.rs-choose.choose-style1 .choose-images {
  text-align: right;
}
.rs-choose.choose-style1 .choose-images img {
  border-radius: 30px 30px 30px 30px;
}
.rs-choose.choose-style2 {
  position: relative;
}
.rs-choose.choose-style2 .bnr-btn-wrap {
  display: flex;
  align-items: center;
}
.rs-choose.choose-style2 .bnr-btn-wrap li {
  display: inline-block;
  margin-right: 25px;
}
.rs-choose.choose-style2 .bnr-btn-wrap li:last-child {
  margin-right: 0;
}
.rs-choose.choose-style2 .image-wrap2 {
  position: relative;
  z-index: 11;
  margin: 0 -120px 0 120px;
}
.rs-choose.choose-style2 .image-wrap2 .main-img {
  max-width: 594px;
  border-radius: 20px 20px 20px 20px;
}
.rs-choose.choose-style2 .image-wrap2 .image-shape {
  position: absolute;
  left: -10%;
  bottom: 119px;
}
.rs-choose.choose-style2 .image-wrap2 .image-shape img {
  max-width: 277px;
  border-radius: 20px 20px 20px 20px;
}
.rs-choose.choose-style2 .images-wrap {
  position: relative;
  z-index: 11;
}
.rs-choose.choose-style2 .images-wrap .pettarn-one {
  position: absolute;
  left: 15px;
  top: -45px;
  z-index: -11;
}
.rs-choose.choose-style2 .images-wrap .dot-part {
  position: absolute;
  left: 6%;
  bottom: 33px;
}
.rs-choose.choose-style2 .addon-services-item {
  position: relative;
  text-align: center;
}
.rs-choose.choose-style2 .addon-services-item .services-inner {
  position: relative;
  text-align: left;
  display: flex;
  align-items: flex-start;
}
.rs-choose.choose-style2 .addon-services-item .services-inner .numbering {
  text-align: center;
  color: #FFFFFF;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  padding: 19px 0px 0px 0px;
  margin: 0px 25px 0px 0px;
  /* background-image: url(assets/images/choose/style1/duoble-color-shape.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: 70px;
}
.rs-choose.choose-style2 .addon-services-item .services-inner .content-part .title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  color: #00204A;
  margin: 0px 0px 11px 0px;
}
.rs-choose.choose-style2 .addon-services-item .services-inner .content-part .desc-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #656567;
  margin: 0 0 10px;
}
.rs-choose.choose-style2 .addon-services-item .services-inner .ser-animate {
  position: absolute;
  left: -39px;
  top: -71px;
}
.rs-choose.choose-style2 .dot-animation {
  position: absolute;
  top: 18%;
  right: 4%;
}
.rs-choose.choose-style2 .dot-animation img {
  opacity: 0.67;
}
.rs-choose.choose-style2 .rs-whychoous .images-wrap {
  position: relative;
  z-index: 11;
}
.rs-choose.choose-style2 .rs-whychoous .images-wrap .main-img {
  max-width: 628px;
  position: relative;
  right: -10%;
}
.rs-choose.choose-style2 .rs-whychoous .images-wrap .pettarn-two {
  position: absolute;
  right: 14px;
  top: 76px;
  transform: rotate(-90deg);
  z-index: -11;
}
.rs-choose.choose-style2 .rs-whychoous .images-wrap .dot-two {
  position: absolute;
  bottom: 0;
  right: 30px;
}
.rs-choose.choose-style2.choose-modify1 .images-part {
  position: relative;
}
.rs-choose.choose-style2.choose-modify1 .images-part img {
  position: relative;
  right: -75px;
}
.rs-choose.choose-style2.choose-modify1 .images-part .choose-counter-wrap .choose-counter {
  position: absolute;
  left: -38px;
  bottom: 26px;
  padding: 22px 32px 22px 28px;
  background-color: #4301FF;
  transform: skewX(-4deg);
  border-radius: 10px;
}
.rs-choose.choose-style2.choose-modify1 .images-part .choose-counter-wrap .choose-counter .count-number .rs-count {
  color: #FFFFFF;
  font-size: 50px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 61px;
  display: inline-block;
  transition: all .3s ease 0s;
}
.rs-choose.choose-style2.choose-modify1 .images-part .choose-counter-wrap .choose-counter .count-number .prefix {
  color: #FFFFFF;
  font-size: 50px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 61px;
}
.rs-choose.choose-style2.choose-modify1 .images-part .choose-counter-wrap .choose-counter .title {
  color: #FFFFFFD9;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin: 0px 0px 0px 0px;
}
/* ------------------------------------
	18. Services Section CSS
---------------------------------------*/
.rs-services.services-main-home .services-item {
  background-color: #ffffff;
  box-shadow: 0px 30px 60px rgba(0, 0, 1, 0.04);
  overflow: hidden;
  margin: 0 -2px 0 -2px;
  border-radius: 15px;
}
.rs-services.services-main-home .services-item .services-wrap {
  position: relative;
  padding: 40px 25px 40px 25px;
}
.rs-services.services-main-home .services-item .services-wrap .services-icon img {
  max-width: 42px;
  transform: scale(1);
  transition: 0.4s;
  transition-duration: 0s;
}
.rs-services.services-main-home .services-item .services-wrap .services-icon.ser-icon1 img {
  height: 40px;
  width: 40px;
}
.rs-services.services-main-home .services-item .services-wrap .services-content {
  margin: 25px 0px 0px 0px;
}
.rs-services.services-main-home .services-item .services-wrap .services-content .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 0 15px;
  transition: 0.3s;
}
.rs-services.services-main-home .services-item .services-wrap .services-content .title a {
  color: #080d41;
}
.rs-services.services-main-home .services-item .services-wrap .services-content .title a:hover {
  color: #126ee8;
}
.rs-services.services-main-home .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #3e3e3e;
  margin-bottom: 20px;
}
.rs-services.services-main-home .services-item:hover {
  background-color: #126ee8;
  box-shadow: 0px 50px 90px rgba(84, 99, 255, 0.2);
}
.rs-services.services-main-home .services-item:hover .services-wrap .services-content .title a {
  color: #ffffff;
}
.rs-services.services-main-home .services-item:hover .services-wrap .services-content .services-txt {
  color: rgba(255, 255, 255, 0.9);
}
.rs-services.services-main-home .services-item:hover .services-wrap .services-button a i:before,
.rs-services.services-main-home .services-item:hover .services-wrap .services-button a {
  color: #ffffff;
}
.rs-services.services-main-home .services-item:hover .services-button a:hover {
  color: #ffffff;
}
.rs-services.services-main-home.services-modify1 .services-item {
  position: relative;
  transition: all 0.4s ease-in-out 0s;
  text-align: left;
  background: #FFFFFF;
  box-shadow: 0px 30px 60px rgba(0, 0, 1, 0.04);
  border-radius: 15px;
}
.rs-services.services-main-home.services-modify1 .services-item .services-wrap .numbering {
  color: #e2ebea;
  font-size: 48px;
  font-weight: 800;
  top: 30px;
  position: absolute;
  left: 330px;
}
.rs-services.services-main-home.services-modify1 .services-item .services-wrap .services-content .title {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 15px;
}
.rs-services.services-main-home.services-modify1 .services-item .services-wrap .services-content .services-txt {
  font-size: 18px;
  line-height: 28px;
}
.rs-services.services-main-home.services-modify1 .services-item .services-wrap .services-icon img {
  height: 30px;
  width: 30px;
}
.rs-services.services-main-home.services-modify1 .services-item .services-wrap .services-icon {
  width: 86px;
  height: 86px;
  line-height: 86px;
  box-shadow: 0px 20px 40px 0px rgba(99, 75, 248, 0.25);
  background-color: #1a73e9;
  border-radius: 50px 50px 50px 50px;
  text-align: center;
}
.rs-services.services-main-home.services-modify1 .services-item:hover {
  background-color: #1a73e9;
}
.rs-services.services-main-home.services-modify1 .services-item:hover .services-wrap .services-icon {
  background-color: #ffffff;
}
.rs-services.services-main-home.services-modify1 .services-item:hover .services-wrap .numbering {
  color: #FFFFFF30;
}
.rs-services.services-style1 .services-item {
  padding: 34px 30px 30px 30px;
  background-color: #FFFFFF;
  transition: 0.3s;
  border-style: solid;
  border-width: 0px 0px 4px 0px;
  border-color: #FFFFFF;
}
.rs-services.services-style1 .services-item .services-wrap .services-icon {
  display: inline-block;
  width: 70px;
  height: 59px;
  line-height: 59px;
  margin: 0;
  background-color: #292965;
  text-align: center;
  margin: 0px 0px 30px 0px;
  transition-duration: 0.3s;
}
.rs-services.services-style1 .services-item .services-wrap .services-icon img {
  max-width: 30px;
  transform: scale(1);
  transition: 0.4s;
}
.rs-services.services-style1 .services-item .services-wrap .services-content .title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin: 0 0 14px;
}
.rs-services.services-style1 .services-item .services-wrap .services-content .title a {
  color: #00204A;
}
.rs-services.services-style1 .services-item .services-wrap .services-content .title a:hover {
  color: #f05c4e;
}
.rs-services.services-style1 .services-item .services-wrap .services-content .services-txt {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #656567;
  margin-bottom: 0px;
}
.rs-services.services-style1 .services-item:hover {
  border-color: #f05c4e;
}
.rs-services.services-style1 .services-item:hover .services-wrap .services-icon {
  background-color: #f05c4e;
}
.rs-services.services-style1 .services-item:hover .services-wrap .services-icon img {
  transform: scale(0.9);
  transition: 0.4s;
}
.rs-services.services-style2 .services-item {
  padding: 20px 20px 20px 20px;
  border: 1px solid #135c5166;
  border-radius: 12px;
}
.rs-services.services-style2 .services-item .services-icon {
  display: inline-block;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background-color: #135c51;
  border-radius: 7px;
  text-align: center;
  margin: 0px 0px 22px 0px;
}
.rs-services.services-style2 .services-item .services-icon img {
  transition: 0.3s;
  transform: scale(1);
}
.rs-services.services-style2 .services-item .services-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 0 0 14px;
}
.rs-services.services-style2 .services-item .services-content .title a {
  color: rgba(8, 13, 65, 0.8);
}
.rs-services.services-style2 .services-item .services-content .title a:hover {
  color: #00a775;
}
.rs-services.services-style2 .services-item .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #3e3e3e;
  margin-bottom: 0px;
}
.rs-services.services-style2 .services-item:hover .services-icon img {
  transform: scale(0.9);
}
.rs-services.services-style3 .services-item .services-wrap {
  position: relative;
  text-align: center;
  padding: 35px 28px 35px 28px;
  border-right: 1px solid #0000001A;
  border-bottom: 1px solid #0000001A;
  overflow: hidden;
  box-shadow: 30px 30px 60px 0px rgba(1, 61, 101, 0.04);
  transition: all .3s ease 0s;
}
.rs-services.services-style3 .services-item .services-wrap .services-icon {
  position: relative;
  z-index: 1;
}
.rs-services.services-style3 .services-item .services-wrap .services-icon .normal-logo {
  transition: all .3s ease 0s;
}
.rs-services.services-style3 .services-item .services-wrap .services-icon .hover-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all .3s ease 0s;
  z-index: 9;
}
.rs-services.services-style3 .services-item .services-wrap .services-content {
  margin: 25px 0px 0px 0px;
}
.rs-services.services-style3 .services-item .services-wrap .services-content .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 0 15px;
  transition: 0.3s;
}
.rs-services.services-style3 .services-item .services-wrap .services-content .title a {
  color: #080d41;
}
.rs-services.services-style3 .services-item .services-wrap .services-content .title a:hover {
  color: #0092f2;
}
.rs-services.services-style3 .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #3e3e3e;
  margin-bottom: 20px;
}
.rs-services.services-style3 .services-item .services-wrap .services-button a:hover i:before,
.rs-services.services-style3 .services-item .services-wrap .services-button a:hover {
  color: #0092f2;
}
.rs-services.services-style3 .services-item .services-wrap:last-child {
  border-bottom: 1px solid #0000001A;
}
.rs-services.services-style3 .services-item:hover .services-wrap {
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(1, 61, 101, 0.04);
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}
.rs-services.services-style3 .services-item:hover .services-wrap .normal-logo {
  opacity: 0;
}
.rs-services.services-style3 .services-item:hover .services-wrap .hover-logo {
  opacity: 1;
}
.rs-services.services-style3 .services-item:hover:last-child {
  border-bottom: 1px solid #ffffff;
}
.rs-services.services-style3 ul li:nth-child(1) .services-wrap,
.rs-services.services-style3 ul li:nth-child(2) .services-wrap,
.rs-services.services-style3 ul li:nth-child(3) .services-wrap,
.rs-services.services-style3 ul li:nth-child(4) .services-wrap {
  border-top: 1px solid #0000001A;
  transition: all .3s ease 0s;
}
.rs-services.services-style3 ul li:nth-child(1) .services-wrap,
.rs-services.services-style3 ul li:nth-child(5) .services-wrap {
  border-left: 1px solid #0000001A;
  transition: all .3s ease 0s;
}
.rs-services.services-style3 ul li:nth-child(1) .services-wrap {
  border-radius: 25px 0 0 0;
}
.rs-services.services-style3 ul li:nth-child(4) .services-wrap {
  border-radius: 0px 25px 0px 0px;
}
.rs-services.services-style3 ul li:nth-child(5) .services-wrap {
  border-radius: 0px 0px 0px 25px;
}
.rs-services.services-style3 ul li:nth-child(8) .services-wrap {
  border-radius: 0px 0px 25px 0px;
}
.rs-services.services-style3 li:nth-child(1):hover .services-wrap,
.rs-services.services-style3 li:nth-child(2):hover .services-wrap,
.rs-services.services-style3 li:nth-child(3):hover .services-wrap,
.rs-services.services-style3 li:nth-child(4):hover .services-wrap {
  border-top: 1px solid #fff;
}
.rs-services.services-style3 li:nth-child(5):hover .services-wrap {
  border-left: 1px solid #fff;
}
.rs-services.services-style4 .services-item {
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
  padding: 40px 30px 40px 30px;
  margin: 0px 0px 0px 0px;
  border: 0px solid transparent;
  border-radius: 30px 30px 30px 30px;
  overflow: hidden;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.03);
}
.rs-services.services-style4 .services-item .services-wrap {
  position: relative;
}
.rs-services.services-style4 .services-item .services-wrap .services-icon {
  margin: 0px 0px 25px 0px;
}
.rs-services.services-style4 .services-item .services-wrap .services-icon img {
  max-width: 100px;
}
.rs-services.services-style4 .services-item .services-wrap .services-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 0 0 14px;
}
.rs-services.services-style4 .services-item .services-wrap .services-content .title a {
  color: #080d41;
}
.rs-services.services-style4 .services-item .services-wrap .services-content .title a:hover {
  color: #d32f2f;
}
.rs-services.services-style4 .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #3e3e3e;
  margin-bottom: 12px;
}
.rs-services.services-style4 .services-item:before {
  position: absolute;
  content: "";
  left: 1.5px;
  top: 1.5px;
  right: 1.5px;
  bottom: 1.5px;
  background: #fff;
  border-radius: 30px 30px 30px 30px;
}
.rs-services.services-style4 .services-item:hover {
  background-color: transparent;
  /* background-image: linear-gradient(180deg, #0c46db 78 0%, rgba(12, 70, 219, 0) 100%); */
}
.rs-services.services-style5 .services-item {
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
  padding: 40px 30px 40px 30px;
  margin: 0px 0px 0px 0px;
  border-radius: 30px 30px 30px 30px;
  overflow: hidden;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}
.rs-services.services-style5 .services-item .services-wrap {
  position: relative;
}
.rs-services.services-style5 .services-item .services-wrap .services-icon {
  margin: 0px 0px 25px 0px;
}
.rs-services.services-style5 .services-item .services-wrap .services-icon img {
  max-width: 100px;
}
.rs-services.services-style5 .services-item .services-wrap .services-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 0 0 14px;
}
.rs-services.services-style5 .services-item .services-wrap .services-content .title a {
  color: #080d41;
}
.rs-services.services-style5 .services-item .services-wrap .services-content .title a:hover {
  color: #00a775;
}
.rs-services.services-style5 .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #3e3e3e;
  margin-bottom: 0;
}
.rs-services.services-style5.services-modify3 .services-item {
  box-shadow: 0px 30px 60px 0px rgba(0, 0, 1, 0.04);
  border-radius: 15px 15px 15px 15px;
}
.rs-services.services-style5.services-modify3 .services-item .services-wrap .services-icon {
  width: 94px;
  height: 94px;
  line-height: 94px;
  margin: 0 auto;
  box-shadow: 0px 5px 49px 0px rgba(99, 75, 248, 0.25);
  background-color: transparent;
  background-image: linear-gradient(134deg, #9b01ff 0%, #e9ee00 100%);
  border-radius: 50px 50px 50px 50px;
}
.rs-services.services-style5.services-modify3 .services-item .services-wrap .services-content {
  padding: 23px 0 0;
}
.rs-services.services-style5.services-modify3 .services-item .services-wrap .services-content .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin: 0 0 14px;
}
.rs-services.services-style5.services-modify3 .services-item .services-wrap .services-content .title a:hover {
  color: #b65df0;
}
.rs-services.services-style5.services-modify3 .services-item:hover {
  box-shadow: 0px 30px 40px 0px rgba(0, 0, 1, 0.08);
}
.rs-services.services-style5.services-modify4 .services-item {
  border-radius: 15px 15px 15px 15px;
}
.rs-services.services-style5.services-modify4 .services-item .services-wrap .services-icon {
  width: 86px;
  height: 86px;
  line-height: 86px;
  margin: 0 auto;
  box-shadow: 0px 20px 40px 0px rgba(182, 93, 240, 0.2);
  background-color: #B65DF0;
  border-radius: 50px 50px 50px 50px;
}
.rs-services.services-style5.services-modify4 .services-item .services-wrap .services-content {
  padding: 23px 0 0;
}
.rs-services.services-style5.services-modify4 .services-item .services-wrap .services-content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  margin: 0 0 14px;
}
.rs-services.services-style5.services-modify4 .services-item .services-wrap .services-content .title a:hover {
  color: #b65df0;
}
.rs-services.services-style5.services-modify5 .services-item {
  padding: 40px 30px 40px 30px;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 30px 60px 0px rgba(0, 0, 1, 0.05);
}
.rs-services.services-style5.services-modify5 .services-item .services-wrap .services-content .title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  margin: 0 0 14px;
}
.rs-services.services-style5.services-modify5 .services-item .services-wrap .services-content .title a:hover {
  color: #83a300;
}
.rs-services.services-style5.services-modify6 .services-item {
  padding: 33px 30px 33px 30px;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0px 30px 60px 0px rgba(0, 0, 1, 0.05);
  text-align: left;
}
.rs-services.services-style5.services-modify6 .services-item.gap-bottom {
  margin-bottom: 30px !important;
}
.rs-services.services-style5.services-modify6 .services-item .services-wrap .services-content {
  padding: 23px 0 0;
}
.rs-services.services-style5.services-modify6 .services-item .services-wrap .services-icon {
  width: 86px;
  height: 86px;
  line-height: 86px;
  margin: 0 0;
  background-color: #ff6600;
  box-shadow: 0px 20px 40px 0px rgba(255, 102, 0, 0.19);
  border-radius: 50px 50px 50px 50px;
  text-align: center;
}
.rs-services.services-style5.services-modify6 .services-item .services-wrap .services-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 0 0 14px;
}
.rs-services.services-style5.services-modify6 .services-item .services-wrap .services-content .title a:hover {
  color: #ff6600;
}
.rs-services.services-style6 .services-item {
  position: relative;
  padding: 30px 40px 40px 40px;
  background-color: #FFFFFF8A;
  border-radius: 15px 15px 15px 15px;
  overflow: hidden;
  box-shadow: 20px 20px 60px 0px rgba(0, 0, 1, 0.05);
}
.rs-services.services-style6 .services-item .services-wrap .services-icon img {
  width: 80px;
}
.rs-services.services-style6 .services-item .services-wrap .numbering {
  color: #2E80EC12;
  font-size: 60px;
  font-weight: 700;
  top: 28px;
  position: absolute;
  left: 76%;
}
.rs-services.services-style6 .services-item .services-wrap .services-content {
  margin: 20px 0px 0px 0px;
}
.rs-services.services-style6 .services-item .services-wrap .services-content .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin: 0 0 15px;
  transition: 0.3s;
}
.rs-services.services-style6 .services-item .services-wrap .services-content .title a {
  color: #183760;
}
.rs-services.services-style6 .services-item .services-wrap .services-content .title a:hover {
  color: #126ee8;
}
.rs-services.services-style6 .services-item .services-wrap .services-content .services-txt {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #656567;
  margin-bottom: 14px;
}
.rs-services.services-style7 .services-item .services-wrap {
  padding: 30px 30px 30px 30px;
  background-color: #FFFFFF;
  border-radius: 15px 15px 15px 15px;
  overflow: hidden;
}
.rs-services.services-style7 .services-item .services-wrap .images-media-wrap {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}
.rs-services.services-style7 .services-item .services-wrap .images-media-wrap .overlay-normal {
  position: absolute;
  right: -68px;
  bottom: -68px;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  padding: 37px 40px 40px 40px;
  transition: all 0.5s;
  opacity: 1;
  background: linear-gradient(253.04deg, #4200ff 7.71%, #104cba 91.47%);
  z-index: 1;
  pointer-events: none;
}
.rs-services.services-style7 .services-item .services-wrap .images-media-wrap .overlay-normal img {
  filter: brightness(900000000%);
  width: 45px;
  height: auto;
  position: relative;
  z-index: 1;
}
.rs-services.services-style7 .services-item .services-wrap .images-media-wrap .overlay-normal:after {
  position: absolute;
  content: "";
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(253.04deg, #4200ff 7.71%, #104cba 91.47%);
  border-radius: 50%;
  opacity: 0.3;
  z-index: -1;
  transition: all 0.5s;
  transition-delay: 0.5s;
  transform: scale(1.2);
}
.rs-services.services-style7 .services-item .services-wrap .images-media-wrap .overlay-hover {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  border-radius: 15px;
  overflow: hidden;
  transform: scale(0);
}
.rs-services.services-style7 .services-item .services-wrap .images-media-wrap .overlay-hover .icon-wrapper {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background: #fff;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.rs-services.services-style7 .services-item .services-wrap .images-media-wrap .overlay-hover .icon-wrapper img {
  width: 40px;
  height: auto;
}
.rs-services.services-style7 .services-item .services-wrap .images-media-wrap .overlay-hover:after {
  position: absolute;
  content: "";
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(253.04deg, #4200ff 7.71%, #104cba 91.47%);
  opacity: 0.6;
}
.rs-services.services-style7 .services-item .services-wrap .services-content {
  margin: 23px 0px 0px 0px;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin: 0 0 15px;
  transition: 0.3s;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .title a {
  color: #00204A;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .title a:hover {
  color: #3a15fb;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .services-txt {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #656567;
  margin-bottom: 14px;
}
.rs-services.services-style7 .services-item .services-wrap:hover .images-media-wrap .overlay-normal {
  transform: translate(108px, 108px);
  opacity: 0;
}
.rs-services.services-style7 .services-item .services-wrap:hover .images-media-wrap .overlay-normal:after {
  transform: scale(1);
  transition-delay: 0s;
}
.rs-services.services-style7 .services-item .services-wrap:hover .images-media-wrap .overlay-hover {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.rs-services.services-style7 .services-item .services-wrap:hover .services-button.ser-btn5 a i:before,
.rs-services.services-style7 .services-item .services-wrap:hover .services-button.ser-btn5 a {
  color: #3a15fb;
}
.rs-services.services-style8 .services-item {
  position: relative;
}
.rs-services.services-style8 .services-item .services-wrap {
  text-align: center;
  display: block;
  transition: all .3s ease 0s;
  padding: 50px 25px 50px 25px;
  background-color: #F6F9FE;
  /* background-image: url(assets/images/services/main-home/blue/ser-bg1.jpg); */
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px 15px 15px 15px;
  overflow: hidden;
  box-shadow: 0px 30px 60px 0px rgba(0, 0, 1, 0.04);
}
.rs-services.services-style8 .services-item .services-wrap .numbering {
  text-align: left;
  color: #E0DFFD7A;
  font-size: 48px;
  font-weight: 700;
  top: 45px;
  position: absolute;
  left: 15px;
}
.rs-services.services-style8 .services-item .services-wrap .numbering:before {
  content: "";
  opacity: 0.4;
  background-color: #634BF8;
  width: 6px;
  height: 47px;
  top: 15px;
  position: absolute;
  left: -15px;
  border-radius: 0px 10px 10px 0px;
}
.rs-services.services-style8 .services-item .services-wrap .services-icon {
  margin-bottom: 20px;
}
.rs-services.services-style8 .services-item .services-wrap .services-content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin: 0 0 20px;
}
.rs-services.services-style8 .services-item .services-wrap .services-content .title a {
  color: #080d41;
}
.rs-services.services-style8 .services-item .services-wrap .services-content .title a:hover {
  color: #634bf8;
}
.rs-services.services-style8 .services-item .services-wrap .services-content .services-txt {
  font-size: 18px;
  color: #656567;
  font-weight: 500;
  line-height: 26px;
  margin: 0 0 20px;
}
.rs-services.services-style8 .services-item .services-wrap:before {
  position: absolute;
  content: "";
  width: 210px;
  height: 13px;
  background: #efedfe;
  border-radius: 10px 10px 0 0;
  left: 50%;
  top: -12px;
  transform: translate(-108px, 0px);
  transition: 0.5s;
}
.rs-services.services-style8 .services-item .services-wrap:after {
  position: absolute;
  content: "";
  width: 210px;
  height: 13px;
  background: #efedfe;
  border-radius: 0px 0px 10px 10px;
  left: 50%;
  bottom: -12px;
  transform: translate(-108px, 0px);
  transition: 0.5s;
}
.rs-services.services-style8 .services-item .services-wrap:hover:before {
  background-image: linear-gradient(150deg, #634bf8 0%, #f8b401 150%);
}
.rs-services.services-style8 .services-item .services-wrap:hover:after {
  background-image: linear-gradient(150deg, #634bf8 0%, #f8b401 150%);
}
.rs-services.services-style8 .services-item .services-wrap:hover .services-button.ser-btn6 a i:before,
.rs-services.services-style8 .services-item .services-wrap:hover .services-button.ser-btn6 a {
  color: #634bf8;
}
.rs-services.services-style8.services-modify7 .services-item .services-wrap .numbering:before {
  background-color: #126ee8;
}
.rs-services.services-style8.services-modify7 .services-item .services-wrap:hover:after,
.rs-services.services-style8.services-modify7 .services-item .services-wrap:hover:before {
  background-color: #0B63DE;
  background-image: unset;
}
.rs-services.services-style8.services-modify7 .services-item .services-wrap .services-content .title a:hover {
  color: #126ee8;
}
.rs-services.services-style9 .services-item {
  display: flex;
  align-items: flex-start;
}
.rs-services.services-style9 .services-item .services-icon {
  margin: 0px 20px 0px 0px;
}
.rs-services.services-style9 .services-item .services-content {
  margin: -7px 0px 0px 0px;
}
.rs-services.services-style9 .services-item .services-content .title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #00204a;
  margin: 0 0 10px;
}
.rs-services.services-style9 .services-item .services-content .desc-text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #656567;
}
.rs-services.services-style10 .services-item .services-wrap {
  position: relative;
  box-shadow: 18px 18px 0px 0px rgba(93, 151, 222, 0.07);
  padding: 35px 40px 35px 40px;
  background-color: #FFFFFFCC;
  border-radius: 0px 0px 0px 0px;
  overflow: hidden;
  margin: 0px 30px 0px 0px;
}
.rs-services.services-style10 .services-item .services-wrap .numbering {
  text-align: right;
  color: #00469C0F;
  font-size: 82px;
  font-weight: 700;
  padding: 0px 0px 0px 0px;
  border-style: none;
  top: 6px;
  position: absolute;
  left: 64%;
}
.rs-services.services-style10 .services-item .services-wrap .services-icon img {
  width: 80px;
}
.rs-services.services-style10 .services-item .services-wrap .services-content {
  margin: 20px 0px 0px 0px;
}
.rs-services.services-style10 .services-item .services-wrap .services-content .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin: 0 0 15px;
  transition: 0.3s;
}
.rs-services.services-style10 .services-item .services-wrap .services-content .title a {
  color: #183760;
}
.rs-services.services-style10 .services-item .services-wrap .services-content .title a:hover {
  color: #126ee8;
}
.rs-services.services-style10 .services-item .services-wrap .services-content .services-txt {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #656567;
  margin-bottom: 16px;
}
.rs-services.services-style10.services-modify8 .services-item .services-wrap .numbering {
  color: #F971231A;
}
.rs-services.services-style10.services-modify8 .services-item .services-wrap .services-content .title a:hover {
  color: #F97123;
}
.rs-services.services-style10.services-modify8 .services-item .services-wrap {
  box-shadow: 20px 20px 0px 0px rgba(93, 151, 222, 0.07);
  transition-duration: 0.7s;
}
.rs-services.services-style10.services-modify8 .services-item .services-wrap:hover {
  box-shadow: 22px 24px 0px 0px #136B96;
}
.rs-services.services-style10.services-modify8 .services-item .services-wrap:hover .services-content .title a {
  color: #F97123;
}
.rs-services.services-style10.services-modify8 .services-item .services-wrap:hover .services-button a i:before,
.rs-services.services-style10.services-modify8 .services-item .services-wrap:hover .services-button a {
  color: #F97123;
}
.rs-services-details .annual-report {
  background-color: #f6f9fe;
  border-radius: 5px 5px 5px 5px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #1A73E91A;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 18px;
  margin-bottom: 0px;
  padding: 0px 20px 30px 20px;
  text-align: center;
}
.rs-services-details .annual-report .content-txt .title {
  font-size: 28px;
  font-weight: 600;
  line-height: 38px;
  color: #080d41;
  margin: 20px 0px 20px 0px;
}
.rs-services-details .let-talk-part {
  /* background-image: url(assets/images/services/services-details/sidebar-bg.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px 5px 5px 5px;
  padding: 20px 17px 124px 10px;
}
.rs-services-details .let-talk-part .content-wrap {
  padding: 10px 10px 10px 10px;
}
.rs-services-details .let-talk-part .content-wrap .title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  color: #FFFFFF;
  margin: 0px 0px 5px 0px;
}
.rs-services-details .let-talk-part .content-wrap .desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #FFFFFF;
  margin: 0px 0px 2px 0px;
}
/* ------------------------------------
	19. Faq Section CSS
---------------------------------------*/
.rs-faq.faq-style1 .faq-img img {
  border-radius: 40px;
}
.rs-faq.faq-style1 .faq-content .accordion {
  border-width: 12px;
}
.rs-faq.faq-style1 .faq-content .accordion .card {
  margin-bottom: 15px;
  border: none;
  padding: 0px 0px 10px 0px;
  border-style: solid;
  border-width: 0px 0px 0.5px 0px;
  border-color: #3E3E3E1C;
  border-radius: 0px 0px 0px 0px;
  background-color: #FFFFFF;
}
.rs-faq.faq-style1 .faq-content .accordion .card:last-child {
  margin-bottom: 0 !important;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header {
  display: flex;
  align-items: center;
  padding: 0 !important;
  border: none;
  background-color: unset;
  border-radius: unset;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header .card-link {
  position: relative;
  display: block;
  width: 100%;
  padding: 5px 0px 0 0px;
  height: 48px;
  font-size: 20px;
  font-weight: 600;
  color: #0092f2;
  background: unset;
  box-shadow: unset;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header .card-link:after {
  font-family: FontAwesome;
  position: absolute;
  content: "\f0d7";
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 20px;
  color: #0092f2;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #080d41;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #080d41;
  content: "\f0d8";
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-body {
  
  padding: 0 8px 15px 0px !important;
  width: 100%;
  color: #292525;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.32px;
}
.rs-faq.faq-style1.faq-modify1 .faq-img img {
  border-radius: 299.5px;
}
.rs-faq.faq-style1.faq-modify1 .faq-content .accordion .card .card-header .card-link:after,
.rs-faq.faq-style1.faq-modify1 .faq-content .accordion .card .card-header .card-link {
  color: #00a775;
}
.rs-faq.faq-style1.faq-modify1 .faq-content .accordion .card .card-header .card-link.collapsed:after,
.rs-faq.faq-style1.faq-modify1 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: rgba(8, 13, 65, 0.9);
}
.rs-faq.faq-style1.faq-modify2 .faq-content .accordion .card {
  background-color: #FFFFFF;
  background-image: linear-gradient(70deg, #060d58 0%, #5463ff 88%);
  border-radius: 10px 10px 10px 10px;
  padding: 10px 27px;
}
.rs-faq.faq-style1.faq-modify2 .faq-content .accordion .card .card-body,
.rs-faq.faq-style1.faq-modify2 .faq-content .accordion .card .card-header .card-link:after,
.rs-faq.faq-style1.faq-modify2 .faq-content .accordion .card .card-header .card-link.collapsed:after,
.rs-faq.faq-style1.faq-modify2 .faq-content .accordion .card .card-header .card-link {
  color: #ffffff;
}
.rs-faq.faq-style1.faq-modify3 .faq-content .accordion .card {
  margin-bottom: 15px;
  padding: 0px 0px 10px 0px;
  border-style: solid;
  border-width: unset;
  border-color: #3E3E3E1C;
  border-radius: 0px 0px 0px 0px;
  background-color: transparent;
  border: none;
}
.rs-faq.faq-style1.faq-modify3 .faq-content .accordion .card .card-body {
  background-color: #F8FFF2;
  padding: 0 30px 30px 30px !important;
}
.rs-faq.faq-style1.faq-modify3 .faq-content .accordion .card .card-header .card-link {
  background-color: #F8FFF2;
  color: #080D41;
  padding: 20px 30px 0 30px;
  border-radius: 5px;
  height: 71px;
}
.rs-faq.faq-style1.faq-modify3 .faq-content .accordion .card .card-header .card-link:after {
  right: 25px;
  color: #080D41;
}
.rs-faq.faq-style1.faq-modify3 .accordion .card .card-header .card-link.collapsed:after {
  color: #ffffff;
}
.rs-faq.faq-style1.faq-modify3 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #ffffff;
  background-color: #00A775 !important;
  border-radius: 5px;
}
.rs-faq.faq-style1.faq-modify4 {
  position: relative;
}
.rs-faq.faq-style1.faq-modify4 .faq-overly-bg {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: #040D3C;
  opacity: 0.89;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #ffffff;
  content: "\f078";
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card .card-header .card-link:after {
  content: "\f077";
  font-weight: 300;
  font-size: 16px;
  color: #ffffff;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card .card-header .card-link {
  color: #ffffff;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card {
  padding: 24px 25px 25px 25px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #FFFFFF33;
  border-radius: 10px 10px 10px 10px;
  background-color: #FFFFFF0A;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card .card-header .card-link {
  height: 30px;
  padding: 0px 0px 0 0px;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #ffffff;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card .card-body {
  font-size: 16px;
  color: #F3EEEEDB;
  padding: 16px 0px 0px 0px !important;
  width: 100%;
}
.rs-faq.faq-style1.faq-modify5 .content-wrap {
  background-color: #F1F7FF8C;
  padding: 50px 50px 50px 50px;
}
.rs-faq.faq-style1.faq-modify5 .content-wrap .faq-content .accordion .card {
  margin-bottom: 15px;
  border: none;
  padding: 25px 25px 25px 20px;
  border-style: none;
  border-width: none;
  border-color: none;
  background-color: #FFFFFF;
}
.rs-faq.faq-style1.faq-modify5 .content-wrap .faq-content .accordion .card .card-header .card-link {
  font-size: 20px;
  font-weight: 700;
}
.rs-faq.faq-style1.faq-modify5 .content-wrap .faq-content .accordion .card .card-body {
  color: #656567;
  padding: 10px 0px 0px 0px !important;
  width: 100%;
}
.rs-faq.faq-style1.faq-modify5 .content-wrap .faq-content .accordion .card .card-header .card-link {
  padding: 0px 0px 0 0px;
  height: 30px;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card {
  margin-bottom: 15px;
  padding: 0px 0px 10px 0px;
  border-style: solid;
  border-width: unset;
  border-color: #3E3E3E1C;
  border-radius: 0px 0px 0px 0px;
  background-color: transparent;
  border: none;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card .card-body {
  background-color: #FBFBFB;
  padding: 0 30px 30px 30px !important;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card .card-header .card-link {
  background-color: #FBFBFB;
  color: #080D41;
  padding: 20px 30px 0 30px;
  border-radius: 5px;
  height: 71px;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card .card-header .card-link:after {
  right: 25px;
  color: #080D41;
}
.rs-faq.faq-style1.faq-modify6 .accordion .card .card-header .card-link.collapsed:after {
  color: #ffffff;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #ffffff;
  background-color: #1a73e9 !important;
  border-radius: 5px;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  content: "\f078";
  font-weight: 200;
  font-size: 16px;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card .card-header .card-link:after {
  content: "\f077";
  font-weight: 200;
  font-size: 16px;
}
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card {
  margin-bottom: 15px;
  padding: 0px 0px 10px 0px;
  border-style: solid;
  border-width: unset;
  border-color: #3E3E3E1C;
  border-radius: 0px 0px 0px 0px;
  background: #FFF;
box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05);
  border: none;
}
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card .card-body {
  background-color: #fff;
  padding: 0 30px 30px 30px !important;
}
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card .card-header .card-link {
  background-color: #fff;
  padding: 20px 30px 0 30px;
  border-radius: 5px;
  height: 71px;
  color: #0D4058;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.8px;
}
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card .card-header .card-link:after {
  right: 25px;
  color: #1a73e9;
}
.rs-faq.faq-style1.faq-modify7 .accordion .card .card-header .card-link.collapsed:after {
  color: #080d41;
}
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card .card-header .card-link.collapsed {
  background-color: #fff !important;
  border-radius: 5px;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card {
  font-size: 22px;
  font-weight: 700;
  color: #00204A;
  padding: 21px 0px 21px 20px;
  border-radius: 6px 6px 6px 6px;
  background-color: #F6F9FE;
  border-color: #F6F9FE;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-body {
  font-size: 18px;
  color: #656567;
  font-weight: 400;
  padding: 10px 42px 0px 0px !important;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-header .card-link:after {
  border-radius: 50px 50px 50px 50px;
  width: 30px;
  height: 30px;
  line-height: 33px;
  content: "\f068";
  right: 31px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 13px;
  color: #0092f2;
  background-color: #E0ECFC;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-header .card-link {
  padding: 0px 0px 0 0px;
  height: 35px;
  font-size: 22px;
  font-weight: 700;
  color: #0092f2;
  background: unset;
  box-shadow: unset;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #080d41;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  content: "\f067";
  color: #fff;
  background-color: #0092f2;
}
/* ------------------------------------
	20. Team Section CSS
---------------------------------------*/
.rs-team.team-style1 .team-item-wrap {
  overflow: hidden;
  box-shadow: 30px 30px 60px 0px rgba(0, 0, 1, 0.05);
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap {
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 20px rgba(34, 34, 34, 0.1);
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap {
  background-color: #ECF4FF;
  border-radius: 30px 30px 30px 0px;
  transition: all .3s ease 0s;
  position: relative;
  text-align: center;
  transform: translateX(0);
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap a {
  position: relative;
  z-index: 1;
  display: inline-block;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap a img {
  max-width: calc(80%);
  opacity: 1;
  transition: all .3s ease 0s;
  transform: scale(1) !important;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap .team-social-collaps {
  margin: 0;
  position: absolute;
  z-index: 2;
  right: 17px;
  bottom: 17px;
  font-size: 15px;
  cursor: pointer;
  transition: all .3s ease 0s;
  background: #fff;
  color: #1a73e9;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 7px 30px 0px rgba(0, 28, 47, 0.2);
  border-radius: 50% 50% 50% 50%;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap .team-social-collaps:before {
  background: #fff;
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  transition: all .3s ease 0s;
  -webkit-animation: pulse-border 1100ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
  opacity: 0;
  visibility: hidden;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap .plus-team {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  transition: all .3s ease 0s;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap .plus-team .share-excerpt {
  font-size: 14px;
  line-height: 24px;
  color: #3A3A3A;
  writing-mode: vertical-rl;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap .plus-team .social-icons1 {
  background: 0 0;
  padding: 12px 10px;
  transition: all .3s ease 0s;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap .plus-team .social-icons1 a {
  margin-bottom: 4px;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap .plus-team .social-icons1 a i {
  font-size: 12px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: #3A3A3A80 !important;
  background: #02010100 !important;
  margin: 0px 0px 0px 0px;
  border-style: solid;
  border-width: 0.5px 0.5px 0.5px 0.5px;
  border-color: #1837604D;
  text-align: center;
  transition: .3s all ease;
  border-radius: 50%;
  margin: 0;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap .plus-team .social-icons1 a i:hover {
  color: #FFFFFF !important;
  background: #1A73E9 !important;
  border-style: solid;
  border-width: 0.5px 0.5px 0.5px 0.5px;
  border-color: #1A73E9;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .image-wrap:after {
  /* background-image: url(assets/images/team/shape-1.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 186px auto;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: rotate(0);
  transition: transform .8s;
  pointer-events: none;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .team-content {
  position: relative;
  opacity: 1;
  background-color: #FFFFFF;
  box-shadow: 30px 30px 60px 0px rgba(0, 0, 1, 0.05);
  padding: 20px 0px 25px 0px;
  border-radius: 0px 0px 30px 30px;
  text-align: center;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .team-content .team-name {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 0px;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .team-content .team-name a {
  color: #080d41;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .team-content .team-name a:hover {
  color: #1a73e9;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap .team-content .testi-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #3a3a3a;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap:hover .image-wrap .team-social-collaps:before {
  opacity: 1;
  visibility: visible;
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap:hover .image-wrap:after {
  transform: rotate(5deg);
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap.is-open .image-wrap {
  transform: translateX(-40px);
}
.rs-team.team-style1 .team-item-wrap .team-inner-wrap.is-open .image-wrap .share-icon::before {
  opacity: 1;
  visibility: visible;
}
.rs-team-single .team-widget {
  background-color: #F6F9FE;
  padding: 70px 70px 70px 70px;
  border-radius: 10px 10px 10px 10px;
}
.rs-team-single .team-widget .images-part {
  background-color: #ECF4FF;
  border-radius: 50% 50% 50% 50%;
  /* background-image: url(assets/images/team/shape-1.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 60% auto;
}
.rs-team-single .team-widget .images-part img {
  width: 100%;
  max-width: 470px;
  border-radius: 50% 50% 50% 50%;
}
.rs-team-single .team-widget .con-info {
  margin: 0 0 0 50px;
}
.rs-team-single .team-widget .con-info .designation-info {
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
  color: #1a73e9;
  padding-bottom: 20px;
  display: block;
}
.rs-team-single .team-widget .con-info .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #080d41;
  margin: 0px 0px 8px 0px;
}
.rs-team-single .team-widget .con-info .social-info li {
  display: inline-block;
  margin-right: 10px;
}
.rs-team-single .team-widget .con-info .social-info li:last-child {
  margin-right: 0;
}
.rs-team-single .team-widget .con-info .social-info li a i {
  font-size: 16px;
  transition: all .8s ease;
  background-color: #02010100;
  border: 1px solid #1A73E938;
  color: #1a73e9;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  border-radius: 50px;
}
.rs-team-single .team-widget .con-info .social-info li a i:hover {
  color: #ffffff;
  background-color: #126ee8;
  border: 1px solid #126ee8;
}
.rs-team-single .team-widget .con-info .short-desc {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #656567;
  margin: 0 0 30px;
}
.rs-team-single .team-widget .con-info .title-inner .title-wrap {
  margin-bottom: 10px;
}
.rs-team-single .team-widget .con-info .title-inner .title-wrap span {
  font-size: 18px;
  font-weight: 500;
  color: #3d3458;
}
.rs-team-single .team-widget .con-info .title-inner .title-wrap span a {
  font-size: 18px;
  font-weight: 400;
  color: #6D6D6D;
  margin: 0px 0px 0px 0px;
}
.rs-team-single .team-widget .con-info .title-inner .title-wrap span a:hover {
  color: #126ee8;
}
.rs-team-single .title-inner .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #080d41;
  margin: 0px 0px 24px 0px;
}
.rs-team-single .title-inner .description p {
  font-size: 18px;
  color: #656567;
  line-height: 28px;
  transition: all .3s ease 0s;
  margin: 0 0 35px;
  display: block;
}
.rs-team-single .contact-wrap {
  background-color: #f6f9fe;
  padding: 40px 40px 40px 40px;
  border-radius: 10px 10px 10px 10px;
}
.rs-team-single .contact-wrap .from-control {
  font-size: 16px;
  padding: 16px 22px 16px 22px;
  border-radius: 8px 8px 8px 8px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #FFFFFF33;
  background-color: #fff;
  color: #ffffff;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-team-single .contact-wrap textarea {
  height: 120px;
}
/*------------------------------------
	21. Process Section CSS
------------------------------------*/
.rs-process.process-style1 .process-bg-wrap {
  /* background-image: url(assets/images/process/style1/process-line.png); */
  background-repeat: no-repeat;
  text-align: center;
  background-position: 240px 48px;
}
.rs-process.process-style1 .process-bg-wrap .process-item {
  text-align: center;
}
.rs-process.process-style1 .process-bg-wrap .process-item .icon-image {
  width: 89px;
  height: 89px;
  line-height: 89px;
  margin: 0 auto;
  background-color: #292965;
  transition-duration: 0.3s;
  text-align: center;
}
.rs-process.process-style1 .process-bg-wrap .process-item .icon-image img {
  transform: scale(1);
  transition: 0.4s;
}
.rs-process.process-style1 .process-bg-wrap .process-item .content-txt {
  padding: 34px 40px 0;
}
.rs-process.process-style1 .process-bg-wrap .process-item .content-txt .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #080d41;
  margin: 0 0 14px;
}
.rs-process.process-style1 .process-bg-wrap .process-item .content-txt .desc {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #3e3e3e;
  margin: 0;
}
.rs-process.process-style1 .process-bg-wrap .process-item:hover .icon-image img {
  transform: scale(0.9);
}
.rs-process.process-style1 .process-bg-wrap .process-item:hover .content-txt .title {
  color: #f05c4e;
}
.rs-process.process-style2 .process-bg-wrap {
  position: relative;
}
.rs-process.process-style2 .process-bg-wrap .process-item {
  text-align: center;
}
.rs-process.process-style2 .process-bg-wrap .process-item .icon-image {
  display: inline-block;
  width: 110px;
  height: 110px;
  line-height: 110px;
  margin: 0 auto;
  background-color: #D32F2F;
  padding: 0px 0px 0px 0px;
  border-radius: 50% 50% 50% 50%;
  transition: 0.3s;
}
.rs-process.process-style2 .process-bg-wrap .process-item .content-txt {
  padding: 25px 0 0 0;
}
.rs-process.process-style2 .process-bg-wrap .process-item .content-txt .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #080d41;
  margin: 0 0 14px;
}
.rs-process.process-style2 .process-bg-wrap .process-item .content-txt .desc {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #3e3e3e;
  margin: 0;
}
.rs-process.process-style2 .process-bg-wrap .triangle {
  position: absolute;
}
.rs-process.process-style2 .process-bg-wrap .triangle.bg-style1 {
  left: 203px;
  top: 19px;
}
.rs-process.process-style2 .process-bg-wrap .triangle.bg-style2 {
  left: 527px;
  top: 58px;
}
.rs-process.process-style2 .process-bg-wrap .triangle.bg-style3 {
  right: 202px;
  top: 10px;
}
.rs-process.process-style3 {
  position: relative;
}
.rs-process.process-style3 .process-bg-wrap {
  /* background-image: url(assets/images/bg/map.png); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 34% 71%;
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: -24px;
  left: 299px;
  position: absolute;
}
/*------------------------------------
	22. Partner Section CSS
------------------------------------*/
.rs-partner.partner-main-home .slider .partner-item {
  padding: 0 52px;
}
.rs-partner.partner-main-home .slider .partner-item .logo-img {
  text-align: center;
}
.rs-partner.partner-main-home .slider .partner-item .logo-img a {
  overflow: hidden;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.rs-partner.partner-main-home .slider .partner-item .logo-img a .hovers-logos {
  display: none;
}
.rs-partner.partner-main-home .slider .partner-item .logo-img a .mains-logos {
  transition: all 0.3s ease-in-out;
  opacity: 0.8;
  filter: blur(0px);
}
.rs-partner.partner-main-home .slider .slick-list {
  padding: 0 !important;
}
.rs-partner.partner-main-home.partner-modify1 .slider .partner-item {
  padding: 0 38px;
}
.rs-partner.partner-main-home.partner-modify1 .slider .partner-item .logo-img a .mains-logos {
  transition: all 0.3s ease-in-out;
  opacity: unset;
  filter: unset;
}
.rs-partner.partner-style2 {
  z-index: 111;
}
.rs-partner.partner-style2 .partner-bg-overlay {
  background-color: #FFFFFF;
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -111;
}
.rs-partner.partner-style2 .partner-item {
  padding: 80px 80px 80px 80px;
  margin: 0px 0px 0px 0px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  background-color: #FFFFFF;
}
.rs-partner.partner-style2 .partner-item .logo-img {
  text-align: center;
}
.rs-partner.partner-style2 .partner-item .logo-img a {
  overflow: hidden;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.rs-partner.partner-style2 .partner-item .logo-img a .hovers-logos {
  display: none;
}
.rs-partner.partner-style2 .partner-item .logo-img a .mains-logos {
  transition: all 0.3s ease-in-out;
  opacity: 0.8;
  filter: blur(0px);
}
.rs-partner.partner-style2 .partner-item:hover .logo-img a .mains-logos {
  opacity: 0.6;
  filter: blur(0.2px);
}
.rs-partner.partner-style2 .logo-grid-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  border-right: 1px solid rgba(0, 0, 0, 0.04);
}
.rs-partner.partner-style2 .logo-grid-item.pre-last {
  border-right: 0;
}
.rs-partner.partner-style2 .logo-grid-item.pre-last-row {
  border-bottom: 0;
}
.rs-partner.partner-style2.partner-modify4 .partner-item {
  background-color: transparent;
}
.rs-partner.partner-style2.partner-modify4 .logo-grid-item {
  border-bottom: 1px solid #ECE8E821;
  border-right: 1px solid #ECE8E821;
}
.rs-partner.partner-style2.partner-modify4 .pre-last-row {
  border-bottom: 0;
}
.rs-partner.partner-style2.partner-modify4 .pre-last {
  border-right: 0;
}
.rs-partner.partner-style2.partner-modify5 .partner-wrap {
  padding: 60px 70px 50px 70px;
  border-radius: 20px 20px 20px 20px;
  background-color: #FFFFFF96;
}
.rs-partner.partner-style2.partner-modify5 .partner-item {
  padding: 40px 40px 40px 40px;
  margin: 0px 0px 0px 0px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  background-color: transparent;
}
.rs-partner.partner-style2.partner-modify5 .partner-item:hover .logo-img a .mains-logos {
  display: block;
  visibility: hidden;
  transform: translateY(100%);
}
.rs-partner.partner-style2.partner-modify5 .partner-item:hover .logo-img a .hovers-logos {
  display: block;
  opacity: 3;
  visibility: visible;
  transform: translateY(0%);
  filter: grayscale(0%);
}
.rs-partner.partner-style2.partner-modify5 .partner-item .logo-img a .hovers-logos {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  filter: blur(0px);
  transition: 0.3s;
}
.rs-partner.partner-style2.partner-modify5 .partner-item .logo-img a .mains-logos {
  opacity: unset;
  filter: blur(0px);
  transition: 0.3s;
}
.rs-partner.partner-style2.partner-modify5 .logo-grid-item:nth-child(3),
.rs-partner.partner-style2.partner-modify5 .logo-grid-item:nth-child(6),
.rs-partner.partner-style2.partner-modify5 .logo-grid-item:nth-child(9),
.rs-partner.partner-style2.partner-modify5 .logo-grid-item:nth-child(12) {
  border-right: none;
}
.rs-partner.partner-style2.partner-modify5 .logo-grid-item:nth-child(10),
.rs-partner.partner-style2.partner-modify5 .logo-grid-item:nth-child(11),
.rs-partner.partner-style2.partner-modify5 .logo-grid-item:nth-child(12) {
  border-bottom: none;
}
.rs-partner.partner-style2.partner-modify5.partner-about1 .logo-grid-item:nth-child(7),
.rs-partner.partner-style2.partner-modify5.partner-about1 .logo-grid-item:nth-child(8),
.rs-partner.partner-style2.partner-modify5.partner-about1 .logo-grid-item:nth-child(9) {
  border-bottom: none;
}
.rs-partner.partner-style2.partner-modify5.partner-about1 .partner-item .logo-img a .mains-logos {
  transition: .3s;
}
.rs-partner.partner-style3 .partner-item {
  padding: 40px 40px 40px 40px;
  margin: 15px 10px 15px 10px;
}
.rs-partner.partner-style3 .partner-item .logo-img {
  text-align: center;
}
.rs-partner.partner-style3 .partner-item .logo-img a {
  overflow: hidden;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.rs-partner.partner-style3 .partner-item .logo-img a .hovers-logos {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  transition: 0.3s;
}
.rs-partner.partner-style3 .partner-item .logo-img a .mains-logos {
  transition: 0.3s;
  filter: grayscale(100%);
}
.rs-partner.partner-style3 .partner-item .logo-img a:hover .hovers-logos {
  display: block;
  opacity: 3;
  visibility: visible;
  transform: translateY(0%);
  filter: grayscale(0%);
}
.rs-partner.partner-style3 .partner-item .logo-img a:hover .mains-logos {
  display: block;
  visibility: hidden;
  transform: translateY(100%);
}
.rs-partner.partner-style3.partner-modify6 {
  background-color: #14458D;
}
.rs-partner.partner-style3.partner-modify6 .partner-item .logo-img a .mains-logos {
  filter: unset;
}
.rs-partner.partner-style4 .partner-item {
  padding: 0 30px;
}
.rs-partner.partner-style4 .partner-item .logo-img {
  padding: 15px 15px 15px 15px;
}
.rs-partner.partner-style4 .partner-item .logo-img a img {
  filter: blur(0px);
}
/*------------------------------------
	23. Pricing Section CSS
------------------------------------*/
.pricing-container.pricing-style1 .pricing-switcher {
  text-align: center;
}
.pricing-container.pricing-style1 .pricing-switcher .switch {
  position: absolute;
  top: 13px;
  left: 10px;
  height: 45px;
  width: 90px;
  background-color: #0092f2;
  border-radius: 50px;
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  transition: transform 0.5s;
}
.pricing-container.pricing-style1 .pricing-switcher input[type="radio"]:checked + label + .switch,
.pricing-container.pricing-style1 .pricing-switcher input[type="radio"]:checked + label:nth-of-type(n) + .switch {
  -webkit-transform: translateX(95px);
  -moz-transform: translateX(95px);
  -ms-transform: translateX(95px);
  -o-transform: translateX(95px);
  transform: translateX(95px);
  color: #fff;
}
.pricing-container.pricing-style1 .pricing-switcher .fieldset {
  display: inline-block;
  position: relative;
  padding: 20px 8px;
  border-radius: 50px;
  box-shadow: 10px 10px 50px rgba(0, 0, 1, 0.05);
  background: #fff;
  width: 100%;
  max-width: 205px;
}
.pricing-container.pricing-style1 .pricing-switcher .fieldset.mnt-ac .rs-mnt,
.pricing-container.pricing-style1 .pricing-switcher .fieldset.mnt-acs .rs-yrs {
  color: #fff !important;
}
.pricing-container.pricing-style1 .pricing-switcher input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.pricing-container.pricing-style1 .pricing-switcher label {
  position: relative;
  z-index: 1;
  display: inline-block;
  float: left;
  cursor: pointer;
  font-size: 18px;
  color: #3E3E3E;
  font-weight: 500;
  margin: 0;
  width: 50%;
}
.pricing-container.pricing-style1 .no-js .pricing-wrapper .is-hidden {
  position: relative;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  margin-top: 1em;
}
.pricing-container.pricing-style1 .no-js .pricing-switcher {
  display: none;
}
.pricing-container.pricing-style1 .pricing-header {
  position: relative;
  padding: 30px 0 25px;
}
.pricing-container.pricing-style1 .pricing-header .title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #080D41;
  margin: 0 0 15px;
  text-align: center;
}
.pricing-container.pricing-style1 .pricing-header .price-inner {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.pricing-container.pricing-style1 .pricing-header .price-inner .price {
  font-size: 50px;
  line-height: 55px;
  font-weight: 700;
  color: #0092f2;
}
.pricing-container.pricing-style1 .pricing-header .price-inner span {
  position: relative;
  top: -7px;
  margin-left: 10px;
  color: #080D41;
  opacity: 1;
}
.pricing-container.pricing-style1 .pricing-header .price-inner:after {
  content: "";
  position: absolute;
  background-color: #28544B17;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
}
.pricing-container.pricing-style1 .pricing-body {
  padding: 0px 35px 15px 35px;
}
.pricing-container.pricing-style1 .pricing-body .features {
  margin-top: 30px;
}
.pricing-container.pricing-style1 .pricing-body .features ul li {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  color: #656567;
  padding-right: 36px;
  margin: 15px 0;
  transition: all 0.5s ease;
}
.pricing-container.pricing-style1 .pricing-body .features ul li:before {
  content: "\eb80";
  color: #0092f2;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  position: absolute;
  right: 0;
  transition: all 0.5s ease;
}
.pricing-container.pricing-style1 .pricing-body .features ul li.close-icon:before {
  content: "\eb97";
  opacity: 0.6;
  color: #353535;
}
.pricing-container.pricing-style1 .pricing-footer {
  margin: 0px 35px 0px 35px;
}
.pricing-container.pricing-style1 .pricing-footer a {
  display: inline-block;
  border: 2px solid #0092f2;
  font-size: 18px;
  line-height: 28px;
  color: #0092f2;
  font-weight: 700;
  width: 100%;
  border-radius: 50px 50px 50px 50px;
  padding: 17px 15px 15px 15px;
  text-align: center;
}
.pricing-container.pricing-style1 .pricing-wrapper > li:hover .pricing-footer a {
  background: #0092f2;
  color: #ffffff;
  border: 2px solid #0092f2;
}
.pricing-container.pricing-style1 .pricing-list {
  margin: 2em 0 0;
  list-style: none;
}
.pricing-container.pricing-style1 .pricing-list > li {
  position: relative;
}
.pricing-container.pricing-style1 .pricing-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.pricing-container.pricing-style1 .pricing-wrapper {
  position: relative;
  list-style: none;
  margin-left: 20px;
  margin-right: 20px;
}
.pricing-container.pricing-style1 .touch .pricing-wrapper {
  -webkit-perspective: 2000px;
  -moz-perspective: 2000px;
  perspective: 2000px;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched .is-visible {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-animation: rotate 0.5s;
  -moz-animation: rotate 0.5s;
  animation: rotate 0.5s;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched .is-hidden {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-animation: rotate-inverse 0.5s;
  -moz-animation: rotate-inverse 0.5s;
  animation: rotate-inverse 0.5s;
  opacity: 0;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched .is-selected {
  opacity: 1;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched.reverse-animation .is-visible {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-animation: rotate-back 0.5s;
  -moz-animation: rotate-back 0.5s;
  animation: rotate-back 0.5s;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched.reverse-animation .is-hidden {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-animation: rotate-inverse-back 0.5s;
  -moz-animation: rotate-inverse-back 0.5s;
  animation: rotate-inverse-back 0.5s;
  opacity: 0;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched.reverse-animation .is-selected {
  opacity: 1;
}
.pricing-container.pricing-style1 .pricing-wrapper > li {
  background-color: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.03);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 30px;
  padding: 20px 30px 50px;
}
.pricing-container.pricing-style1 .pricing-wrapper > li:hover {
  box-shadow: 20px 20px 40px rgba(0, 0, 1, 0.05);
}
.pricing-container.pricing-style1 .pricing-wrapper .is-visible {
  position: relative;
  z-index: 5;
}
.pricing-container.pricing-style1 .pricing-wrapper .is-hidden {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.pricing-container.pricing-style1 .pricing-wrapper .is-selected {
  z-index: 3 !important;
}
.pricing-container.pricing-style1 .is-switched .pricing-body {
  overflow: hidden;
}
.pricing-container.pricing-style1 .pricing-body {
  overflow-x: visible;
}
.pricing-container.pricing-style1 .pricing-features {
  width: 600px;
}
.pricing-container.pricing-style1 .pricing-features li {
  width: 100px;
  float: left;
  padding: 1.6em 1em;
  font-size: 1.5rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pricing-container.pricing-style1 .has-margins .exclusive .pricing-features li {
  margin: 0;
}
.pricing-container.pricing-style1 .has-margins .pricing-footer {
  padding-bottom: 0;
}
.pricing-container.pricing-style1 .select {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  color: transparent;
}
.pricing-container.pricing-style1 .select {
  position: static;
  display: inline-block;
  height: auto;
  padding: 1.3em 2em;
  color: #1bbc9d;
  border-radius: 8px;
  border: 2px solid #1bbc9d;
  font-size: 1.4rem;
  text-indent: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all .6s;
  width: 70%;
}
.pricing-container.pricing-style1 .no-touch .select:hover {
  background-color: #1bbc9d;
  color: #ffffff;
}
.pricing-container.pricing-style1 .exclusive .select {
  background-color: #1bbc9d;
  color: #ffffff;
}
.pricing-container.pricing-style1 .no-touch .exclusive .select:hover {
  background-color: #24e0ba;
}
.pricing-container.pricing-style1 .secondary-theme .exclusive .select {
  background-color: #1bbc9d;
}
.pricing-container.pricing-style1 .no-touch .secondary-theme .exclusive .select:hover {
  background-color: #112e3c;
}
.pricing-container.pricing-style1 .has-margins .select {
  display: block;
  padding: 1.7em 0;
  border-radius: 0 0 4px 4px;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-switcher .switch {
  background-color: #126ee8;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-body .features ul li:before,
.pricing-container.pricing-style1.pricing-modify1 .pricing-header .price-inner .price {
  color: #126ee8;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-body .features ul li.close-icon:before {
  color: #353535;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-footer a {
  border: 2px solid #126ee8;
  color: #126ee8;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-wrapper > li:hover .pricing-footer a {
  background: #126ee8;
  color: #ffffff;
  border: 2px solid #126ee8;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-wrapper .is-visible .pricebadge {
  text-align: center;
  font-size: 15px;
  line-height: 28px;
  color: #FFFFFF;
  padding: 2px 5px 2px 5px;
  margin: 0px 0px 0px 0px;
  border-radius: 0px 0px 0px 20px;
  background-color: #1A73E9;
  width: 151px;
  top: 0px;
  position: absolute;
  right: 0px;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-switcher {
  position: relative;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-switcher label {
  font-weight: 400;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-header .price-inner {
  justify-content: flex-start;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-header .title {
  font-size: 25px;
  line-height: 35px;
  font-weight: 600;
  margin: 0 0 15px;
  text-align: left;
  color: #183760;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-footer {
  margin: 0px 5px 15px 5px;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-body {
  padding: 0px 5px 15px 5px;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-wrapper {
  margin-left: 14px;
  margin-right: 14px;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-switcher .fieldset {
  position: absolute;
  top: -90px;
  right: 18%;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-switcher .switch {
  background-color: #1a73e9;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-body .features ul li:before,
.pricing-container.pricing-style1.pricing-modify2 .pricing-header .price-inner .price {
  color: #1a73e9;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-body .features ul li.close-icon:before {
  color: #353535;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-footer a {
  border: 1px solid #1a73e9;
  color: #1a73e9;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-wrapper > li:hover .pricing-footer a {
  background: #1a73e9;
  color: #ffffff;
  border: 1px solid #1a73e9;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-body .features ul li {
  font-size: 18px;
  line-height: 28px;
  color: #3a3a3a;
}
.pricing-container.pricing-style1.pricing-modify2.inner-pricing1 .pricing-switcher .fieldset {
  top: -124px;
}
.rs-pricing.pricing-style2 .pricing-table-wrap {
  position: relative;
  overflow: hidden;
  padding: 27px 40px 45px 40px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  border-radius: 10px;
  margin: 0 -2px 0 -2px;
  text-align: center;
}
.rs-pricing.pricing-style2 .pricing-table-wrap .pricing-table-header .pricing-table-title {
  color: #080D41;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 22px;
}
.rs-pricing.pricing-style2 .pricing-table-wrap .pricing-icon img {
  width: 60px;
}
.rs-pricing.pricing-style2 .pricing-table-wrap .pricing-table-price-tag .pricing-table-currency {
  font-size: 24px;
  top: -44px;
  position: relative;
  color: #080D41;
  font-weight: 500;
  left: 6px;
}
.rs-pricing.pricing-style2 .pricing-table-wrap .pricing-table-price-tag .pricing-table-price-text {
  font-size: 90px;
  font-weight: 600;
  color: #080d41;
}
.rs-pricing.pricing-style2 .pricing-table-wrap .pricing-table-price-tag .pricing-table-period {
  position: relative;
  padding-left: 14px;
  display: inline-block;
  font-size: 16px;
  color: #8c8c8c;
  font-weight: 500;
}
.rs-pricing.pricing-style2 .pricing-table-wrap .pricing-table-price-tag .pricing-table-period:before {
  content: "";
  height: 12px;
  width: 1.1px;
  position: absolute;
  left: 3px;
  transform: rotate(20deg) translateY(-50%);
  top: 46%;
  background-color: #8c8c8c;
}
.rs-pricing.pricing-style2 .pricing-table-wrap .pricing-table-features-list {
  padding: 0px 0px 0px 0px;
  text-align: left;
}
.rs-pricing.pricing-style2 .pricing-table-wrap .pricing-table-features-list li {
  padding: 0px 0px 0px 0px;
  font-size: 16px;
  margin-bottom: 15px;
}
.rs-pricing.pricing-style2 .pricing-table-wrap .pricing-table-features-list li i {
  margin-right: 10px;
  min-width: 15px;
  font-size: 16px;
  position: relative;
  top: 3px;
  color: #555555;
  float: right;
  margin-right: 0;
}
.rs-pricing.pricing-style2 .pricing-table-wrap .pricing-table-features-list li .feature-text {
  font-size: 16px;
  color: #555555;
}
.rs-pricing.pricing-style2 .pricing-table-wrap.price-center {
  box-shadow: 0 0.2rem 2.8rem rgba(36, 36, 36, 0.1);
}
.rs-pricing.pricing-style2 .pricing-table-wrap.price-center .pricing-table-features-list li i,
.rs-pricing.pricing-style2 .pricing-table-wrap.price-center .pricing-table-price-tag .pricing-table-currency,
.rs-pricing.pricing-style2 .pricing-table-wrap.price-center .pricing-table-price-tag .pricing-table-price-text {
  color: #126ee8;
}
.rs-pricing.pricing-style3 .pricing-table-wrap {
  position: relative;
  overflow: hidden;
  padding: 40px 40px 40px 40px;
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  margin: 0 -2px 0 -2px;
  text-align: center;
}
.rs-pricing.pricing-style3 .pricing-table-wrap .pricing-table-header .pricing-table-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #080d41;
  margin-bottom: 10px;
}
.rs-pricing.pricing-style3 .pricing-table-wrap .pricing-icon {
  margin-bottom: 25px;
}
.rs-pricing.pricing-style3 .pricing-table-wrap .pricing-icon img {
  width: 70px;
  height: 70px;
}
.rs-pricing.pricing-style3 .pricing-table-wrap .pricing-table-price-tag .pricing-table-currency {
  font-size: 24px;
  top: 0px;
  right: -5px;
  position: relative;
  color: #1a73e9;
  font-weight: 700;
}
.rs-pricing.pricing-style3 .pricing-table-wrap .pricing-table-price-tag .pricing-table-price-text {
  font-size: 60px;
  font-weight: 700;
  color: #1a73e9;
}
.rs-pricing.pricing-style3 .pricing-table-wrap .pricing-table-price-tag .pricing-table-period {
  position: relative;
  padding-left: 20px;
  display: inline-block;
  font-size: 16px;
  color: #8c8c8c;
  font-weight: 600;
}
.rs-pricing.pricing-style3 .pricing-table-wrap .pricing-table-price-tag .pricing-table-period:before {
  content: "";
  height: 15px;
  width: 2px;
  position: absolute;
  left: 3px;
  transform: rotate(20deg) translateY(-50%);
  top: 40%;
  background-color: #f2f2f2;
}
.rs-pricing.pricing-style3 .pricing-table-wrap .pricing-table-features-list {
  padding: 20px 0px 0px 0px;
  text-align: left;
  color: #656567;
}
.rs-pricing.pricing-style3 .pricing-table-wrap .pricing-table-features-list li {
  padding: 0px 0px 0px 0px;
  font-size: 16px;
  margin-bottom: 11px;
}
.rs-pricing.pricing-style3 .pricing-table-wrap .pricing-table-features-list li i {
  margin-right: 10px;
  min-width: 15px;
  font-size: 16px;
  position: relative;
  top: 3px;
  color: #1a73e9;
  float: right;
  margin-right: 0;
}
.rs-pricing.pricing-style3 .pricing-table-wrap .pricing-table-features-list li .feature-text {
  font-size: 16px;
  color: #656567;
}
/* ------------------------------------
	24. Project Section CSS
---------------------------------------*/
.rs-project.project-style1 {
  position: relative;
  overflow: hidden;
}
/* .rs-project.project-style1 .slider { */
  /* padding: 0 0 0 17%  !important; */
/* } */
.rs-project.project-style1 .slider .project-item {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 40px 40px 40px 40px;
  margin: 0 0 0 24px;
  z-index: 1;
}
.rs-project.project-style1 .slider .project-item .project-img img {
  width: 100%;
  border-radius: 40px 40px 40px 40px;
}
.rs-project.project-style1 .slider .project-item .project-content {
  position: absolute;
  background-color: #FFFFFF;
  padding: 20px 30px 20px 30px !important;
  border-radius: 0px 15px 15px 0px;
  transition: all ease 300ms;
  -moz-transition: all ease 300ms;
  -webkit-transition: all ease 300ms;
  opacity: 0;
  visibility: hidden;
  bottom: 40px;
  left: -40px;
  z-index: 11;
}
.rs-project.project-style1 .slider .project-item .project-content .category {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  display: block;
  margin: 0 0 10px;
}
.rs-project.project-style1 .slider .project-item .project-content .category a {
  color: #126ee8;
}
.rs-project.project-style1 .slider .project-item .project-content .category a:hover {
  color: #126ee8;
}
.rs-project.project-style1 .slider .project-item .project-content .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin: 0 0 0px;
}
.rs-project.project-style1 .slider .project-item .project-content .title a {
  color: #080d41;
}
.rs-project.project-style1 .slider .project-item .project-content .title a:hover {
  color: #126ee8;
}
.rs-project.project-style1 .slider .project-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #5463FF5E;
  opacity: 0;
  visibility: hidden;
  transition: .3s all ease;
}
.rs-project.project-style1 .slider .project-item:hover:before {
  opacity: 1;
  visibility: visible;
}
.rs-project.project-style1 .slider .project-item:hover .project-content {
  bottom: 40px;
  left: 0 !important;
  opacity: 1;
  visibility: visible;
}
.rs-project.project-style1 .slider .slick-list {
  padding: 0 0 !important;
}
.rs-project.project-style1 .slider .slick-arrow {
  position: absolute;
  bottom: 0;
  border: 1px solid #126ee8;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 0;
  z-index: 99;
  opacity: 1;
  display: block !important;
}
.rs-project.project-style1 .slider .slick-arrow.slick-next {
  right: 17%;
  top: -120px;
}
.rs-project.project-style1 .slider .slick-arrow.slick-next:before {
  font-size: 28px;
  color: #126ee8;
  margin: 0;
  font-family: remixicon;
  content: "\ea6e";
  text-align: center;
}
.rs-project.project-style1 .slider .slick-arrow.slick-prev {
  right: 20.2%;
  top: -120px;
}
.rs-project.project-style1 .slider .slick-arrow.slick-prev:before {
  content: "\ea64";
  font-size: 28px;
  color: #126ee8;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-project.project-style1 .slider .slick-arrow:hover {
  background: #126ee8;
}
.rs-project.project-style1 .slider .slick-arrow:hover.slick-next:before,
.rs-project.project-style1 .slider .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-project.project-style1 .pro-shape .pro-animate {
  position: absolute;
}
.rs-project.project-style1 .pro-shape .pro-animate img {
  width: 100%;
  height: 100%;
}
.rs-project.project-style1 .pro-shape .pro-animate.one {
  left: 0%;
  top: -5%;
}
.rs-project.project-style1 .pro-shape .pro-animate.two {
  left: -4%;
  top: -10%;
}
.rs-project.project-style1.project-modify2 .slider .project-item .project-content .title a:hover,
.rs-project.project-style1.project-modify2 .slider .project-item .project-content .category a {
  color: #0c46db;
}
.rs-project.project-style1.project-modify2 .slider .slick-arrow {
  border: 1px solid #0c46db;
}
.rs-project.project-style1.project-modify2 .slider .slick-arrow.slick-prev:before,
.rs-project.project-style1.project-modify2 .slider .slick-arrow.slick-next:before {
  color: #0c46db;
}
.rs-project.project-style1.project-modify2 .slider .slick-arrow:hover {
  background: #0c46db;
  border: 1px solid #0c46db;
}
.rs-project.project-style1.project-modify2 .slider .slick-arrow:hover.slick-next:before,
.rs-project.project-style1.project-modify2 .slider .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-project.project-style1.project-modify2 .slider .slick-arrow.slick-prev {
  right: 20.5%;
}
.rs-project.project-style1.project-modify3 {
  position: relative;
  overflow: visible;
  z-index: 99;
}
.rs-project.project-style1.project-modify3 .slider {
  
  margin: 0 0 -26% 0;
}
.rs-project.project-style1.project-modify3 .project-overlay {
  padding: 120px 0 200px;
}
.rs-project.project-style1.project-modify3 .slider .project-item:before {
  background: #1C73E94D;
}
.rs-project.project-style1.project-modify3 .slider .project-item .project-content .title a:hover,
.rs-project.project-style1.project-modify3 .slider .project-item .project-content .category a {
  color: #1a73e9;
}
.rs-project.project-style1.project-modify3 .slider .slick-arrow {
  border: 1px solid #1a73e9;
}
.rs-project.project-style1.project-modify3 .slider .slick-arrow.slick-prev:before,
.rs-project.project-style1.project-modify3 .slider .slick-arrow.slick-next:before {
  color: #C69944;
}
.rs-project.project-style1.project-modify3 .slider .slick-arrow:hover {
  background: #1a73e9;
  border: 1px solid #1a73e9;
}
.rs-project.project-style1.project-modify3 .slider .slick-arrow:hover.slick-next:before,
.rs-project.project-style1.project-modify3 .slider .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-project.project-style1.project-modify3 .slider .slick-arrow.slick-next,
.rs-project.project-style1.project-modify3 .slider .slick-arrow.slick-prev {
  top: -108px;
}
.rs-project.project-style2 .slider .project-item {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 40px 40px 40px 40px;
  margin: 0 0 0 24px;
  z-index: 1;
}
.rs-project.project-style2 .slider .project-item .project-img img {
  width: 100%;
  border-radius: 40px 40px 40px 40px;
}
.rs-project.project-style2 .slider .project-item .project-content {
  position: absolute;
  background-color: #FFFFFF;
  padding: 20px 30px 20px 30px !important;
  border-radius: 0px 15px 15px 0px;
  transition: all ease 300ms;
  -moz-transition: all ease 300ms;
  -webkit-transition: all ease 300ms;
  opacity: 0;
  visibility: hidden;
  bottom: 40px;
  left: -40px;
  z-index: 11;
}
.rs-project.project-style2 .slider .project-item .project-content .category {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  display: block;
  margin: 0 0 10px;
}
.rs-project.project-style2 .slider .project-item .project-content .category a {
  color: #5463ff;
}
.rs-project.project-style2 .slider .project-item .project-content .category a:hover {
  color: #634bf8;
}
.rs-project.project-style2 .slider .project-item .project-content .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin: 0 0 0px;
}
.rs-project.project-style2 .slider .project-item .project-content .title a {
  color: #080d41;
}
.rs-project.project-style2 .slider .project-item .project-content .title a:hover {
  color: #634bf8;
}
.rs-project.project-style2 .slider .project-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #5463FF5E;
  opacity: 0;
  visibility: hidden;
  transition: .3s all ease;
}
.rs-project.project-style2 .slider .project-item:hover:before {
  opacity: 1;
  visibility: visible;
}
.rs-project.project-style2 .slider .project-item:hover .project-content {
  bottom: 40px;
  left: 0 !important;
  opacity: 1;
  visibility: visible;
}
.rs-project.project-style2 .slider .slick-list {
  padding: 0 280px  !important;
}
.rs-project.project-style2 .slider .slick-arrow {
  position: absolute;
  bottom: 0;
  border: 1px solid #634bf8;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 0;
  z-index: 99;
  opacity: 1;
  display: block !important;
}
.rs-project.project-style2 .slider .slick-arrow.slick-next {
  right: 17%;
  top: -120px;
}
.rs-project.project-style2 .slider .slick-arrow.slick-next:before {
  font-size: 28px;
  color: #634bf8;
  margin: 0;
  font-family: remixicon;
  content: "\ea6e";
  text-align: center;
}
.rs-project.project-style2 .slider .slick-arrow.slick-prev {
  right: 20.5%;
  top: -120px;
}
.rs-project.project-style2 .slider .slick-arrow.slick-prev:before {
  content: "\ea64";
  font-size: 28px;
  color: #634bf8;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-project.project-style2 .slider .slick-arrow:hover {
  background: #634bf8;
}
.rs-project.project-style2 .slider .slick-arrow:hover.slick-next:before,
.rs-project.project-style2 .slider .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-project.project-style3 {
  position: relative;
  z-index: 11;
}
.rs-project.project-style3 .swiper {
  position: relative;
}
.rs-project.project-style3 .swiper .swiper-wrapper {
  padding-top: 120px;
}
.rs-project.project-style3 .swiper .swiper-wrapper .project-item .project-img {
  position: relative;
  overflow: hidden;
}
.rs-project.project-style3 .swiper .swiper-wrapper .project-item .project-img a img {
  transition: 0.5s;
  transform: scale(1);
}
.rs-project.project-style3 .swiper .swiper-wrapper .project-item .project-content {
  padding: 25px 0 50px 0;
}
.rs-project.project-style3 .swiper .swiper-wrapper .project-item .project-content .category {
  display: block;
  margin: 0 0 6px;
}
.rs-project.project-style3 .swiper .swiper-wrapper .project-item .project-content .category a {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #f05c4e;
}
.rs-project.project-style3 .swiper .swiper-wrapper .project-item .project-content .category a:hover {
  color: #f05c4e;
}
.rs-project.project-style3 .swiper .swiper-wrapper .project-item .project-content .title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin: 0 0 0;
}
.rs-project.project-style3 .swiper .swiper-wrapper .project-item .project-content .title a {
  color: #080d41;
}
.rs-project.project-style3 .swiper .swiper-wrapper .project-item .project-content .title a:hover {
  color: #f05c4e;
}
.rs-project.project-style3 .swiper .swiper-scrollbar {
  width: 100%;
  background-color: #fcacac40;
  height: 7px;
  left: 0;
  border-radius: 10px;
  bottom: 3px;
  z-index: 50;
}
.rs-project.project-style3 .swiper .swiper-scrollbar .swiper-scrollbar-drag {
  position: relative;
  background-color: #f05c4e;
  height: 100%;
  border-radius: 10px;
  left: 0;
  top: 0;
}
.rs-project.project-style3 .swiper .swiper-navigation .swiper-button-prev {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  left: auto;
  text-align: center;
  width: 50px;
  height: 50px;
  top: 23px;
  border: 2px solid #3e3e3e8c;
  color: #080d41;
}
.rs-project.project-style3 .swiper .swiper-navigation .swiper-button-next {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 70px;
  left: auto;
  text-align: center;
  width: 50px;
  height: 50px;
  top: 23px;
  border: 2px solid #3e3e3e8c;
  color: #080d41;
}
.rs-project.project-style3 .swiper .swiper-navigation .swiper-button-prev:after {
  color: rgba(62, 62, 62, 0.7);
  content: "\f110";
  font-size: 22px;
  font-family: flaticon;
}
.rs-project.project-style3 .swiper .swiper-navigation .swiper-button-next:after {
  color: rgba(62, 62, 62, 0.7);
  content: "\f111";
  font-size: 22px;
  font-family: flaticon;
}
.rs-project.project-style3 .swiper .swiper-navigation .swiper-button-prev:hover,
.rs-project.project-style3 .swiper .swiper-navigation .swiper-button-next:hover {
  color: #ffffff;
  background-color: #f05c4e;
  border: 2px solid #f05c4e;
}
.rs-project.project-style3 .swiper .swiper-navigation .swiper-button-prev:hover:after,
.rs-project.project-style3 .swiper .swiper-navigation .swiper-button-next:hover:after {
  color: #ffffff;
}
.rs-project.project-style4 .slider .project-item {
  position: relative;
  overflow: visible;
  margin: 0 0px 0 30px;
}
.rs-project.project-style4 .slider .project-item .project-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.rs-project.project-style4 .slider .project-item .project-img a img {
  border-radius: 25px;
  width: 100%;
  transition: 1.3s all ease;
}
.rs-project.project-style4 .slider .project-item .project-content {
  position: absolute;
  padding: 30px 25px 30px 25px;
  bottom: -60px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
  background: #fff;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
  transition: .6s;
  transition: all 0.5s ease-in-out;
  z-index: 10;
  left: 30px;
  right: 30px;
}
.rs-project.project-style4 .slider .project-item .project-content .category {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  display: block;
  margin: 0 0 10px;
}
.rs-project.project-style4 .slider .project-item .project-content .category a {
  color: #00a775;
}
.rs-project.project-style4 .slider .project-item .project-content .category a:hover {
  color: #00a775;
}
.rs-project.project-style4 .slider .project-item .project-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 0 0 0px;
}
.rs-project.project-style4 .slider .project-item .project-content .title a {
  color: #080d41;
}
.rs-project.project-style4 .slider .project-item .project-content .title a:hover {
  color: #00a775;
}
.rs-project.project-style4 .slider .project-item:hover .project-img a img {
  transform: scale(1.3);
}
.rs-project.project-style4 .slider .project-item:hover .project-content {
  bottom: -50px;
}
.rs-project.project-style4 .slider .slick-list {
  padding: 0 0 80px  !important;
}
.rs-project.project-style4 .slider .slick-arrow {
  display: none !important;
}
.rs-project.project-style4.project-modify1 .slider .project-item .project-content .title a:hover,
.rs-project.project-style4.project-modify1 .slider .project-item .project-content .category a {
  color: #0092f2;
}
.rs-project.project-style4.project-modify12 .slider .project-item .project-content .title a:hover,
.rs-project.project-style4.project-modify12 .slider .project-item .project-content .category a {
  color: #00a775;
}
.rs-project.project-style4.project-modify13 .slider .project-item .project-content .title a:hover,
.rs-project.project-style4.project-modify13 .slider .project-item .project-content .category a {
  color: #b65df0;
}
.rs-project.project-style4.project-modify14 .slider .project-item .project-content .title a:hover,
.rs-project.project-style4.project-modify14 .slider .project-item .project-content .category a {
  color: #83a300;
}
.rs-project.project-style5 .project-wrapper {
  position: relative;
  margin-top: 25px;
  margin-bottom: -300px;
  padding: 30px 30px 80px 25px;
  z-index: 1;
  background-color: #FFFFFF;
  border-radius: 15px 15px 15px 15px;
}
.rs-project.project-style5 .project-wrapper .slider {
  padding-bottom: 80px !important;
}
.rs-project.project-style5 .project-wrapper .slider .project-item {
  position: relative;
  overflow: visible;
  transition: all 0.3s ease;
  margin: 0 0 80px 24px;
}
.rs-project.project-style5 .project-wrapper .slider .project-item .project-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.rs-project.project-style5 .project-wrapper .slider .project-item .project-img a img {
  width: 100%;
  transition: 1.3s all ease;
}
.rs-project.project-style5 .project-wrapper .slider .project-item .project-content {
  text-align: left;
  padding: 25px 25px 25px 25px;
  border-radius: 5px 5px 5px 5px;
  background: #fff;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
  position: absolute;
  bottom: -60px;
  transition: all 0.5s ease-in-out;
  z-index: 10;
  left: 30px;
  right: 30px;
}
.rs-project.project-style5 .project-wrapper .slider .project-item .project-content .category {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  margin: 0 0 6px;
}
.rs-project.project-style5 .project-wrapper .slider .project-item .project-content .category a {
  color: #666666;
}
.rs-project.project-style5 .project-wrapper .slider .project-item .project-content .category a:hover {
  color: #ff6600;
}
.rs-project.project-style5 .project-wrapper .slider .project-item .project-content .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin: 0 0 0px;
}
.rs-project.project-style5 .project-wrapper .slider .project-item .project-content .title a {
  color: #080D41;
}
.rs-project.project-style5 .project-wrapper .slider .project-item .project-content .title a:hover {
  color: #ff6600;
}
.rs-project.project-style5 .project-wrapper .slider .project-item:hover .project-img a img {
  transform: scale(1.3);
}
.rs-project.project-style5 .project-wrapper .slider .project-item:hover .project-content {
  bottom: -50px;
}
.rs-project.project-style5 .project-wrapper .slider .slick-list {
  padding: 0 0 0!important;
}
.rs-project.project-style5 .project-wrapper .slider .slick-dots {
  bottom: 45px;
}
.rs-project.project-style5 .project-wrapper .slider .slick-dots li button {
  background: #ff6600;
}
.rs-project.project-style6 .project-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  z-index: 1;
}
.rs-project.project-style6 .project-item .project-content .p-icon {
  position: absolute;
  z-index: 11;
  margin: 0;
  top: 25px;
  right: 0px;
  width: 50px;
  height: 50px;
  line-height: 52px;
  text-align: center;
  border-radius: 50% 50% 50% 50% !important;
  -webkit-transition: all ease 800ms;
  opacity: 0;
  visibility: hidden;
  color: #091771;
  background-color: #ffffff;
}
.rs-project.project-style6 .project-item .project-content .p-icon i {
  font-size: 15px;
}
.rs-project.project-style6 .project-item .project-content .project-inner {
  position: absolute;
  z-index: 11;
  margin: 0;
  bottom: 40px;
  left: -40px;
  padding: 30px;
  transition: all ease 300ms;
  -webkit-transition: all ease 300ms;
  opacity: 0;
  visibility: hidden;
}
.rs-project.project-style6 .project-item .project-content .project-inner .title {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 10px;
}
.rs-project.project-style6 .project-item .project-content .project-inner .title a {
  color: #ffffff;
}
.rs-project.project-style6 .project-item .project-content .project-inner .title a:hover {
  color: #1a73e9;
}
.rs-project.project-style6 .project-item .project-content .project-inner .category a {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  color: #ffffff;
  display: block;
}
.rs-project.project-style6 .project-item .project-content .project-inner .category a:hover {
  color: #1a73e9;
}
.rs-project.project-style6 .project-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #183760;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease 0s;
}
.rs-project.project-style6 .project-item:hover .project-content .p-icon {
  opacity: 1;
  visibility: visible;
  right: 25px;
}
.rs-project.project-style6 .project-item:hover .project-content .project-inner {
  bottom: 40px;
  left: 0 !important;
  opacity: 1;
  visibility: visible;
}
.rs-project.project-style6 .project-item:hover:before {
  opacity: 1;
  visibility: visible;
}
.rs-project.project-style7 .project-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  transition: all 0.3s ease;
  z-index: 1;
}
.rs-project.project-style7 .project-item .project-content {
  position: absolute;
  padding: 20px 25px 20px 25px;
  opacity: 0;
  z-index: 1;
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
  width: 86%;
  z-index: 9;
  background: #fff;
  transition: .6s;
}
.rs-project.project-style7 .project-item .project-content .category {
  font-size: 15px;
  line-height: 26px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
}
.rs-project.project-style7 .project-item .project-content .category a {
  color: #1a73e9;
}
.rs-project.project-style7 .project-item .project-content .category a:hover {
  color: #1a73e9ad;
}
.rs-project.project-style7 .project-item .project-content .title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 0px;
}
.rs-project.project-style7 .project-item .project-content .title a {
  color: #080d41;
}
.rs-project.project-style7 .project-item .project-content .title a:hover {
  color: #1a73e9;
}
.rs-project.project-style7 .project-item:hover .project-content {
  opacity: 1;
  bottom: 30px;
}
.rs-project.project-style7.project-modify4 .project-item .project-content {
  padding: 30px 25px 30px 25px;
}
.rs-project.project-style7.project-modify4 .project-item .project-content .category a:hover,
.rs-project.project-style7.project-modify4 .project-item .project-content .title a:hover,
.rs-project.project-style7.project-modify4 .project-item .project-content .category a {
  color: #1A73E9;
}
.rs-project.project-style7.project-modify4 .project-item .project-content .category {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  text-transform: capitalize;
}
.rs-project.project-style7.project-modify4 .project-item .project-content .title {
  font-size: 25px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 0px;
}
.rs-project.project-style7.project-modify4 .project-item .project-content .title a {
  color: #121933;
}
.rs-project.project-style8 .project-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  z-index: 1;
}
.rs-project.project-style8 .project-item .project-img img {
  width: 100%;
  transition: 1.3s all ease;
}
.rs-project.project-style8 .project-item .project-content {
  position: absolute;
  padding: 17px 25px 15px;
  opacity: 0;
  visibility: hidden;
  left: 0;
  bottom: -100px;
  text-align: left;
  width: 100%;
  border-top: 0;
  z-index: 3;
  transition: 0.6s;
  height: 100%;
}
.rs-project.project-style8 .project-item .project-content .project-inner {
  position: absolute;
  bottom: -100px;
  transition: 0.5s;
  left: 30px;
}
.rs-project.project-style8 .project-item .project-content .project-inner .title {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 10px;
}
.rs-project.project-style8 .project-item .project-content .project-inner .title a {
  color: #ffffff;
}
.rs-project.project-style8 .project-item .project-content .project-inner .title a:hover {
  color: #FFFFFFCC;
}
.rs-project.project-style8 .project-item .project-content .project-inner .category a {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  display: block;
}
.rs-project.project-style8 .project-item .project-content .project-inner .category a:hover {
  color: #FFFFFFCC;
}
.rs-project.project-style8 .project-item .project-content:before {
  background-color: #1A73E966;
  content: "";
  position: absolute;
  bottom: -150px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  transition: all 0.4s;
  transition: 0.6s;
}
.rs-project.project-style8 .project-item:hover .project-content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
.rs-project.project-style8 .project-item:hover .project-content .project-inner {
  bottom: 30px;
}
.rs-project.project-style8 .project-item:hover .project-content:before {
  opacity: 1;
  bottom: 0;
  visibility: visible;
}
.rs-project.project-style9 .slider {
  padding: 0 0 0 0 !important;
}
.rs-project.project-style9 .slider .project-item {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}
.rs-project.project-style9 .slider .project-item .project-img img {
  width: 100%;
}
.rs-project.project-style9 .slider .project-item .project-content {
  opacity: 0;
  visibility: hidden;
  transition: .5s;
  z-index: 9;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  bottom: -20px;
  transition: .4s;
}
.rs-project.project-style9 .slider .project-item .project-content .category {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  display: block;
  margin: 0 0 10px;
}
.rs-project.project-style9 .slider .project-item .project-content .category a {
  color: rgba(255, 255, 255, 0.8);
}
.rs-project.project-style9 .slider .project-item .project-content .category a:hover {
  color: #ccc;
}
.rs-project.project-style9 .slider .project-item .project-content .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  margin: 0 0 0px;
}
.rs-project.project-style9 .slider .project-item .project-content .title a {
  color: #ffffff;
}
.rs-project.project-style9 .slider .project-item .project-content .title a:hover {
  color: #ccc;
}
.rs-project.project-style9 .slider .project-item:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: .4s;
  background: rgba(25, 8, 45, 0.8);
  background-image: linear-gradient(93.9deg, #104cba 0%, #4200ff 86.57%);
  z-index: 1;
  mix-blend-mode: multiply;
  transform: scale(1.5);
}
.rs-project.project-style9 .slider .project-item:hover:before {
  opacity: 1;
  visibility: visible;
}
.rs-project.project-style9 .slider .project-item:hover .project-content {
  bottom: 44%;
  opacity: 1;
  visibility: visible;
}
.rs-project.project-style9 .slider .slick-list {
  padding: 0 0 !important;
}
.rs-project.project-style9 .slider .slick-arrow {
  position: absolute;
  bottom: 0;
  border: 1px solid #3a15fb;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 5px 5px 5px 5px;
  font-size: 0;
  z-index: 99;
  opacity: 1;
  display: block !important;
}
.rs-project.project-style9 .slider .slick-arrow.slick-next {
  right: 16%;
  top: -120px;
}
.rs-project.project-style9 .slider .slick-arrow.slick-next:before {
  font-size: 23px;
  color: #3a15fb;
  margin: 0;
  font-family: remixicon;
  content: "\ea6c";
  text-align: center;
}
.rs-project.project-style9 .slider .slick-arrow.slick-prev {
  right: 19.6%;
  top: -120px;
}
.rs-project.project-style9 .slider .slick-arrow.slick-prev:before {
  content: "\ea60";
  font-size: 23px;
  color: #3a15fb;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-project.project-style9 .slider .slick-arrow:hover {
  background: #3a15fb;
}
.rs-project.project-style9 .slider .slick-arrow:hover.slick-next:before,
.rs-project.project-style9 .slider .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-project.project-style10 .slider {
  padding: 0 0 0 0 !important;
}
.rs-project.project-style10 .slider .project-item {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}
.rs-project.project-style10 .slider .project-item .project-img img {
  width: 100%;
}
.rs-project.project-style10 .slider .project-item .project-content {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: -82px;
  z-index: 1;
  color: #fff;
  transition: all .5s ease;
}
.rs-project.project-style10 .slider .project-item .project-content .content-txt {
  transition: all .5s ease;
}
.rs-project.project-style10 .slider .project-item .project-content .content-txt .category {
  display: block;
  margin-bottom: 15px;
}
.rs-project.project-style10 .slider .project-item .project-content .content-txt .category a {
  font-size: 14px;
  font-weight: 500;
  color: #1644c2;
  background-color: #fff;
  padding: 6px 12px;
  border-radius: 40px;
}
.rs-project.project-style10 .slider .project-item .project-content .content-txt .title {
  font-size: 30px;
  line-height: 50px;
  font-weight: 600;
  margin: 0 0 10px;
}
.rs-project.project-style10 .slider .project-item .project-content .content-txt .title a {
  color: #ffffff;
}
.rs-project.project-style10 .slider .project-item .project-content .content-txt .title a:hover {
  opacity: 0.78;
}
.rs-project.project-style10 .slider .project-item .project-content .description {
  position: relative;
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease;
  min-height: 110px;
}
.rs-project.project-style10 .slider .project-item .project-content .description p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin: 0 0 0px;
  color: #ffffff;
}
.rs-project.project-style10 .slider .project-item .project-content .description:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background-color: #fff;
  opacity: .3;
  top: -22px;
}
.rs-project.project-style10 .slider .project-item:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  transition: .4s;
  background-image: linear-gradient(231deg, #f8b401 42 0%, #634bf8 88%);
}
.rs-project.project-style10 .slider .project-item:hover:before {
  opacity: 1;
  visibility: visible;
}
.rs-project.project-style10 .slider .project-item:hover .project-content {
  bottom: -15px;
}
.rs-project.project-style10 .slider .project-item:hover .project-content .content-txt {
  padding-bottom: 30px;
}
.rs-project.project-style10 .slider .project-item:hover .project-content .description {
  visibility: visible;
  opacity: 1;
}
.rs-project.project-style10 .slider .slick-list {
  padding: 0 0 !important;
}
.rs-project.project-style10 .slider .slick-arrow {
  position: absolute;
  bottom: 0;
  border: 2px solid #634BF8DB;
  background-color: transparent;
  width: 45px;
  height: 45px;
  border-radius: 50px 50px 50px 50px;
  font-size: 0;
  z-index: 99;
  opacity: 1;
  display: block !important;
}
.rs-project.project-style10 .slider .slick-arrow.slick-next {
  right: 16%;
  top: -120px;
}
.rs-project.project-style10 .slider .slick-arrow.slick-next:before {
  font-size: 23px;
  color: #634BF8DB;
  margin: 0;
  font-family: remixicon;
  content: "\ea6c";
  text-align: center;
}
.rs-project.project-style10 .slider .slick-arrow.slick-prev {
  right: 13%;
  top: -120px;
}
.rs-project.project-style10 .slider .slick-arrow.slick-prev:before {
  content: "\ea60";
  font-size: 23px;
  color: #634BF8DB;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-project.project-style10 .slider .slick-arrow:hover {
  background: #634BF8DB;
  border: 2px solid #634BF8DB;
}
.rs-project.project-style10 .slider .slick-arrow:hover.slick-next:before,
.rs-project.project-style10 .slider .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-project.project-single .project-information .content-wrap .title-inner .title {
  font-size: 17px;
  line-height: 27px;
  font-weight: 600;
  color: #fff;
  margin: 0px 0px 5px 0px;
}
.rs-project.project-single .project-information .content-wrap .title-inner .desc {
  color: #FFFFFFCC;
  margin: 0;
}
/*------------------------------------
	25. Blog Section CSS
------------------------------------*/
.rs-blog.blog-main-home .slider {
  position: relative;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap {
  transition: all 500ms ease;
  padding: 0px 15px 0px 15px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .image-wrap {
  position: relative;
  overflow: hidden;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .image-wrap a img {
  transition: all 0.5s ease 0s;
  border-radius: 15px 15px 0px 0px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .image-wrap .pre-cat-list {
  position: absolute;
  z-index: 111;
  top: 20px;
  left: 20px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .image-wrap .pre-cat-list a {
  display: inline-block;
  padding: 3px 12px 2px;
  border-radius: 1px;
  color: #ffffff;
  background: #634BF8C2;
  border-radius: 50px 50px 50px 50px;
  transition: 0.4s;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 5px;
  margin-right: 5px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content {
  text-align: left;
  padding: 35px 35px 40px;
  box-shadow: 0 5px 20px rgba(34, 34, 34, 0.03);
  background: #fff;
  border-radius: 0px 0px 15px 15px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta {
  display: flex;
  margin: 4px 0px 0px;
  padding: 0 0 15px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .categories {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #3e3e3e;
  margin-right: 45px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .categories i {
  color: #126ee8;
  margin-right: 6px;
  font-weight: 900;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .categories i:before {
  top: -1px;
  position: relative;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .categories:after {
  content: "";
  position: absolute;
  right: -20px;
  top: 12px;
  height: 7px;
  width: 1px;
  background: rgba(62, 62, 62, 0.3);
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .admin {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #3e3e3e;
  display: block;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .admin i {
  color: #126ee8;
  margin-right: 6px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .admin i:before {
  top: -1px;
  position: relative;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .admin a {
  font-size: 16px;
  font-weight: 500;
  color: #3e3e3e;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .admin a:hover {
  color: #126ee8;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin: 0 0 27px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-title a {
  color: #080d41;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-title a:hover {
  color: #126ee8;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap:hover .image-wrap a img {
  filter: grayscale(100%);
}
.rs-blog.blog-main-home .slider .slick-list {
  padding: 0 !important;
}
.rs-blog.blog-main-home .slider .slick-arrow {
  position: absolute;
  top: 26%;
  transform: translate(0, -50%);
  display: block;
  bottom: 0;
  width: 48px;
  height: 48px;
  border-radius: 50px 50px 50px 50px;
  line-height: 48px;
  font-size: 0;
  border: 1px solid #126EE8A6;
  z-index: 9;
  background: transparent;
  transition: 0.4s;
}
.rs-blog.blog-main-home .slider .slick-arrow.slick-next {
  right: 20px;
  top: -80px;
}
.rs-blog.blog-main-home .slider .slick-arrow.slick-next:before {
  content: "\ea6c";
  font-size: 18px;
  color: #126ee8;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  text-align: center;
}
.rs-blog.blog-main-home .slider .slick-arrow.slick-prev {
  right: 90px;
  top: -80px;
}
.rs-blog.blog-main-home .slider .slick-arrow.slick-prev:before {
  content: "\ea60";
  font-size: 18px;
  color: #126ee8;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  text-align: center;
}
.rs-blog.blog-main-home .slider:hover .slick-arrow.slick-prev:before,
.rs-blog.blog-main-home .slider:hover .slick-arrow.slick-next:before {
  color: #126ee8;
}
.rs-blog.blog-main-home .slider:hover .slick-arrow:hover {
  background: #126ee8;
}
.rs-blog.blog-main-home .slider:hover .slick-arrow:hover.slick-next:before,
.rs-blog.blog-main-home .slider:hover .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-blog.blog-main-home .slider .slick-dots li button {
  background-color: #126EE8;
  width: 8px;
  height: 8px;
}
.rs-blog.blog-main-home .slider .slick-dots li:hover button,
.rs-blog.blog-main-home .slider .slick-dots li.slick-active button {
  background-color: #126EE8;
  width: 20px;
  opacity: 1;
}
.rs-blog.blog-main-home.blog-modify1 .slider .blog-item .blog-inner-wrap .blog-content {
  box-shadow: 0 5px 20px rgba(34, 34, 34, 0.03);
  overflow: visible;
  margin-bottom: 20px;
  padding: 30px 35px 35px;
  text-align: center;
}
.rs-blog.blog-main-home.blog-modify1 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 0 20px;
}
.rs-blog.blog-main-home.blog-modify1 .slider .blog-item .blog-inner-wrap .blog-content .blog-title {
  margin: 18px 0 20px;
}
.rs-blog.blog-main-home.blog-modify1 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta:before {
  position: absolute;
  content: "";
  width: 80%;
  height: 1px;
  background: #ebeff1;
  bottom: 0;
  left: 20px;
  right: 0;
}
.rs-blog.blog-main-home.blog-modify1 .slider .blog-item .blog-inner-wrap .image-wrap .pre-cat-list a {
  background-color: #634bf8;
}
.rs-blog.blog-main-home.blog-modify1 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .admin a:hover,
.rs-blog.blog-main-home.blog-modify1 .slider .blog-item .blog-inner-wrap .blog-content .blog-title a:hover,
.rs-blog.blog-main-home.blog-modify1 .blog-item .blog-inner-wrap .blog-content .blog-meta .admin i,
.rs-blog.blog-main-home.blog-modify1 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .categories i {
  color: #634bf8;
}
.rs-blog.blog-main-home.blog-modify1 .slider .slick-arrow {
  display: none !important;
}
.rs-blog.blog-main-home.blog-modify1 .slider .slick-dots li button {
  background: #634bf8ad;
}
.rs-blog.blog-main-home.blog-modify1 .slider .slick-dots li:hover button,
.rs-blog.blog-main-home.blog-modify1 .slider .slick-dots li.slick-active button {
  background: #634bf8;
}
.rs-blog.blog-main-home.blog-modify3 .slider .blog-item .blog-inner-wrap .blog-content {
  box-shadow: 0 5px 20px rgba(34, 34, 34, 0.03);
  overflow: visible;
  margin-bottom: 20px;
  padding: 30px 35px 35px;
}
.rs-blog.blog-main-home.blog-modify3 .blog-button.blog-btn1 a,
.rs-blog.blog-main-home.blog-modify3 .slider .blog-item .blog-inner-wrap .image-wrap .pre-cat-list a {
  background-color: #b65df0;
}
.rs-blog.blog-main-home.blog-modify3 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .admin a:hover,
.rs-blog.blog-main-home.blog-modify3 .slider .blog-item .blog-inner-wrap .blog-content .blog-title a:hover,
.rs-blog.blog-main-home.blog-modify3 .blog-item .blog-inner-wrap .blog-content .blog-meta .admin i,
.rs-blog.blog-main-home.blog-modify3 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .categories i {
  color: #b65df0;
}
.rs-blog.blog-main-home.blog-modify3 .slider .blog-item .blog-inner-wrap .blog-content .blog-title {
  margin: 0 0 25px;
}
.rs-blog.blog-main-home.blog-modify3 .slider .slick-arrow {
  display: none !important;
}
.rs-blog.blog-main-home.blog-modify3 .slider .slick-dots li button {
  background: #b65df0;
}
.rs-blog.blog-main-home.blog-modify3 .slider .slick-dots li:hover button,
.rs-blog.blog-main-home.blog-modify3 .slider .slick-dots li.slick-active button {
  background: #b772dc;
}
.rs-blog.blog-main-home.blog-modify3 .slider .slick-arrow.slick-next:before,
.rs-blog.blog-main-home.blog-modify3 .slider .slick-arrow.slick-prev:before {
  color: #b65df0;
}
.rs-blog.blog-main-home.blog-modify3 .slider .slick-arrow {
  border: 1px solid #00a775;
}
.rs-blog.blog-main-home.blog-modify3 .slider:hover .slick-arrow:hover {
  background: #b65df0;
}
.rs-blog.blog-main-home.blog-modify3 .slider .slick-arrow.slick-prev {
  right: 82px;
}
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap .blog-content {
  box-shadow: 0 5px 20px rgba(34, 34, 34, 0.03);
  overflow: visible;
  margin-bottom: 20px;
  padding: 30px 30px 35px;
}
.rs-blog.blog-main-home.blog-modify4 .blog-button.blog-btn1 a,
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap .image-wrap .pre-cat-list a {
  background-color: #00a775;
}
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .admin a:hover,
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap .blog-content .blog-title a:hover,
.rs-blog.blog-main-home.blog-modify4 .blog-item .blog-inner-wrap .blog-content .blog-meta .admin i,
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .categories i {
  color: #00a775;
}
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap .blog-content .blog-desc {
  font-size: 17px;
  color: #656567;
  margin: 0 0 25px;
}
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap .blog-content .blog-title {
  margin: 0 0 20px;
}
.rs-blog.blog-main-home.blog-modify4 .slider .slick-dots li button {
  background: #00a775;
}
.rs-blog.blog-main-home.blog-modify4 .slider .slick-dots li:hover button,
.rs-blog.blog-main-home.blog-modify4 .slider .slick-dots li.slick-active button {
  background: #00a775;
}
.rs-blog.blog-main-home.blog-modify4 .slider .slick-arrow.slick-next:before,
.rs-blog.blog-main-home.blog-modify4 .slider .slick-arrow.slick-prev:before {
  color: #00a775;
}
.rs-blog.blog-main-home.blog-modify4 .slider .slick-arrow {
  border: 1px solid #00a775;
}
.rs-blog.blog-main-home.blog-modify4 .slider:hover .slick-arrow:hover {
  background: #00a775;
}
.rs-blog.blog-main-home.blog-modify4 .slider .slick-arrow.slick-prev {
  right: 82px;
}
.rs-blog.blog-main-home.blog-modify5 .slider .blog-item .blog-inner-wrap .blog-content {
  box-shadow: 0 5px 20px rgba(34, 34, 34, 0.03);
  overflow: visible;
  margin-bottom: 20px;
  padding: 30px 30px 35px;
}
.rs-blog.blog-main-home.blog-modify5 .blog-button.blog-btn1 a,
.rs-blog.blog-main-home.blog-modify5 .slider .blog-item .blog-inner-wrap .image-wrap .pre-cat-list a {
  background-color: #83a300;
}
.rs-blog.blog-main-home.blog-modify5 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .admin a:hover,
.rs-blog.blog-main-home.blog-modify5 .slider .blog-item .blog-inner-wrap .blog-content .blog-title a:hover,
.rs-blog.blog-main-home.blog-modify5 .blog-item .blog-inner-wrap .blog-content .blog-meta .admin i,
.rs-blog.blog-main-home.blog-modify5 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .categories i {
  color: #83a300;
}
.rs-blog.blog-main-home.blog-modify5 .slider .blog-item .blog-inner-wrap .blog-content .blog-desc {
  font-size: 17px;
  color: #656567;
  margin: 0 0 25px;
}
.rs-blog.blog-main-home.blog-modify5 .slider .blog-item .blog-inner-wrap .blog-content .blog-title {
  margin: 0 0 20px;
}
.rs-blog.blog-main-home.blog-modify5 .slider .slick-dots li button {
  background: #83a300;
}
.rs-blog.blog-main-home.blog-modify5 .slider .slick-dots li:hover button,
.rs-blog.blog-main-home.blog-modify5 .slider .slick-dots li.slick-active button {
  background: #83a300;
}
.rs-blog.blog-main-home.blog-modify5 .slider .slick-arrow {
  display: none !important;
}
.rs-blog.blog-main-home.blog-modify5.onepage-modify1 .slider .blog-item .blog-inner-wrap .blog-content .blog-title a:hover,
.rs-blog.blog-main-home.blog-modify5.onepage-modify1 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .admin a:hover,
.rs-blog.blog-main-home.blog-modify5.onepage-modify1 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .categories i,
.rs-blog.blog-main-home.blog-modify5.onepage-modify1 .slider .blog-item .blog-inner-wrap .blog-content .blog-meta .admin i {
  color: #0092f2;
}
.rs-blog.blog-main-home.blog-modify5.onepage-modify1 .slider .blog-item .blog-inner-wrap .image-wrap .pre-cat-list a {
  background-color: #0092f2;
}
.rs-blog.blog-main-home.blog-modify5.onepage-modify1 .slider .slick-dots li button,
.rs-blog.blog-main-home.blog-modify5.onepage-modify1 .slider .slick-dots li.slick-active button {
  background: #0092f2;
}
.rs-blog.blog-main-home.blog-modify6 .slider .blog-item .blog-inner-wrap .blog-content {
  box-shadow: 0 5px 20px rgba(34, 34, 34, 0.03);
  padding: 35px 30px 40px;
}
.rs-blog.blog-main-home.blog-modify6 .slider .slick-arrow {
  display: none !important;
}
.rs-blog.blog-style1 .blog-item {
  background-color: #fff;
  padding: 20px;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-meta {
  display: flex;
  padding: 20px 30px 15px 10px;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-meta .categories {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #3e3e3e;
  margin-right: 45px;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-meta .categories i {
  color: #3e3e3e;
  margin-right: 6px;
  font-weight: 900;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-meta .categories i:before {
  top: -1px;
  position: relative;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-meta .categories:after {
  content: "";
  position: absolute;
  right: -20px;
  top: 12px;
  height: 7px;
  width: 1px;
  background: rgba(62, 62, 62, 0.3);
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-meta .admin {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #3e3e3e;
  display: block;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-meta .admin i {
  color: #3e3e3e;
  margin-right: 6px;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-meta .admin i:before {
  top: -1px;
  position: relative;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-meta .admin a {
  font-size: 14px;
  font-weight: 400;
  color: #3e3e3e;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-meta .admin a:hover {
  color: #f05c4e;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  margin: 0 0 10px;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-title a {
  color: #080d41;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-title a:hover {
  color: #f05c4e;
}
.rs-blog.blog-style1 .blog-item .blog-content .blog-button a:hover i:before,
.rs-blog.blog-style1 .blog-item .blog-content .blog-button a:hover .btn-txt {
  color: #f05c4e;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 20px;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .image-wrap {
  position: relative;
  max-width: 37.5%;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content {
  padding: 0px 0px 0px 30px;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta {
  display: flex;
  padding: 0px 0 15px;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .categories {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #3e3e3e;
  margin-right: 45px;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .categories i {
  color: #3e3e3e;
  margin-right: 6px;
  font-weight: 900;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .categories i:before {
  top: -1px;
  position: relative;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .categories:after {
  content: "";
  position: absolute;
  right: -20px;
  top: 12px;
  height: 7px;
  width: 1px;
  background: rgba(62, 62, 62, 0.3);
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .admin {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #3e3e3e;
  display: block;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .admin i {
  color: #3e3e3e;
  margin-right: 6px;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .admin i:before {
  top: -1px;
  position: relative;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .admin a {
  font-size: 14px;
  font-weight: 400;
  color: #3e3e3e;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .admin a:hover {
  color: #f05c4e;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin: 0 0 13px;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-title a {
  color: #080d41;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-title a:hover {
  color: #f05c4e;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-desc {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin: 0 0 10px;
}
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-button a:hover i:before,
.rs-blog.blog-style1 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-button a:hover .btn-txt {
  color: #f05c4e;
}
.rs-blog.blog-style1.blog-modify2 .blog-item {
  margin: 20px 0px 0px 0px;
  transition: all 0.3s ease 0s;
  box-shadow: 30px 30px 60px 0px rgba(0, 0, 1, 0.08);
  background-color: #fff;
  padding: 25px 20px 30px 20px;
  border-radius: 20px 20px 20px 20px;
}
.rs-blog.blog-style1.blog-modify2 .blog-horizontal .blog-meta .blog-item-wrap .image-wrap a img,
.rs-blog.blog-style1.blog-modify2 .blog-item .image-wrap a img {
  border-radius: 20px 20px 20px 20px;
}
.rs-blog.blog-style1.blog-modify2 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-desc {
  font-size: 18px;
  line-height: 28px;
  color: #656567;
}
.rs-blog.blog-style1.blog-modify2 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .admin,
.rs-blog.blog-style1.blog-modify2 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .categories,
.rs-blog.blog-style1.blog-modify2 .blog-item .blog-content .blog-meta .admin a,
.rs-blog.blog-style1.blog-modify2 .blog-item .blog-content .blog-meta .categories {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #3a3a3a;
}
.rs-blog.blog-style1.blog-modify2 .blog-horizontal .blog-meta .blog-item-wrap {
  margin: 20px 0px 20px 0px;
  box-shadow: 30px 30px 60px 0px rgba(0, 0, 1, 0.08);
  border-radius: 20px 20px 20px 20px;
}
.rs-blog.blog-style1.blog-modify2 .blog-item .blog-content .blog-meta .categories i:before,
.rs-blog.blog-style1.blog-modify2 .blog-item .blog-content .blog-meta .admin i,
.rs-blog.blog-style1.blog-modify2 .blog-item .blog-content .blog-title a:hover,
.rs-blog.blog-style1.blog-modify2 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-title a:hover,
.rs-blog.blog-style1.blog-modify2 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .admin i,
.rs-blog.blog-style1.blog-modify2 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .categories i {
  color: #1a73e9;
}
.rs-blog.blog-style1.blog-modify2 .blog-horizontal .blog-meta .blog-item-wrap .blog-content .blog-meta .admin a:hover,
.rs-blog.blog-style1.blog-modify2 .blog-item .blog-content .blog-meta .admin a:hover {
  color: #1a73e9;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap {
  transition: all 500ms ease;
  padding: 0px 15px 0px 15px;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .blog-content {
  background-color: #F6F9FE;
  padding: 30px 25px 30px 25px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .blog-content .blog-meta {
  display: flex;
  margin: 4px 0px 0px;
  padding: 0 0 15px;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .blog-content .blog-meta li {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #656567;
  margin-right: 35px;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .blog-content .blog-meta li:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 8px;
  background-color: #656567;
  transform: translate(16px, 9px);
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .blog-content .blog-meta li a {
  font-size: 16px;
  font-weight: 400;
  color: #656567;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .blog-content .blog-meta li a:hover {
  color: #1a73e9;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .blog-content .blog-meta li:last-child:after {
  display: none;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .blog-content .blog-title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin: 0 0 0px;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .blog-content .blog-title a {
  color: #080d41;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .blog-content .blog-title a:hover {
  color: #1a73e9;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .image-wrap {
  position: relative;
  overflow: hidden;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .image-wrap a img {
  transition: all 0.5s ease 0s;
  border-radius: 0px 0px 10px 10px;
  filter: grayscale(0) !important;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .image-wrap .pre-cat-list {
  position: absolute;
  z-index: 111;
  left: 0px;
  top: 0px;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .image-wrap .pre-cat-list i:before {
  position: relative;
  top: -1px;
  right: 5px;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap .image-wrap .pre-cat-list a {
  display: inline-block;
  background: #1A73E9;
  color: #FFFFFFE0;
  text-transform: capitalize;
  padding: 8px 20px 8px 20px;
  margin: 0px 8px 0px 0px;
  border-radius: 0px 0px 6px 0px;
  transition: 0.4s;
  font-size: 13px;
  font-weight: 500;
}
.rs-blog.blog-style2 .blog-item .blog-inner-wrap:hover .image-wrap a img {
  filter: grayscale(100%);
  transform: scale(1.1);
}
.rs-blog.blog-style3 {
  position: relative;
  margin-top: -41px;
  z-index: 11;
}
.rs-blog.blog-style3 .slider {
  position: relative;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap {
  transition: all 500ms ease;
  padding: 20px 15px 40px 15px;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .image-wrap {
  position: relative;
  overflow: hidden;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .image-wrap a img {
  transition: all 0.5s ease 0s;
  border-radius: 30px 30px 30px 30px;
  filter: grayscale(0) !important;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content {
  position: relative;
  box-shadow: -3px 0px 18px rgba(23, 0, 87, 0.05);
  background-color: #FFFFFF;
  padding: 0px 0px 30px 0px;
  margin: -80px 0px 0px 70px;
  border-radius: 0px 0px 10px 10px;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .pre-blog-meta {
  padding: 10px 30px 10px 20px;
  background-color: transparent;
  background-image: linear-gradient(93.97deg, #2056c0 0%, #4200ff 86.57%);
  display: inline-block;
  border-radius: 0px 30px 30px 0px;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .pre-blog-meta li {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  margin-right: 30px;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .pre-blog-meta li:after {
  content: "";
  position: absolute;
  right: -20px;
  top: 12px;
  height: 7px;
  width: 1px;
  background: #fff;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .pre-blog-meta li i {
  color: #ffffff;
  margin-right: 6px;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .pre-blog-meta li i:before {
  top: -1px;
  position: relative;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .pre-blog-meta li a {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #fff;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .pre-blog-meta li a:hover {
  color: #ccc;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .pre-blog-meta li:last-child {
  margin-right: 0;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .pre-blog-meta li:last-child:after {
  display: none;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .blog-title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  padding: 0 23px 0 25px;
  margin: 16px 0 10px;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .blog-title a {
  color: #080d41;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap .blog-content .blog-title a:hover {
  color: #3a15fb;
}
.rs-blog.blog-style3 .slider .blog-item .blog-inner-wrap:hover .image-wrap a img {
  filter: grayscale(100%);
  transform: scale(1.1);
}
.rs-blog.blog-style3 .slider .slick-list {
  padding: 0 !important;
}
.rs-blog.blog-style3 .slider .slick-arrow {
  position: absolute;
  bottom: 0;
  border: 1px solid #3a15fb;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 5px 5px 5px 5px;
  font-size: 0;
  z-index: 99;
  opacity: 1;
  display: block !important;
}
.rs-blog.blog-style3 .slider .slick-arrow.slick-next {
  right: 3%;
  top: -100px;
}
.rs-blog.blog-style3 .slider .slick-arrow.slick-next:before {
  font-size: 23px;
  color: #3a15fb;
  margin: 0;
  font-family: remixicon;
  content: "\ea6c";
  text-align: center;
}
.rs-blog.blog-style3 .slider .slick-arrow.slick-prev {
  right: 8%;
  top: -100px;
}
.rs-blog.blog-style3 .slider .slick-arrow.slick-prev:before {
  content: "\ea60";
  font-size: 23px;
  color: #3a15fb;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-blog.blog-style3 .slider .slick-arrow:hover {
  background: #3a15fb;
}
.rs-blog.blog-style3 .slider .slick-arrow:hover.slick-next:before,
.rs-blog.blog-style3 .slider .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap {
  transition: all 500ms ease;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .image-wrap {
  position: relative;
  overflow: hidden;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .image-wrap a img {
  transition: all 0.5s ease 0s;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .image-wrap .pre-cat-list {
  position: absolute;
  z-index: 111;
  top: 20px;
  left: 20px;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .image-wrap .pre-cat-list a {
  display: inline-block;
  padding: 3px 12px 2px;
  border-radius: 1px;
  color: #ffffff;
  background: #634BF8C2;
  border-radius: 50px 50px 50px 50px;
  transition: 0.4s;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 5px;
  margin-right: 5px;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content {
  text-align: left;
  padding: 35px 35px 40px;
  box-shadow: 0 5px 20px rgba(34, 34, 34, 0.03);
  background: #fff;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-meta {
  display: flex;
  margin: 4px 0px 0px;
  padding: 0 0 15px;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-meta .categories {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #3e3e3e;
  margin-right: 45px;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-meta .categories i {
  color: #126ee8;
  margin-right: 6px;
  font-weight: 900;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-meta .categories i:before {
  top: -1px;
  position: relative;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-meta .categories:after {
  content: "";
  position: absolute;
  right: -20px;
  top: 12px;
  height: 7px;
  width: 1px;
  background: rgba(62, 62, 62, 0.3);
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-meta .admin {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #3e3e3e;
  display: block;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-meta .admin i {
  color: #126ee8;
  margin-right: 6px;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-meta .admin i:before {
  top: -1px;
  position: relative;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-meta .admin a {
  font-size: 16px;
  font-weight: 500;
  color: #3e3e3e;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-meta .admin a:hover {
  color: #126ee8;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin: 0 0 27px;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-title a {
  color: #080d41;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap .blog-content .blog-title a:hover {
  color: #126ee8;
}
.rs-blog.blog-style4 .blog-item .blog-inner-wrap:hover .image-wrap a img {
  filter: grayscale(100%);
  transform: scale(1.1);
}
.rs-inner-blog .blog-item {
  background: #fff;
  border-radius: 10px;
  transition: .3s;
  padding: 0;
  box-shadow: 0 20px 20px rgba(0, 32, 74, 0.01);
  border: 1px solid rgba(238, 238, 238, 0.4509803922);
}
.rs-inner-blog .blog-item .blog-img {
  position: relative;
  border-radius: 0;
  z-index: 0;
}
.rs-inner-blog .blog-item .blog-img a {
  overflow: hidden;
  position: relative;
}
.rs-inner-blog .blog-item .blog-img a img {
  border-radius: 10px 10px 0 0;
}
.rs-inner-blog .blog-item .blog-img .blog-meta {
  position: absolute;
  bottom: -17px;
  top: auto;
  left: 40px;
  right: 30px;
  z-index: 99;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate {
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 3px 19px 5px 16px;
  position: relative;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  background: #1a73e9;
  border-radius: 5px;
  flex-wrap: wrap;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li {
  display: inline-block;
  margin-right: 10px;
  color: #fff;
  line-height: 24px;
  position: relative;
  margin-left: 26px;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li a {
  color: #fff;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li i:before {
  font-size: 16px;
  margin-right: 5px;
  top: 0px;
  color: #fff;
  position: relative;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li:before {
  content: "";
  position: absolute;
  left: -17px;
  top: 11px;
  height: 8px;
  width: 1px;
  background: #fff;
  transform: rotate(22deg);
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li:last-child {
  margin-right: 0;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li:first-child {
  margin-left: 0;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li:first-child:before {
  display: none;
}
.rs-inner-blog .blog-item .blog-content {
  padding: 40px 40px 46px;
  overflow: hidden;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate {
  overflow: hidden;
  list-style: none;
  padding: 0 0 12px;
  position: relative;
  display: flex;
  align-items: center;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li {
  margin-right: 20px;
  background-color: #f2f0ff;
  color: #126ee8;
  padding: 12px 20px;
  border-radius: 50px;
  transition: all 0.3s ease;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li i {
  color: #126ee8;
  position: relative;
  margin-right: 4px;
  top: 2px;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li a {
  color: #126ee8;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li:last-child {
  margin-right: 0;
}
.rs-inner-blog .blog-item .blog-content .blog-title {
  margin: 4px 0 15px;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}
.rs-inner-blog .blog-item .blog-content .blog-title a {
  color: #080d41;
}
.rs-inner-blog .blog-item .blog-content .blog-title a:hover {
  color: #126ee8;
}
.rs-inner-blog .blog-item .blog-content .blog-desc {
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
  color: #656567;
  margin-bottom: 25px;
}
.rs-inner-blog .widget-area .widget-title .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #00204a;
  margin-bottom: 0px;
  position: relative;
}
.rs-inner-blog .widget-area .widget-title .title:before {
  content: "";
  position: absolute;
  left: -7px;
  top: 2px;
  width: 24px;
  height: 24px;
  background: #1a73e9;
  border-radius: 50%;
  opacity: .2;
  display: block;
}
.rs-inner-blog .widget-area .search-widget {
  background: #f6f9fe;
  padding: 40px 30px;
  box-shadow: 0 20px 20px rgba(0, 0, 1, 0.01);
  border-radius: 10px;
}
.rs-inner-blog .widget-area .search-widget .search-wrap {
  position: relative;
}
.rs-inner-blog .widget-area .search-widget .search-wrap [type=search] {
  color: #444444;
  border: none;
  color: #444;
  padding: 16px 20px;
  width: 100%;
  border-radius: 5px;
  background: #fff;
}
.rs-inner-blog .widget-area .search-widget .search-wrap button {
  background: transparent;
  border: medium none;
  color: #3e3e3e;
  padding: 11px 15px 12px;
  position: absolute;
  display: block;
  right: 0px;
  top: 0;
  z-index: 10;
  font-size: 20px;
  border-radius: 0 5px 5px;
}
.rs-inner-blog .widget-area .search-widget .search-wrap button i:before {
  font-size: 18px;
  font-weight: 600;
  font-family: 'remixicon' !important;
  top: -2px;
  position: relative;
}
.rs-inner-blog .widget-area .search-widget .search-wrap button:hover {
  color: #126ee8;
}
.rs-inner-blog .widget-area .recent-posts {
  background: #f6f9fe;
  padding: 40px 30px;
  box-shadow: 0 20px 20px rgba(0, 0, 1, 0.01);
  border-radius: 10px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 0.5px solid rgba(53, 53, 53, 0.11);
  overflow: hidden;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-img {
  float: left;
  padding-right: 15px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-img img {
  border-radius: 5px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc {
  overflow: hidden;
  margin-top: 9px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc a {
  display: block;
  color: #00204a;
  transition: all .3s ease 0s;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 4px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc a:hover {
  color: #126ee8;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date-post {
  display: block;
  font-size: 14px;
  color: #656567;
  margin-bottom: 0px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date-post i {
  margin-right: 6px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date-post i:before {
  top: 0px;
  font-size: 16px;
  color: #126ee8;
  position: relative;
}
.rs-inner-blog .widget-area .categories {
  background: #f6f9fe;
  padding: 40px 30px;
  box-shadow: 0 20px 20px rgba(0, 0, 1, 0.01);
  border-radius: 10px;
}
.rs-inner-blog .widget-area .categories ul li {
  position: relative;
  background: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 12px;
  border-radius: 5px;
  font-size: 16px;
  color: #1a73e9;
}
.rs-inner-blog .widget-area .categories ul li a {
  font-weight: 500;
  font-size: 15px;
  color: #0a0a0a;
}
.rs-inner-blog .widget-area .categories ul li:last-child {
  margin-bottom: 0;
}
.rs-inner-blog .widget-area .categories ul li:hover a {
  opacity: 0.9;
}
.rs-inner-blog .widget-area .categories ul li:before {
  content: "\ea6c" !important;
  font-family: remixicon!important;
  position: absolute;
  right: 16px;
  top: 14px;
  font-size: 18px;
  color: #1a73e9;
  text-align: center;
  display: block;
  line-height: 1;
}
.rs-inner-blog .widget-area .tags-cloud {
  background: #f6f9fe;
  padding: 40px 30px;
  box-shadow: 0 20px 20px rgba(0, 0, 1, 0.01);
  border-radius: 10px;
}
.rs-inner-blog .widget-area .tags-cloud .tagcloud a {
  display: inline-block;
  margin: 0 4px 8px 0;
  line-height: 20px;
  padding: 8px 15px;
  font-size: 16px!important;
  text-transform: capitalize;
  transition: all .3s ease;
  background: #1a73e9;
  color: #fff;
  font-weight: 500;
  border: none;
  position: relative;
  border-radius: 5px;
}
.rs-inner-blog .widget-area .tags-cloud .tagcloud a:hover {
  color: #fff;
  opacity: .9;
}
.rs-inner-blog .widget-area .let-talk-part {
  /* background-image: url(assets/images/services/services-details/sidebar-bg.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px 5px 5px 5px;
  padding: 30px 40px 124px 40px;
}
.rs-inner-blog .widget-area .let-talk-part .content-wrap .title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  color: #FFFFFF;
  margin: 0px 0px 5px 0px;
}
.rs-inner-blog .widget-area .let-talk-part .content-wrap .desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #FFFFFF;
  margin: 0px 0px 2px 0px;
}
.rs-inner-blog .blog-details .bs-img {
  position: relative;
}
.rs-inner-blog .blog-details .bs-img .blog-meta {
  position: absolute;
  bottom: -20px;
  left: 8%;
}
.rs-inner-blog .blog-details .bs-img .blog-meta .btm-cate {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.rs-inner-blog .blog-details .bs-img .blog-meta .btm-cate li {
  margin-right: 15px;
  background-color: #f2f0ff;
  color: #126ee8;
  padding: 12px 20px;
  border-radius: 50px;
  transition: all 0.3s ease;
}
.rs-inner-blog .blog-details .bs-img .blog-meta .btm-cate li i {
  color: #126ee8;
  position: relative;
  margin-right: 4px;
  top: 2px;
}
.rs-inner-blog .blog-details .bs-img .blog-meta .btm-cate li a {
  color: #126ee8;
}
.rs-inner-blog .blog-details .bs-img .blog-meta .btm-cate li:last-child {
  margin-right: 0;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full {
  padding: 0px 0px 20px 40px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: #080d41;
  margin: 0;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .title.title2 {
  font-size: 20px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .title.title3 {
  font-size: 24px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full p {
  margin: 0;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .bs-info {
  margin-top: 25px;
  clear: both;
  margin-bottom: 40px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .bs-info a {
  font-size: 13px;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
  font-weight: normal;
  color: #ffffff;
  border: 0.5px solid rgba(53, 53, 53, 0.11);
  color: #3a3a3a;
  padding: 2px 15px 4px;
  margin-left: 7px;
  border-radius: 20px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 4px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .bs-info a:hover {
  background: #126ee8;
  border: 0.5px solid #126ee8;
  color: #ffffff;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .contact-widget {
  background: #F7F7F7;
  border-radius: 20px;
  padding: 35px 30px 35px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .contact-widget .contact-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .contact-widget .contact-wrap .images-wrap {
  position: relative;
  max-width: 37.5%;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .contact-widget .contact-wrap .content-wrap {
  padding: 0px 0px 0px 30px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .contact-widget .contact-wrap .content-wrap span {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #000000;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .contact-widget .contact-wrap .content-wrap .description {
  margin-bottom: 15px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .contact-widget .contact-wrap .content-wrap .social-icons li {
  display: inline-block;
  margin-right: 6px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .contact-widget .contact-wrap .content-wrap .social-icons li a i {
  color: #353535;
  display: block;
  border-radius: 100%;
  border: 0.5px solid rgba(53, 53, 53, 0.11);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: all 0.4s;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .contact-widget .contact-wrap .content-wrap .social-icons li a i:hover {
  background-color: #126ee8;
  color: #ffffff;
  border: 0.5px solid #126ee8;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note {
  padding: 35px 30px 35px;
  background: #F7F7F7;
  border-radius: 20px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note .form-desc i {
  margin-right: 7px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note .from-control {
  width: 100%;
  padding: 12px 20px;
  background: transparent;
  border: 0.5px solid rgba(53, 53, 53, 0.11);
  border-radius: 5px;
  max-width: 100%;
  opacity: 1;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note textarea {
  height: 125px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #3a3a3a;
  opacity: 0.79;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note ::-moz-placeholder {
  /* Firefox 19+ */

  color: #3a3a3a;
  opacity: 0.79;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note :-ms-input-placeholder {
  /* IE 10+ */

  color: #3a3a3a;
  opacity: 0.79;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note :-moz-placeholder {
  /* Firefox 18- */

  color: #3a3a3a;
  opacity: 0.79;
}
/* ------------------------------------
	26. Tab Section CSS
---------------------------------------*/
.rs-tab-main.tab-style1 .tab-main-wrap {
  background-color: #FFFFFF00;
}
/* .rs-tab-main.tab-style1 .tab-main-wrap .tab-menu-wrapper .nav-tabs { */
  /* border-bottom: 1px solid transparent; */
/* } */
.rs-tab-main.tab-style1 .tab-main-wrap .tab-menu-wrapper .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.rs-tab-main.tab-style1 .tab-main-wrap .tab-menu-wrapper .nav .nav-link {
  position: relative;
  transition: all .3s ease 0s;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  padding: 10px 42px 10px 43px;
  margin: 0px 0px 0px 0px;
  border-style: solid;
  border-width: 0px 0px 2px 0px;
  border-radius: 5px 5px 0px 0px !important;
  color: #393A3B;
}
.rs-tab-main.tab-style1 .tab-main-wrap .tab-menu-wrapper .nav .nav-link i {
  font-size: 20px;
  margin-right: 10px;
  font-weight: 900;
}
.rs-tab-main.tab-style1 .tab-main-wrap .tab-menu-wrapper .nav .nav-link.active {
      color: #FFFFFF;
    background: #C69944;
    border: 2px solid #C69944 !Important;
    border-radius: 78px !important;
}
.rs-tab-main.tab-style1 .tab-main-wrap .tab-menu-wrapper .nav .nav-link:hover:not(.active) {
      color: #c69944;
    border-bottom: 0;
}
.rs-tab-main.tab-style1 .tab-main-wrap .tab-content {
  background-color: transparent;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  border-style: none;
  padding: 50px 30px 40px 30px;
  border-radius: 0px 0px 10px 10px !important;
  overflow: hidden !important;
}
.rs-tab-main.tab-style1 .tab-main-wrap .tab-content .tab-pane .content-teb-area .content-left-area p {
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
  color: #656567;
  margin-bottom: 30px;
}
.rs-tab-main.tab-style1 .tab-main-wrap .tab-content .tab-pane .content-teb-area .content-left-area .check-arrow li {
  position: relative;
  list-style: none;
  padding: 3px 0 5px 48px;
  margin: 4px 0;
}
.rs-tab-main.tab-style1 .tab-main-wrap .tab-content .tab-pane .content-teb-area .content-left-area .check-arrow li:before {
  content: "\eb7b";
  font-family: remixicon!important;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  font-size: 18px;
  color: #1a73e9;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(26, 115, 233, 0.1411764706);
  line-height: 30px;
  text-align: center;
  display: block;
  border-radius: 50%;
}
.rs-tab-main.tab-style1 .tab-main-wrap .tab-content .tab-content > .tab-pane {
  display: none;
}
.rs-tab-main.tab-style1 .tab-main-wrap .tab-content .fade:not(.show) {
  opacity: 0;
}
.rs-tab-main.tab-style1 .tab-main-wrap .tab-content .tab-pane.active {
  -webkit-animation: slide-down 5s ease-out;
  animation: slide-down 5s ease-out;
}
@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
}
.rs-tab-main.tab-style2 {
  position: relative;
}
.rs-tab-main.tab-style2 .tab-main-wrap {
  display: flex;
  align-items: flex-end;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-menu-wrapper {
  max-width: 36%;
  width: 100%;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-menu-wrapper .nav-tabs {
  border-bottom: 1px solid transparent;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-menu-wrapper .nav .nav-link {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  max-width: 100%;
  width: 100%;
  padding: 13.5px 10px 13.5px 10px;
  margin: 12px 195px 0px 0px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #FA0B0B00;
  border-radius: 10px 10px 10px 10px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #656567;
  background-color: #FFFFFF;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-menu-wrapper .nav .nav-link i {
  font-size: 25px;
  color: #1A73E9;
  padding: 0px 15px 0px 0px;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-menu-wrapper .nav .nav-link.active {
  border-color: #1A73E9CC;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-content {
  background-color: #FFFFFF00;
  box-shadow: 0px 0px 10px 0px rgba(2, 1, 1, 0);
  text-align: left;
  padding: 0px 0px 0px 0px;
  max-width: 70%;
  width: 100%;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-content .content-tab-area {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-content .content-tab-area .content-left-area {
  padding: 32px 30px 30px 30px;
  display: flex;
  align-items: center;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-content .content-tab-area .content-left-area .title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #00204A;
  margin: 0px 0px 0px 0px;
  max-width: 33%;
  width: 67%;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-content .content-tab-area .content-left-area .desc-btn-wrapper {
  max-width: 70%;
  width: 100%;
  padding: 0px 0px 0px 44px;
  margin: -3px 0px 0px 44px;
  border-style: solid;
  border-width: 0px 0px 0px 1px;
  border-color: #E5E9ED;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-content .content-tab-area .content-left-area .desc-btn-wrapper p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #656567;
  margin: 0 0 10px;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-content .tab-content > .tab-pane {
  display: none;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-content .fade:not(.show) {
  opacity: 0;
}
.rs-tab-main.tab-style2 .tab-main-wrap .tab-content .tab-pane.active {
  -webkit-animation: slide-down 5s ease-out;
  animation: slide-down 5s ease-out;
}
@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
}
.rs-tab-main.tab-style2 .tab-animate {
  position: absolute;
  bottom: 34%;
  left: 27%;
}
.rs-tab-main.tab-style2 .tab-animate img {
  opacity: 0.59;
}
.rs-tab-main.tab-style2.tab-modify1 .tab-main-wrap {
  display: block;
}
.rs-tab-main.tab-style2.tab-modify1 .tab-main-wrap .tab-menu-wrapper .nav-tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-between !important;
  border-style: none;
}
.rs-tab-main.tab-style3 .tab-menu-wrapper .nav-tabs {
  border-bottom: 1px solid transparent;
}
.rs-tab-main.tab-style3 .tab-menu-wrapper .nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly !important;
  border-style: none;
}
.rs-tab-main.tab-style3 .tab-menu-wrapper .nav .nav-link {
    position: relative;
    font-weight: 500;
    line-height: 30px;
    padding: 10px 2px;
    margin: 0px 5px 0px 0px;
    border-style: solid;
    border-width: 0px 0px 7px 0px;
    border-color: #D1D1D1;
    border-radius: 0px 0px 0px 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    background-color: #FFFFFF;
    transition: all .3s ease 0s;
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    /* line-height: 129.5%; */
    letter-spacing: 0.04em;
    color: #0D4058;
}
.rs-tab-main.tab-style3 .tab-menu-wrapper .nav .nav-link i {
  font-size: 35px;
  margin-bottom: 18px;
  color: #1A73E9;
}
.rs-tab-main.tab-style3 .tab-menu-wrapper .nav .nav-link:hover,
.rs-tab-main.tab-style3 .tab-menu-wrapper .nav .nav-link.active {
      padding: 10px 2px;
    border-color: #C69944;
    color: #d4aa59;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate;
    color: #d4aa59 !important;
}
.rs-tab-main.tab-style3 .tab-menu-wrapper .nav .nav-link:hover:after,
.rs-tab-main.tab-style3 .tab-menu-wrapper .nav .nav-link.active:after {
  opacity: 1;
  visibility: visible;
}
/* .rs-tab-main.tab-style3 .tab-menu-wrapper .nav .nav-link:after { */
  /* color: #1A73E9; */
  /* display: unset !important; */
  /* font-size: 42px; */
  /* top: calc(95%); */
  /* transform: translateX(-50%); */
  /* left: 50%; */
  /* position: absolute; */
  /* content: "\ea4d"; */
  /* font-family: remixicon!important; */
  /* font-style: normal; */
  /* z-index: 1; */
  /* opacity: 0; */
  /* visibility: hidden; */
/* } */
.rs-tab-main.tab-style3 .tab-content {
  margin-top: 80px;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden !important;
}
.rs-tab-main.tab-style3 .tab-content .content-tab-area {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 0px;
}
.rs-tab-main.tab-style3 .tab-content .content-tab-area .content-left-area {
  flex: 0 0 50%;
  display: block;
  padding: 50px 38px 60px 50px !important;
}
.rs-tab-main.tab-style3 .tab-content .content-tab-area .content-left-area .logo-img {
  margin-bottom: 27px;
}
.rs-tab-main.tab-style3 .tab-content .content-tab-area .content-left-area .logo-img img {
  width: 150px;
  height: auto;
}
.rs-tab-main.tab-style3 .tab-content .content-tab-area .content-left-area .content-wrap .title {
  color: #00204A;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin: 0px 0px 20px 0px;
}
.rs-tab-main.tab-style3 .tab-content .content-tab-area .content-left-area .content-wrap .desc {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #656567;
  margin: 0 0 0;
}
.rs-tab-main.tab-style3 .tab-content .content-tab-area .content-left-area .content-wrap .desc-btn-wrapper {
  margin-top: 40px;
}
.rs-tab-main.tab-style3 .tab-content .content-tab-area .content-left-area .content-wrap .desc-btn-wrapper li {
  display: inline-block;
  margin-right: 10px;
}
.rs-tab-main.tab-style3 .tab-content .content-tab-area .content-left-area .content-wrap .desc-btn-wrapper li:last-child {
  margin-right: 10px;
}
.rs-tab-main.tab-style3 .tab-content .content-tab-area .images {
  flex: 0 0 50%;
}
.rs-tab-main.tab-style3 .tab-content .tab-content > .tab-pane {
  display: none;
}
.rs-tab-main.tab-style3 .tab-content .fade:not(.show) {
  opacity: 0;
}
.rs-tab-main.tab-style3 .tab-content .tab-pane.active {
  -webkit-animation: slide-down 5s ease-out;
  animation: slide-down 5s ease-out;
}
.rs-tab-main.tab-style3 .tab-content .tab-pane {
    -webkit-animation: slide-down 5s ease-out;
    animation: slide-down 5s ease-out;
    padding: 60px;
    border: 2px solid #C69944;
    border-radius: 9px;
}
@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* ------------------------------------
	27. Testimonial Section CSS
---------------------------------------*/
.rs-testimonial.testimonial-style1 .slider {
  position: relative;
  z-index: 11;
}
.rs-testimonial.testimonial-style1 .slider .testi-item {
  display: flex;
  flex-wrap: wrap;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-images {
  flex: 0 0 23%;
  max-width: 23%;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-images .image-wrap img {
  position: relative;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #F05C4E;
  padding: 25px 20px 25px 22px;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-wrap {
  flex: 0 0 77%;
  max-width: 77%;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-wrap .testi-content {
  position: relative;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #F05C4E;
  margin: 0px 0px 0px 60px;
  padding: 45px 120px 42px 50px;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-wrap .testi-content .rattings img {
  max-width: 100px;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-wrap .testi-content .desc {
  position: relative;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #FFFFFFB3;
  margin: 15px 0 25px;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-wrap .testi-content .testi-information {
  position: relative;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-wrap .testi-content .testi-information .testi-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #FFFFFF;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-wrap .testi-content .testi-information .testi-title {
  margin-top: 6px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #FFFFFFB3;
  display: block;
}
.rs-testimonial.testimonial-style1 .slider .slick-list {
  padding: 0 0 0  !important;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow {
  position: absolute;
  bottom: 0;
  border: 1px solid #3e3e3e;
  opacity: 0.8;
  background-color: #F6F3FF;
  width: 50px;
  height: 50px;
  font-size: 0;
  z-index: 99;
  display: block !important;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow.slick-next {
  top: 73%;
  right: 4%;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow.slick-next:before {
  font-size: 24px;
  opacity: 0.8;
  color: #636265DB;
  margin: 0;
  font-family: remixicon;
  content: "\ea6c";
  text-align: center;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow.slick-prev {
  top: 73%;
  right: 9%;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow.slick-prev:before {
  content: "\ea60";
  font-size: 24px;
  opacity: 0.8;
  color: #636265DB;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow:hover {
  background: #f05c4e;
  border: 1px solid #f05c4e;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style1 .slider .slick-arrow:hover.slick-prev:before {
  opacity: 1;
  color: #ffffff;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-images .image-wrap img,
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-wrap .testi-content {
  border-width: unset;
  border-color: unset;
  border-style: unset;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item {
  padding: 0px 60px 55px 40px;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-wrap .testi-content {
  border-radius: 0;
  background: linear-gradient(201.3deg, #93ae22 10.74%, rgba(157, 182, 57, 0) 88.93%);
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-wrap .testi-content:before {
  background-image: linear-gradient(180deg, #f4f4f4 0%, #f4f4f4 100%);
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-images .image-wrap {
  background: linear-gradient(201.3deg, #93ae22 10.74%, rgba(157, 182, 57, 0) 88.93%);
  border-radius: 0;
  background-color: #F4F4F4;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-images .image-wrap img {
  border-radius: 0;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-images .image-wrap:before {
  position: absolute;
  content: "";
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  z-index: -11;
  background-color: transparent;
  background-image: linear-gradient(180deg, #f4f4f4 0%, #f4f4f4 100%);
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-wrap .testi-content:before {
  content: "";
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  position: absolute;
  border-radius: inherit;
  background-color: transparent;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-wrap .testi-content .desc {
  position: relative;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #3e3e3e;
  margin: 15px 0 20px;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-wrap .testi-content .rattings img {
  position: relative;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-wrap .testi-content .testi-information .testi-name {
  color: #080d41;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-wrap .testi-content .testi-information .testi-title {
  font-size: 14px;
  color: rgba(62, 62, 62, 0.68);
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-images .image-wrap {
  position: relative;
  z-index: 11;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-images .image-wrap .animate-shape {
  position: absolute;
  left: -31px;
  bottom: -27px;
  z-index: -11;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .slick-arrow.slick-next:before,
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .slick-arrow.slick-prev:before {
  font-size: 24px;
  color: #83a300;
  line-height: 34px;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .slick-arrow {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #83a300;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slick-arrow.slick-next {
  left: 35%;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .slick-arrow.slick-prev {
  right: 66%;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .slick-arrow.slick-next,
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .slick-arrow.slick-prev {
  top: 92%;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .slick-arrow:hover {
  color: #ffffff;
  background: #83a300;
  border: 1px solid #83a300;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .slick-arrow:hover:before {
  color: #ffffff;
}
.rs-testimonial.testimonial-style2 .slider {
  position: relative;
}
.rs-testimonial.testimonial-style2 .slider .testi-item {
  padding: 60px 30px 30px 30px;
  margin: 0px 15px 0px 15px;
  background: #FFFFFF;
  border-radius: 20px;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .item-content span {
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .item-content span .normal-img {
  top: -47px;
  position: absolute;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .item-content span .center-logo {
  top: -47px;
  position: absolute;
  opacity: 0;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .item-content .desc {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #3e3e3e;
  margin: 0 0 25px;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .testi-content {
  display: flex;
  align-items: center;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .testi-content .testi-information {
  padding: 0 0 0 20px;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .testi-content .testi-information .testi-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #080d41;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .testi-content .testi-information .testi-title {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #3e3e3e;
  display: block;
}
.rs-testimonial.testimonial-style2 .slider .slick-center {
  box-shadow: 20px 35px 70px rgba(99, 75, 248, 0.05);
}
.rs-testimonial.testimonial-style2 .slider .slick-center .item-content span .center-logo {
  opacity: 1;
}
.rs-testimonial.testimonial-style2 .slider .slick-list {
  padding: 0 0 0  !important;
}
.rs-testimonial.testimonial-style2 .slider .slick-arrow {
  position: absolute;
  bottom: 0;
  border: 1px solid #634bf8;
  opacity: 0.8;
  background-color: transparent;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 0;
  z-index: 99;
  transform: translate(0, -50%);
  display: block !important;
}
.rs-testimonial.testimonial-style2 .slider .slick-arrow.slick-next {
  top: -30%;
  right: 30px;
}
.rs-testimonial.testimonial-style2 .slider .slick-arrow.slick-next:before {
  font-size: 24px;
  opacity: 0.8;
  color: #634bf8;
  margin: 0;
  font-family: remixicon;
  content: "\ea6c";
  text-align: center;
}
.rs-testimonial.testimonial-style2 .slider .slick-arrow.slick-prev {
  top: -30%;
  right: 88px;
}
.rs-testimonial.testimonial-style2 .slider .slick-arrow.slick-prev:before {
  content: "\ea60";
  font-size: 24px;
  opacity: 0.8;
  color: #634bf8;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style2 .slider .slick-arrow:hover {
  background: #634bf8;
  border: 1px solid #634bf8;
}
.rs-testimonial.testimonial-style2 .slider .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style2 .slider .slick-arrow:hover.slick-prev:before {
  opacity: 1;
  color: #ffffff;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .testi-item {
  padding: 60px 30px 35px 30px;
  margin: 30px 12px 50px 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .slick-center {
  box-shadow: 0px 7px 60px 0px rgba(8, 0, 27, 0.1);
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .slick-arrow {
  border: 1px solid #00a775;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .slick-arrow.slick-next {
  top: 110%;
  left: 50%;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .slick-arrow.slick-prev {
  left: 45.5%;
  top: 110%;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .slick-arrow.slick-next:before,
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .slick-arrow.slick-prev:before {
  color: #00a775;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .slick-arrow:hover {
  background: #00a775;
  border: 1px solid #00a775;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .slick-arrow:hover.slick-prev:before {
  opacity: 1;
  color: #ffffff;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .slick-arrow {
  border: 1px solid #00a775;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .slick-arrow.slick-next {
  top: 120%;
  left: 50%;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .slick-arrow.slick-prev {
  left: 45.5%;
  top: 120%;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .slick-arrow.slick-next:before,
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .slick-arrow.slick-prev:before {
  color: #00a775;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .slick-arrow:hover {
  background: #00a775;
  border: 1px solid #00a775;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .slick-arrow:hover.slick-prev:before {
  opacity: 1;
  color: #ffffff;
}
.rs-testimonial.testimonial-style2.testimonial-modify5 .slider .testi-item {
  padding: 60px 30px 35px 30px;
  margin: 30px 12px 50px 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
}
.rs-testimonial.testimonial-style2.testimonial-modify5 .slider .slick-center {
  box-shadow: 0px 7px 60px 0px rgba(8, 0, 27, 0.1);
}
.rs-testimonial.testimonial-style2.testimonial-modify5 .slider .slick-arrow.slick-next {
  top: 105%;
  left: 50%;
}
.rs-testimonial.testimonial-style2.testimonial-modify5 .slider .slick-arrow.slick-prev {
  left: 45%;
  top: 105%;
}
.rs-testimonial.testimonial-style2.testimonial-modify5 .slider .slick-arrow {
  border: 1px solid #b65df0;
}
.rs-testimonial.testimonial-style2.testimonial-modify5 .slider .slick-arrow.slick-next:before,
.rs-testimonial.testimonial-style2.testimonial-modify5 .slider .slick-arrow.slick-prev:before {
  color: #b65df0;
}
.rs-testimonial.testimonial-style2.testimonial-modify5 .slider .slick-arrow:hover {
  background: #b65df0;
  border: 1px solid #b65df0;
}
.rs-testimonial.testimonial-style2.testimonial-modify5 .slider .slick-arrow:hover.slick-prev:before,
.rs-testimonial.testimonial-style2.testimonial-modify5 .slider .slick-arrow:hover.slick-next:before {
  color: #ffffff;
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .testi-item {
  padding: 60px 30px 35px 30px;
  margin: 30px 12px 50px 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .slick-center {
  box-shadow: 0px 7px 60px 0px rgba(8, 0, 27, 0.1);
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .slick-arrow.slick-next {
  top: 105%;
  left: 50%;
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .slick-arrow.slick-prev {
  left: 45.5%;
  top: 105%;
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .slick-arrow {
  border: 1px solid #ff6600;
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .slick-arrow.slick-next:before,
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .slick-arrow.slick-prev:before {
  color: #ff6600;
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .slick-arrow:hover {
  background: #ff6600;
  border: 1px solid #ff6600;
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .slick-arrow:hover.slick-prev:before,
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .slick-arrow:hover.slick-next:before {
  color: #ffffff;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slider .testi-item {
  box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.05);
  transform: skewY(2deg);
  padding: 60px 25px 30px 30px;
  margin: 20px 12px 20px 12px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slider .testi-item .item-content .desc {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #656567;
  margin: 0 0 25px;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slider .testi-item .item-content span .normal-img {
  top: -50px;
  position: absolute;
  max-width: 45px;
}
.rs-testimonial.testimonial-style3 .slider {
  position: relative;
}
.rs-testimonial.testimonial-style3 .slider .testi-item .item-content {
  text-align: center;
  padding: 40px 20px 75px 20px;
  border-radius: 20px;
  margin: 15px 12px 0px 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.03);
}
.rs-testimonial.testimonial-style3 .slider .testi-item .item-content span {
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.rs-testimonial.testimonial-style3 .slider .testi-item .item-content span img {
  margin: 0 auto;
}
.rs-testimonial.testimonial-style3 .slider .testi-item .item-content .desc {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #3e3e3e;
  margin: 0 0 0px;
}
.rs-testimonial.testimonial-style3 .slider .testi-item .testi-content {
  text-align: center;
  margin-top: -30px;
}
.rs-testimonial.testimonial-style3 .slider .testi-item .testi-content .image-wrap img {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  border-style: solid;
  border-width: 5px 5px 5px;
  border-color: #f4f5f7;
  margin: auto;
  position: relative;
}
.rs-testimonial.testimonial-style3 .slider .testi-item .testi-content .testi-information {
  padding: 15px 0 0 0;
}
.rs-testimonial.testimonial-style3 .slider .testi-item .testi-content .testi-information .testi-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #080d41;
}
.rs-testimonial.testimonial-style3 .slider .testi-item .testi-content .testi-information .testi-title {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #3e3e3e;
  display: block;
}
.rs-testimonial.testimonial-style3 .slider .slick-center .item-content {
  opacity: 0.8;
  border-radius: 20px;
  background: linear-gradient(180deg, #0092f2 57 0%, rgba(0, 146, 242, 0) 100%);
  position: relative;
  padding: 40px 20px 75px 20px;
}
.rs-testimonial.testimonial-style3 .slider .slick-center .item-content:before {
  position: absolute;
  content: "";
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: inherit;
  background-color: transparent;
  background: #FFFFFF;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.03);
}
.rs-testimonial.testimonial-style3 .slider .slick-center .item-content .desc {
  position: relative;
}
.rs-testimonial.testimonial-style3 .slider .slick-list {
  padding: 0 0 0  !important;
}
.rs-testimonial.testimonial-style3 .slider .slick-arrow {
  position: absolute;
  top: 43%;
  transform: translate(0, -50%);
  border: 1px solid #0092f2;
  opacity: 0.8;
  background-color: transparent;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 0;
  z-index: 99;
  display: block !important;
}
.rs-testimonial.testimonial-style3 .slider .slick-arrow.slick-next {
  right: -6%;
}
.rs-testimonial.testimonial-style3 .slider .slick-arrow.slick-next:before {
  font-size: 24px;
  opacity: 0.8;
  color: #0092f2;
  margin: 0;
  font-family: remixicon;
  content: "\ea6c";
  text-align: center;
}
.rs-testimonial.testimonial-style3 .slider .slick-arrow.slick-prev {
  left: -6%;
}
.rs-testimonial.testimonial-style3 .slider .slick-arrow.slick-prev:before {
  content: "\ea60";
  font-size: 24px;
  opacity: 0.8;
  color: #0092f2;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style3 .slider .slick-arrow:hover {
  background: #0092f2;
  border: 1px solid #0092f2;
}
.rs-testimonial.testimonial-style3 .slider .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style3 .slider .slick-arrow:hover.slick-prev:before {
  opacity: 1;
  color: #ffffff;
}
.rs-testimonial.testimonial-style3.testimonial-modify3 .slider .slick-center .item-content {
  background: linear-gradient(180deg, #dc5959 0%, rgba(220, 89, 89, 0) 100%);
}
.rs-testimonial.testimonial-style3.testimonial-modify3 .slider .slick-arrow.slick-next:before,
.rs-testimonial.testimonial-style3.testimonial-modify3 .slider .slick-arrow.slick-prev:before {
  color: #0c46db;
}
.rs-testimonial.testimonial-style3.testimonial-modify3 .slider .slick-arrow {
  border: 1px solid #0c46db;
}
.rs-testimonial.testimonial-style3.testimonial-modify3 .slider .slick-arrow:hover {
  background: #0c46db;
  border: 1px solid #0c46db;
}
.rs-testimonial.testimonial-style3.testimonial-modify3 .slider .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style3.testimonial-modify3 .slider .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-testimonial.testimonial-style3.testimonial-modify3 .slick-dots li.slick-active button,
.rs-testimonial.testimonial-style3.testimonial-modify3 .slick-dots li:hover button {
  width: 24px;
  background: #0c46db;
  opacity: 40%;
}
.rs-testimonial.testimonial-style3.testimonial-modify3 .slick-dots li button {
  background: #0c46db;
}
.rs-testimonial.testimonial-style4 {
  position: relative;
}
.rs-testimonial.testimonial-style4 .testi-bg-wrap {
  background-color: #FFFFFF;
  opacity: 0.2;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.rs-testimonial.testimonial-style4 .slider {
  position: relative;
}
.rs-testimonial.testimonial-style4 .slider .testi-item {
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #F3F3FA;
  background-color: #FFFFFFEB;
  box-shadow: 0px 20px 24px -13px rgba(83, 79, 248, 0.16);
  padding: 40px 30px 50px 30px;
  margin: 0px 15px 40px 15px;
  border-radius: 30px 30px 30px 30px;
}
.rs-testimonial.testimonial-style4 .slider .testi-item .item-content span {
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.rs-testimonial.testimonial-style4 .slider .testi-item .item-content span img {
  width: 51px;
}
.rs-testimonial.testimonial-style4 .slider .testi-item .item-content .desc {
  color: #3E3E3E;
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  line-height: 30px;
  margin: 0 0 25px;
}
.rs-testimonial.testimonial-style4 .slider .testi-item .testi-content {
  display: flex;
  align-items: center;
}
.rs-testimonial.testimonial-style4 .slider .testi-item .testi-content .testi-information {
  padding: 0 0 0 20px;
}
.rs-testimonial.testimonial-style4 .slider .testi-item .testi-content .testi-information .testi-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #080D41;
}
.rs-testimonial.testimonial-style4 .slider .testi-item .testi-content .testi-information .testi-title {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #3E3E3EAD;
  display: block;
}
.rs-testimonial.testimonial-style4 .slider .slick-list {
  padding: 0 0 0  !important;
}
.rs-testimonial.testimonial-style4 .slider .slick-arrow {
  position: absolute;
  bottom: 0;
  border: 1px solid #126ee8;
  opacity: 0.8;
  background-color: transparent;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 0;
  z-index: 99;
  transform: translate(0, -50%);
  display: block !important;
}
.rs-testimonial.testimonial-style4 .slider .slick-arrow.slick-next {
  left: 51%;
  top: 112%;
}
.rs-testimonial.testimonial-style4 .slider .slick-arrow.slick-next:before {
  font-size: 24px;
  opacity: 0.8;
  color: #126ee8;
  margin: 0;
  font-family: remixicon;
  content: "\ea6c";
  text-align: center;
}
.rs-testimonial.testimonial-style4 .slider .slick-arrow.slick-prev {
  left: 46%;
  top: 112%;
}
.rs-testimonial.testimonial-style4 .slider .slick-arrow.slick-prev:before {
  content: "\ea60";
  font-size: 24px;
  opacity: 0.8;
  color: #126ee8;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style4 .slider .slick-arrow:hover {
  background: #126ee8;
  border: 1px solid #126ee8;
}
.rs-testimonial.testimonial-style4 .slider .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style4 .slider .slick-arrow:hover.slick-prev:before {
  opacity: 1;
  color: #ffffff;
}
.rs-testimonial.testimonial-style4.testimonial-modify6 .slider .testi-item {
  background-color: #634BF8;
  box-shadow: 20px 35px 70px 0px rgba(99, 75, 248, 0);
  border-width: 2px 2px 2px 2px;
  border-color: #634BF8;
  border-radius: 20px 20px 20px 20px;
}
.rs-testimonial.testimonial-style4.testimonial-modify6 .slider .testi-item .item-content span img {
  width: 40px;
}
.rs-testimonial.testimonial-style4.testimonial-modify6 .slider .testi-item .testi-content .testi-information .testi-title,
.rs-testimonial.testimonial-style4.testimonial-modify6 .slider .testi-item .testi-content .testi-information .testi-name {
  color: #fff;
}
.rs-testimonial.testimonial-style4.testimonial-modify6 .slider .testi-item .item-content .desc {
  color: #fff;
  font-size: 18px;
}
.rs-testimonial.testimonial-style4.testimonial-modify6 .slider .testi-item:not(.slick-center) {
  opacity: .7;
}
.rs-testimonial.testimonial-style4.testimonial-modify6 .slider .slick-arrow {
  border: 1px solid #fff;
}
.rs-testimonial.testimonial-style4.testimonial-modify6 .slider .slick-arrow.slick-prev:before,
.rs-testimonial.testimonial-style4.testimonial-modify6 .slider .slick-arrow.slick-next:before {
  color: #fff;
}
.rs-testimonial.testimonial-style4.testimonial-modify6 .slider .slick-arrow:hover {
  background: #634bf8;
  border: 1px solid #634bf8;
}
.rs-testimonial.testimonial-style5 {
  position: relative;
}
.rs-testimonial.testimonial-style5 .slider {
  position: relative;
}
.rs-testimonial.testimonial-style5 .slider .testi-item {
  position: relative;
  display: flex;
  align-items: center;
  vertical-align: middle;
  background: #fff;
  padding: 50px 30px 45px 50px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}
.rs-testimonial.testimonial-style5 .slider .testi-item .image-wrap img {
  max-width: 230px;
  border-radius: 50%;
}
.rs-testimonial.testimonial-style5 .slider .testi-item .testi-content {
  padding: 0 200px 0 50px;
}
.rs-testimonial.testimonial-style5 .slider .testi-item .testi-content .ratting {
  padding-bottom: 20px;
}
.rs-testimonial.testimonial-style5 .slider .testi-item .testi-content .ratting img {
  max-width: 100px;
}
.rs-testimonial.testimonial-style5 .slider .testi-item .testi-content .quote-img-icon {
  background-color: #1A73E9;
  border-radius: 50px 50px 50px 50px;
  width: 60px;
  height: 60px;
  top: 53px;
  position: absolute;
  left: 216px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rs-testimonial.testimonial-style5 .slider .testi-item .testi-content .desc {
  color: #656567;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 15px;
}
.rs-testimonial.testimonial-style5 .slider .testi-item .testi-content .testi-information .testi-name {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  color: #656567;
  margin-bottom: 10px;
}
.rs-testimonial.testimonial-style5 .slider .testi-item .testi-content .testi-information .testi-title {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #656567;
  display: block;
}
.rs-testimonial.testimonial-style5 .slider .slick-list {
  padding: 0 0 0  !important;
}
.rs-testimonial.testimonial-style5 .slider .slick-arrow {
  position: absolute;
  bottom: 0;
 border: 1.5px solid #C69944;
  opacity: 0.8;
  background-color: transparent;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 0;
  z-index: 99;
  transform: translate(0, -50%);
  display: block !important;
}
.rs-testimonial.testimonial-style5 .slider .slick-arrow.slick-next {
  top: -13%;
  right: 0;
}
.rs-testimonial.testimonial-style5 .slider .slick-arrow.slick-next:before {
  font-size: 24px;
  opacity: 0.8;
  color: #c69944;
  margin: 0;
  font-family: remixicon;
  content: "\ea6c";
  text-align: center;
}
.rs-testimonial.testimonial-style5 .slider .slick-arrow.slick-prev {
  top: -13%;
  right: 56px;
}
.rs-testimonial.testimonial-style5 .slider .slick-arrow.slick-prev:before {
  content: "\ea60";
  font-size: 24px;
  opacity: 0.8;
  color: #c69944;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style5 .slider .slick-arrow:hover {
  background: #c69944;
    border: 1px solid #c69944;
}
.rs-testimonial.testimonial-style5 .slider .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style5 .slider .slick-arrow:hover.slick-prev:before {
  opacity: 1;
  color: #ffffff;
}
/*----------------------------------------
	28. Shop Section CSS
----------------------------------------*/
.rs-shop-part .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #080d41;
  margin: 0;
}
.rs-shop-part .woocommerce-result-count {
  font-size: 16px;
  line-height: 26px;
  color: #3e3e3e;
  font-weight: 400;
  margin: 0;
}
.rs-shop-part .from-control {
  float: right;
  font-size: 16px;
  color: #3e3e3e;
  font-weight: 400;
  vertical-align: top;
  padding: 10px;
  border: none;
  border-radius: 0px;
  border-right: 10px solid #fff;
  background: none;
  box-shadow: 0 0 1px #999;
}
.rs-shop-part .product-list {
  text-align: center;
}
.rs-shop-part .product-list .image-product {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin-bottom: 20px;
}
.rs-shop-part .product-list .image-product img {
  margin-bottom: 0 !important;
  position: relative;
  width: 100% !important;
}
.rs-shop-part .product-list .image-product .overley {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  background: transparent;
  bottom: -50px;
  transform: scaleY(0);
  transform-origin: 0 1 0;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
  padding: 0;
}
.rs-shop-part .product-list .image-product .overley .product-info a {
  text-transform: capitalize;
  text-decoration: none;
  border-radius: 0px;
  line-height: 33px;
  text-align: center;
  transition: all 0.4s ease-in-out 0s;
  padding: 5px 20px;
  color: #ffffff;
  background: #080d41;
  display: block;
}
.rs-shop-part .product-list .image-product .overley .product-info a:hover {
  background-color: #126ee8 !important;
}
.rs-shop-part .product-list .image-product .onsale {
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  background: #126ee8;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 13px;
  right: 13px;
}
.rs-shop-part .product-list .content-desc .loop-product-title {
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  float: left;
}
.rs-shop-part .product-list .content-desc .loop-product-title a {
  color: #080d41;
}
.rs-shop-part .product-list .content-desc .loop-product-title a:hover {
  color: #126ee8;
}
.rs-shop-part .product-list .content-desc .price {
  font-size: 16px;
  line-height: 25px;
  color: #5d5d5d;
  font-weight: 600;
  margin: 0;
  float: right;
}
.rs-shop-part .product-list:hover .image-product .overley {
  opacity: 1;
  transform: scaleY(1);
  bottom: 0;
}
.rs-shop-part .product-list:hover .image-product .overley .product-info a {
  background: #080d41;
}
.rs-shop-part .widget-sidebar .widget-title {
  position: relative;
  color: #00204a;
  font-size: 24px;
  line-height: 26px;
  font-weight: 700!important;
  z-index: 1;
  padding-bottom: 0;
}
.rs-shop-part .widget-sidebar .widget-title:before {
  content: "";
  position: absolute;
  left: -7px;
  top: 2px;
  width: 24px;
  height: 24px;
  background: #1a73e9;
  border-radius: 50%;
  opacity: .2;
  display: block;
}
.rs-shop-part .widget-sidebar .product-category {
  background: #f6f9fe;
  padding: 40px 30px;
  box-shadow: 0 20px 20px rgba(0, 0, 1, 0.01);
  border-radius: 10px;
}
.rs-shop-part .widget-sidebar .product-category ul li {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.rs-shop-part .widget-sidebar .product-category ul li a {
  font-size: 18px;
  font-weight: 500;
  color: #656567;
}
.rs-shop-part .widget-sidebar .product-category ul li:first-child {
  border-top: unset;
}
.rs-shop-part .widget-sidebar .filter-price {
  background: #f6f9fe;
  padding: 40px 30px;
  box-shadow: 0 20px 20px rgba(0, 0, 1, 0.01);
  border-radius: 10px;
}
.rs-shop-part .widget-sidebar .filter-price .rs-tooltip {
  position: relative;
  height: 13px;
  border: 1px solid #1a73e9;
  border-radius: 40px;
  background: #1a73e9;
  margin-top: 40px;
  margin-bottom: 40px;
}
.rs-shop-part .widget-sidebar .filter-price .rs-tooltip:after {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-color: #1a73e9;
  left: -3px;
  position: absolute;
  top: -5px;
}
.rs-shop-part .widget-sidebar .filter-price .rs-tooltip:before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-color: #1a73e9;
  right: -3px;
  position: absolute;
  top: -5px;
}
.rs-shop-part .widget-sidebar .filter-price ul {
  display: flex;
  justify-content: space-between;
}
.rs-shop-part .widget-sidebar .filter-price ul li .price-label {
  top: 8px;
  position: relative;
  font-weight: 600;
}
.rs-shop-part .widget-sidebar .product-tags {
  background: #f6f9fe;
  padding: 40px 30px;
  box-shadow: 0 20px 20px rgba(0, 0, 1, 0.01);
  border-radius: 10px;
}
.rs-shop-part .widget-sidebar .product-tags .tagcloud a {
  display: inline-block;
  margin: 0 4px 8px 0;
  line-height: 20px;
  padding: 8px 15px;
  font-size: 16px!important;
  text-transform: capitalize;
  transition: all .3s ease;
  background: #1a73e9;
  color: #fff;
  font-weight: 500;
  border: none;
  position: relative;
  border-radius: 5px;
}
/*----------------------------------------
	29. Single Shop Section CSS
----------------------------------------*/
.rs-single-shop .single-product-image .images-single {
  z-index: 1 !important;
}
.rs-single-shop .single-product-image img {
  width: 100%;
}
.rs-single-shop .single-price-info .product-title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 12px;
}
.rs-single-shop .single-price-info .single-price {
  color: #126ee8;
  font-weight: 600;
}
.rs-single-shop .single-price-info .some-text {
  margin-top: 15px;
}
.rs-single-shop .single-price-info form {
  margin-bottom: 30px;
}
.rs-single-shop .single-price-info form input {
  height: 40px;
  width: 70px;
  line-height: 40px;
  text-align: center;
  padding-left: 10px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  outline: none;
}
.rs-single-shop .single-price-info p.category {
  margin: 0;
  padding-top: 25px;
  border-top: 1px solid #e6e6e6;
  font-size: 14px;
}
.rs-single-shop .single-price-info p.category span {
  font-weight: 700;
  padding-right: 10px;
}
.rs-single-shop .single-price-info p.category a {
  color: #666666;
}
.rs-single-shop .single-price-info p.category a:hover {
  color: #126ee8;
}
.rs-single-shop .single-price-info .tag {
  margin: 0;
}
.rs-single-shop .single-price-info .tag span {
  font-weight: 700;
}
.rs-single-shop .tab-area {
  margin-top: 50px;
}
.rs-single-shop .tab-area ul.nav-tabs {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 32px;
}
.rs-single-shop .tab-area ul.nav-tabs li {
  margin-right: 13px;
}
.rs-single-shop .tab-area ul.nav-tabs li .nav-link {
  padding: 20px 45px;
  display: inline-block;
  border-radius: 0px;
  font-size: 18px;
  background: #fbfbfb;
  border: 1px solid #fbfbfb;
  background-color: #fbfbfb;
  color: #080d41;
  font-weight: 400;
}
.rs-single-shop .tab-area ul.nav-tabs li .nav-link:hover,
.rs-single-shop .tab-area ul.nav-tabs li .nav-link.active {
  background-color: #126ee8;
  color: #ffffff;
}
.rs-single-shop .tab-area ul.nav-tabs li:last-child {
  margin-right: 0;
}
.rs-single-shop .tab-area .tab-content .tab-pane .tab-title {
  font-weight: 700;
  margin-bottom: 34px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .dsc-p {
  font-size: 18px;
  line-height: 28px;
  color: #656567;
  margin: 0;
}
.rs-single-shop .tab-area .tab-content .tab-pane table {
  border: 0;
  border-top: 1px dotted rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  width: 100%;
}
.rs-single-shop .tab-area .tab-content .tab-pane table .table-box tr th {
  width: 150px;
  font-weight: 700;
  margin: 0;
  line-height: 1.5;
  padding: 3px 15px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.rs-single-shop .tab-area .tab-content .tab-pane table .table-box tr td {
  padding: 3px 15px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area {
  margin-bottom: 5px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area p {
  margin-bottom: 23px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area h6 {
  font-size: 15px;
  font-weight: 600;
  color: #3e3e3e;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form {
  max-width: 600px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form p.comment-notes {
  margin-bottom: 15px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl label {
  font-weight: 600;
  display: block;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl input {
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl textarea {
  width: 100%;
  padding: 10px 15px;
  height: 75px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  margin-bottom: 20px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings {
  font-weight: 600;
  margin-bottom: 18px;
  display: block;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings ul li {
  display: inline;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings ul li i {
  color: #126ee8;
  font-weight: normal;
}
/*----------------------------------------
	30. Cart Section CSS
----------------------------------------*/
.rs-cart .cart-wrap table.cart-table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  border-collapse: collapse;
}
.rs-cart .cart-wrap table.cart-table td,
.rs-cart .cart-wrap table.cart-table th {
  padding: 25px;
  text-align: center;
  border: 1px solid #ccc;
}
.rs-cart .cart-wrap table.cart-table th {
  border: none;
  font-size: 18px;
  padding: 25px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table td {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 1px solid #e6e6e6;
}
.rs-cart .cart-wrap table.cart-table .product-remove a {
  margin: 0 auto;
  color: #080d41;
  display: block;
  border-radius: 100%;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight: 400;
  height: 20px;
  width: 20px;
  line-height: 17px;
  text-align: center;
}
.rs-cart .cart-wrap table.cart-table .product-remove a:hover {
  background: #ff0000;
  color: #ffffff;
}
.rs-cart .cart-wrap table.cart-table .product-thumbnail {
  min-width: 32px;
}
.rs-cart .cart-wrap table.cart-table .product-thumbnail a img {
  width: 80px;
  height: auto;
}
.rs-cart .cart-wrap table.cart-table .product-name a {
  color: #3e3e3e;
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table .product-name a:hover {
  color: #126ee8;
}
.rs-cart .cart-wrap table.cart-table .product-price {
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table .product-quantity input {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 0 0 0 10px;
  max-width: 65px;
  margin: 0 auto;
  outline: none;
}
.rs-cart .cart-wrap table.cart-table .action .coupon {
  float: left;
}
.rs-cart .cart-wrap table.cart-table .action .coupon input {
  box-sizing: border-box;
  border: 1px solid #d3ced2;
  padding: 6px 6px 5px;
  margin: 0 4px 0 0;
  outline: none;
  width: 320px;
  border-radius: 4px;
  height: 45px;
  margin-right: 20px;
  text-align: left;
  padding-left: 22px;
}
.rs-cart .cart-collaterals {
  width: 100%;
  overflow: hidden;
}
.rs-cart .cart-collaterals .cart-totals {
  float: right;
  width: 48%;
}
.rs-cart .cart-collaterals .cart-totals .title {
  font-size: 26px;
  line-height: 36px;
  font-weight: 700;
  color: #3e3e3e;
  text-transform: capitalize;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table {
  text-align: left;
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr th {
  font-size: 18px;
  font-weight: 700;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr td,
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr th {
  padding: 30px 10px;
}
/*----------------------------------------
	31. Checkout Section CSS
----------------------------------------*/
.rs-checkout .checkout-title {
  margin-bottom: 30px;
}
.rs-checkout .checkout-title h3 {
  font-size: 26px;
  margin: 0;
}
.rs-checkout .coupon-toggle .accordion .card {
  border: unset;
  border-top: 3px solid #126ee8;
  border-radius: 0;
}
.rs-checkout .coupon-toggle .accordion .card .card-header {
  border: none;
  margin: 0;
  border-radius: unset;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title {
  margin: 0;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title span i {
  margin-right: 10px;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title button {
  background: unset;
  border: none;
  color: #126ee8;
  transition: all 0.3s ease;
  outline: none;
  cursor: pointer;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title button:hover {
  color: #126ee8;
}
.rs-checkout .coupon-toggle .accordion .card .card-body {
  border: 1px solid #d3ced2;
  padding: 20px 20px 50px 20px;
  margin-top: 2em;
  text-align: left;
}
.rs-checkout .coupon-toggle .accordion .card .card-body .coupon-code-input {
  width: 47%;
  float: left;
  margin-right: 50px;
}
.rs-checkout .coupon-toggle .accordion .card .card-body .coupon-code-input input {
  width: 100%;
  height: 45px;
  outline: none;
  padding: 10px 18px;
  color: #3e3e3e;
  border: 1px solid rgba(54, 54, 54, 0.1);
}
.rs-checkout .full-grid {
  margin-top: 25px;
}
.rs-checkout .full-grid .form-content-box {
  margin-bottom: 50px;
}
.rs-checkout .full-grid .form-content-box .form-group label {
  line-height: 2;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}
.rs-checkout .full-grid .form-content-box .form-group select {
  color: #666666;
  opacity: .5;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #ccc;
  border-radius: 0;
  height: 45px;
  line-height: 45px;
  cursor: pointer;
  width: 100%;
  outline: none;
}
.rs-checkout .full-grid .form-content-box .form-group textarea {
  height: 4em;
  line-height: 1.5;
  display: block;
  box-shadow: none;
  width: 100%;
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 10px 18px;
  margin: 0;
  outline: none;
}
.rs-checkout .full-grid .form-content-box .form-group .form-control-mod {
  border: 1px solid rgba(54, 54, 54, 0.1);
  height: 45px;
  padding: 10px 18px;
  width: 100%;
  margin: 0;
  outline: none;
  line-height: normal;
  border-radius: unset;
}
.rs-checkout .full-grid .form-content-box .form-group .form-control-mod.margin-bottom {
  margin-bottom: 10px !important;
}
.rs-checkout .full-grid .ordered-product table {
  width: 100%;
}
.rs-checkout .full-grid .ordered-product table tr th {
  border: 1px solid #ccc;
  padding: 9px 12px;
}
.rs-checkout .full-grid .ordered-product table tr td {
  border: 1px solid #ccc;
  padding: 6px 12px;
}
.rs-checkout .full-grid .payment-method .top-area {
  border-bottom: 1px solid #d3ced2;
}
.rs-checkout .full-grid .payment-method .top-area .payment-co {
  margin-bottom: 20px;
}
.rs-checkout .full-grid .payment-method .top-area .payment-co span {
  font-weight: 600;
  margin-right: 10px;
}
.rs-checkout .full-grid .payment-method .top-area .p-msg {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  margin: 1em 0 2em;
  font-size: .92em;
  border-radius: 2px;
  line-height: 1.5;
  background-color: #f9f9f9;
}
.rs-checkout .full-grid .payment-method .top-area .p-msg:before {
  content: '';
  display: block;
  border: 1em solid #f9f9f9;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  top: -0.75em;
  left: 0;
  margin: -1em 0 0 2em;
}
/*----------------------------------------
	32. Account Section CSS
----------------------------------------*/
.rs-my-account .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #080d41;
  margin: 0;
}
.rs-my-account .rs-login {
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 20px;
  text-align: left;
  border-radius: 5px;
}
.rs-my-account .rs-login .form-group label {
  display: block;
  font-size: 15px;
  line-height: 30px;
  font-weight: 600;
  color: #3e3e3e;
}
.rs-my-account .rs-login .form-group label span {
  color: #126ee8;
}
.rs-my-account .rs-login .form-group input {
  width: 100%;
  margin: 0;
  outline: 0;
  line-height: normal;
  border: 1px solid rgba(54, 54, 54, 0.1);
  height: 45px;
}
.rs-my-account .rs-login .last-password a {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #126ee8;
}
.rs-my-account .rs-login p {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #3e3e3e;
  margin-bottom: 14px;
}
.rs-my-account .rs-login p a {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #126ee8;
}
/*------------------------------------
	33. Footer Section CSS
------------------------------------*/
.rs-footer.footer-main-home {
  background: linear-gradient(90.06deg, #C69944 0.05%, #D4AA59 98.79%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.rs-footer.footer-main-home .footer-top {
  padding: 90px 0 0px;
}
.rs-footer.footer-main-home .footer-top .footer-logo {
  margin-bottom: 30px;
}
.rs-footer.footer-main-home .footer-top .footer-logo a img {
  height: 40px;
}
.rs-footer.footer-main-home .footer-top .description {
  margin: 0 0 34px;
    font-weight: 600;
    font-size: 14px;
    line-height: 165.5%;
    letter-spacing: 0.04em;
    color: #FFFFFF;
  
}
.rs-footer.footer-main-home .footer-top .footer-social li {
  display: inline-block;
  margin-right: 6px;
}
.rs-footer.footer-main-home .footer-top .footer-social li:last-child {
  margin-right: 0;
}
.rs-footer.footer-main-home .footer-top .footer-social li a i {
  font-size: 16px;
  transition: all .8s ease;
  background-color: #E7ECFD;
  color: #1a73e9;
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  border-radius: 50px;
}
.rs-footer.footer-main-home .footer-top .footer-social li a i:hover {
  background-color: #1a73e9;
  color: #fff;
}
.rs-footer.footer-main-home .footer-top .footer-title {
      position: relative;
    
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 165.5%;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}
.rs-footer.footer-main-home .footer-top .footer-title:before {
  content: "";
  position: absolute;
  left: -6px;
  top: 4px;
  width: 24px;
  height: 24px;
  background: transparent;
  border-radius: 50%;
  opacity: 0.2;
  display: block;
}
.rs-footer.footer-main-home .footer-top .site-map {
  margin-top: -6px;
}
.rs-footer.footer-main-home .footer-top .site-map li {
  margin-bottom: 9px;
}
.rs-footer.footer-main-home .footer-top .site-map li a {

    transition: all 0.3s;
    font-weight: 500;
    font-size: 16px;
    line-height: 165.5%;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}
  i.ri-phone-line.mti {
    top: -1px !important;
}

.rs-footer.footer-main-home .footer-top .site-map li a:hover {
  color: #1a73e9;
}

.rs-footer.footer-main-home .footer-top .contact-box .address-box {
  text-align: left;
  display: flex;
  align-items: start;
}
.rs-footer.footer-main-home .footer-top .contact-box .address-box .address-icon {
  margin-right: 10px;
  line-height: 10px;
}
.rs-footer.footer-main-home .footer-top .contact-box .address-box .address-icon i {
     position: relative;
    top: -4px;
    font-size: 25px;
    line-height: 34px;
    color: #fff;
	padding: 5px;
}
.rs-footer.footer-main-home .footer-top .contact-box .address-box .address-text .text a {
  color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.rs-footer.footer-main-home .footer-top .contact-box .address-box .address-text .text a:hover {
  color: #ccc;
}
.rs-footer.footer-main-home .footer-top .contact-box .address-box .address-text .text .des {
  display: block;
  color: #FFFFFFD6;
}
.rs-footer.footer-main-home .footer-top .widget-desc {
  margin: 0px 0px 25px 0;
  color: #FFFFFFDE;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border {
  padding: 25px 0 25px;
  border-top: 1px solid #fff;
}
.address-box.mb-12.bgph {
    background: #0D4058;
    /* background: black; */
    width: 250px;
    padding: 10px 15px;
    border-radius: 10px 0 20px 0;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copyright p {
  font-size: 16px;
  color: #FFF;
  margin: 0;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copyright p a {
  transition: all 0.4s;
  color: #FFFFFFD1;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copyright p a:hover {
  color: #1a73e9;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copy-right-menu li {
  display: inline-block;
  position: relative;
  padding: 0px 19px 0 25px;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copy-right-menu li a {
  font-size: 16px;
  color: #FFFFFFD1;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copy-right-menu li a:hover {
  color: #126ee8;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copy-right-menu li:before {
  display: block;
  content: "";
  position: absolute;
  background: #1a73e9;
  left: 0;
  top: 50%;
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  border-radius: 50%;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copy-right-menu li:first-child:before {
  display: none;
}
.rs-footer.footer-main-home.footer-style1 .footer-top {
  
}
.rs-footer.footer-main-home.footer-modify1 {
  position: relative;
  z-index: 11;
  /* background-image: url(assets/images/footer/footer-1.png); */
  background-color: #F6F9FE;
  background-position: 105% -48px;
  background-repeat: no-repeat;
  background-size: unset;
}
.rs-footer.footer-main-home.footer-modify1 .footer-overlay {
  /* background-image: url(assets/images/footer/footer-2.png); */
  background-position: -1% 98%;
  background-repeat: no-repeat;
  opacity: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -11;
}
.rs-footer.footer-main-home.footer-modify1 .newsletter-wrap {
  background-color: transparent;
  background-image: linear-gradient(60deg, #eef3fc 0%, #f0f0ff 100%);
  border-radius: 10px 10px 10px 10px;
  padding: 40px 50px 40px 50px;
}
.rs-footer.footer-main-home.footer-modify1 .newsletter-wrap .title {
  color: #080D41;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 0;
}
.rs-footer.footer-main-home.footer-modify1 .newsletter-wrap .description {
  color: #656567;
  margin: 0 0 10px;
}
.rs-footer.footer-main-home.footer-modify1 .newsletter-wrap input[type="email"] {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  height: 100%;
  width: 100%;
  padding: 15px 0px 15px 20px;
  border-radius: 10px 10px 10px 10px;
  color: #3A3A3A;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #3A3A3A26;
  background-color: #FFFFFF12;
}
.rs-footer.footer-main-home.footer-modify1 .newsletter-wrap input[type="submit"] {
  color: #FFFFFF;
  background-color: #1A73E9;
  width: 130px;
  margin: -30px 0px 0px 0px;
  padding: 12px 0px 12px 0px;
  font-weight: 600;
  border-radius: 10px;
  border-style: none;
  transition: all 0.4s;
}
.rs-footer.footer-main-home.footer-modify1 .newsletter-wrap input[type="submit"]:hover {
  opacity: 0.8;
}
.rs-footer.footer-main-home.footer-modify1 .footer-top {
  padding: 90px 0 100px;
}
.rs-footer.footer-main-home.footer-modify1 .footer-bottom {
  background-color: #22254B;
}
.rs-footer.footer-main-home.footer-modify1 .footer-top .footer-title {
  color: #080d41;
}
.rs-footer.footer-main-home.footer-modify1 .footer-top .contact-box .address-box .address-text .text a,
.rs-footer.footer-main-home.footer-modify1 .footer-top .contact-box .address-box .address-text .text a:hover,
.rs-footer.footer-main-home.footer-modify1 .footer-top .site-map li a:hover {
  color: #1a73e9;
}
.rs-footer.footer-main-home.footer-modify1 .footer-top .contact-box .address-box .address-text .text .des,
.rs-footer.footer-main-home.footer-modify1 .footer-top .contact-box .address-box .address-text .text a,
.rs-footer.footer-main-home.footer-modify1 .footer-top .site-map li a {
  color: #3a3a3a;
}
.rs-footer.footer-main-home.footer-modify1 .footer-top .description {
  font-size: 18px;
  color: #656567;
}
.rs-footer.footer-main-home.footer-modify1 .footer-bottom .bottom-border {
  border-top: 1px solid unset;
}
.rs-footer.footer-main-home.footer-modify1.footer-blue-style1 {
  /* background-image: url(assets/images/footer/footer-2-1.png); */
  background-position: 100% -48px;
}
.rs-footer.footer-main-home.footer-modify1.footer-blue-style1 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify1.footer-blue-style1 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify1.footer-blue-style1 .footer-top .site-map li a:hover {
  color: #3a15fb;
}
.rs-footer.footer-main-home.footer-modify1.footer-blue-style1 .footer-bottom .bottom-border .copy-right-menu li:before,
.rs-footer.footer-main-home.footer-modify1.footer-blue-style1 .footer-top .footer-social li a i:hover,
.rs-footer.footer-main-home.footer-modify1.footer-blue-style1 .newsletter-wrap input[type="submit"],
.rs-footer.footer-main-home.footer-modify1.footer-blue-style1 .footer-top .site-map li a:before {
  background-color: #3a15fb;
}
.rs-footer.footer-main-home.footer-modify1.footer-blue-style1 .footer-bottom {
  background-color: #1A001B;
}
.rs-footer.footer-main-home.footer-modify1.footer-blue-style1 .footer-overlay {
  /* background-image: url(assets/images/footer/footer-2-2.png); */
  background-position: 0% 84%;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 {
  /* background-image: url(assets/images/bg/footer-bg-black.jpg); */
  background-color: #F4F4F4;
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-overlay {
  display: none;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .contact-wrap {
  background-color: #534FF81F;
  padding: 35px 40px 40px 40px;
  border-radius: 10px 10px 10px 10px;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .contact-wrap .address-box {
  text-align: left;
  display: flex;
  align-items: start;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .contact-wrap .address-box .address-icon {
  margin-right: 10px;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .contact-wrap .address-box .address-icon i {
  position: relative;
  top: -4px;
  font-size: 20px;
  line-height: 34px;
  color: #FFFFFF;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .contact-wrap .address-box .address-text .text {
  color: #FFFFFFDE;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .contact-wrap .address-box .address-text .text a {
  color: #FFFFFFDE;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .contact-wrap .address-box .address-text .text a:hover {
  color: #FFFFFF99;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .contact-wrap .footer-social-wrap li {
  display: inline-block;
  margin-right: 14px;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .contact-wrap .footer-social-wrap li:last-child {
  margin-right: 0;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .contact-wrap .footer-social-wrap li a i {
  font-size: 16px;
  transition: all .8s ease;
  background-color: transparent;
  color: #FFFFFF;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .contact-wrap .footer-social-wrap li a i:hover {
  color: #FFFFFF99;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-top .footer-title {
  color: #ffffff;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-top .footer-title:before {
  background: #00a775;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-bottom {
  background-color: #000035;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-top .site-map li {
  margin-bottom: 17px;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .newsletter-wrap {
  background-color: transparent;
  background-image: unset;
  padding: 0px 50px 0px 50px;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-top .site-map li a,
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-top .description {
  color: #FFFFFFDE;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .newsletter-wrap input[type="email"] {
  color: #3A3A3A;
  border-color: #ffffff;
  background-color: #ffffff;
  padding: 20px 0px 20px 20px;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #3a3a3a;
  opacity: 0.57;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 ::-moz-placeholder {
  /* Firefox 19+ */

  color: #3a3a3a;
  opacity: 0.57;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 :-ms-input-placeholder {
  /* IE 10+ */

  color: #3a3a3a;
  opacity: 0.57;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 :-moz-placeholder {
  /* Firefox 18- */

  color: #3a3a3a;
  opacity: 0.57;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .newsletter-wrap input[type="submit"] {
  background-color: #00a775;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-bottom .bottom-border {
  border-top: 1px solid transparent;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-top .site-map li a:before,
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-bottom .bottom-border .copy-right-menu li:before {
  background: #00a775;
}
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-top .site-map li a:hover,
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify1.footer-black1 .footer-bottom .bottom-border .copyright p a:hover {
  color: #00a775;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 {
  background-image: unset;
  background-color: #F6F4FF;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-top .site-map li a:hover {
  color: #f05c4e;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-bottom .bottom-border .copy-right-menu li:before,
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .newsletter-wrap input[type="submit"],
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-top .site-map li a:before {
  background-color: #f05c4e;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-bottom {
  background-color: #eeebff;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-bottom .bottom-border {
  border-top: 1px solid transparent;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-top .footer-title:before {
  background-color: #F05C4E;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-top .footer-social li a i:hover {
  color: #ffffff;
  background-color: #f05c4e;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-top .footer-social li a i {
  color: #f05c4e;
  border-color: #F05C4E42;
  background-color: #E7ECFD00;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-bottom .bottom-border .copy-right-menu li a,
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-bottom .bottom-border .copyright p a,
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-bottom .bottom-border .copyright p {
  font-size: 18px;
  color: #656567;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-top .site-map li a {
  font-size: 18px;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .newsletter-wrap {
  background-image: linear-gradient(60deg, #eeebff 0%, #eeebff 100%);
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .newsletter-wrap input[type="submit"] {
  border-radius: 0px;
}
.rs-footer.footer-main-home.footer-modify1.footer-orange-style1 .footer-overlay {
  display: none;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 {
  background-image: unset;
  background-color: #0c0e10;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-top {
  padding: 240px 0 100px;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-top .site-map li a:hover {
  color: #634bf8;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-bottom .bottom-border .copy-right-menu li:before,
.rs-footer.footer-main-home.footer-modify1.footer-black2 .newsletter-wrap input[type="submit"],
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-top .site-map li a:before {
  background-color: #634bf8;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-top .description,
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-top .footer-title {
  color: #fff;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .newsletter-wrap .title {
  color: #fff;
  margin: 0 0 5px;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-bottom {
  background-color: #242424;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-bottom .bottom-border {
  border-top: 1px solid transparent;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-top .footer-title:before {
  background-color: #634bf8;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-top .footer-social li a i:hover {
  color: #ffffff;
  background-color: #634bf8;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-top .footer-social li a i {
  color: #634bf8;
  background-color: #ffffff;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-bottom .bottom-border .copy-right-menu li a,
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-bottom .bottom-border .copyright p a,
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-bottom .bottom-border .copyright p {
  font-size: 18px;
  color: #F6F6F6;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-top .site-map li a {
  font-size: 18px;
  color: #F6F6F6;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-top .site-map li {
  margin-bottom: 14px;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .newsletter-wrap {
  background-image: unset;
  background-color: #242424;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .newsletter-wrap input[type="submit"] {
  border-radius: 10px;
}
.rs-footer.footer-main-home.footer-modify1.footer-black2 .footer-overlay {
  display: none;
}
.rs-footer.footer-main-home.footer-modify3 {
  background-image: unset;
  background-color: #242424;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .footer-social li a i {
  font-size: 16px;
  transition: all .8s ease;
  background-color: #E7ECFD00;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #0092F242;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .footer-social li a i:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #0092f2;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .description {
  font-size: 18px;
  line-height: 29px;
}
.rs-footer.footer-main-home.footer-modify3 .footer-bottom {
  background-color: #2d2b2b;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .site-map li a:before,
.rs-footer.footer-main-home.footer-modify3 .footer-bottom .bottom-border .copy-right-menu li:before {
  background-color: #0092f2;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .site-map li a:hover,
.rs-footer.footer-main-home.footer-modify3 .footer-top .contact-box .address-box .address-icon i,
.rs-footer.footer-main-home.footer-modify3 .footer-top .contact-box .address-box .address-text .text a:hover,
.rs-footer.footer-main-home.footer-modify3 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify3 .footer-bottom .bottom-border .copyright p a:hover {
  color: #0092f2;
}
.rs-footer.footer-main-home.footer-modify3 .footer-bottom .bottom-border {
  border-top: 1px solid transparent;
}
.rs-footer.footer-style2 {
  /* background-image: url(assets/images/bg/footer-bg.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-footer.footer-style2 .footer-top {
  padding: 90px 0 90px;
}
.rs-footer.footer-style2 .footer-top .footer-logo {
  margin-bottom: 30px;
}
.rs-footer.footer-style2 .footer-top .footer-logo a img {
  height: 34px;
}
.rs-footer.footer-style2 .footer-top .description {
  font-size: 17px;
  line-height: 27px;
  font-weight: 500;
  color: #3e3e3e;
  margin: 0 0 30px;
}
.rs-footer.footer-style2 .footer-top .widget-wrap {
  padding: 40px 60px 40px 60px;
  position: relative;
  z-index: 1;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .background-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  border-radius: 30px 30px 30px 30px;
  z-index: -1;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .footer-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #121933;
  text-transform: uppercase;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .footer-social {
  margin-top: 20px;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .footer-social li {
  display: inline-block;
  margin-right: 10px;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .footer-social li a i {
  color: #747378;
  display: block;
  text-align: center;
  transition: all 0.4s;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .footer-social li a i:hover {
  color: #126ee8;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .footer-social li:last-child {
  margin-right: 0;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .site-map {
  margin-top: -6px;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .site-map li {
  margin-bottom: 7px;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .site-map li a {
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding: 6px 0 4px 0px;
  display: inline-block;
  color: #3e3e3e;
  transition: all 0.3s;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .site-map li a:hover {
  color: #126ee8;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .contact-box .address-box {
  text-align: left;
  display: flex;
  align-items: start;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .contact-box .address-box .address-icon {
  margin-right: 10px;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .contact-box .address-box .address-icon i {
  position: relative;
  top: -4px;
  font-size: 18px;
  line-height: 34px;
  color: #126ee8;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .contact-box .address-box .address-text .text a {
  color: #3E3E3E;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .contact-box .address-box .address-text .text a:hover {
  color: #126ee8;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .contact-box .address-box .address-text .text .des {
  color: #3E3E3E;
}
.rs-footer.footer-style2 .footer-top .widget-wrap .widget-desc {
  margin: 0px 0px 25px 0;
  color: #FFFFFFDE;
}
.rs-footer.footer-style2 .footer-bottom {
  padding: 25px 0 23px;
  background-color: #ffffff;
}
.rs-footer.footer-style2 .footer-bottom .bottom-border .copyright p {
  font-size: 16px;
  font-weight: 400;
  color: #3a3a3b;
  margin: 0;
}
.rs-footer.footer-style2 .footer-bottom .bottom-border .copyright p a {
  transition: all 0.4s;
  color: #3a3a3b;
}
.rs-footer.footer-style2 .footer-bottom .bottom-border .copyright p a:hover {
  color: #126ee8;
}
.rs-footer.footer-style2 .footer-bottom .bottom-border .copy-right-menu li {
  display: inline-block;
  position: relative;
  padding: 0px 19px 0 25px;
}
.rs-footer.footer-style2 .footer-bottom .bottom-border .copy-right-menu li a {
  font-size: 16px;
  font-weight: 400;
  color: #3a3a3b;
}
.rs-footer.footer-style2 .footer-bottom .bottom-border .copy-right-menu li a:hover {
  color: #126ee8;
}
.rs-footer.footer-style2 .footer-bottom .bottom-border .copy-right-menu li:before {
  display: block;
  content: "";
  position: absolute;
  background: #126ee8;
  left: 0;
  top: 50%;
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  border-radius: 50%;
}
.rs-footer.footer-style2 .footer-bottom .bottom-border .copy-right-menu li:first-child:before {
  display: none;
}
.rs-footer.footer-style2.footer-modify2 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-style2.footer-modify2 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-style2.footer-modify2 .footer-top .widget-wrap .footer-social li a i:hover,
.rs-footer.footer-style2.footer-modify2 .footer-top .widget-wrap .contact-box .address-box .address-text .text a:hover,
.rs-footer.footer-style2.footer-modify2 .footer-top .widget-wrap .contact-box .address-box .address-text .text a:hover,
.rs-footer.footer-style2.footer-modify2 .footer-top .widget-wrap .contact-box .address-box .address-icon i,
.rs-footer.footer-style2.footer-modify2 .footer-top .widget-wrap .site-map li a:hover {
  color: #b65df0;
}
.rs-footer.footer-style2.footer-modify2 .footer-bottom .bottom-border .copy-right-menu li:before {
  background: #b65df0;
}
.rs-footer.footer-style3 {
  background-color: #E8EECF;
}
.rs-footer.footer-style3 .footer-top {
  padding: 90px 0 90px;
}
.rs-footer.footer-style3 .footer-top .description {
  font-size: 17px;
  line-height: 27px;
  font-weight: 500;
  color: #3e3e3e;
  margin: 0 0 30px;
}
.rs-footer.footer-style3 .footer-top .footer-inner .footer-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #121933;
  margin: 0 0 10px;
  text-transform: uppercase;
}
.rs-footer.footer-style3 .footer-top .footer-inner .heading-border-line {
  position: relative;
  margin-left: 44px;
  margin-top: 15px;
}
.rs-footer.footer-style3 .footer-top .footer-inner .heading-border-line:before {
  content: "";
  width: 12px;
  height: 4px;
  background: #83a300;
  position: absolute;
  bottom: -4px;
  left: 0;
  margin-left: -35px;
  transform: translateX(-50%);
  border-radius: 2px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 1;
}
.rs-footer.footer-style3 .footer-top .footer-inner .heading-border-line:after {
  content: "";
  width: 65px;
  height: 4px;
  background: #83a300;
  position: absolute;
  bottom: -4px;
  right: 0;
  left: 0;
  margin-left: -20px;
  border-radius: 2px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 0;
}
.rs-footer.footer-style3 .footer-top .footer-inner .heading-border-line.left-style {
  position: relative;
  margin-left: 44px;
}
.rs-footer.footer-style3 .footer-top .footer-inner .heading-border-line.left-style:before {
  left: 0;
}
.rs-footer.footer-style3 .footer-top .footer-inner .heading-border-line.left-style:after {
  left: 0;
}
.rs-footer.footer-style3 .footer-top .footer-inner .heading-border-line.left-style.blue-text:before {
  background: #83a300;
}
.rs-footer.footer-style3 .footer-top .footer-inner .heading-border-line.left-style.blue-text:after {
  background: #83a300;
}
.rs-footer.footer-style3 .footer-top .footer-inner:hover .heading-border-line {
  position: relative;
}
.rs-footer.footer-style3 .footer-top .footer-inner:hover .heading-border-line:before {
  width: 65px;
  margin-left: -5px;
  background: #83a300;
}
.rs-footer.footer-style3 .footer-top .footer-inner:hover .heading-border-line:after {
  width: 12px;
  margin-left: 35px;
  background: #83a300;
}
.rs-footer.footer-style3 .footer-top .footer-social {
  margin-top: 20px;
}
.rs-footer.footer-style3 .footer-top .footer-social li {
  display: inline-block;
  margin-right: 10px;
}
.rs-footer.footer-style3 .footer-top .footer-social li a i {
  color: #747378;
  display: block;
  text-align: center;
  transition: all 0.4s;
}
.rs-footer.footer-style3 .footer-top .footer-social li a i:hover {
  color: #83a300;
}
.rs-footer.footer-style3 .footer-top .footer-social li:last-child {
  margin-right: 0;
}
.rs-footer.footer-style3 .footer-top .site-map {
  margin-top: -6px;
}
.rs-footer.footer-style3 .footer-top .site-map li {
  margin-bottom: 7px;
}
.rs-footer.footer-style3 .footer-top .site-map li a {
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding: 6px 0 4px 0px;
  display: inline-block;
  color: #3e3e3e;
  transition: all 0.3s;
}
.rs-footer.footer-style3 .footer-top .site-map li a:hover {
  color: #83a300;
}
.rs-footer.footer-style3 .footer-top .contact-box .address-box {
  text-align: left;
  display: flex;
  align-items: start;
}
.rs-footer.footer-style3 .footer-top .contact-box .address-box .address-icon {
  margin-right: 10px;
}
.rs-footer.footer-style3 .footer-top .contact-box .address-box .address-icon i {
  position: relative;
  top: -4px;
  font-size: 18px;
  line-height: 34px;
  color: #83a300;
}
.rs-footer.footer-style3 .footer-top .contact-box .address-box .address-text .text a {
  color: #3E3E3E;
}
.rs-footer.footer-style3 .footer-top .contact-box .address-box .address-text .text a:hover {
  color: #83a300;
}
.rs-footer.footer-style3 .footer-top .contact-box .address-box .address-text .text .des {
  color: #3E3E3E;
}
.rs-footer.footer-style3 .footer-top .widget-desc {
  margin: 0px 0px 25px 0;
  color: #FFFFFFDE;
}
.rs-footer.footer-style3 .footer-bottom {
  padding: 25px 0 23px;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #DEE3CBCF;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.rs-footer.footer-style3 .footer-bottom .bottom-border .copyright p {
  font-size: 16px;
  font-weight: 400;
  color: #3a3a3b;
  margin: 0;
}
.rs-footer.footer-style3 .footer-bottom .bottom-border .copyright p a {
  transition: all 0.4s;
  color: #3a3a3b;
}
.rs-footer.footer-style3 .footer-bottom .bottom-border .copyright p a:hover {
  color: #83a300;
}
.rs-footer.footer-style3 .footer-bottom .bottom-border .copy-right-menu li {
  display: inline-block;
  position: relative;
  padding: 0px 19px 0 25px;
}
.rs-footer.footer-style3 .footer-bottom .bottom-border .copy-right-menu li a {
  font-size: 16px;
  font-weight: 400;
  color: #3a3a3b;
}
.rs-footer.footer-style3 .footer-bottom .bottom-border .copy-right-menu li a:hover {
  color: #83a300;
}
.rs-footer.footer-style3 .footer-bottom .bottom-border .copy-right-menu li:before {
  display: block;
  content: "";
  position: absolute;
  background: #83a300;
  left: 0;
  top: 50%;
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  border-radius: 50%;
}
.rs-footer.footer-style3 .footer-bottom .bottom-border .copy-right-menu li:first-child:before {
  display: none;
}
/* -----------------------
	34. Scroll Up CSS
--------------------------*/
#scrollUp {
  text-align: center;
  bottom: 40px;
  cursor: pointer;
  display: none;
  position: fixed;
  right: 20px;
  z-index: 999;
  border-radius: 50px 50px 4px 4px;
}
#scrollUp i {
  height: 40px;
  font-size: 24px;
  width: 42px;
  border-radius: 5px;
  color: #ffffff;
  line-height: 36px;
  transition: all 0.3s ease 0s;
  background: #2B59FF;
  margin-left: 2px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.02);
  display: block;
}
#scrollUp i:before {
  font-weight: 900;
}
#scrollUp i:hover {
  background: #080d41;
  color: #fff;
}
#scrollUp.primary-color i {
      background: #fff;
    color: #d1a755;
}
#scrollUp.primary-color i:hover {
  background: #080d41;
  color: #fff;
}
#scrollUp.primary-color2 i {
  background: #634bf8;
  color: #ffffff;
}
#scrollUp.primary-color2 i:hover {
  background: #080d41;
  color: #fff;
}
#scrollUp.orange-color i {
  background: #f05c4e;
  color: #ffffff;
}
#scrollUp.orange-color i:hover {
  background: #080d41;
  color: #fff;
}
#scrollUp.orange-color2 i {
  background: #ff6600;
  color: #ffffff;
}
#scrollUp.orange-color2 i:hover {
  background: #080d41;
  color: #fff;
}
#scrollUp.green-color i {
  background: #00a775;
  color: #ffffff;
}
#scrollUp.green-color i:hover {
  background: #080d41;
  color: #fff;
}
#scrollUp.green-color2 i {
  background: #83a300;
  color: #ffffff;
}
#scrollUp.green-color2 i:hover {
  background: #080d41;
  color: #fff;
}
#scrollUp.purple-color i {
  background: #0092f2;
  color: #ffffff;
}
#scrollUp.purple-color i:hover {
  background: #080d41;
  color: #fff;
}
#scrollUp.red-color i {
  background: #d32f2f;
  color: #ffffff;
}
#scrollUp.red-color i:hover {
  background: #080d41;
  color: #fff;
}
#scrollUp.pink-color i {
  background: #b65df0;
  color: #ffffff;
}
#scrollUp.pink-color i:hover {
  background: #080d41;
  color: #fff;
}
/*-----------------------------------------------
    35. Woocommerce Section CSS
-----------------------------------------------*/
.woocommerce-mini-cart {
  line-height: 20px;
  background-color: #fff;
  position: absolute;
  right: 13.3%;
  font-size: 14px;
  top: 100%;
  width: 278px;
  z-index: 10000;
  transition: all .2s ease 0s;
  box-shadow: 0 0 15px #eee;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0 0;
}
.woocommerce-mini-cart .cart-bottom-part {
  padding: 25px 10px 0;
}
.woocommerce-mini-cart .cart-bottom-part .widget-title {
  text-align: center;
  text-transform: uppercase;
  position: relative;
  padding-top: 50px;
  color: #0a0a0a;
  font-family: Nunito;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.woocommerce-mini-cart .cart-bottom-part .widget-title:before {
  position: absolute;
  content: "\f124";
  color: #161616;
  left: 50%;
  transform: translateX(-50%);
  bottom: 42px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 30px;
  font-style: normal;
}
.mini-cart-active {
  cursor: pointer;
}
.mini-cart-active:hover .woocommerce-mini-cart {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
/*----------------------------------------------
	36. Preloader CSS
----------------------------------------------*/
#pre-load {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 9999;
}
.loader .loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 3px solid #D4AA59;
  border-radius: 50%;
}
.loader .loader-container:before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-top: 3px solid #D4AA59;
  border-radius: 50%;
  animation: loaderspin 1.8s infinite ease-in-out;
  -webkit-animation: loaderspin 1.8s infinite ease-in-out;
}
.loader .loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  text-align: center;
}
.loader .loader-icon img {
  animation: loaderpulse alternate 900ms infinite;
  width: 40px;
}
.woocommerce .quantity input[type="number"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]::-moz-placeholder {
  /* Firefox 19+ */

  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]:-ms-input-placeholder {
  /* IE 10+ */

  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]:-moz-placeholder {
  /* Firefox 18- */

  color: #363636 !important;
  opacity: 1;
}
@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
/* ------------------------------------
	37. Animation Part CSS
---------------------------------------*/
@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.move-y {
  animation: move-y 2s alternate infinite;
  -webkit-animation: move-y 2s alternate infinite;
}
@keyframes move-y {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 2s;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 2s;
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeft2 {
  animation-name: fadeInLeft2;
  animation-duration: 2.5s;
}
@keyframes fadeInLeft2 {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 2s;
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRight2 {
  animation-name: fadeInRight2;
  animation-duration: 2.5s;
}
@keyframes fadeInRight2 {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 2s;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.scale {
  animation-duration: 2s;
  -webkit-animation: scale 2s alternate infinite;
}
.scale.new {
  animation-duration: 2.5s;
}
@-webkit-keyframes scale {
  0% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.08);
    -webkit-transform: scale(1.08);
  }
}
.scale2 {
  animation: scale2 2s alternate infinite;
  -webkit-animation: scale2 2s alternate infinite;
}
@-webkit-keyframes scale2 {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(1.07);
    -webkit-transform: scale(1.07);
  }
}
.horizontal {
  animation: horizontal 2s alternate infinite;
  -webkit-animation: horizontal 2s alternate infinite;
}
@keyframes horizontal {
  0% {
    transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
  }
  0% {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
  }
}
.horizontal2 {
  animation-timing-function: linear;
  animation-duration: 12s;
  animation-name: horizontal2;
}
@keyframes horizontal2 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}
.horizontal3 {
  animation-duration: 2s;
  animation: horizontal3 2s alternate infinite;
  -webkit-animation: horizontal3 2s alternate infinite;
}
@keyframes horizontal3 {
  0% {
    transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
  }
  0% {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
  }
}
.horizontal4 {
  animation-duration: 3s;
  animation: horizontal4 3s alternate infinite;
  -webkit-animation: horizontal4 3s alternate infinite;
}
@keyframes horizontal4 {
  0% {
    transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
  }
  0% {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
  }
}
.rotated-style {
  animation: rotated-style 5s linear infinite;
}
@keyframes rotated-style {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(-10px, 10px) rotate(10deg);
    transform: translate(-10px, 10px) rotate(10deg);
  }
  50% {
    -webkit-transform: translate(5px, -5px) rotate(25deg);
    transform: translate(5px, -5px) rotate(25deg);
  }
  75% {
    -webkit-transform: translate(15px, -5px) rotate(15deg);
    transform: translate(15px, -5px) rotate(15deg);
  }
  100% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
}
.spine {
  animation: spine 8s infinite linear;
  -webkit-animation: spine 8s infinite linear;
  animation-duration: spine 8s;
}
@keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.spiner {
  animation: spiner 30s infinite linear;
  -webkit-animation: spiner 30s infinite linear;
  animation-duration: spiner 30s;
}
@keyframes spiner {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spiner {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.veritcal {
  animation-name: veritcal;
  animation-timing-function: linear;
  animation-duration: 12s;
  animation-iteration-count: infinite;
}
@keyframes veritcal {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
.veritcal2 {
  animation-name: veritcal2 3s alternate infinite;
  -webkit-animation: veritcal2 3s alternate infinite;
}
@keyframes veritcal2 {
  0% {
    transform: translate3d(0, -10px, 0);
    -webkit-transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}
@-webkit-keyframes veritcal2 {
  0% {
    transform: translate3d(0, -10px, 0);
    -webkit-transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}
.veritcal3 {
  animation-name: veritcal3 4s alternate infinite;
  -webkit-animation: veritcal3 4s alternate infinite;
}
@keyframes veritcal3 {
  0% {
    transform: translate3d(0, -10px, 0);
    -webkit-transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}
.veritcal4 {
  animation-name: veritcal4;
  animation-timing-function: linear;
  animation-duration: 12s;
  animation-iteration-count: infinite;
}
@keyframes veritcal4 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
.left-right2 {
  animation-name: left-right2;
  -webkit-animation-name: left-right2;
  animation-duration: 6s;
  -webkit-animation-duration: 6s;
  animation-direction: linear;
  -webkit-animation-direction: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@keyframes left-right2 {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(5px, 0, 0) rotate(4deg);
    -webkit-transform: translate3d(5px, 0, 0) rotate(4deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotate(-2deg);
    -webkit-transform: translate3d(0, 0, 0) rotate(-2deg);
  }
  75% {
    transform: translate3d(5px, 0, 0) rotate(4deg);
    -webkit-transform: translate3d(5px, 0, 0) rotate(4deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
.left-right {
  animation: left-right 5s cubic-bezier(0.41, 0.04, 0.03, 1.1) infinite;
  -webkit-animation: left-right 5s cubic-bezier(0.41, 0.04, 0.03, 1.1) infinite;
}
@keyframes left-right {
  0% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }
  50% {
    transform: translateY(-50px);
    -webkit-transform: translatex(-50px);
  }
  100% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }
}
@-webkit-keyframes left-right {
  0% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }
  50% {
    transform: translatex(-50px);
    -webkit-transform: translatex(-50px);
  }
  100% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }
}
.move-reverse {
  -webkit-animation-duration: 4s;
  animation-name: move-reverse;
  -webkit-animation-name: move-reverse;
  -webkit-animation-iteration-count: infinite;
}
@keyframes move-reverse {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(5px, 0, 0) rotate(4deg);
    -webkit-transform: translate3d(5px, 0, 0) rotate(4deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotate(-2deg);
    -webkit-transform: translate3d(0, 0, 0) rotate(-2deg);
  }
  75% {
    transform: translate3d(5px, 0, 0) rotate(4deg);
    -webkit-transform: translate3d(5px, 0, 0) rotate(4deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
.rs-services.services-main-home .services-item:hover .dance_hover {
  animation-name: dance_hover;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes dance_hover {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
.morphine-wrap {
  position: relative;
}
.rs-feature.feature-style4 .useful-wrap .morphine-wrap img,
.rs-feature.feature-style2 .useful-wrap .morphine-wrap img,
.rs-feature.feature-style2 .images-wrap .morphine-wrap img,
.rs-feature.feature-style1.feature-modify2 .feature-images .morphine-wrap img,
.rs-banner.banner-style3 .bnr-images .morphine-wrap img,
.rs-feature.feature-style1 .morphine-wrap img {
  -webkit-animation: morphine 15s linear infinite alternate forwards;
  animation: morphine 15s linear infinite alternate forwards;
  border-radius: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}
.parent-morpheneanim {
  -webkit-animation: parent-morphine 15s linear infinite alternate forwards;
  animation: parent-morphine 15s linear infinite alternate forwards;
}
/********** Price Table *************/
.pricing-switcher .switch {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 45px;
  width: 90px;
  background-color: #6722B5;
  border-radius: 10px;
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  transition: transform 0.5s;
}
.pricing-switcher input[type="radio"]:checked + label + .switch,
.pricing-switcher input[type="radio"]:checked + label:nth-of-type(n) + .switch {
  -webkit-transform: translateX(95px);
  -moz-transform: translateX(95px);
  -ms-transform: translateX(95px);
  -o-transform: translateX(95px);
  transform: translateX(95px);
  color: #fff;
}
.pricing-switcher .fieldset {
  display: inline-block;
  position: relative;
  padding: 20px 8px;
  border-radius: 15px;
  background: #fff;
  width: 100%;
  max-width: 205px;
}
.pricing-switcher .fieldset.mnt-ac .rs-mnt,
.pricing-switcher .fieldset.mnt-acs .rs-yrs {
  color: #fff !important;
}
.pricing-switcher input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.pricing-switcher label {
  position: relative;
  z-index: 1;
  display: inline-block;
  float: left;
  cursor: pointer;
  font-size: 16px;
  color: #3E3E3E;
  font-weight: 400;
  margin: 0;
  width: 50%;
}
.no-js .pricing-switcher {
  display: none;
}
.pricing-list {
  margin: 2em 0 0;
  list-style: none;
}
.pricing-list > li {
  position: relative;
}
@media only screen and (min-width: 992px) {
  .pricing-list:after {
    content: "";
    display: table;
    clear: both;
  }
  .pricing-list > li {
    width: 33.333%;
    float: left;
  }
  .has-margins .pricing-list > li {
    width: 32.3333333333%;
    float: left;
    margin-right: 1.5%;
  }
  .has-margins .pricing-list > li:last-of-type {
    margin-right: 0;
  }
}
.pricing-wrapper {
  position: relative;
  list-style: none;
  margin-left: 20px;
  margin-right: 20px;
}
.touch .pricing-wrapper {
  -webkit-perspective: 2000px;
  -moz-perspective: 2000px;
  perspective: 2000px;
}
.pricing-wrapper.is-switched .is-visible {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-animation: rotate 0.5s;
  -moz-animation: rotate 0.5s;
  animation: rotate 0.5s;
}
.pricing-wrapper.is-switched .is-hidden {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-animation: rotate-inverse 0.5s;
  -moz-animation: rotate-inverse 0.5s;
  animation: rotate-inverse 0.5s;
  opacity: 0;
}
.pricing-wrapper.is-switched .is-selected {
  opacity: 1;
}
.pricing-wrapper.is-switched.reverse-animation .is-visible {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-animation: rotate-back 0.5s;
  -moz-animation: rotate-back 0.5s;
  animation: rotate-back 0.5s;
}
.pricing-wrapper.is-switched.reverse-animation .is-hidden {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-animation: rotate-inverse-back 0.5s;
  -moz-animation: rotate-inverse-back 0.5s;
  animation: rotate-inverse-back 0.5s;
  opacity: 0;
}
.pricing-wrapper.is-switched.reverse-animation .is-selected {
  opacity: 1;
}
.pricing-wrapper .is-visible {
  position: relative;
  z-index: 5;
}
.pricing-wrapper .is-hidden {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.pricing-wrapper .is-selected {
  z-index: 3 !important;
}
@media only screen and (min-width: 1050px) {
  .pricing-wrapper > li::before {
    content: '';
    position: absolute;
    z-index: 6;
    left: -1px;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 50%;
    width: 1px;
  }
  .pricing-wrapper > li::after {
    display: none;
  }
  .has-margins .pricing-wrapper > li,
  .has-margins .exclusive .pricing-wrapper > li {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }
  :nth-of-type(1) > .pricing-wrapper > li::before {
    display: none;
  }
  .has-margins .pricing-wrapper > li {
    border-radius: 4px 4px 6px 6px;
  }
  .has-margins .pricing-wrapper > li::before {
    display: none;
  }
}
@media only screen and (min-width: 1500px) {
  .full-width .pricing-wrapper > li {
    padding: 2.5em 0;
  }
}
.no-js .pricing-wrapper .is-hidden {
  position: relative;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  margin-top: 1em;
}
@media only screen and (min-width: 1050px) {
  .exclusive .pricing-wrapper > li::before {
    display: none;
  }
  .exclusive + li .pricing-wrapper > li::before {
    display: none;
  }
}
.pricing-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.is-switched .pricing-body {
  overflow: hidden;
}
.pricing-body {
  overflow-x: visible;
  padding-bottom: 20px;
}
.pricing-features {
  width: 600px;
}
.pricing-features li {
  width: 100px;
  float: left;
  padding: 1.6em 1em;
  font-size: 1.5rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.has-margins .exclusive .pricing-features li {
  margin: 0;
}
.has-margins .pricing-footer {
  padding-bottom: 0;
}
.select {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  color: transparent;
}
.select {
  position: static;
  display: inline-block;
  height: auto;
  padding: 1.3em 2em;
  color: #1bbc9d;
  border-radius: 8px;
  border: 2px solid #1bbc9d;
  font-size: 1.4rem;
  text-indent: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all .6s;
  width: 70%;
}
.no-touch .select:hover {
  background-color: #1bbc9d;
  color: #ffffff;
}
.exclusive .select {
  background-color: #1bbc9d;
  color: #ffffff;
}
.no-touch .exclusive .select:hover {
  background-color: #24e0ba;
}
.secondary-theme .exclusive .select {
  background-color: #1bbc9d;
}
.no-touch .secondary-theme .exclusive .select:hover {
  background-color: #112e3c;
}
.has-margins .select {
  display: block;
  padding: 1.7em 0;
  border-radius: 0 0 4px 4px;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
  }
}
@-moz-keyframes rotate {
  0% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(180deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(200deg);
    -moz-transform: perspective(2000px) rotateY(200deg);
    -ms-transform: perspective(2000px) rotateY(200deg);
    -o-transform: perspective(2000px) rotateY(200deg);
    transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
}
@-webkit-keyframes rotate-inverse {
  0% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
}
@-moz-keyframes rotate-inverse {
  0% {
    -moz-transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
}
@keyframes rotate-inverse {
  0% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(20deg);
    -moz-transform: perspective(2000px) rotateY(20deg);
    -ms-transform: perspective(2000px) rotateY(20deg);
    -o-transform: perspective(2000px) rotateY(20deg);
    transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}
@-webkit-keyframes rotate-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
  }
}
@-moz-keyframes rotate-back {
  0% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(-180deg);
  }
}
@keyframes rotate-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-200deg);
    -moz-transform: perspective(2000px) rotateY(-200deg);
    -ms-transform: perspective(2000px) rotateY(-200deg);
    -o-transform: perspective(2000px) rotateY(-200deg);
    transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
}
@-webkit-keyframes rotate-inverse-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
}
@-moz-keyframes rotate-inverse-back {
  0% {
    -moz-transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
}
@keyframes rotate-inverse-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-20deg);
    -moz-transform: perspective(2000px) rotateY(-20deg);
    -ms-transform: perspective(2000px) rotateY(-20deg);
    -o-transform: perspective(2000px) rotateY(-20deg);
    transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}


.pd-58 {
	padding-right: 58px;
}

@media (max-width: 991px) {
	.full-width-header .rs-header {
    position: relative;
    background: #D4AA59;
    border: 4px solid rgba(198, 153, 68, 0.23);
    padding: 0;
}
}

@media (max-width: 786px) {
	.sli-he {
    font-size: 27px;
}
.hiddenmobile {
    display: none;
}
.oggbx {
	margin-bottom: 20px;
}
.rowhead h4 {
    font-size: 18px;
}
h4.srpkhe2 {
	display: none;
}
.al-lef {
    text-align: center;
}
.al-ri {
    text-align: center;
}
.investsq2 {
    margin-bottom: 40px;
}
.whtbx {
    margin-bottom: 20px;
}
.bluer {
	font-size: 14px;
}
.yelr {
	font-size: 14px;
}


.fillorg::before {
    
    bottom: -10px;
    left: -10px;
}
.fillorg2::before {
    bottom: -10px;
    right: -10px;
}
.brdor {
    padding: 20px;
}
	.ser-he {
    font-size: 27px;
}
h4.srpkhe3 {
	font-size: 22px;
}
h4.srpkhe {
	font-size: 22px;
}
.readon.started.phone-number.normal-blue  {
	width: 222px;
}
img.gari {
	width: 250px;
}
img.gari-ar {
	width: 250px;
}
.pd-58 {
	padding-right: 0;
}
.mbilecenter {
	text-align: center;
}
button.cpn {
    margin-left: 0;
}
.pdmb-10 {
	    padding-bottom: 10px;
}
.span.drn {
	font-size: 18px;
}
.orgtb {
    border: 2px solid #C69944;
    border-radius: 30px;
}
.tbb-he {
    font-size: 26px;
}
.rs-tab-main.tab-style3 .tab-content .tab-pane {
	padding: 20px;
}
h3.blio {
	margin: 14px 0;
}
ul.cnt2 li {
	padding: 3px;
}
.rs-footer.footer-main-home.footer-style1 .footer-top {
    padding: 50px 0 30px;
}
}