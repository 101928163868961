/* General styles */
.gallery-container {
  display: grid;
  gap: 16px;
  padding: 16px;
}

.detail-label span{
  font-size: 17px;
  display: inline-block;
  color: black;
}

.detail-label {
  display: flex;
  align-items: center;
}

.detail-icon {
  margin-right: 5px; /* Adjust the spacing between the icon and the text as needed */
}

.detail-text {
  font-size: 17px;
  color: black;
}

.detail-label{
  font-size: 16px;
  color: rgb(162, 161, 161);
}

/* Desktop styles */
@media (min-width: 769px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .card-container {
    box-shadow: rgba(78, 78, 78, 0.14) 0px 3px 10px;
    margin: 10px;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
  }
  .card-container:hover {
    transform: scale(1.05);
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-container {
    width: 100%;
    margin-bottom: 16px;
  }
}

.card-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  position: relative;
}

.card-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #e0e0e0;
}

.add-to-favorite {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  gap: 8px;
}

.card-details {
  padding: 16px;
  background-color: #ffffff;
}

.price{
  font-weight: bold;
  font-size: 20px;
  color: black;
}
.card-title {
  font-size: 1.25rem;
  margin-bottom: 8px;
}

.product-status,
.detail-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.button-container {
  display: flex;
  gap: 8px;
}

.buy-button,
.details-button {
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.buy-button {
  background-color: #ffc107;
}

.buy-button:hover {
  background-color: #ffb300;
}

.details-button {
  background-color: #007bff;
}

.details-button:hover {
  background-color: #0056b3;
}




.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: #fff;
}

.pagination button:hover {
  background-color: #007bff;
  color: #fff;
}


.card-title{
  overflow: hidden;
  max-height: 35px;
}