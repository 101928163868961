
@media (max-width: 768px) {
.number_bid_price_en{
    position: absolute;
    top: -93px;
    right: 15px;
}
.number_bid_button_en{
    position: absolute;
    top: -25px;
    right: 10px;
}
.number_bid_count_en{
    margin-bottom: 25px;
}
.number_bid_price_ar{
    position: absolute;
    top: -93px;
    left: -70px;
}
.number_bid_button_ar{
    position: absolute;
    top: -40px;
    left: -100px;
}
.number_bid_count_ar{
    margin-bottom: 0px;
}
}

.number-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 8px;
    text-align: center;
  }
  
  .number-card-header {
    margin-bottom: 16px;
  }
  
  .number-card-location {
    font-size: 14px;
    color: #666;
  }
  
  .number-card-number {
    font-size: 24px;
    font-weight: bold;
  }
  
  .number-card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .number-card-price {
    font-size: 18px;
    color: #333;
  }
  
  .buy-now-button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .buy-now-button:hover {
    background-color: #444;
  }


  .number-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 8px;
    text-align: center;
    width: 200px;
  }
  
  .number-card-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .number-card-header {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  
  .number-card-location {
    font-size: 14px;
    color: #666;
  }
  
  .number-card-number {
    font-size: 24px;
    font-weight: bold;
  }
  
  .number-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .number-card-price {
    font-size: 18px;
    color: #333;
    margin-bottom: 16px;
  }
  
  .buy-now-button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .buy-now-button:hover {
    background-color: #444;
  }

.container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.centered-image {
    max-width: 100%;
    height: auto;
}
