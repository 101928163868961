.banner_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    /* Optional background color for the banner container */
  }
  
  .banner1 {
    max-width: 100%;
    /* Adjust width as needed */
    background-color: #fff;
    /* Banner background color */
    border: 1px solid #ccc;
    /* Optional border */
    border-radius: 5px;
    /* Optional border radius */
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Optional shadow */
  }
  
  .banner_image {
    width: 100%;
    /* Ensure the image covers the entire banner width */
    height: auto;
    /* Maintain aspect ratio */
    display: block;
  }
  
  .banner-content {
    padding: 20px;
  }
  
   .button-container {
    display: flex;
    max-width: 270px;
  }
  