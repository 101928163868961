.d-inline-block-status {
    display: inline-block !important;
    width: 70%;
}

.d-inline-block {
    display: inline-block !important;
    width: 45%;
}

select.bg-danger {
    color: #fff !important;
}

.bg-success {
    background-color: #31CE36 !important;
}

select.bg-success {
    color: #fff !important;
}

.bg-danger {
    background-color: #F25961 !important;
}

.custom-select {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .custom-select-dropdown {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
    cursor: pointer;
  }
  
  .custom-select-icon {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 12px;
    color: #333;
  }