	/*
  	Flaticon icon font: Flaticon
  	Creation date: 10/09/2019 11:56
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("Flaticon.eot");
  src: url("Flaticond41d.eot?#iefix") format("embedded-opentype"),
       url("Flaticon.woff2") format("woff2"),
       url("Flaticon.woff") format("woff"),
       url("Flaticon.ttf") format("truetype"),
       url("Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-best:before { content: "\f100"; }
.flaticon-flower:before { content: "\f101"; }
.flaticon-timer:before { content: "\f102"; }
.flaticon-suitcase:before { content: "\f103"; }
.flaticon-chess-piece:before { content: "\f104"; }
.flaticon-clock:before { content: "\f105"; }
.flaticon-time-call:before { content: "\f106"; }
.flaticon-phone:before { content: "\f107"; }
.flaticon-call:before { content: "\f108"; }
.flaticon-email:before { content: "\f109"; }
.flaticon-location:before { content: "\f10a"; }
.flaticon-send:before { content: "\f10b"; }
.flaticon-bag:before { content: "\f10c"; }
.flaticon-supermarket:before { content: "\f10d"; }
.flaticon-basket:before { content: "\f10e"; }
.flaticon-shopping-bag:before { content: "\f10f"; }
.flaticon-right-arrow:before { content: "\f110"; }
.flaticon-left-arrow:before { content: "\f111"; }
.flaticon-back:before { content: "\f112"; }
.flaticon-right-arrow-1:before { content: "\f113"; }
.flaticon-next:before { content: "\f114"; }
.flaticon-back-1:before { content: "\f115"; }
.flaticon-upload:before { content: "\f116"; }
.flaticon-next-1:before { content: "\f117"; }
.flaticon-ui:before { content: "\f118"; }
.flaticon-next-2:before { content: "\f119"; }
.flaticon-back-2:before { content: "\f11a"; }
.flaticon-play-button:before { content: "\f11b"; }
.flaticon-play-button-1:before { content: "\f11c"; }
.flaticon-picture:before { content: "\f11d"; }
.flaticon-image:before { content: "\f11e"; }
.flaticon-gallery:before { content: "\f11f"; }
.flaticon-pictures:before { content: "\f120"; }
.flaticon-unlink:before { content: "\f121"; }
.flaticon-link:before { content: "\f122"; }
.flaticon-link-1:before { content: "\f123"; }
.flaticon-link-2:before { content: "\f124"; }
.flaticon-speaker:before { content: "\f125"; }
.flaticon-speaker-1:before { content: "\f126"; }
.flaticon-picture-1:before { content: "\f127"; }
.flaticon-picture-2:before { content: "\f128"; }
.flaticon-right-quote:before { content: "\f129"; }
.flaticon-error:before { content: "\f12a"; }
.flaticon-cross:before { content: "\f12b"; }
.flaticon-search:before { content: "\f12c"; }
.flaticon-shopping-bag-1:before { content: "\f12d"; }
.flaticon-eye:before { content: "\f12e"; }
.flaticon-user:before { content: "\f12f"; }
.flaticon-user-1:before { content: "\f130"; }
.flaticon-clock-1:before { content: "\f131"; }
.flaticon-comment:before { content: "\f132"; }
.flaticon-folder:before { content: "\f133"; }
.flaticon-price-tag:before { content: "\f134"; }