.newcont {
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally center the content */
    justify-content: center; /* Vertically center the content */
  }

.btn-new1{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.btn-new2{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}