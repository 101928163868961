.styles_auctionsLayoutList__2WnvP {
  position: relative;
  width: calc(100% - 376px);
  flex: auto;
  padding: 0 0 32px;
}

.styles_ListHeaderContainer__Wj9_d {
  width: 100%;
  height: 104px;
  max-width: 1450px;
  padding: 10px 16px 10px 24px;
  border-radius: 8px;
  box-shadow: 0 5px 15px 0 rgba(78, 78, 78, .14);
  border-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.styles_ListHeaderContainer__Wj9_d div {
  line-height: 30px;
}

.styles_ListHeaderContainer__Wj9_d p {
  font-size: 16px !important;
  font-weight: 700;
  font-family: sfbd;
  color: #3b3a4b;
}

.styles_ListHeaderContainer__Wj9_d h1 {
  color: #3b3a4b;
  line-height: 30px;
  margin: 0;
  -webkit-text-stroke-width: 0;
  -webkit-text-stroke-color: transparent;
}

.styles_ListHeaderContainer__Wj9_d .styles_ListHeaderImagesContainer__T7mFd {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.styles_auctionsLayoutList__2WnvP .styles_filterContainer__mUPQ6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 17px;
}

.styles_ListHeaderContainer__dy9Lq {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.styles_ListHeaderContainer__dy9Lq .styles_flex__MxPTO {
  display: flex;
  align-items: center;
  gap: 4px;
}

.styles_ListHeaderContainer__dy9Lq .styles_flex__MxPTO img {
  width: 27px;
  height: 27px;
}

.styles_ListHeaderContainer__dy9Lq .styles_font18_500__c6Jn4 {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5d5d5d;
}

.styles_wrapper__Esa60 {
  display: flex;
  gap: 3px;
  margin-inline-start: 2px;
}

.styles_wrapper__Esa60 .styles_item__jnNtb {
  text-align: center;
}

.styles_wrapper__Esa60 .styles_item__jnNtb .styles_number__v738w {
  font-family: sfbd;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0b0b0b;
}

.styles_SearchSort__idHUq .styles_DisFlex__3n4ez {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.styles_DisFlex2__R2wG2 {
  display: flex;
  justify-content: space-between;
}

.styles_main__f3mw5 {
  margin: 0 auto;
  position: relative;
}

.styles_hasSearch__5k8YO {
  display: flex;
  align-items: center;
  box-shadow: 0 5px 25px 0 rgba(78, 78, 78, .15);
  border-radius: 6px;
}

.styles_hasSearch__5k8YO .styles_formControl__Kj_hE {
  padding: 0 40px 0 16px;
}

.styles_hasSearch__5k8YO .styles_imgContain__e5H3K {
  height: 40px;
  border: 1px solid #fff !important;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #fff !important;
  padding: 0 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_hasSearch__5k8YO .styles_formControlFeedback__grud3 {
  width: 17px;
  height: 17px;
}

.styles_btnFilter__loYk_ {
  display: none;
}

.styles_btnSort__oU457 {
  margin-left: 1rem;
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #fff;
  font-size: 15px;
  color: #3f3f3f;
  cursor: pointer;
  font-weight: 500;
  height: 40px;
  padding: 1px 20px;
  box-shadow: 0 5px 25px 0 rgba(78, 78, 78, .15);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_HiddenSm__SEE0f {
  display: block;
  font-weight: 400;
  margin-left: 9px;
  font-size: 15px;
  color: #93999d;
}

.styles_SearchSort__idHUq .styles_toggleBtns__a_eC4 {
  display: flex;
}

.styles_btnSort2__TQ_3_ {
  margin-left: 13px;
  background: #e51937;
  font-size: 15px;
  color: #3f3f3f;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  box-shadow: 0 5px 25px 0 rgba(78, 78, 78, .15);
}

.styles_btnSortFilter__Io_Si {
  margin-left: 11px;
  background: #fff;
  font-size: 15px;
  color: #3f3f3f;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  box-shadow: 0 5px 25px 0 rgba(78, 78, 78, .15);
  border-radius: 6px;
}

@media (max-width: 1260px) {
  .styles_btnSort__oU457 {
    padding: 0;
    width: 40px;
    height: 40px;
  }

  
}