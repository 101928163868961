.new {
  width: 100%;
  display: flex;

  .newContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: rgb(35, 30, 30);
        font-size: 30px;
      }

      .left {
        flex: 1;
        text-align: center;

        img {
          width: 200px;
          height: 130px;
          border-radius: 10%;
          object-fit: cover;
          margin-left: 30%;
          margin-top: 3% ;
          box-shadow: 0 5px 25px rgba(8, 8, 11, 0.141);
        }
      }

      .right {
        flex: 2;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            width: 40%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: 0;
              border-bottom: 1px solid gray;
            }

            input:focus {
              outline: none;
            }
          }

          button {
            width: 150px;
            padding: 10px;
            border: none;
            background-color: teal;
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
    }
  }
}


.expand-form-btn {
  margin: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

.expand-button {
  display: block;
  width: 100%;
  padding: 20px 30px;
  background-color: white;
  color: black;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 400;
  color: #007bff;
  text-decoration: none;

  &:hover {
    background-color: #0056b3;
  }
}

.submit-btn {
  width: 150px;
  padding: 10px;
  border: none;
  background-color: teal;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin: 3%;
}