.autocomplete-container {
    position: relative;
    z-index: 1000; /* Ensure this is higher than other elements */
  }
  
  .autocomplete-input {
    width: 100%;
    height: 40px;
    font-size: 16px;
    margin-bottom: 20px;
    position: relative;
    z-index: 19999;
  }
  
  .google-map-container {
    position: relative;
    z-index: 1; /* Ensure map is behind the autocomplete */
  }
  

  body .modal{
    z-index: 100 !important;
  }